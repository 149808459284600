import * as React from 'react';

import { clean } from '~/src/shared/ui/styles/helpers';
import { AngleDown } from '~/src/shared/ui/icons/angles';

import './select.css';
import { CircleExclamationSolid } from '~/src/shared/ui/icons/circle-exclamation.tsx';

interface SelectProps
  extends React.DetailedHTMLProps<
    React.SelectHTMLAttributes<HTMLSelectElement>,
    HTMLSelectElement
  > {
  label: string;
  invalid?: boolean;
  helpText?: string;
}

export const Select = React.forwardRef(function Select(
  props: SelectProps,
  ref: React.ForwardedRef<HTMLSelectElement>,
) {
  const { name, label, className, required, invalid, helpText, ...rest } =
    props;

  const labelId = `${name}__label`;
  const errorId = `${name}__error`;

  return (
    <label htmlFor={name} className={clean(`_select-label ${className}`)}>
      <div
        id={labelId}
        className={clean(
          `_label-text ${required ? '_required' : ''} ${invalid ? 'text-danger-500' : 'text-gray-900'}`,
        )}
      >
        {label}
      </div>
      <div className="relative">
        <select
          id={name}
          name={name}
          aria-describedby={errorId}
          className={`_select _clear-select-appearance ${invalid ? 'border-danger-500' : ''}`}
          ref={ref}
          required={required}
          {...rest}
        />
        <AngleDown
          className={`${invalid ? 'right-9' : 'right-3'} pointer-events-none`}
          aria-hidden
        />
      </div>
      {helpText && <div className="_help-text">{helpText}</div>}
      {invalid && <CircleExclamationSolid className="right-3" />}
      <div id={errorId} className="_error-message" />
    </label>
  );
});
