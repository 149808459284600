import * as React from 'react';

import { AngleLeft, AngleRight } from '~/src/shared/ui/icons/angles.tsx';
import { range } from 'lodash-es';
import { Logger } from '~/src/shared/service/logger';
import usePaginationWindow from '~/src/shared/ui/components/pagination/hooks/use-pagination-window.ts';
import './pagination.css';
import { clean } from '~/src/shared/ui/styles/helpers.ts';

interface PaginationProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  amountOfPages: number;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
}

export function Pagination(props: PaginationProps) {
  const { amountOfPages, className, currentPage, setCurrentPage } = props;
  const [start, end] = usePaginationWindow(amountOfPages, currentPage);

  function changePage(page: number) {
    if (page < 1 || page > amountOfPages) {
      Logger.warn('Invalid page number');
    }

    setCurrentPage(page);
  }

  return (
    <div className={clean(`_pagination ${className}`)}>
      <button
        className="_arrow"
        onClick={() => changePage(currentPage - 1)}
        disabled={currentPage === 1}
      >
        <AngleLeft />
      </button>
      {range(start, end + 1).map((page, index) => (
        <button
          className={`${currentPage != page ? '_inactive' : ''}`}
          key={index}
          onClick={() => changePage(page)}
        >
          {page}
        </button>
      ))}
      <button
        className="_arrow"
        onClick={() => changePage(currentPage + 1)}
        disabled={currentPage === amountOfPages}
      >
        <AngleRight />
      </button>
    </div>
  );
}
