import { useQuery } from '@tanstack/react-query';
import { query } from '~/src/shared/api/request-config.ts';
import { paths } from '~/src/shared/api/api.schema.ts';
import { useParams } from 'react-router-dom';
import { Tab, Tabs } from '~/src/shared/ui/components/tabs/tabs.tsx';
import { useLocation, useNavigate } from 'react-router-dom';
import { routes } from '~/src/routes.ts';

import '~/src/customers/styles/customer-detail.page.css';
import { Menu, MenuOption } from '~/src/shared/ui/components/menu/menu.tsx';
import Pencil from '~/src/shared/ui/icons/pencil.tsx';
import ToggleOff from '~/src/shared/ui/icons/toggle-off.tsx';
import Trash from '~/src/shared/ui/icons/trash.tsx';
import { Breadcrumbs } from '~/src/shared/ui/components/breadcrumbs/breadcrumbs.tsx';
import { Colors } from '~/src/shared/ui/styles/const.ts';
import { ContactModal } from '~/src/contacts/components/contact-modal.component.tsx';
import { ContactInformation } from '~/src/contacts/components/contact-information.component.tsx';
import { Button } from '~/src/shared/ui/components';
import { AngleLeft } from '~/src/shared/ui/icons/angles.tsx';
import Divider from '~/src/shared/ui/components/divider/divider.tsx';
import { Vendor } from '~/src/vendors/vendors.type';
import { Customer } from '~/src/customers/customers.type';
import { Contact } from '../contacts.type';

// Helper interface for this module
interface ParentMap {
  name: string;
  editRoute: string;
  backRoute: string;
}

// Function to get the parent map based on parent type
function getParentMap(
  parent: Required<Vendor | Customer>,
  contact: Required<Contact>,
): ParentMap {
  if (parent.type === 'vendor') {
    return {
      name: parent.name,
      editRoute: routes.editVendorContact.getPath(parent.id, contact.id),
      backRoute: routes.vendorInfo.getPath(parent.id),
    };
  } else {
    return {
      name: parent.companyName,
      editRoute: routes.editCustomerContact.getPath(parent.id, contact.id),
      backRoute: routes.customerInfo.getPath(parent.id),
    };
  }
}

export function ContactDetail() {
  const { parentId, contactId } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const parentType = pathname.includes('/vendors/')
    ? 'vendor'
    : pathname.includes('/customers/')
      ? 'customer'
      : null;

  if (!parentType) throw new Error('Unrecognized parent type for contact.');

  const { data: parent } = useQuery({
    ...query(paths[parentType], { params: { id: parentId as string } }),
  });

  const contactPath =
    parentType === 'vendor' ? paths.vendorContact : paths.customerContact;
  const { data: contact, refetch: refetchContact } = useQuery({
    ...query(contactPath, {
      params: {
        parentId: parentId as string,
        contactId: contactId as string,
      },
    }),
  });

  if (!contact || !parent) {
    return (
      <div className="flex items-center justify-center w-full">
        Loading contact...
      </div>
    );
  }

  parent.type = parentType;
  const parentMap = getParentMap(parent, contact);

  return (
    <div className="_page">
      <header>
        <div>
          <Breadcrumbs idMapping={{ [parent.id]: parentMap.name }} />
          <h1>
            {contact.firstName} {contact.lastName}
          </h1>
        </div>

        <div>
          <Menu>
            <MenuOption
              action={() => navigate(parentMap.editRoute)}
              icon={<Pencil />}
              label="Edit"
            />
            <MenuOption
              action={() => {}}
              icon={<ToggleOff color={Colors.danger['500']} />}
              label="Mark Inactive"
              color="danger"
            />
            <Divider />
            <MenuOption
              action={() => {}}
              icon={<Trash color={Colors.danger['500']} />}
              label="Remove Contact"
              color="danger"
            />
          </Menu>
        </div>
      </header>

      <Button
        className="_secondary"
        onClick={() => navigate(parentMap.backRoute)}
      >
        <div className="flex gap-2">
          <AngleLeft />
          <span>Back to contacts</span>
        </div>
      </Button>

      <div className="_container">
        <Tabs>
          <Tab label="Contact Information">
            <ContactInformation parent={parent} contact={contact} />
          </Tab>
          <Tab label="Orders">Orders detail</Tab>
          <Tab label="Quotes">Quotes detail</Tab>
          <Tab label="Work History">Orders detail</Tab>
        </Tabs>
      </div>

      {pathname.includes('edit') ? (
        <ContactModal
          refetchContacts={refetchContact}
          parent={parent}
          contact={contact}
        />
      ) : null}
    </div>
  );
}
