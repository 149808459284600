import { QuoteControl } from '~/src/quotes/pages/quote-building.page.tsx';
import { useToggle } from '~/src/shared/hooks/use-toggle.ts';
import * as React from 'react';
import {
  DynamicTableColumn,
  TableRow,
} from '~/src/shared/ui/components/table/types.ts';
import { SetUpFee } from '~/src/quotes/quotes.type.ts';
import { mutation, request } from '~/src/shared/api/request-config.ts';
import { quotesPaths } from '~/src/quotes/quotes.schema.ts';
import { useMutation } from '@tanstack/react-query';
import { paths } from '~/src/shared/api/api.schema.ts';
import { Button, Input } from '~/src/shared/ui/components';
import { SymbolInput } from '~/src/shared/ui/components/input/input.tsx';
import Floppy from '~/src/shared/ui/icons/floppy.tsx';
import { Colors } from '~/src/shared/ui/styles/const.ts';
import Trash from '~/src/shared/ui/icons/trash.tsx';
import { AngleDown } from '~/src/shared/ui/icons/angles.tsx';
import { clean } from '~/src/shared/ui/styles/helpers.ts';
import { Conditionally } from '~/src/shared/ui/components/switch/switch.ts';
import { DynamicTable } from '~/src/shared/ui/components/table/dynamic-table.tsx';

export default function SetUpFeesContainer(props: QuoteControl) {
  const { quote, refetchQuote } = props;

  const [expanded, toggleExpanded] = useToggle(true);

  function onRowChange(
    event: React.ChangeEvent<HTMLInputElement>,
    row: TableRow<SetUpFee>,
  ) {
    const name = event.target.name;
    const value = name === 'amount' ? event.target.value : event.target.checked;

    request(quotesPaths.updateQuoteSetUpFee, {
      params: { quoteId: quote.id, setupFeeId: row.id },
    })
      .fn({ [name]: value })
      .then(() => refetchQuote());
  }

  const { mutate: createSetUpFeeMutation } = useMutation(
    mutation(paths.createQuoteSetUpFee, {
      params: { id: quote.id },
    }),
  );

  const [newSetUpFee, setNewSetUpFee] = React.useState<React.JSX.Element>();

  function cancelSetUpFeeCreation() {
    setNewSetUpFee(undefined);
  }

  const columns = [
    { field: 'name', headerName: 'Set Up Fee Name', width: 'w-full' },
    {
      field: 'amount',
      headerName: 'Dollar Amount',
      formatType: 'input_currency',
      onUpdate: onRowChange,
    },
  ] as DynamicTableColumn<Partial<SetUpFee>>[];

  const footerRow = (
    <tr>
      <td className="py-4">
        <div className="flex items-center justify-between">
          <Button className="_outline" onClick={addSetUpFeeToTable}>
            Add New Set Up Fee
          </Button>
          <p className="font-medium">Set Up Fees Total</p>
        </div>
      </td>
      <td>
        <SymbolInput
          className="w-32"
          symbol="$"
          name="setUpFeesTotal"
          label="Set Up Fees Total"
          placeholder="-"
          hideLabel
          disabled
          value={quote.totalSetUpFees}
        />
      </td>
      <td />
    </tr>
  );

  function addSetUpFeeToTable() {
    const NewSetUpFee = () => {
      type SetUpFeeField = 'name' | 'amount';

      const [name, setName] = React.useState('');
      const [amount, setAmount] = React.useState('');
      const [errors, setErrors] = React.useState<string[]>([]);

      const setUpFee = { name, amount };

      function checkErrors() {
        let error = false;

        for (const name of ['name', 'amount']) {
          if (setUpFee[name as SetUpFeeField].trim().length === 0) {
            setErrors((err) => [...err, name]);
            error = true;
          } else {
            setErrors((err) => err.filter((e) => e !== name));
          }
        }

        return error;
      }

      function createSetUpFee() {
        const isInvalid = checkErrors();

        if (!isInvalid) {
          const discount = {
            name,
            amount,
            isActive: true,
          };

          createSetUpFeeMutation(discount, {
            onSuccess: () => {
              refetchQuote();
              cancelSetUpFeeCreation();
            },
          });
        }
      }

      return (
        <tr>
          <td className="w-full">
            <Input
              name="setUpFeeName"
              className="w-full"
              label="New Set Up Fee"
              placeholder="Set Up Fee Name"
              onChange={(e) => setName(e.target.value)}
              invalid={errors.includes('name')}
              hideLabel
            />
          </td>
          <td>
            <SymbolInput
              type="number"
              className="w-32"
              symbol="$"
              placeholder="0"
              onChange={(e) => setAmount(e.target.value)}
              name="setUpFeeAmount"
              label="Set Up Fee Amount"
              invalid={errors.includes('amount')}
              hideLabel
            />
          </td>
          <td className="flex flex-row justify-center">
            <Button className="_icon-button" onClick={createSetUpFee}>
              <Floppy color={Colors.success['500']} />
            </Button>

            <Button className="_icon-button" onClick={cancelSetUpFeeCreation}>
              <Trash color={Colors.danger['500']} />
            </Button>
          </td>
        </tr>
      );
    };

    setNewSetUpFee(<NewSetUpFee />);
  }

  return (
    <div className="_quote-container">
      <div className="flex items-center justify-between">
        <p className="text-lg">Set Up Fees</p>
        <div className="flex gap-20">
          <p>
            <span className="text-gray-300">Total:</span> $
            {quote.totalSetUpFees}
          </p>
          <AngleDown
            className={clean(
              `hover:cursor-pointer ${expanded ? 'rotate-180' : ''}`,
            )}
            onClick={toggleExpanded}
          />
        </div>
      </div>

      <Conditionally when={expanded}>
        {() => (
          <DynamicTable<SetUpFee>
            columns={columns}
            rows={quote.setUpFees as Required<SetUpFee>[]}
            loading={false}
            onRowClick={() => {}}
            customRows={newSetUpFee ? [newSetUpFee, footerRow] : [footerRow]}
            onToggle={onRowChange}
          />
        )}
      </Conditionally>
    </div>
  );
}
