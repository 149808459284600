import * as React from 'react';
import Fuse from 'fuse.js';
import ReactDOM from 'react-dom';

import './dropdown.css';
import { AngleDown } from '~/src/shared/ui/icons/angles.tsx';
import { isUndefined, upperFirst } from 'lodash-es';
import { clean } from '~/src/shared/ui/styles/helpers.ts';
import { CircleExclamationSolid } from '~/src/shared/ui/icons/circle-exclamation.tsx';
import { usePopup } from '~/src/shared/hooks/use-popup.ts';
import Xmark from '../../icons/xmark';
import Divider from '../divider/divider';

export interface DropDownOption {
  value: string | null;
  title: string;
}

interface DropDownProps {
  title?: string;
  fixedTitle?: boolean;
  options: DropDownOption[];
  onChange: (value: string | null) => void;
  defaultValue?: string | null;
  name: string;
  className?: string;
  hideErrors?: boolean;
  hideLabel?: boolean;
  required?: boolean;
  label: string;
  invalid?: boolean;
  disabled?: boolean;
  onClear?: () => void;
  value?: string | null;
  searchable?: boolean;
}

export function DropDown(props: DropDownProps) {
  const {
    options,
    label,
    required,
    hideLabel,
    hideErrors,
    defaultValue,
    className,
    name,
    fixedTitle,
    title,
    invalid,
    onChange,
    disabled,
    onClear,
    value,
    searchable = false,
  } = props;

  if (fixedTitle && !title) {
    throw new Error('You have to provide a title if fixedTitle === true.');
  }

  const { ref, expanded, toggleExpanded, onPopupDisplay } =
    usePopup<HTMLDivElement>();
  const [selectedOption, setSelectedOption] = React.useState(
    options.find((option) => option.value === defaultValue),
  );
  const [searchQuery, setSearchQuery] = React.useState('');
  const [filteredOptions, setFilteredOptions] = React.useState(options);

  const hasSelection = !isUndefined(selectedOption);
  const isClearable = hasSelection && !isUndefined(onClear);

  const labelId = `${name}__label`;
  const errorId = `${name}__error`;

  const onKeyUp = React.useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === 'Space') {
        event.stopPropagation();
        toggleExpanded();
      }
    },
    [toggleExpanded],
  );

  React.useEffect(() => {
    if (!isUndefined(value))
      setSelectedOption(options.find((option) => option.value === value));
  }, [value, options]);

  React.useEffect(() => {
    if (defaultValue) {
      onChange(defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const fuse = new Fuse(options, { keys: ['title'], threshold: 0.3 });
    if (searchQuery) {
      setFilteredOptions(fuse.search(searchQuery).map((result) => result.item));
    } else {
      setFilteredOptions(options);
    }
  }, [searchQuery, options]);

  const onClearHandler = () => {
    if (isClearable) onClear();
    setSelectedOption(undefined);
    onChange(null);
  };

  const onOptionClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      const newValue = event.currentTarget.getAttribute('data-value');
      onChange(newValue);
      setSelectedOption(
        options.find((option) => option.value === newValue) as DropDownOption,
      );
      setSearchQuery('');
      toggleExpanded();
    },
    [onChange, toggleExpanded, options],
  );

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  return (
    <label
      className={clean(`_dropdown min-w-20 ${className}`)}
      aria-expanded={expanded}
      aria-haspopup
      htmlFor={name}
    >
      <div ref={ref}>
        <div
          id={labelId}
          className={clean(
            `_label-text ${required ? '_required' : ''} ${hideLabel ? 'hidden' : ''} ${invalid ? 'text-danger-500' : 'text-gray-900'}`,
          )}
        >
          {label}
        </div>

        {isClearable && <Xmark onClick={onClearHandler} />}

        <button
          name={name}
          type="button"
          onClick={toggleExpanded}
          onKeyUp={onKeyUp}
          className={`${invalid ? 'border-danger-500' : 'border-gray-100'}`}
          role="menu"
          disabled={disabled}
        >
          <span
            className={clean(
              `truncate leading-5 mr-4 ${selectedOption ? 'text-gray-900' : 'text-gray-500'}`,
            )}
          >
            {fixedTitle
              ? upperFirst(title)
              : selectedOption
                ? selectedOption?.title
                : upperFirst(title)}
          </span>
          <div>
            {!isClearable && !disabled && (
              <AngleDown className={`${expanded ? 'rotate-180' : ''}`} />
            )}

            {invalid && (
              <span className="right-3">
                <CircleExclamationSolid />
              </span>
            )}
          </div>
        </button>
        {expanded &&
          ReactDOM.createPortal(
            <ul ref={onPopupDisplay} className="_dropdown-popup">
              {searchable && (
                <>
                  <li className="_li-search">
                    <input
                      type="text"
                      value={searchQuery}
                      onChange={onSearchChange}
                      placeholder="Type to search..."
                      className="_dropdown-search-input"
                    />
                  </li>
                  <Divider />
                </>
              )}
              {filteredOptions.map((option, index) => (
                // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                <li key={index} role="menuitem">
                  <button
                    type="button"
                    data-value={option.value}
                    onClick={onOptionClick}
                  >
                    {option.title}
                  </button>
                </li>
              ))}
            </ul>,
            document.body,
          )}
        {!hideErrors && <div id={errorId} className="_error-message" />}
      </div>
    </label>
  );
}
