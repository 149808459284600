import * as React from 'react';

// Custom useEffect hook that only triggers on updates, not on initial mount
export default function useUpdateEffect(
  effect: () => void,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dependencies: any[] = [],
) {
  const isInitialMount = React.useRef(true);

  React.useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      return effect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
}
