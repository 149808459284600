import * as React from 'react';

import './dropdown-multi.css';
import { AngleDown } from '~/src/shared/ui/icons/angles.tsx';
import { capitalize } from 'lodash-es';
import { clean } from '~/src/shared/ui/styles/helpers.ts';
import { CircleExclamationSolid } from '~/src/shared/ui/icons/circle-exclamation.tsx';
import { usePopup } from '~/src/shared/hooks/use-popup.ts';
import ReactDOM from 'react-dom';

export interface DropDownOptionRequired {
  value: string;
  title: string;
}

interface DropDownMultiSelectProps {
  title?: string;
  options: DropDownOptionRequired[];
  onChange: (value: string) => void;
  defaultValue?: string;
  name: string;
  className?: string;
  hideErrors?: boolean;
  hideLabel?: boolean;
  required?: boolean;
  label: string;
  invalid?: boolean;
}

/**
 * Renders a multi-select dropdown component that allows users to select multiple options.
 * The selected options' values are maintained in the order they appear in the `options` prop.
 * The component also supports accessibility features such as keyboard navigation and ARIA attributes.

 * @example
 * // Using DropDownMultiSelect with required options and a callback
 * <DropDownMultiSelect
 *   name="weekdays"
 *   label="Select Weekdays"
 *   options={[
 *     { value: 'monday', title: 'Monday' },
 *     { value: 'tuesday', title: 'Tuesday' },
 *     { value: 'wednesday', title: 'Wednesday' },
 *   ]}
 *   defaultValue="monday, wednesday"
 *   onChange={selectedValues => console.log('Selected values: ', selectedValues)}
 *   required
 * />
 */
export function DropDownMultiSelect(props: DropDownMultiSelectProps) {
  const {
    options,
    label,
    required,
    hideLabel,
    hideErrors,
    defaultValue,
    className,
    name,
    title,
    invalid,
    onChange,
  } = props;

  const { ref, expanded, toggleExpanded, onPopupDisplay } =
    usePopup<HTMLDivElement>();
  const initialSelectedValues = React.useMemo(() => {
    if (defaultValue) {
      const defaultValues = defaultValue
        .split(',')
        .map((value) => value.trim().toUpperCase());
      return defaultValues.filter((value) =>
        options.some((option) => option.value === value),
      );
    }
    return [];
  }, [defaultValue, options]);

  const [selectedValues, setSelectedValues] = React.useState<string[]>(
    initialSelectedValues,
  );

  // Disable this as adding `onChange` to dependency array causes infinite rerendering.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => onChange(selectedValues.join(', ')), [selectedValues]);

  const onOptionClick = (selectedOption: DropDownOptionRequired) => {
    if (selectedValues.includes(selectedOption.value)) {
      setSelectedValues((currentValues) =>
        currentValues.filter((value) => value !== selectedOption.value),
      );
    } else {
      setSelectedValues((currentValues) =>
        [...currentValues, selectedOption.value]
          .filter((value) => options.some((option) => option.value === value))
          .sort(
            (a, b) =>
              options.findIndex((option) => option.value === a) -
              options.findIndex((option) => option.value === b),
          ),
      );
    }
  };

  return (
    <label
      className={clean(`_dropdown ${className}`)}
      aria-expanded={expanded}
      aria-haspopup
      htmlFor={name}
    >
      <div ref={ref}>
        <div
          id={`${name}__label`}
          className={clean(
            `_label-text ${required ? '_required' : ''} ${hideLabel ? 'hidden' : ''} ${invalid ? 'text-danger-500' : 'text-gray-900'}`,
          )}
        >
          {label}
        </div>
        <button
          name={name}
          type="button"
          onClick={toggleExpanded}
          className={`${invalid ? 'border-danger-500' : 'border-gray-100'}`}
          role="menu"
        >
          <span className="leading-5 mr-4 text-gray-900">
            {title
              ? capitalize(title)
              : options
                  .filter((option) => selectedValues.includes(option.value))
                  .map((option) => option.title)
                  .join(', ') || 'Select...'}
          </span>
          <AngleDown className={`${expanded ? 'rotate-180' : ''}`} />

          {invalid && (
            <span className="right-3">
              <CircleExclamationSolid />
            </span>
          )}
        </button>
        {expanded &&
          ReactDOM.createPortal(
            <ul ref={onPopupDisplay} className="_dropdown-popup">
              {options.map((option, index) => (
                // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                <li key={index} role="menuitem">
                  <button
                    type="button"
                    data-value={option.value}
                    onClick={() => onOptionClick(option)}
                    className={
                      selectedValues.includes(option.value) ? 'selected' : ''
                    }
                  >
                    {option.title}
                  </button>
                </li>
              ))}
            </ul>,
            document.body,
          )}
        {!hideErrors && (
          <div id={`${name}__error`} className="_error-message" />
        )}
      </div>
    </label>
  );
}
