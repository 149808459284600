import * as React from 'react';

type ScreenSize = 'xl' | 'lg' | 'md' | 'sm' | 'xs';

interface UseScreenSizeInterface {
  screenSize: ScreenSize;
  windowWidth: number;
  windowHeight: number;
}

export default function useScreenSize(): UseScreenSizeInterface {
  const [screenSize, setScreenSize] = React.useState<ScreenSize>('md');

  React.useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;

      switch (true) {
        case width >= 1280:
          setScreenSize('xl');
          break;

        case width >= 1024:
          setScreenSize('lg');
          break;

        case width >= 768:
          setScreenSize('md');
          break;

        case width >= 640:
          setScreenSize('sm');
          break;

        default:
          setScreenSize('xs');
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return {
    screenSize,
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight,
  };
}
