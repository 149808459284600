export const MainPages = [
  'home',
  'customers',
  'inventory',
  'vendors',
  'purchase-orders',
  'quotes',
] as const;

export type Page = (typeof MainPages)[number];

export interface FlowData {
  currentPage: Page;
}
