import { LoggerBackendAlias } from '~/src/shared/service/logger/types.ts';

type Environment = 'test' | 'local' | 'nonprod';

const GlobalConfig = {};

interface AppConfig {
  apiUrl: string;
  logBackend?: LoggerBackendAlias;
  logDebounceTime?: number;
  logGroupName?: string;
}

export function getEnvironment(): Environment {
  if (process.env.NODE_ENV === 'test') {
    return 'test';
  }

  const host = window.location.host;
  const envs = ['local', 'nonprod'];
  return (envs.find((env) => host.includes(env)) as Environment) ?? 'prod';
}

const Configs: Record<Environment, AppConfig> = {
  test: {
    apiUrl: 'http://test-api.com',
  },
  local: {
    apiUrl: 'http://localhost:8000/api',
    logBackend: 'console',
  },
  nonprod: {
    apiUrl: 'https://api.nonprod.erp.luckyyougifts.com/api',
    logBackend: 'console',
  },
};

export const env = getEnvironment();
export const Config = { ...GlobalConfig, ...Configs[env] };
