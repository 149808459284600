import { IconProps } from '~/src/shared/ui/icons/types.ts';

export default function Trash(props: IconProps) {
  const { color } = props;

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.53125 3.5C8.4375 3.5 8.375 3.5625 8.3125 3.625L7.71875 4.5H12.25L11.6562 3.625C11.625 3.5625 11.5312 3.5 11.4375 3.5H8.53125ZM14.0625 4.5H14.4688H16H16.25C16.6562 4.5 17 4.84375 17 5.25C17 5.6875 16.6562 6 16.25 6H15.875L15.125 16.1562C15.0312 17.2188 14.1875 18 13.125 18H6.84375C5.78125 18 4.9375 17.2188 4.84375 16.1562L4.09375 6H3.75C3.3125 6 3 5.6875 3 5.25C3 4.84375 3.3125 4.5 3.75 4.5H4H5.5H5.90625L7.0625 2.78125C7.375 2.3125 7.9375 2 8.53125 2H11.4375C12.0312 2 12.5938 2.3125 12.9062 2.78125L14.0625 4.5ZM14.375 6H5.59375L6.34375 16.0625C6.375 16.3125 6.59375 16.5 6.84375 16.5H13.125C13.375 16.5 13.5938 16.3125 13.625 16.0625L14.375 6Z"
        fill={color ?? 'black'}
      />
    </svg>
  );
}
