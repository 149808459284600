import {
  DropDown,
  DropDownMultiSelect,
  Input,
} from '~/src/shared/ui/components';
import { useNavigate } from 'react-router-dom';
import {
  PaymentTermsOptions,
  USAStates,
  WeekdayOptions,
} from '~/src/shared/consts.ts';
import { Select } from '~/src/shared/ui/components/select/select.tsx';
import { FormModal } from '~/src/shared/ui/components/modal/modal.tsx';

import '~/src/shared/ui/components/modal/form-modal.css';
import { fetcher, FetchError } from '~/src/shared/service/fetch/fetcher.ts';
import { paths } from '~/src/shared/api/api.schema.ts';
import { Logger } from '~/src/shared/service/logger';
import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query';
import { flattenErrors, makeDropDownOptions } from '~/src/shared/utils.ts';
import { CircleExclamation } from '~/src/shared/ui/icons/circle-exclamation.tsx';
import Xmark from '~/src/shared/ui/icons/xmark.tsx';
import { Colors } from '~/src/shared/ui/styles/const.ts';
import { capitalize } from 'lodash-es';
import { prependHttps } from '~/src/shared/utils.ts';
import { Vendor } from '../vendors.type';
import { useCustomForm } from '~/src/shared/hooks/use-custom-form';

interface VendorModalProps {
  refetchVendors: (
    options?: RefetchOptions | undefined,
  ) => Promise<QueryObserverResult<Vendor[] | Vendor, Error>>;
  vendor?: Vendor;
}

const emptyVendor = { vendorAddress: { state: '' } } as Partial<Vendor>;

export function VendorModal(props: VendorModalProps) {
  const { refetchVendors, vendor } = props;

  const navigate = useNavigate();
  const {
    errorMessage,
    showError,
    register,
    controlled,
    handleSubmit,
    setFormError,
    isDirty,
    isSubmitting,
  } = useCustomForm<Vendor>({
    initialData: vendor ?? emptyVendor,
    validate: (data) => {
      if (data.website) {
        data.website = prependHttps(data.website);
      }

      if (data.vendorAddress?.state?.length === 0) {
        setFormError(['state', 'Select a state.']);
        return false;
      }

      return data;
    },
    handleSubmit: async (data) => {
      const vendorsPath = paths.vendors.split(' ')[1];
      const path = vendor ? `${vendorsPath}/${vendor.id}/` : vendorsPath;

      try {
        const response = vendor
          ? await fetcher.put(path, data)
          : await fetcher.post(path, data);

        refetchVendors();
        Logger.info('Vendor successfully created.', { data: response });
        navigate(-1);
      } catch (error) {
        if (error instanceof FetchError && error.status === 400) {
          const firstError = Object.entries(flattenErrors(error.data))[0];

          setFormError(
            firstError ?? [
              '',
              `There was an error during vendor ${vendor ? 'update' : 'creation'}.`,
            ],
          );
        } else {
          Logger.error('Error on creating/updating vendor.', {
            error: (error as Error).message,
          });
        }
      }
    },
  });

  return (
    <FormModal
      name="vendor"
      title={vendor ? 'Update Vendor' : 'Add new vendor'}
      id="vendor-modal"
      data-testid="vendor-modal"
      onSubmit={() => handleSubmit}
      isDirty={isDirty}
      isSubmitting={isSubmitting}
      floating
    >
      {showError && (
        <div className="_error-balloon">
          <CircleExclamation />
          <p>{capitalize(errorMessage)}</p>
          <button onClick={() => setFormError(['', ''])}>
            <Xmark color={Colors.danger['200']} size="md" />
          </button>
        </div>
      )}

      <Input
        label="Vendor name"
        placeholder="Vendor name"
        required
        {...register('name')}
      />

      <div className="w-full border-t-[1px] border-gray-100" />

      <div>
        <p className="_section-title mb-4">Address</p>
        {vendor && (
          <Input
            label="Vendor address id"
            type="hidden"
            value={vendor?.vendorAddress?.id}
            {...register('vendorAddress.id')}
            hideLabel
          />
        )}

        <p className="text-xs font-medium text-gray-500">Address</p>

        <div className="_section">
          <div className="_row">
            <Input
              label="Address line 1"
              placeholder="1234 Name St."
              required
              {...register('vendorAddress.addressLine1')}
            />
            <Input
              label="Address line 2"
              placeholder="Apartment, Suite"
              {...register('vendorAddress.addressLine2')}
            />
          </div>

          <div className="_row">
            <Input
              label="City"
              placeholder="City name"
              required
              {...register('vendorAddress.city')}
            />
            <Select label="State" required {...register('vendorAddress.state')}>
              <option value="" disabled>
                Select a state
              </option>
              {Object.entries(USAStates).map(([stateKey, stateName], index) => (
                <option
                  key={index}
                  value={stateKey}
                  className="!hover:bg-black"
                >
                  {stateName}
                </option>
              ))}
            </Select>
          </div>

          <div>
            <Input
              label="Zipcode"
              placeholder="92210"
              required
              {...register('vendorAddress.zipcode')}
            />
          </div>
        </div>
      </div>

      <div className="w-full border-t-[1px] border-gray-100" />

      <Input
        label="Phone Number"
        placeholder="111-111-1111"
        {...register('phoneNumber')}
        required
      />

      <Input
        label="Website"
        placeholder="https://"
        {...register('website')}
        required
      />

      <Input
        label="Account Number"
        placeholder="1234567890"
        {...register('accountNumber')}
      />

      <div className="w-full border-t-[1px] border-gray-100" />

      <div className="_row">
        <DropDown
          title="Net 30, Net 60, Net 90, Net 120, Due on Receipt"
          label="Payment Terms"
          className="flex-1"
          // onChange={(value) => setValue('paymentTerms', value as PaymentTerms)}
          {...controlled('paymentTerms')}
          options={makeDropDownOptions(PaymentTermsOptions)}
        />

        <DropDownMultiSelect
          className="flex-1"
          label="Delivery Days"
          {...controlled('deliveryDays')}
          options={makeDropDownOptions(WeekdayOptions)}
        />
      </div>

      <Input
        label="Minimum Order"
        type="number"
        step=".01"
        min="0"
        placeholder="50.10"
        {...register('minimumOrder')}
      />

      <div className="w-full border-t-[1px] border-gray-100" />
    </FormModal>
  );
}
