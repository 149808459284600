import React, { PropsWithChildren, useState } from 'react';
import './tabs.css';

interface TabProps extends PropsWithChildren {
  label: string;
}

export function Tab(props: TabProps) {
  const { children } = props;

  return <div className="_tab">{children}</div>;
}

interface TabsProps {
  children: React.ReactElement<TabProps>[];
}

export function Tabs(props: TabsProps) {
  const { children } = props;
  const [activeTab, setActiveTab] = useState(0);

  function applyTabStyle(tabIndex: number) {
    const inactive = 'text-gray-500 hover:text-gray-900';
    const active = 'border-b-black border-b-2';

    return tabIndex === activeTab ? active : inactive;
  }

  React.Children.forEach(children, (child) => {
    if (React.isValidElement(child) && child.type !== Tab) {
      throw new Error(
        'Tabs component only accepts Tab components as children.',
      );
    }
  });

  return (
    <div>
      <div className="_tabs">
        {React.Children.map(children, (child, index) => {
          if (React.isValidElement(child)) {
            return (
              <button
                className={applyTabStyle(index)}
                onClick={() => setActiveTab(index)}
              >
                {child.props.label}
              </button>
            );
          }
        })}
      </div>

      <div className="py-4">{children[activeTab]}</div>
    </div>
  );
}
