import { Table } from '~/src/shared/ui/components/table/table.tsx';
import {
  TableDynamicFilter,
  TableColumn,
} from '~/src/shared/ui/components/table/types.ts';
import { useLocation, useNavigate } from 'react-router-dom';
import { paths } from '~/src/shared/api/api.schema.ts';
import { query } from '~/src/shared/api/request-config.ts';
import { useQuery } from '@tanstack/react-query';
import { routes } from '~/src/routes.ts';
import { Button } from '~/src/shared/ui/components';
import { InventoryCategoryOptions } from '../consts';
import { InventoryListItem } from '../inventory.type';
import { DropDownOption } from '~/src/shared/ui/components/select/dropdown';
import { InventoryModal } from '../components/inventory-item-modal.component';

export function Inventory() {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { data: vendors, fetchStatus: vendorFetchStatus } = useQuery({
    ...query(paths.vendors),
  });

  const {
    data,
    fetchStatus: inventoryFetchStatus,
    refetch,
  } = useQuery({
    ...query(paths.inventoryItems),
  });

  const isLoading =
    vendorFetchStatus === 'fetching' || inventoryFetchStatus === 'fetching';

  const columns = [
    { field: 'name', headerName: 'Item Name' },
    { field: 'skuCost', headerName: 'Unit Cost' },
    { field: 'unitOfMeasure', headerName: 'UOM' },
    { field: 'piecesPerPound', headerName: 'Pieces Per Pound' },
    { field: 'sellUnitsOnHand', headerName: 'Sell Units on Hand' },
  ] as TableColumn<Partial<InventoryListItem>>[];

  const categoryOptions = Object.entries(InventoryCategoryOptions).map(
    ([key, value]) => ({ value: key, title: value }),
  ) as DropDownOption[];
  categoryOptions.unshift({ value: null, title: 'All' });

  const dynamicFilters = {
    'On Hand Filters': [
      { value: null, title: 'All', fn: () => true },
      {
        value: 'On Hand',
        title: 'On Hand',
        fn: (row) => (row.sellUnitsOnHand as number) > 0,
      },
      {
        value: 'Sell Me List',
        title: 'Sell Me List',
        fn: (row) => row.sellMe,
      },
      {
        value: 'Out Of Stock',
        title: 'Out Of Stock',
        fn: (row) => (row.sellUnitsOnHand as number) === 0,
      },
    ],
  } as TableDynamicFilter<InventoryListItem>;

  return (
    <div className="_page">
      <header>
        <div>
          <h1>Inventory Items</h1>
        </div>

        <div className="_header-actions">
          <Button onClick={() => navigate(routes.newInventory.path)}>
            Add New Inventory Item
          </Button>
        </div>
      </header>

      <Table<Required<Partial<InventoryListItem>>>
        name="Inventory Item"
        columns={columns}
        rows={data ?? []}
        loading={isLoading}
        defaultRowsPerPage={50}
        onRowClick={(item) => navigate(routes.inventoryDetail.getPath(item.id))}
        showSearchInput
        filters={{
          category: categoryOptions,
        }}
        dynamicFilters={dynamicFilters}
      />

      {pathname.includes('new') ? (
        <InventoryModal refetchInventory={refetch} vendors={vendors} />
      ) : null}
    </div>
  );
}
