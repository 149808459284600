import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { flowStore } from '~/src/state/flow.store.ts';
import { useRecoilValue } from 'recoil';
import { flowState } from '~/src/state/atoms/flow.atom.ts';
import { Page, MainPages } from '~/src/state/flow.types.ts';
import { Logger } from '~/src/shared/service/logger';
import useUpdateEffect from '~/src/shared/hooks/use-update-effect.ts';

export default function useNavigationBar() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const currentPath = pathname.split('/')[1];
  const { currentPage } = useRecoilValue(flowState);

  React.useEffect(() => {
    if (MainPages.includes(currentPath as Page)) {
      flowStore.setPage(currentPath as Page);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useUpdateEffect(() => {
    Logger.info(`Navigating to '${currentPage}'.`);

    if ((currentPath as Page) != currentPage) {
      navigate(currentPage, { replace: true });
    }
  }, [currentPage]);

  function navigateTo(newOption: Page) {
    if (newOption === currentPage) {
      return;
    }

    flowStore.setPage(newOption);
  }

  function inputProps(value: Page) {
    return {
      checked: value === currentPage,
      value: value,
      onClick: () => navigateTo(value),
      onChange() {
        // This is so React doesn't complain about the input being
        // "read only" since we already control it via onClick.
      },
    };
  }

  return inputProps;
}
