import Xmark from '~/src/shared/ui/icons/xmark.tsx';
import { clean } from '~/src/shared/ui/styles/helpers.ts';
import './badge.css';
import { Colors } from '~/src/shared/ui/styles/const.js';

interface BadgeProps {
  content: string;
  onClose?: () => void;
  color: 'info' | 'success' | 'gray' | 'warning';
}

export function Badge(props: BadgeProps) {
  const { content, color, onClose } = props;
  // Tailwind doesn't work if we build class names dynamically
  const colorVariants = {
    info: 'bg-info-50 border-info-100 text-info-700',
    success: 'bg-success-50 border-success-100 text-success-700',
    gray: 'bg-gray-50 border-gray-100 text-gray-700',
    warning: 'bg-warning-50 border-warning-100 text-warning-700',
  };

  return (
    <div className={clean(`_badge ${colorVariants[color]}`)}>
      <span>{content}</span>
      {onClose && (
        <button onClick={onClose}>
          <Xmark color={Colors.info['200']} />
        </button>
      )}
    </div>
  );
}
