import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import dayjs from 'dayjs';
import { nanoid } from 'nanoid';
import App from './App.tsx';
import { SessionStorage } from '~/src/shared/service/storage';
import './index.css';
import { env } from './shared/config.ts';

if (env != 'local') {
  Sentry.init({
    dsn: 'https://a2e5142d5c9cf9678eaf6c66663e4c2b@o4507386715766784.ingest.us.sentry.io/4507386719109120',
    environment: env,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [
      'localhost',
      /^https:\/\/api\.nonprod\.erp\.luckyyougifts\.com/,
      /^https:\/\/api\.prod\.erp\.luckyyougifts\.com/,
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

// Define sessionId for logging/error tracking purposes
const sessionId = `${dayjs().format('YYYY-MM-DD')}/${nanoid()}`;
SessionStorage.write('SessionId', sessionId);

ReactDOM.createRoot(document.getElementById('root')!).render(<App />);
