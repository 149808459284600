import { IconProps } from '~/src/shared/ui/icons/types.ts';

export function CheckList(props: IconProps) {
  return (
    <svg
      width="25"
      height="20"
      viewBox="0 0 25 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.21875 2.375L4.84375 6.125C4.625 6.34375 4.35938 6.46875 4.04688 6.5C3.70312 6.5 3.42188 6.39063 3.20312 6.17188L1.32812 4.29688C0.890625 3.76563 0.890625 3.23437 1.32812 2.70312C1.85938 2.26563 2.39062 2.26563 2.92188 2.70312L3.95312 3.73438L6.53125 0.875C7.03125 0.40625 7.5625 0.375 8.125 0.78125C8.59375 1.28125 8.625 1.8125 8.21875 2.375ZM8.21875 9.875L4.84375 13.625C4.625 13.8438 4.35938 13.9688 4.04688 14C3.70312 14 3.42188 13.8906 3.20312 13.6719L1.32812 11.7969C0.890625 11.2656 0.890625 10.7344 1.32812 10.2031C1.85938 9.76562 2.39062 9.76562 2.92188 10.2031L3.95312 11.2344L6.53125 8.375C7.03125 7.90625 7.5625 7.875 8.125 8.28125C8.59375 8.78125 8.625 9.3125 8.21875 9.875ZM11.125 4.625C10.4375 4.5625 10.0625 4.1875 10 3.5C10.0625 2.8125 10.4375 2.4375 11.125 2.375H23.875C24.5625 2.4375 24.9375 2.8125 25 3.5C24.9375 4.1875 24.5625 4.5625 23.875 4.625H11.125H23.875H11.125ZM10 11C10.0625 10.3125 10.4375 9.9375 11.125 9.875H23.875C24.5625 9.9375 24.9375 10.3125 25 11C24.9375 11.6875 24.5625 12.0625 23.875 12.125H11.125C10.4375 12.0625 10.0625 11.6875 10 11ZM8.5 18.5C8.5625 17.8125 8.9375 17.4375 9.625 17.375H23.875C24.5625 17.4375 24.9375 17.8125 25 18.5C24.9375 19.1875 24.5625 19.5625 23.875 19.625H9.625C8.9375 19.5625 8.5625 19.1875 8.5 18.5ZM5.5 18.5C5.5 18.9375 5.35938 19.2969 5.07812 19.5781C4.79688 19.8594 4.4375 20 4 20C3.5625 20 3.20312 19.8594 2.92188 19.5781C2.64062 19.2969 2.5 18.9375 2.5 18.5C2.5 18.0625 2.64062 17.7031 2.92188 17.4219C3.20312 17.1406 3.5625 17 4 17C4.4375 17 4.79688 17.1406 5.07812 17.4219C5.35938 17.7031 5.5 18.0625 5.5 18.5Z"
        fill={props.color ?? 'black'}
      />
    </svg>
  );
}
