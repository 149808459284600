import * as React from 'react';

export default function Minus(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.625 8.5C12.625 8.82812 12.3672 9.0625 12.0625 9.0625H3.4375C3.10938 9.0625 2.875 8.82812 2.875 8.5C2.875 8.19531 3.10938 7.9375 3.4375 7.9375H12.0625C12.3672 7.9375 12.625 8.19531 12.625 8.5Z"
        fill="white"
      />
    </svg>
  );
}
