import { Button, DropDown, Input } from '~/src/shared/ui/components';
import { Datepicker } from '~/src/shared/ui/components/datepicker/datepicker';
import { Breadcrumbs } from '~/src/shared/ui/components/breadcrumbs/breadcrumbs';
import '~/src/purchase-orders/styles/purchase-orders-common.page.css';
import { ItemsTable } from '../components/items-table.component';
import Plus from '~/src/shared/ui/icons/plus';
import { PurchaseOrder, PurchaseOrderLineRow } from '../purchase-orders.type';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { paths } from '~/src/shared/api/api.schema';
import { query } from '~/src/shared/api/request-config.ts';
import { DropDownOption } from '~/src/shared/ui/components/select/dropdown';
import { useCustomForm } from '~/src/shared/hooks/use-custom-form';
import { useFieldArray } from 'react-hook-form';
import { AddItemToPurchaseOrderModal } from '../components/add-item-modal.component';
import { routes } from '~/src/routes';
import Loading from '~/src/shared/ui/components/loading/loading';
import { Logger } from '~/src/shared/service/logger';
import { FetchError, fetcher } from '~/src/shared/service/fetch/fetcher';
import useAlert from '~/src/shared/ui/components/alert/hooks/use-alert';
import { useRef } from 'react';
import { Vendor } from '~/src/vendors/vendors.type';

const prepareData = (
  data: Omit<PurchaseOrder, 'lines'>,
  lines: PurchaseOrderLineRow[],
) => {
  const mappedLines = lines?.map((l) => ({
    inventoryItemVendor: l.vendorInventoryItemId,
    quantity: l.qty,
    lineTotal: l.lineTotal,
    numberOfPallets: l.numberOfPallets,
  }));

  return {
    lines: mappedLines,
    submittedDate: data.submittedDate,
    branch: data.branch,
    contact: data.contact.id,
    deliveryByDate: data.deliveryByDate,
    orderedBy: data.orderedBy,
    notesForVendor: data.notesForVendor,
    internalNotes: data.internalNotes,
    total: lines
      .map((line) => line.lineTotal)
      .reduce((lineTotal, acc) => acc + lineTotal),
    vendor: data.vendor.id,
  };
};

export function PurchaseOrderCreate() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const alert = useAlert();
  const { vendorId } = useParams();
  const formRef = useRef<HTMLFormElement>(null);

  const { data: vendor, fetchStatus } = useQuery({
    ...query(paths.vendor, { params: { id: vendorId as string } }),
  });
  const { data: contacts } = useQuery({
    ...query(paths.vendorContacts, {
      params: { id: vendor?.id.toString() as string },
    }),
    enabled: Boolean(vendor?.id),
  });
  const { data: users } = useQuery({
    ...query(paths.users),
  });
  const { data: inventoryItems } = useQuery({
    ...query(paths.inventoryItems),
  });
  const vendorItems = inventoryItems?.filter((i) =>
    i.inventoryItemVendors.some((iv) => iv.vendor.id === vendor?.id),
  );

  const {
    register,
    handleSubmit,
    controlled,
    control,
    isSubmitting,
    getValues,
    setFormError,
  } = useCustomForm<
    Omit<PurchaseOrder, 'lines'> & { lines: PurchaseOrderLineRow[] }
  >({
    initialData: {},
    validate: (data) => {
      if (!formRef.current!.reportValidity()) return false;
      if (!data.contact) {
        setFormError(['contact', 'This field is required.']);
        return false;
      }
      if (!data.orderedBy) {
        setFormError(['orderedBy', 'This field is required.']);
        return false;
      }
      if (data.contact?.department && data.contact?.department != data.branch) {
        Logger.warn('Attempted submit of invalid contact for branch.', {
          contact: data.contact,
          branch: data.branch,
        });
        setFormError(['contact', 'Invalid contact for this branch.']);
        return false;
      }
      return data;
    },
    handleSubmit: async (data) => {
      data.vendor = vendor as Vendor;
      const preparedData = prepareData(data, lines);

      // TODO: handle updates?
      const purchaseOrderPath = paths.purchaseOrders.split(' ')[1];
      const path = purchaseOrderPath;

      try {
        const response = await fetcher.post(path, preparedData);
        Logger.info('Purchase Order successfully created/updated.', {
          data: response,
        });
        alert.showSuccess('Purchase Order successfully created.');
        navigate(routes.purchaseOrders.path);
      } catch (error) {
        if (error instanceof FetchError && error.status === 400) {
          setFormError([
            '',
            'There was an error during purchase order creation.',
          ]);
        } else {
          alert.showError(
            'Something went wrong. Please check the purchase order information and try again.',
          );
          Logger.error('Error on creating/updating purchase order.', {
            error: (error as Error).message,
          });
        }
      }
    },
  });

  const { fields: lines, append: appendLine } = useFieldArray({
    control,
    name: 'lines',
  });

  const noItems = lines.length == 0;
  if (contacts) {
    contacts.map((contact) => {
      contact['fullName'] = `${contact.firstName} ${contact.lastName}`;
    });
  }

  // Branch must be choosed first in case it is available.
  // Contacts must only be chosen from that branch.
  const branchOptions: DropDownOption[] =
    contacts
      ?.filter((c) => c.department)
      .map((c) => ({ value: c.department, title: c.department })) || [];
  const branchValue = getValues('branch');
  const contactOptions: DropDownOption[] =
    contacts
      ?.filter((c) => !branchValue || c.department == branchValue)
      .map((c) => ({ value: c.id.toString(), title: c.fullName })) || [];

  if (fetchStatus === 'fetching') return <Loading />;

  if (!vendor || !vendorId || !users) {
    // TODO: this is just to make typescript happy.
    return <>Something went wrong.</>;
  }
  if (!vendorItems) {
    return <>No items available for vendor.</>;
  }

  const userOptions = users.map((u) => {
    const title =
      u.firstName || u.lastName
        ? `${u.firstName} ${u.lastName}`
        : ((u.email || u.username) as string);
    return {
      title,
      value: u.username as string,
    };
  });

  const addButton = (
    <Button
      onClick={() =>
        navigate(routes.addItemPurchaseOrderPage.getPath(vendor.id))
      }
    >
      <Plus className="inline" /> Add Items to the PO
    </Button>
  );

  return (
    <form ref={formRef} id="purchase-orders-create-page" className="_page">
      <header>
        <div>
          <Breadcrumbs depth={1} />
          <h1>{vendor.name}</h1>
        </div>
        <div className="_header-actions">
          {noItems ? (
            addButton
          ) : (
            <Button
              type="submit"
              onClick={handleSubmit}
              disabled={isSubmitting}
            >
              Create Purchase Order
            </Button>
          )}
        </div>
      </header>

      <div className="_summary">
        <div className="_row">
          <Datepicker
            label="Purchase Order Date"
            {...register('submittedDate')}
            placeholder="mm/dd/yyyy"
            required
          />
          <DropDown
            label="Branch"
            title="Select a Branch"
            options={branchOptions}
            {...controlled('branch')}
            required={branchOptions.length > 0}
            disabled={branchOptions.length == 0}
          ></DropDown>
          <DropDown
            label="Contact"
            title={
              contactOptions.length > 0
                ? 'Select a Contact'
                : 'No contacts for this vendor.'
            }
            options={contactOptions}
            {...controlled('contact', {
              setValueAs: (contactId) =>
                contacts?.find((c) => c.id == (contactId as number)),
            })}
            required
            disabled={branchOptions.length > 0 && !branchValue}
          ></DropDown>
          <Datepicker
            label="Delivery By Date"
            placeholder="mm/dd/yyyy"
            {...register('deliveryByDate')}
          />
          <DropDown
            label="Ordered By"
            title="Select an employee"
            options={userOptions}
            {...controlled('orderedBy')}
            required
          ></DropDown>
        </div>
      </div>
      <div className="_row">
        <Input
          label="PO Notes for Vendor"
          placeholder="Write your notes here..."
          helpText="Shows on Purchase Order."
          {...register('notesForVendor')}
        />
      </div>

      <div className="_container">
        {noItems ? (
          <div className="_no-items">
            <p>There are no Inventory Items in this PO.</p>
            {addButton}
          </div>
        ) : (
          <ItemsTable
            purchaseOrderLines={lines as Required<PurchaseOrderLineRow>[]}
            onClickAdd={() =>
              navigate(routes.addItemPurchaseOrderPage.getPath(vendor.id))
            }
            readOnly={false}
          />
        )}
      </div>

      <div className="_row">
        <Input
          label="Internal Notes"
          placeholder="Write your notes here..."
          helpText="Does not show on Purchase Order."
          {...register('internalNotes')}
        />
      </div>
      {pathname.includes('add-item') ? (
        <AddItemToPurchaseOrderModal
          items={vendorItems}
          vendorId={vendorId}
          existingLines={lines as Required<PurchaseOrderLineRow>[]}
          onSubmit={(line) => appendLine(line)}
        />
      ) : null}
    </form>
  );
}
