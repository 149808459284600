/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import * as React from 'react';
import * as ReactDOM from 'react-dom';

import Xmark from '~/src/shared/ui/icons/xmark';
import { clean } from '~/src/shared/ui/styles/helpers';
import { Conditionally } from '~/src/shared/ui/components/switch/switch';

import './modal.css';
import { Button } from '~/src/shared/ui/components';
import { FormEventHandler } from 'react';
import { useNavigate } from 'react-router-dom';

interface ModalProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  close?: () => void;
  title?: string;
  className?: string;
  titleClassName?: string;
  width?: number;
  floating?: boolean;
}

export function Modal(props: ModalProps) {
  const {
    close,
    title,
    children,
    className,
    titleClassName,
    width,
    floating,
    ...rest
  } = props;
  const ref = React.useRef<HTMLDivElement>(null);

  const onClose = React.useCallback(
    (event: React.MouseEvent | React.KeyboardEvent) => {
      event.stopPropagation();
      if (close) {
        close();
      }
    },
    [close],
  );

  const onKeyUp = React.useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose(event);
      }
    },
    [onClose],
  );

  React.useEffect(() => {
    if (ref.current) {
      ref.current.focus();
    }
  }, [ref]);

  React.useEffect(() => {
    document.body.style.overflowY = 'hidden';

    return () => {
      document.body.style.overflowY = '';
    };
  }, []);

  return ReactDOM.createPortal(
    <div className={clean('_modal z-10')}>
      <div className="_backdrop" onClick={onClose} />
      <div
        ref={ref}
        className={`_content ${floating ? '!rounded-md' : ''} ${className}`.trim()}
        tabIndex={0}
        role="dialog"
        aria-labelledby="modal-title"
        onKeyUp={onKeyUp}
        style={{ width }}
        {...rest}
      >
        <div className="_header">
          <Conditionally when={Boolean(title)}>
            {() => (
              <h2
                id="modal-title"
                className={clean(`flex-1 ${titleClassName}`)}
              >
                {title}
              </h2>
            )}
          </Conditionally>
          <Conditionally when={close != null}>
            {() => (
              <button type="button" onClick={onClose} aria-label="Close dialog">
                <Xmark size="lg" aria-hidden />
              </button>
            )}
          </Conditionally>
        </div>

        <div className="px-1 grow overflow-auto">{children}</div>
      </div>
    </div>,
    document.body,
  );
}

interface FormModalProps extends ModalProps {
  name: string;
  onSubmit: () => FormEventHandler<HTMLFormElement>;
  actionName?: string;
  isDirty?: boolean;
  isUpdate?: boolean;
  isSubmitting: boolean;
  onSecondaryActionClick?: () => void;
  secondaryActionName?: string;
}

export function FormModal(props: FormModalProps) {
  const {
    onSubmit,
    children,
    actionName,
    isSubmitting,
    name,
    isDirty,
    isUpdate,
    onSecondaryActionClick,
    secondaryActionName,
    ...rest
  } = props;
  const [showConfirmation, setShowConfirmation] = React.useState(false);
  const navigate = useNavigate();
  const ref = React.useRef<HTMLDivElement>(null);

  function onActionClick() {
    if (ref.current) {
      ref.current.scrollTop = 0;
    }
  }

  function onAbandon() {
    setShowConfirmation(false);
    navigate(-1);
  }

  function onClose() {
    if (isDirty) {
      setShowConfirmation(true);
    } else {
      navigate(-1);
    }
  }

  return (
    <Modal className="_form-modal" close={onClose} {...rest}>
      <form onSubmit={onSubmit()} className="flex flex-col h-full">
        <div className="_form-items" ref={ref}>
          {children}
        </div>

        {
          <div
            className={`_modal-actions ${secondaryActionName ? 'justify-between' : 'justify-end'}`}
          >
            {secondaryActionName && (
              <Button
                onClick={onClose}
                className="_secondary"
                disabled={isSubmitting}
              >
                Cancel
              </Button>
            )}

            <div className="flex gap-4">
              <Button
                onClick={onSecondaryActionClick || onClose}
                className="_secondary"
                disabled={isSubmitting}
              >
                {secondaryActionName ?? 'Cancel'}
              </Button>
              <Button
                type="submit"
                onClick={onActionClick}
                disabled={isSubmitting}
              >
                {actionName ?? 'Save'}
              </Button>
            </div>
          </div>
        }
      </form>

      {showConfirmation && (
        <ConfirmationModal
          title={`Are you sure you want to abandon ${name} ${isUpdate ? 'update' : 'creation'}?`}
          actionName="Abandon"
          onAction={onAbandon}
          onCancel={() => setShowConfirmation(false)}
          close={() => setShowConfirmation(false)}
        >
          Your changes will be lost if you don&apos;t save them.
        </ConfirmationModal>
      )}
    </Modal>
  );
}

interface ConfirmationModalProps extends ModalProps {
  onAction: () => void;
  onCancel: () => void;
  title: string;
  actionName: string;
  destructive?: boolean;
}

export function ConfirmationModal(props: ConfirmationModalProps) {
  const {
    onCancel,
    onAction,
    title,
    actionName,
    children,
    destructive,
    ...rest
  } = props;

  return (
    <Modal title={title} className="!w-[500px] !gap-0 !h-auto" {...rest}>
      <div className="text-sm px-4">{children}</div>

      {
        <div className="_modal-actions justify-end gap-4 mt-8">
          <Button onClick={onCancel} className="_secondary">
            Cancel
          </Button>
          <Button className={destructive ? '_red' : ''} onClick={onAction}>
            {actionName}
          </Button>
        </div>
      }
    </Modal>
  );
}
