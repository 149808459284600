import { DynamicTable } from '~/src/shared/ui/components/table/dynamic-table.tsx';
import {
  DynamicTableColumn,
  TableRow,
} from '~/src/shared/ui/components/table/types.ts';
import { OrderComponent } from '~/src/orders/orders.type.ts';
import { useQuery } from '@tanstack/react-query';
import { query, request } from '~/src/shared/api/request-config.ts';
import { paths } from '~/src/shared/api/api.schema.ts';
import { Button } from '~/src/shared/ui/components';
import { useLocation, useNavigate } from 'react-router-dom';
import ComponentModal from '~/src/orders/components/component-modal.component.tsx';
import { routes } from '~/src/routes.ts';
import useAlert from '~/src/shared/ui/components/alert/hooks/use-alert.tsx';
import * as React from 'react';
import { SetUpFee } from '~/src/quotes/quotes.type.ts';

export default function ComponentsTab(props: { orderId: number }) {
  const { orderId } = props;
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const alert = useAlert();

  const {
    data: components,
    isLoading,
    refetch,
  } = useQuery({
    ...query(paths.orderComponents, { params: { id: orderId } }),
  });

  function deleteComponent(componentId: number) {
    request(paths.deleteOrderComponent, {
      params: { orderId, componentId },
    })
      .fn()
      .then(() => {
        refetch();
        alert.showSuccess('Component was deleted successfully.');
      });
  }

  const columns = [
    { field: 'inventoryItemVendor.name', headerName: 'Component Name' },
    {
      field: 'inventoryItemVendor.unitOfMeasure',
      headerName: 'Unit of Measure',
    },
    {
      field: 'quantity',
      headerName: 'Quantity',
      formatType: 'input_number',
      onUpdate: onRowChange,
      width: 'w-80',
    },
    {
      field: 'modifier',
      headerName: 'Modifier',
      formatType: 'input_number',
      onUpdate: onRowChange,
      width: 'w-80',
    },
  ] as unknown as DynamicTableColumn<Partial<OrderComponent>>[];

  function onRowChange(
    event: React.ChangeEvent<HTMLInputElement>,
    row: TableRow<SetUpFee>,
  ) {
    const name = event.target.name;
    const value = event.target.value;

    request(paths.partialUpdateOrderComponent, {
      params: { orderId, componentId: row.id },
    })
      .fn({ [name]: value })
      .then(() => refetch());
  }

  return (
    <div>
      <div className="flex justify-end mt-2 mb-8">
        <Button
          className="_secondary"
          onClick={() =>
            navigate(routes.newOrderComponent.getPath(props.orderId))
          }
        >
          Add Components
        </Button>
      </div>
      <DynamicTable<OrderComponent>
        columns={columns}
        rows={components ?? []}
        loading={isLoading}
        onRowClick={() => {}}
        onDeleteRow={(row) => deleteComponent(row.id)}
        deletableRow
      />

      {pathname.includes('add-component') && (
        <ComponentModal refetchComponents={refetch} />
      )}
    </div>
  );
}
