import { capitalize } from 'lodash-es';
import { paths } from '~/src/shared/api/api.schema.ts';
import { query } from '~/src/shared/api/request-config.ts';
import { useQuery } from '@tanstack/react-query';
import { Tab, Tabs } from '~/src/shared/ui/components/tabs/tabs.tsx';
import { OrderType } from '../orders.type.ts';
import { OrdersTable } from '../components/orders-table.component.tsx';

export function Orders() {
  const { data, fetchStatus } = useQuery({
    ...query(paths.orders),
  });

  const isLoading = fetchStatus === 'fetching';

  const getLabel = (orderType: (typeof OrderType)[number]) => {
    let label: string = orderType
      .replaceAll('sample', 'Samples')
      .replaceAll('order', 'Orders');
    label = label.replaceAll('_', ' ');
    return capitalize(label);
  };

  return (
    <div className="_page">
      <header>
        <div>
          <h1>Orders</h1>
        </div>
      </header>

      <Tabs>
        {OrderType.map((orderType, index) => (
          <Tab key={index} label={getLabel(orderType)}>
            <OrdersTable
              orders={data?.filter((o) => o.orderType === orderType) ?? []}
              isLoading={isLoading}
            />
          </Tab>
        ))}
      </Tabs>
    </div>
  );
}
