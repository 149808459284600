import { useMutation, useQuery } from '@tanstack/react-query';
import { mutation, query } from '~/src/shared/api/request-config.ts';
import { paths } from '~/src/shared/api/api.schema.ts';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import InfoPiece from '~/src/shared/ui/components/info-piece/info-piece.component';

import '~/src/customers/styles/customer-detail.page.css';
import { Menu, MenuOption } from '~/src/shared/ui/components/menu/menu.tsx';
import Copy from '~/src/shared/ui/icons/copy.tsx';
import Pencil from '~/src/shared/ui/icons/pencil.tsx';
import Trash from '~/src/shared/ui/icons/trash.tsx';
import { Breadcrumbs } from '~/src/shared/ui/components/breadcrumbs/breadcrumbs.tsx';
import { Button } from '~/src/shared/ui/components';
import { Colors } from '~/src/shared/ui/styles/const.ts';
import Divider from '~/src/shared/ui/components/divider/divider.tsx';
import { InventoryCategoryOptions } from '~/src/inventory/consts.ts';
import VendorsTable from '~/src/inventory/components/vendors-table.component.tsx';
import useAlert from '~/src/shared/ui/components/alert/hooks/use-alert.tsx';
import {
  deletion,
  deletionError,
} from '~/src/shared/ui/components/alert/models.tsx';
import useUpdateEffect from '~/src/shared/hooks/use-update-effect.ts';
import { routes } from '~/src/routes.ts';
import { InventoryModal } from '~/src/inventory/components/inventory-item-modal.component.tsx';
import useConfirmation from '~/src/shared/hooks/use-confirmation.tsx';
import { TruckRampBox } from '~/src/shared/ui/components/navigation-bar/icons';
import InventoryItemVendorModal from '~/src/inventory/components/inventory-item-vendor-modal.component.tsx';

export function InventoryDetail() {
  const { id } = useParams();
  const alert = useAlert();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { data: inventory, refetch: refetchInventory } = useQuery({
    ...query(paths.inventoryItem, { params: { id: id as string } }),
  });

  const { data: itemVendors, refetch: refetchVendors } = useQuery({
    ...query(paths.itemVendors, { params: { id: id as string } }),
  });

  const { mutate, status } = useMutation(
    mutation(paths.deleteInventoryItem, { params: { id: id as string } }),
  );

  const { confirmationModal, showConfirmation } = useConfirmation();

  useUpdateEffect(() => {
    if (status === 'success') {
      navigate(routes.inventory.path);
      alert.show(deletion(inventory?.name ?? 'Item'));
    } else if (status === 'error') {
      alert.show(deletionError(inventory?.name ?? 'Item'));
    }
  }, [status]);

  const deleteInventoryConfirmation = {
    title: `Are you sure you want to remove ${inventory?.name} from your items?`,
    bodyText: "This action can't be undone.",
    actionName: 'Remove',
    onAction: () => mutate(),
    isDelete: true,
  };

  if (!inventory) {
    return (
      <div className="flex items-center justify-center w-full">
        Loading inventory item...
      </div>
    );
  }

  return (
    <div className="_page">
      <header>
        <div>
          <Breadcrumbs />
          <h1>{inventory.name}</h1>
        </div>

        <div>
          <Button onClick={() => {}}>Inventory Documentary</Button>

          <Menu>
            <MenuOption
              action={() =>
                navigate(routes.editInventory.getPath(inventory.id))
              }
              icon={<Pencil />}
              label="Edit"
            />
            <MenuOption action={() => {}} icon={<Copy />} label="Copy Item" />
            <MenuOption
              action={() =>
                navigate(
                  routes.includeInventoryItemVendor.getPath(
                    parseInt(id as string),
                  ),
                )
              }
              icon={<TruckRampBox size="xs" />}
              label="Add Vendor"
            />
            <Divider />
            <MenuOption
              action={() => showConfirmation(deleteInventoryConfirmation)}
              icon={<Trash color={Colors.danger['500']} />}
              label="Remove"
              color="danger"
            />
          </Menu>
        </div>
      </header>

      <div className="flex gap-4">
        <InfoPiece
          description="Shows on Purchase Orders"
          label="Item name"
          content={inventory.name}
        />
        <InfoPiece
          description="Shows on Quotes"
          label="Description"
          content={inventory.description}
        />
        <InfoPiece
          description="Visible to line workers"
          label="Pack List Description"
          content={inventory.packListDescription}
        />
      </div>

      <div className="flex gap-4">
        <InfoPiece
          label="Inventory Category"
          content={InventoryCategoryOptions[inventory.category]}
        />
        <InfoPiece label="Qty on Hand" content={inventory.sellUnitsOnHand} />
        <div className="flex-1" />
      </div>

      <div className="flex gap-4">
        <InfoPiece label="Lenght" content={inventory.dimensions?.length} />
        <InfoPiece label="Depth" content={inventory.dimensions?.depth} />
        <InfoPiece label="Height" content={inventory.dimensions?.height} />
        <InfoPiece
          label="Pallet Count"
          content={inventory.dimensions?.palletCount}
        />
        <InfoPiece label="Weight" content={inventory.dimensions?.weight} />
      </div>

      <VendorsTable vendors={itemVendors ?? []} />

      {pathname.includes('edit') ? (
        <InventoryModal refetchInventory={refetchInventory} item={inventory} />
      ) : null}

      {pathname.includes('include-vendor') ? (
        <InventoryItemVendorModal
          item={inventory}
          refetchVendors={refetchVendors}
        />
      ) : null}

      {confirmationModal()}
    </div>
  );
}
