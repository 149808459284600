import { generatePath } from 'react-router-dom';

export const routes = {
  home: {
    path: '/home',
    label: 'Home page',
  },

  login: {
    path: '/login',
    label: 'Login',
  },
  loginCallback: {
    path: '/login-callback',
    label: 'Login',
  },

  customers: {
    path: '/customers',
    label: 'Customers Page',
  },
  newCustomer: {
    path: '/customers/new',
    label: 'Create new customer',
  },
  customerInfo: {
    path: '/customers/:id',
    getPath: (id: number) => generatePath(routes.customerInfo.path, { id }),
    label: 'Customer info',
  },
  editCustomer: {
    path: '/customers/:id/edit',
    getPath: (id: number) => generatePath(routes.editCustomer.path, { id }),
    label: 'Edit customer',
  },
  customerContacts: {
    path: '/customers/:id/contacts',
    getPath: (id: number) => generatePath(routes.customerContacts.path, { id }),
    label: 'Customer contacts list',
  },
  customerContactInfo: {
    path: '/customers/:parentId/contacts/:contactId',
    getPath: (parentId: number, contactId: number) =>
      generatePath(routes.customerContactInfo.path, { parentId, contactId }),
    label: 'Customer contact detail',
  },
  newCustomerContact: {
    path: '/customers/:id/new-contact',
    getPath: (id: number) =>
      generatePath(routes.newCustomerContact.path, { id }),
    label: 'Create new customer contact',
  },
  editCustomerContact: {
    path: '/customers/:parentId/contacts/:contactId/edit',
    getPath: (parentId: number, contactId: number) =>
      generatePath(routes.editCustomerContact.path, { parentId, contactId }),
    label: 'Edit customer contact',
  },

  vendors: {
    path: '/vendors',
    label: 'Vendors Page',
  },
  newVendor: {
    path: '/vendors/new',
    label: 'Create new vendor',
  },
  vendorInfo: {
    path: '/vendors/:id',
    getPath: (id: number) => generatePath(routes.vendorInfo.path, { id }),
    label: 'Vendor info',
  },
  editVendor: {
    path: '/vendors/:id/edit',
    getPath: (id: number) => generatePath(routes.editVendor.path, { id }),
    label: 'Edit vendor',
  },
  vendorContacts: {
    path: '/vendors/:id/contacts',
    getPath: (id: number) => generatePath(routes.customerContacts.path, { id }),
    label: 'Vendor contacts list',
  },
  vendorContactInfo: {
    path: '/vendors/:parentId/contacts/:contactId',
    getPath: (parentId: number, contactId: number) =>
      generatePath(routes.vendorContactInfo.path, { parentId, contactId }),
    label: 'Vendot contact detail',
  },
  newVendorContact: {
    path: '/vendors/:id/new-contact',
    getPath: (id: number) => generatePath(routes.newVendorContact.path, { id }),
    label: 'Create new vendor contact',
  },
  editVendorContact: {
    path: '/vendors/:parentId/contacts/:contactId/edit',
    getPath: (parentId: number, contactId: number) =>
      generatePath(routes.editVendorContact.path, { parentId, contactId }),
    label: 'Edit vendor contact',
  },
  inventory: {
    path: '/inventory',
    label: 'Inventory Page',
  },
  inventoryDetail: {
    path: '/inventory/:id',
    getPath: (id: number) => generatePath(routes.inventoryDetail.path, { id }),
    label: 'Inventory Detail Page',
  },
  includeInventoryItemVendor: {
    path: '/inventory/:id/include-vendor',
    getPath: (id: number) =>
      generatePath(routes.includeInventoryItemVendor.path, { id }),
    label: 'Create New Inventory Item Vendor',
  },
  editInventory: {
    path: '/inventory/:id/edit',
    getPath: (id: number) => generatePath(routes.editInventory.path, { id }),
    label: 'Edit Inventory',
  },
  newInventory: {
    path: '/inventory/new',
    label: 'Create new inventory item',
  },

  purchaseOrders: {
    path: '/purchase-orders',
    label: 'Purchase orders Page',
  },
  createPurchaseOrderFromOrdersModal: {
    path: '/purchase-orders/create-from-orders',
    label: 'Create Purchase Order Based on Orders',
  },
  createPurchaseOrderStandaloneModal: {
    path: '/purchase-orders/create-standalone',
    label: 'Create Stand Alone Purchase Order',
  },
  createPurchaseOrderPage: {
    path: '/purchase-orders/create/:vendorId',
    getPath: (vendorId: number) =>
      generatePath(routes.createPurchaseOrderPage.path, { vendorId }),
    label: 'Create Purchase Order',
  },
  addItemPurchaseOrderPage: {
    path: '/purchase-orders/create/:vendorId/add-item/:itemId?',
    getPath: (vendorId: number, itemId?: number) =>
      generatePath(routes.addItemPurchaseOrderPage.path, { vendorId, itemId }),
    label: 'Add Item to Purchase Order',
  },
  purchaseOrderInfo: {
    path: '/purchase-orders/:purchaseOrderId/',
    getPath: (purchaseOrderId: number) =>
      generatePath(routes.purchaseOrderInfo.path, { purchaseOrderId }),
    label: 'Purchase Order detail',
  },

  quotes: {
    path: '/quotes',
    label: 'Quotes Page',
  },
  newQuote: {
    path: '/quotes/new',
    label: 'Create Quote',
  },
  quoteBuilding: {
    path: '/quotes/:id',
    getPath: (id: number) => generatePath(routes.quoteBuilding.path, { id }),
    label: 'Quote Building Page',
  },
  quoteAddInventoryItems: {
    path: '/quotes/:id/add-items',
    getPath: (id: number) =>
      generatePath(routes.quoteAddInventoryItems.path, { id }),
    label: 'Add Inventory Items into Quote',
  },
  orders: {
    path: '/orders',
    label: 'Orders Page',
  },
  orderDetail: {
    path: '/orders/:id',
    getPath: (id: number) => generatePath(routes.orderDetail.path, { id }),
    label: 'Order Detail Page',
  },
  newOrderComponent: {
    path: '/orders/:id/add-component',
    getPath: (id: number) =>
      generatePath(routes.newOrderComponent.path, { id }),
    label: 'Add Order Component',
  },
};
