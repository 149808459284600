import { Table } from '~/src/shared/ui/components/table/table.tsx';
import { TableColumn } from '~/src/shared/ui/components/table/types.ts';
import { useLocation, useNavigate } from 'react-router-dom';
import { VendorModal } from '~/src/vendors/components/vendor-modal.component.tsx';
import { paths } from '~/src/shared/api/api.schema.ts';
import { query } from '~/src/shared/api/request-config.ts';
import { useQuery } from '@tanstack/react-query';
import { routes } from '~/src/routes.ts';
import { Button } from '~/src/shared/ui/components';
import { Vendor } from '../vendors.type';

export function Vendors() {
  const { data, fetchStatus, refetch } = useQuery({
    ...query(paths.vendors),
  });

  const isLoading = fetchStatus === 'fetching';
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const columns = [{ field: 'name', headerName: 'Vendor Name' }] as TableColumn<
    Partial<Vendor>
  >[];

  return (
    <div className="_page">
      <header>
        <div>
          <h1>Vendors</h1>
        </div>

        <div className="_header-actions">
          <Button onClick={() => navigate(routes.newVendor.path)}>
            Add New Vendor
          </Button>
        </div>
      </header>

      <Table<Pick<Vendor, 'name'>>
        name="Vendor"
        columns={columns}
        rows={data ?? []}
        loading={isLoading}
        defaultRowsPerPage={50}
        onRowClick={(vendor) => navigate(routes.vendorInfo.getPath(vendor.id))}
        showSearchInput
      />

      {pathname.includes('new') ? (
        <VendorModal refetchVendors={refetch} />
      ) : null}
    </div>
  );
}
