/**
 * Extracting some recoil-nexus functionality, since it currently has issues with vitest.
 * https://github.com/luisanton-io/recoil-nexus/issues/31
 */
import { PropsWithChildren } from 'react';
import {
  RecoilRoot,
  useRecoilCallback,
  useGetRecoilValueInfo_UNSTABLE,
  useRecoilTransaction_UNSTABLE,
  type RecoilState,
} from 'recoil';

export default function Recoil({ children }: PropsWithChildren) {
  return (
    <RecoilRoot>
      <RecoilExternal />
      {children}
    </RecoilRoot>
  );
}

let _setRecoil: typeof setRecoil;

function RecoilExternal() {
  const getInfo = useGetRecoilValueInfo_UNSTABLE();
  const transact = useRecoilTransaction_UNSTABLE(({ set }) => set);

  _setRecoil = useRecoilCallback(
    ({ set }) =>
      (recoilState, valOrUpdater) =>
        (getInfo(recoilState).type === 'atom' ? transact : set)(
          recoilState,
          valOrUpdater,
        ),
    [],
  );

  return <></>;
}

export const setRecoil = <T,>(
  atom: RecoilState<T>,
  valOrUpdater: T | ((currVal: T) => T),
): void => _setRecoil(atom, valOrUpdater);
