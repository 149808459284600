import * as React from 'react';

interface DragAndDropProps {
  maxFileSizeMB: number;
  acceptedFormats: string[];
  fileName: string;
  icon: React.ReactElement<SVGElement>;
}

export default function DragAndDrop(props: DragAndDropProps) {
  const { maxFileSizeMB, acceptedFormats, fileName, icon } = props;

  return (
    <div className="flex flex-col items-center w-full border-dashed border-2 border-gray-800 rounded-xl gap-4 p-6">
      <div>{icon}</div>

      <p className="text-lg">
        <span className="text-gray-700">
          Drag and drop your {fileName} File here or
        </span>{' '}
        search for it.
      </p>

      <div className="flex flex-col items-center text-xs text-gray-500">
        <p>Accepted formats: {acceptedFormats.join(', ')}.</p>
        <p>Max file size {maxFileSizeMB} MB.</p>
      </div>
    </div>
  );
}
