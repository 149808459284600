import {
  Font,
  Image,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Styles,
} from '@react-pdf/renderer';
import { logoDarkPng } from '~/src/shared/assets/logo.ts';
import { formatCurrency, formatDate } from '~/src/shared/utils';
import { PurchaseOrder, PurchaseOrderLine } from '../purchase-orders.type.ts';

Font.register({
  family: 'Poppins',
  fonts: [
    {
      src: '/fonts/Poppins-Regular.ttf',
    },
    {
      src: '/fonts/Poppins-Medium.ttf',
      fontWeight: 'medium',
    },
  ],
});

const styles = StyleSheet.create({
  viewer: {
    width: window.innerWidth,
    height: window.innerHeight,
  },
  page: {
    flexDirection: 'column',
    padding: 25,
    fontFamily: 'Poppins',
    fontSize: 10,
    paddingBottom: 50,
  },
  pageNumber: {
    position: 'absolute',
    bottom: 30,
    paddingLeft: 25,
  },
  table: {
    width: 550,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignContent: 'stretch',
    flexWrap: 'nowrap',
    alignItems: 'stretch',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignContent: 'stretch',
    flexWrap: 'nowrap',
    alignItems: 'stretch',
    flexGrow: 0,
    flexShrink: 0,
  },
  leftCell: {
    flex: 3,
    paddingRight: 20,
    paddingBottom: 30,
  },
  rigthCell: {
    flex: 2,
    paddingBottom: 30,
  },
  headerRow: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'flex-end',
    flexWrap: 'nowrap',
    alignItems: 'stretch',
    flexGrow: 0,
    flexShrink: 0,
    paddingRight: 10,
  },
  headerCell: {
    alignSelf: 'stretch',
    margin: 0,
    paddingLeft: 10,
  },
  headerImage: {
    width: 40,
  },
  headerText: {
    fontSize: 9,
  },
  headerClientSection: {
    paddingTop: 20,
    paddingBottom: 30,
  },
  sectionText: {
    paddingBottom: 5,
  },
  sectionTextRed: {
    color: 'red',
  },
  sectionTitle: {
    paddingBottom: 5,
    fontWeight: 'medium',
  },
  tableHeaderText: {
    fontWeight: 'medium',
  },
  divider: {
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    marginBottom: 10,
  },
  tableRowDivider: {
    borderBottomWidth: 0.4,
    borderBottomStyle: 'solid',
    marginTop: 8,
    marginBottom: 8,
  },
  tableBottomDivider: {
    borderBottomWidth: 1.5,
    borderBottomStyle: 'solid',
    marginTop: 4,
    marginBottom: 4,
  },
});

interface DocumentProps {
  order?: PurchaseOrder;
}

interface SectionProps {
  order: PurchaseOrder;
}

type Style = Styles[keyof Styles];

interface TableColumns {
  title: string;
  field: string;
  styles?: Style[];
  formatType?: string;
  footer?: string;
}

const Divider = () => <View style={[styles.row, styles.divider]}></View>;

const TableRowDivider = () => (
  <View style={[styles.row, styles.tableRowDivider]}></View>
);

const TableBottomDivider = () => (
  <View style={[styles.row, styles.tableBottomDivider]}></View>
);

const PageHeader = (props: SectionProps) => {
  const { order } = props;
  return (
    <View style={[styles.row]}>
      <View style={[styles.leftCell]}>
        <View style={[styles.headerRow]}>
          <View style={[styles.headerCell, styles.headerImage]}>
            <Image src={logoDarkPng.href}></Image>
          </View>
          <View style={[styles.headerCell]}>
            <Text style={[styles.headerText]}>
              2926 Main Street San Diego, CA 92113
            </Text>
            <Text style={[styles.headerText]}>
              Phone: (619) 450-6700 Fax: (619) 450-6701
            </Text>
            <Text style={[styles.headerText]}>www.LuckyYouGifts.com</Text>
          </View>
        </View>
      </View>
      <View style={[styles.rigthCell, styles.headerClientSection]}>
        <Text>Purchase Order #: {order.poNumber}</Text>
        <Text>Date: {formatDate(new Date().toString())}</Text>
        <Text>Ordered By: {order.orderedBy}</Text>
        <Text>Email: shipping@LuckyYouGifts.com</Text>
        <Text>Client ID: {order.vendor.id}</Text>
      </View>
    </View>
  );
};

const NotesSection = (props: SectionProps) => {
  const { order } = props;
  return (
    <View style={[styles.row]} wrap={false}>
      <View style={[styles.leftCell]}>
        <Text style={[styles.sectionTitle]}>Notes</Text>
        <Text style={[styles.sectionText, styles.sectionTextRed]}>
          {order.notesForVendor}
        </Text>
      </View>
      <View style={[styles.rigthCell]}>
        <Text style={[styles.sectionText]}>
          Deliver By: {formatDate(order.deliveryByDate)}
        </Text>
        <Text style={[styles.sectionText]}>
          Please notify of shortages/delays.
        </Text>
      </View>
    </View>
  );
};

const VendorSection = (props: SectionProps) => {
  const { order } = props;
  const vendor = order.vendor;
  return (
    <View style={[styles.row]} wrap={false}>
      <View style={[styles.leftCell]}>
        <Text style={[styles.sectionTitle]}>Vendor</Text>
        <Text>{vendor.name}</Text>
        <Text>{vendor.vendorAddress?.addressLine1}</Text>
        <Text>{vendor.vendorAddress?.addressLine2}</Text>
      </View>
      <View style={[styles.rigthCell]}>
        <Text style={[styles.sectionText]}>Name: {order.contact.fullName}</Text>
        <Text style={[styles.sectionText]}>
          Phone: {order.contact.phoneNumber}
        </Text>
        <Text style={[styles.sectionText]}>Email: {order.contact.email}</Text>
      </View>
    </View>
  );
};

const PurchaseOrderLinesSection = (props: SectionProps) => {
  const { order } = props;
  const lines: PurchaseOrderLine[] = order?.lines;
  const tableColumns: TableColumns[] = [
    {
      title: 'Item #',
      field: 'inventoryItemVendor.sku',
      styles: [{ flex: 1, textAlign: 'left' }],
    },
    {
      title: 'Item Description / Size',
      field: 'inventoryItemVendor.inventory.description',
      styles: [{ flex: 3, paddingRight: 2 }],
    },
    {
      title: 'Qty',
      field: 'quantity',
      styles: [{ flex: 1.1 }],
    },
    {
      title: 'Pack Size',
      field: 'inventoryItemVendor.qtyPerSku',
      styles: [{ flex: 1 }],
    },
    {
      title: 'UOM',
      field: 'inventoryItemVendor.vendorUnitOfMeasure',
      styles: [{ flex: 0.3 }],
    },
    {
      title: 'Unit Price',
      field: 'inventoryItemVendor.skuCost',
      styles: [{ flex: 1, textAlign: 'right' }],
      formatType: 'currency',
      footer: 'PO Total:',
    },
    {
      title: 'Total Price',
      field: 'lineTotal',
      styles: [{ flex: 1, textAlign: 'right' }],
      formatType: 'currency',
      footer: formatCurrency(order?.total),
    },
    {
      title: '#Pallets',
      field: 'numberOfPallets',
      styles: [{ flex: 0.8, textAlign: 'right' }],
    },
  ];

  // Helper function to get nested field value
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getNestedFieldValue = (row: any, path: string): string | number => {
    return path.split('.').reduce((acc, key) => {
      return acc && acc[key];
    }, row);
  };

  const getTableContent = (column: TableColumns, line: PurchaseOrderLine) => {
    const value = getNestedFieldValue(line, column.field);
    const formattedValue =
      column?.formatType === 'currency' ? formatCurrency(Number(value)) : value;

    if (column.field === 'quantity') {
      return <Text>{formattedValue} Total</Text>;
    }
    return <Text>{formattedValue}</Text>;
  };

  return (
    <View style={styles.table}>
      <View style={[styles.row, styles.tableHeaderText]}>
        {tableColumns.map((column, key) => (
          <View style={column.styles} key={key}>
            <Text>{column.title}</Text>
          </View>
        ))}
      </View>
      {lines?.map((line, key) => (
        <View wrap={false} key={key}>
          <TableRowDivider></TableRowDivider>
          <View style={[styles.row]}>
            {tableColumns.map((column, key) => (
              <View style={column.styles} key={key}>
                {getTableContent(column, line)}
              </View>
            ))}
          </View>
        </View>
      ))}
      <TableBottomDivider></TableBottomDivider>
      <View style={[styles.row]}>
        {tableColumns.map((column, key) => (
          <View style={column.styles} key={key}>
            <Text>{column?.footer}</Text>
          </View>
        ))}
      </View>
    </View>
  );
};

export function PurchaseOrderDocument(props: DocumentProps) {
  const { order } = props;
  if (!order) {
    return <Document></Document>;
  }
  return (
    <Document style={styles.viewer}>
      <Page style={styles.page} size="A4" wrap>
        <View style={styles.table} fixed>
          <PageHeader order={order}></PageHeader>
        </View>
        <View style={styles.table}>
          <Divider></Divider>
          <NotesSection order={order}></NotesSection>
          <Divider></Divider>
          <VendorSection order={order}></VendorSection>
          <Divider></Divider>
        </View>
        <PurchaseOrderLinesSection order={order}></PurchaseOrderLinesSection>
        <Text style={[styles.sectionText]}>Order Ref: -</Text>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `Page ${pageNumber} of ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
}
