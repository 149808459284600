import * as React from 'react';
import { TableData, TableRow } from '~/src/shared/ui/components/table/types.ts';
import { chunk } from 'lodash-es';

export default function usePagination<T extends TableData<T>>(
  rows: TableRow<T>[],
  chunkSize: number,
) {
  return React.useMemo(() => {
    return chunk(rows, chunkSize);
  }, [rows, chunkSize]);
}
