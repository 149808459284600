import { IconProps } from '~/src/shared/ui/icons/types.ts';

export default function ToggleOff(props: IconProps) {
  const { color } = props;

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13 5.5H7C4.5 5.5 2.5 7.53125 2.5 10C2.5 12.5 4.5 14.5 7 14.5H13C15.4688 14.5 17.5 12.5 17.5 10C17.5 7.53125 15.4688 5.5 13 5.5ZM19 10C19 13.3125 16.3125 16 13 16H7C3.6875 16 1 13.3125 1 10C1 6.6875 3.6875 4 7 4H13C16.3125 4 19 6.6875 19 10ZM7 13C5.90625 13 4.9375 12.4375 4.375 11.5C3.84375 10.5938 3.84375 9.4375 4.375 8.5C4.9375 7.59375 5.90625 7 7 7C8.0625 7 9.03125 7.59375 9.59375 8.5C10.125 9.4375 10.125 10.5938 9.59375 11.5C9.03125 12.4375 8.0625 13 7 13Z"
        fill={color ?? 'black'}
      />
    </svg>
  );
}
