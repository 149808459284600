import { IconProps } from '~/src/shared/ui/icons/types.ts';

export default function Envelope(props: IconProps) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4 5.5C3.71875 5.5 3.5 5.75 3.5 6V6.71875L8.875 11.125C9.53125 11.6562 10.4375 11.6562 11.0938 11.125L16.5 6.71875V6C16.5 5.75 16.25 5.5 16 5.5H4ZM3.5 8.65625V14C3.5 14.2812 3.71875 14.5 4 14.5H16C16.25 14.5 16.5 14.2812 16.5 14V8.65625L12.0625 12.2812C10.8438 13.2812 9.125 13.2812 7.9375 12.2812L3.5 8.65625ZM2 6C2 4.90625 2.875 4 4 4H16C17.0938 4 18 4.90625 18 6V14C18 15.125 17.0938 16 16 16H4C2.875 16 2 15.125 2 14V6Z"
        fill={props.color ?? 'black'}
      />
    </svg>
  );
}
