import { Select } from '~/src/shared/ui/components/select/select.tsx';
import { BillingMethod, ShippingService } from '~/src/quotes/consts.ts';
import { Input } from '~/src/shared/ui/components';
import { Datepicker } from '~/src/shared/ui/components/datepicker/datepicker.tsx';
import { Quote } from '~/src/quotes/quotes.type.ts';

interface ShippingTabProps {
  quote: Quote;
  updateQuote: (data: Partial<Quote>) => void;
}

export default function ShippingTab(props: ShippingTabProps) {
  const { quote, updateQuote } = props;

  return (
    <div className="_table-body">
      <div className="_row">
        <Select
          label="Billing Method"
          value={quote?.billingMethod}
          onChange={(e) => updateQuote({ billingMethod: e.target.value })}
        >
          <option value="" disabled>
            Select a Billing Method
          </option>
          {Object.entries(BillingMethod).map(([key, title], index) => (
            <option key={index} value={key} className="!hover:bg-black">
              {title}
            </option>
          ))}
        </Select>
        <Select
          label="Shipping Service"
          value={quote?.shippingService}
          onChange={(e) => updateQuote({ shippingService: e.target.value })}
        >
          <option value="" disabled>
            Select a Shipping Service
          </option>
          {Object.entries(ShippingService).map(([key, title], index) => (
            <option key={index} value={key} className="!hover:bg-black">
              {title}
            </option>
          ))}
        </Select>
        <Input
          label="Account Number"
          placeholder="Select a Courrier first"
          defaultValue={quote?.accountNumber}
          onBlur={(e) => updateQuote({ accountNumber: e.target.value })}
        />
      </div>
      <div className="_row">
        <Input
          label="Ship Label Reference"
          placeholder="Enter a Label Reference"
          defaultValue={quote?.shipLabelReference}
          onBlur={(e) => updateQuote({ shipLabelReference: e.target.value })}
        />
        <Datepicker
          label="Ship Date"
          placeholder="mm/dd/yyyy"
          required
          defaultValue={quote?.shipDate}
          onChange={(e) => updateQuote({ shipDate: e.target.value })}
        />
        <Datepicker
          label="In Store Date"
          placeholder="mm/dd/yyyy"
          required
          defaultValue={quote?.inStoreDate}
          onChange={(e) => updateQuote({ inStoreDate: e.target.value })}
        />
      </div>
      <div className="_row">
        <Input
          label="Ship Weight"
          placeholder="Exact Value"
          defaultValue={quote?.shipWeight}
          onBlur={(e) => updateQuote({ shipWeight: Number(e.target.value) })}
        />
        <Input
          label="Ship Height"
          placeholder="Exact Value"
          defaultValue={quote?.shipHeight}
          onBlur={(e) => updateQuote({ shipHeight: Number(e.target.value) })}
        />
        <Input
          label="Ship Width"
          placeholder="Exact Value"
          defaultValue={quote?.shipWidth}
          onBlur={(e) => updateQuote({ shipWidth: Number(e.target.value) })}
        />
        <Input
          label="Ship Length"
          placeholder="Exact Value"
          defaultValue={quote?.shipLength}
          onBlur={(e) => updateQuote({ shipLength: Number(e.target.value) })}
        />
      </div>
    </div>
  );
}
