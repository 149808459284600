import { LocalStorage } from '~/src/shared/service/storage';
import { Logger } from '~/src/shared/service/logger';
import { Store } from '~/src/shared/service/store/store.ts';
import { Auth, AuthUser } from '../luckyyou/auth.type';
import { authState } from './atoms/auth.atom';
import { fetcher } from '~/src/shared/service/fetch/fetcher.ts';

export class AuthStore extends Store<Auth> {
  static cachedKeys: (keyof Auth)[] = [];

  static defaults() {
    return { user: {} } satisfies Partial<Auth>;
  }

  static restore() {
    return {
      ...this.defaults(),
      ...{ user: (LocalStorage.read('AuthUser') as AuthUser) ?? {} },
      ...{ token: LocalStorage.read('AuthToken') as string },
    };
  }

  constructor() {
    super(AuthStore.restore(), authState);

    Logger.setContextGetter(() => ({
      authUsername: this.state.user?.username,
    }));

    fetcher.on((response) => {
      if (response.status === 401) {
        authStore.resetAuthData();
      }
    });
  }

  setAuthData(user: AuthUser, token: string) {
    this.updateState((draft) => {
      draft.user.email = user.email;
      draft.user.username = user.username;
      draft.user.firstName = user.firstName;
      draft.user.lastName = user.lastName;

      draft.token = token;
    });

    LocalStorage.write('AuthUser', this.state.user);
    LocalStorage.write('AuthToken', this.state.token);
  }

  resetAuthData() {
    this.updateState((draft) => {
      draft.user = {};
      draft.token = undefined;
    });

    LocalStorage.clear('AuthUser');
    LocalStorage.clear('AuthToken');
  }
}

export const authStore = new AuthStore();
