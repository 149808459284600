import {
  DynamicInputProps,
  DynamicTableColumn,
  TableData,
  TableRow,
  TableValue,
} from '~/src/shared/ui/components/table/types.ts';
import * as React from 'react';
import useSortBy from '~/src/shared/ui/components/table/hooks/use-sort-by.ts';
import { Button, Input } from '~/src/shared/ui/components';
import { SymbolInput } from '~/src/shared/ui/components/input/input.tsx';
import { ReactElement } from 'react';
import { formatCurrency, formatDate } from '~/src/shared/utils.ts';
import ArrowDownAZ from '~/src/shared/ui/icons/arrow-down-a-z.tsx';
import Trash from '~/src/shared/ui/icons/trash.tsx';
import { Colors } from '~/src/shared/ui/styles/const.ts';
import { Toggle } from '~/src/shared/ui/components/toggle/toggle.tsx';

import './table.css';

export interface DynamicTableProps<T extends TableData<T>> {
  columns: DynamicTableColumn<T>[];
  rows: TableRow<Partial<T>>[];
  loading?: boolean;
  onRowClick?: (data: TableRow<T>) => void;
  deletableRow?: boolean;
  onToggle?: (
    event: React.ChangeEvent<HTMLInputElement>,
    row: TableRow<T>,
  ) => void;
  customRows?: React.JSX.Element[];
  onDeleteRow?: (row: TableRow<T>) => void;
}

export function DynamicTable<T extends TableData<T>>(
  props: DynamicTableProps<T>,
) {
  const {
    columns,
    rows,
    deletableRow,
    onToggle,
    onRowClick = () => {},
    customRows,
    onDeleteRow,
  } = props;

  const { sortedRows, fieldToSort, onSetFieldToSort, descending } = useSortBy(
    rows,
    columns,
  );

  const columnFields = React.useMemo(
    () => columns.map((col) => col.field),
    [columns],
  ) as (keyof Partial<TableRow<T>>)[];

  function TableInput(props: DynamicInputProps<T>) {
    const { name, value, onUpdate, row } = props;
    return (
      <Input
        key={row.id}
        inputMode="numeric"
        name={name}
        label={name}
        placeholder="0"
        defaultValue={value}
        onBlur={(e) => (onUpdate ? onUpdate(e, row) : null)}
        hideLabel
      />
    );
  }

  function TableInputPercent(props: DynamicInputProps<T>) {
    const { name, value, onUpdate, row } = props;

    return (
      <SymbolInput
        key={row.id}
        type="number"
        className="w-32"
        symbol="%"
        name={name}
        label={name}
        placeholder={name}
        defaultValue={value}
        disabled={!row.isActive}
        onBlur={(e) => (onUpdate ? onUpdate(e, row) : null)}
        hideLabel
      />
    );
  }

  function TableInputCurrency(props: DynamicInputProps<T>) {
    const { name, value, onUpdate, row } = props;

    return (
      <SymbolInput
        key={row.id}
        className="w-32"
        symbol="$"
        name={name}
        label={name}
        placeholder={name}
        defaultValue={value}
        disabled={!row.isActive}
        onBlur={(e) => (onUpdate ? onUpdate(e, row) : null)}
        hideLabel
      />
    );
  }

  // Helper function to get nested field value
  // TODO LUCKYYOU-99: this is duplicate code from the Table component.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getNestedFieldValue = (row: any, path: string): TableValue => {
    return path.split('.').reduce((acc, key) => {
      return acc && acc[key];
    }, row);
  };

  const getContentFromRow = (
    row: TableRow<T>,
    columnField: string | number | symbol,
  ) => {
    const columnMeta = columns.find(
      (c) => c.field === columnField,
    ) as DynamicTableColumn<T>;

    let content: TableValue | ReactElement =
      row[columnField as keyof TableRow<T>];

    // Handle nested fields
    // TODO LUCKYYOU-99: this is duplicate code from the Table component.
    if (typeof columnField === 'string' && columnField.includes('.')) {
      content = getNestedFieldValue(row, columnField as string);
    }

    const inputArgs = {
      name: columnMeta.field,
      value: content as number,
      row,
      onUpdate: columnMeta.onUpdate,
    } as DynamicInputProps<T>;

    if (columnMeta?.formatType === 'currency') {
      content = formatCurrency(content as number);
    } else if (columnMeta?.formatType === 'date') {
      content = content && formatDate(content as string);
    } else if (columnMeta?.formatType === 'input_number') {
      content = TableInput(inputArgs);
    } else if (columnMeta?.formatType === 'input_percent') {
      content = TableInputPercent(inputArgs);
    } else if (columnMeta?.formatType === 'input_currency') {
      content = TableInputCurrency(inputArgs);
    }

    return content as Exclude<TableValue, object> | ReactElement;
  };

  return (
    <div className="_table-body">
      <table>
        <thead>
          <tr>
            {columns.map((col, index) => (
              <td key={index} className={col.width}>
                <div className="flex items-center gap-3 min-w-max">
                  {col.headerName}
                  <ArrowDownAZ
                    className="_clickable"
                    onClick={() => onSetFieldToSort(col.field)}
                    inactive={col.field !== fieldToSort}
                    inverted={col.field === fieldToSort && descending}
                  />
                </div>
              </td>
            ))}
            {deletableRow && <td className="w-0" />}
            {onToggle && <td className="w-0" />}
          </tr>
        </thead>
        <tbody className="text-gray-900">
          {sortedRows.map((row, index) => (
            <tr key={index} data-testid="table-row">
              {columnFields.map((col, index) => (
                <td key={index} onDoubleClick={() => onRowClick(row)}>
                  <span>{getContentFromRow(row, col) ?? '-'}</span>
                </td>
              ))}
              {deletableRow && (
                <td className="pr-2">
                  <Button
                    className="_icon-button"
                    onClick={() => (onDeleteRow ? onDeleteRow(row) : null)}
                  >
                    <Trash color={Colors.danger['500']} />
                  </Button>
                </td>
              )}
              {onToggle && (
                <td className="!pl-6 pr-6">
                  <Toggle
                    name="isActive"
                    checked={row.isActive}
                    onChange={(e) => onToggle(e, row)}
                  />
                </td>
              )}
            </tr>
          ))}
          {customRows}
        </tbody>
      </table>
    </div>
  );
}
