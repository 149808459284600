import * as React from 'react';

import { Button, Input } from '~/src/shared/ui/components';
import {
  DynamicTableColumn,
  TableRow,
} from '~/src/shared/ui/components/table/types.ts';
import Plus from '~/src/shared/ui/icons/plus.tsx';
import { Toggle } from '~/src/shared/ui/components/toggle/toggle.tsx';
import { SymbolInput } from '~/src/shared/ui/components/input/input.tsx';
import InfoPiece from '~/src/shared/ui/components/info-piece/info-piece.component.tsx';
import { DynamicTable } from '~/src/shared/ui/components/table/dynamic-table.tsx';

import '../styles/quote-container.component.css';
import { InventoryItemVendor } from '~/src/inventory/inventory.type';
import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query';
import { request } from '~/src/shared/api/request-config.ts';
import { Logger } from '~/src/shared/service/logger';
import {
  deletion,
  deletionError,
  updateError,
} from '~/src/shared/ui/components/alert/models.tsx';
import { quotesPaths } from '~/src/quotes/quotes.schema.ts';
import {
  Quote,
  QuoteItem,
  QuoteItemPayload,
} from '~/src/quotes/quotes.type.ts';
import useAlert from '~/src/shared/ui/components/alert/hooks/use-alert.tsx';
import { QuoteControl } from '~/src/quotes/pages/quote-building.page.tsx';

export function QuoteContainer(props: {
  title: string;
  actionName: string;
  action: () => void;
  items: TableRow<QuoteItem>[];
  quote: Quote;
  refetchItems: (
    options?: RefetchOptions | undefined,
  ) => Promise<QueryObserverResult<Quote, Error>>;
}) {
  const { title, actionName, action, items, quote, refetchItems } = props;
  const alert = useAlert();

  function deleteItem(itemId: number) {
    request(quotesPaths.deleteQuoteItem, {
      params: { quoteId: quote.id, itemId },
    })
      .fn()
      .then(() => {
        refetchItems();
        Logger.info('Inventory item deleted.');
        alert.show(deletion(title), 5);
      })
      .catch((error) => {
        Logger.warn('Error on deleting inventory item.', error);
        alert.show(deletionError(title), 5);
      });
  }

  function updateItem(payload: QuoteItemPayload) {
    request(quotesPaths.updateQuoteItem, {
      params: { quoteId: quote.id, itemId: payload.id },
    })
      .fn(payload)
      .then(() => {
        refetchItems();
        Logger.info('Inventory item updated.');
      })
      .catch((error) => {
        Logger.warn('Error on updating inventory item.', error);
        alert.show(updateError(title), 5);
      });
  }

  function onQuantityChange(
    event: React.ChangeEvent<HTMLInputElement>,
    row: TableRow<QuoteItem>,
  ) {
    const quantity = parseInt(event.target.value, 10);

    if (isNaN(quantity)) return;

    const payload = {
      ...row,
      inventoryItemVendor: (row.inventoryItemVendor as InventoryItemVendor)?.id,
      quantity: quantity,
    } as QuoteItemPayload;

    Logger.info('Updating quote item', { data: payload });
    updateItem(payload);
  }

  const columns = [
    {
      field: 'inventoryItemVendor.name',
      headerName: 'Component',
      width: 'w-full',
    },
    {
      field: 'inventoryItemVendor.unitPrice',
      headerName: 'Unit Cost',
      formatType: 'currency',
    },
    {
      field: 'quantity',
      headerName: 'Units',
      formatType: 'input_number',
      onUpdate: onQuantityChange,
    },
    {
      field: 'inventoryItemVendor.datePricing',
      headerName: 'Date Pricing',
      formatType: 'date',
    },
  ] as unknown as DynamicTableColumn<Partial<QuoteItem>>[];

  return (
    <div className="_quote-container">
      <div className="flex items-center justify-between">
        <p className="text-lg">{title}</p>
        <Button className="_outline flex items-center gap-1" onClick={action}>
          <Plus className="inline" /> {actionName}
        </Button>
      </div>

      <DynamicTable<QuoteItem>
        columns={columns}
        rows={items}
        loading={false}
        onRowClick={() => {}}
        onDeleteRow={(row) => deleteItem(row.id)}
        deletableRow
      />
    </div>
  );
}

export function MaterialTotalContainer(props: QuoteControl) {
  const { quote, updateQuote } = props;

  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;
    const name = event.target.name;

    updateQuote({ [name]: value });
  }

  return (
    <div className="_quote-container">
      <SymbolInput
        key="materialTotal"
        className="w-64"
        symbol="$"
        name="materialTotal"
        label="Material Total"
        placeholder="-"
        value={quote.materialTotal}
        disabled
      />

      <div className="flex gap-4">
        <Input
          className="w-64"
          name="laborMinutes"
          label="Labor Minutes"
          placeholder="-"
          defaultValue={quote.laborMinutes}
          onBlur={onChange}
          required
        />
        <SymbolInput
          className="w-64"
          symbol="$"
          name="costPerMinute"
          label="Labor Per Minute"
          placeholder="-"
          defaultValue={quote.costPerMinute}
          onBlur={onChange}
          required
        />
        <SymbolInput
          className="w-64"
          symbol="$"
          name="laborTotal"
          label="Labor Total"
          placeholder="Labor Total"
          value={quote.laborTotal}
          disabled
        />
      </div>

      <SymbolInput
        className="w-64"
        symbol="$"
        name="materialPlusLabor"
        label="Material + Labor"
        placeholder="-"
        value={quote.materialPlusLabor}
        disabled
      />
      <SymbolInput
        className="w-64"
        symbol="$"
        name="freightCost"
        label="Freight Cost"
        placeholder="-"
        value={quote.freightCost}
        disabled
      />
      <SymbolInput
        className="w-64"
        symbol="$"
        name="setupFees"
        label="Set up Fees"
        placeholder="-"
        value={quote.totalSetUpFees}
        disabled
      />
      <SymbolInput
        className="w-64"
        symbol="$"
        name="costOfGoods"
        label="Cost of Goods"
        placeholder="-"
        value={quote.costOfGoods}
        required
        disabled
      />
    </div>
  );
}

export function PricesContainer(props: QuoteControl) {
  const { quote, updateQuote } = props;

  return (
    <div className="_quote-container">
      <SymbolInput
        className="w-64"
        symbol="%"
        name="markup"
        label="Markup"
        placeholder="Markup"
        value={quote.markup}
        disabled
      />

      <div className="flex gap-4">
        <SymbolInput
          className="w-64"
          symbol="$"
          name="giftPrice"
          label="Gift Price"
          placeholder="Gift Price"
          value={quote.giftPrice}
          disabled
        />
        <SymbolInput
          className="w-64"
          symbol="$"
          name="profitPerGift"
          label="Profit per Gift"
          placeholder="Profit per Gift"
          value={quote.profitPerGift}
          disabled
        />
      </div>

      <SymbolInput
        className="w-64"
        symbol="$"
        name="totalOrderPrice"
        label="Total Order Price"
        placeholder="Total Order Price"
        value={quote.totalOrderPrice}
        disabled
      />

      <div className="flex gap-4">
        <SymbolInput
          className="w-64"
          symbol="$"
          name="discountedGiftPrice"
          label="Discounted Gift Price"
          placeholder="Discounted Gift Price"
          value={quote.discountedGiftPrice}
          disabled
        />
        <SymbolInput
          className="w-64"
          symbol="$"
          name="postDiscountProfit"
          label="Post Discount Profit"
          placeholder="Post Discount Profit"
          value={quote.postDiscountProfit}
          disabled
        />
        <SymbolInput
          className="w-64"
          symbol="$"
          name="totalDiscountOrderPrice"
          label="Total Discount Order Price"
          placeholder="Total Discount Order Price"
          value={quote.totalDiscountedOrderPrice}
          disabled
        />
      </div>

      <div className="flex gap-4">
        <Input
          type="number"
          className="w-64"
          name="caQty"
          label="CA Qty"
          placeholder="CA Qty"
          defaultValue={quote.caQuantity}
          onBlur={(e) =>
            updateQuote({
              caQuantity: e.target.value,
            })
          }
          required
        />
        <SymbolInput
          className="w-64"
          symbol="%"
          name="taxRate"
          label="Tax Rate"
          placeholder="Tax Rate"
          value={quote.taxRate}
          disabled
        />
        <SymbolInput
          className="w-64"
          symbol="$"
          name="totalSalesTax"
          label="Total Sales Tax"
          placeholder="Total Sales Tax"
          value={quote.preTaxTotal}
          disabled
        />
      </div>
    </div>
  );
}

export function QuoteTotalContainer(props: QuoteControl) {
  const { quote, updateQuote } = props;

  return (
    <div className="_quote-container flex-row justify-start gap-52">
      <InfoPiece
        className="max-w-fit"
        label="Quote Total"
        content={<p className="text-lg">$ {quote.total}</p>}
      />

      <Toggle
        rightLabel="50% Deposit Required"
        checked={quote.depositRequired}
        onChange={(e) =>
          updateQuote({
            depositRequired: e.target.checked,
          })
        }
      />
    </div>
  );
}
