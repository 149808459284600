export function Home() {
  return (
    <div className="_page">
      <header>
        <div>
          <h1>Home</h1>
        </div>
      </header>
      Home page
    </div>
  );
}
