import dayjs from 'dayjs';

import { SessionStorage } from '~/src/shared/service/storage/';
import type {
  LoggerFunction,
  LogMessage,
} from '~/src/shared/service/logger/types';

export class LoggerBackend {
  backend: LoggerFunction;
  getContext: () => Record<string, string | undefined> = () => ({});

  constructor(_backend: LoggerFunction) {
    this.backend = _backend;
  }

  setContextGetter(_getContext: () => Record<string, string | undefined>) {
    this.getContext = _getContext;
  }

  sessionId() {
    return SessionStorage.read('SessionId');
  }

  info(message: LogMessage, data?: Record<string, unknown>) {
    this.backend({
      message,
      level: 'info',
      timestamp: dayjs(),
      ...(data && { data: data }),
    });
  }

  metric(message: LogMessage, data?: Record<string, unknown>) {
    this.backend({
      message: `[METRIC] ${message}`,
      level: 'info',
      timestamp: dayjs(),
      ...(data && { data: data }),
    });
  }

  warn(message: LogMessage, data?: Record<string, unknown>) {
    this.backend({
      message,
      level: 'warn',
      timestamp: dayjs(),
      ...(data && { data: data }),
    });
  }

  error(error: LogMessage, data?: Record<string, unknown>) {
    this.backend({
      message: error,
      level: 'error',
      timestamp: dayjs(),
      ...(data && { data: data }),
    });
  }

  async flushLogs(): Promise<void> {}
}
