import { IconProps } from '~/src/shared/ui/icons/types.ts';

export function Boxes(props: IconProps) {
  return (
    <svg
      width="28"
      height="24"
      viewBox="0 0 28 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.5 2.25H15.875V5.25C15.875 5.67188 15.5 6 15.125 6H12.875C12.4531 6 12.125 5.67188 12.125 5.25V2.25H9.5C9.07812 2.25 8.75 2.625 8.75 3V9H6.5V3C6.5 1.35938 7.8125 0 9.5 0H18.5C20.1406 0 21.5 1.35938 21.5 3V9H19.25V3C19.25 2.625 18.875 2.25 18.5 2.25ZM15.8281 24C16.3906 23.3906 16.7656 22.6406 16.9062 21.75H24.5C24.875 21.75 25.25 21.4219 25.25 21V13.5C25.25 13.125 24.875 12.75 24.5 12.75H21.875V15.75C21.875 16.1719 21.5 16.5 21.125 16.5H18.875C18.4531 16.5 18.125 16.1719 18.125 15.75V12.75H16.9062C16.7656 11.9062 16.3906 11.1562 15.8281 10.5H24.5C26.1406 10.5 27.5 11.8594 27.5 13.5V21C27.5 22.6875 26.1406 24 24.5 24H15.8281ZM12.5 12.75H9.875V15.75C9.875 16.1719 9.5 16.5 9.125 16.5H6.875C6.45312 16.5 6.125 16.1719 6.125 15.75V12.75H3.5C3.07812 12.75 2.75 13.125 2.75 13.5V21C2.75 21.4219 3.07812 21.75 3.5 21.75H12.5C12.875 21.75 13.25 21.4219 13.25 21V13.5C13.25 13.125 12.875 12.75 12.5 12.75ZM3.5 10.5H12.5C14.1406 10.5 15.5 11.8594 15.5 13.5V21C15.5 22.6875 14.1406 24 12.5 24H3.5C1.8125 24 0.5 22.6875 0.5 21V13.5C0.5 11.8594 1.8125 10.5 3.5 10.5Z"
        fill={props.color ?? 'black'}
      />
    </svg>
  );
}
