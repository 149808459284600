import { IconProps } from '~/src/shared/ui/icons/types.ts';
import { Colors } from '~/src/shared/ui/styles/const.ts';

export function CircleExclamationSolid(props: IconProps) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10 18C7.125 18 4.5 16.5 3.0625 14C1.625 11.5312 1.625 8.5 3.0625 6C4.5 3.53125 7.125 2 10 2C12.8438 2 15.4688 3.53125 16.9062 6C18.3438 8.5 18.3438 11.5312 16.9062 14C15.4688 16.5 12.8438 18 10 18ZM10 6C9.5625 6 9.25 6.34375 9.25 6.75V10.25C9.25 10.6875 9.5625 11 10 11C10.4062 11 10.75 10.6875 10.75 10.25V6.75C10.75 6.34375 10.4062 6 10 6ZM9 13C9 13.5625 9.4375 14 10 14C10.5312 14 11 13.5625 11 13C11 12.4688 10.5312 12 10 12C9.4375 12 9 12.4688 9 13Z"
        fill={Colors.danger['500']}
      />
    </svg>
  );
}

export function CircleExclamation(props: IconProps) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10 3.5C7.65625 3.5 5.53125 4.75 4.34375 6.75C3.1875 8.78125 3.1875 11.25 4.34375 13.25C5.53125 15.2812 7.65625 16.5 10 16.5C12.3125 16.5 14.4375 15.2812 15.625 13.25C16.7812 11.25 16.7812 8.78125 15.625 6.75C14.4375 4.75 12.3125 3.5 10 3.5ZM10 18C7.125 18 4.5 16.5 3.0625 14C1.625 11.5312 1.625 8.5 3.0625 6C4.5 3.53125 7.125 2 10 2C12.8438 2 15.4688 3.53125 16.9062 6C18.3438 8.5 18.3438 11.5312 16.9062 14C15.4688 16.5 12.8438 18 10 18ZM10 6C10.4062 6 10.75 6.34375 10.75 6.75V10.25C10.75 10.6875 10.4062 11 10 11C9.5625 11 9.25 10.6875 9.25 10.25V6.75C9.25 6.34375 9.5625 6 10 6ZM11 13C11 13.5625 10.5312 14 10 14C9.4375 14 9 13.5625 9 13C9 12.4688 9.4375 12 10 12C10.5312 12 11 12.4688 11 13Z"
        fill={props.color ?? 'black'}
      />
    </svg>
  );
}
