import * as React from 'react';
import {
  TableColumn,
  TableData,
  TableRow,
} from '~/src/shared/ui/components/table/types.ts';
import Fuse from 'fuse.js';

export default function useSearch<T extends TableData<T>>(
  rows: TableRow<T>[],
  columnsToSearch: TableColumn<T>[],
  searchTerm: string,
) {
  const fieldsToSearch = React.useMemo(
    () => columnsToSearch.map((col) => col.field),
    [columnsToSearch],
  ) as (keyof Partial<TableRow<T>>)[];

  const fuse = React.useMemo(
    () =>
      new Fuse(rows, {
        keys: fieldsToSearch as string[],
        threshold: 0.5,
        ignoreFieldNorm: true,
        ignoreLocation: true,
        shouldSort: true,
      }),
    [rows, fieldsToSearch],
  );

  return React.useMemo(() => {
    if (!searchTerm) {
      return rows;
    }

    // Try exact match first
    const lowerSearchTerm = searchTerm.toLowerCase();
    const exactMatches = rows.filter((row) => {
      return fieldsToSearch.some((field) => {
        return row[field]
          ?.toString()
          .toLowerCase()
          .split(' ')
          .includes(lowerSearchTerm);
      });
    });

    if (exactMatches.length !== 0) {
      return exactMatches;
    }

    // If there is no exact match, perform fuzzy search
    return fuse.search(searchTerm).map((result) => result.item);
  }, [rows, searchTerm, fuse, fieldsToSearch]);
}
