import { IconProps } from '~/src/shared/ui/icons/types.ts';

export default function ListRadio(props: IconProps) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4 3C4.75 3.02083 5.32292 3.35417 5.71875 4C6.09375 4.66667 6.09375 5.33333 5.71875 6C5.32292 6.64583 4.75 6.97917 4 7C3.25 6.97917 2.67708 6.64583 2.28125 6C1.90625 5.33333 1.90625 4.66667 2.28125 4C2.67708 3.35417 3.25 3.02083 4 3ZM7.75 4.25H17.25C17.7083 4.29167 17.9583 4.54167 18 5C17.9583 5.45833 17.7083 5.70833 17.25 5.75H7.75C7.29167 5.70833 7.04167 5.45833 7 5C7.04167 4.54167 7.29167 4.29167 7.75 4.25ZM7.75 9.25H17.25C17.7083 9.29167 17.9583 9.54167 18 10C17.9583 10.4583 17.7083 10.7083 17.25 10.75H7.75C7.29167 10.7083 7.04167 10.4583 7 10C7.04167 9.54167 7.29167 9.29167 7.75 9.25ZM7.75 14.25H17.25C17.7083 14.2917 17.9583 14.5417 18 15C17.9583 15.4583 17.7083 15.7083 17.25 15.75H7.75C7.29167 15.7083 7.04167 15.4583 7 15C7.04167 14.5417 7.29167 14.2917 7.75 14.25ZM4 10.75C4.45833 10.7083 4.70833 10.4583 4.75 10C4.70833 9.54167 4.45833 9.29167 4 9.25C3.54167 9.29167 3.29167 9.54167 3.25 10C3.29167 10.4583 3.54167 10.7083 4 10.75ZM4 8C4.75 8.02083 5.32292 8.35417 5.71875 9C6.09375 9.66667 6.09375 10.3333 5.71875 11C5.32292 11.6458 4.75 11.9792 4 12C3.25 11.9792 2.67708 11.6458 2.28125 11C1.90625 10.3333 1.90625 9.66667 2.28125 9C2.67708 8.35417 3.25 8.02083 4 8ZM3.25 15C3.29167 15.4583 3.54167 15.7083 4 15.75C4.45833 15.7083 4.70833 15.4583 4.75 15C4.70833 14.5417 4.45833 14.2917 4 14.25C3.54167 14.2917 3.29167 14.5417 3.25 15ZM6 15C5.97917 15.75 5.64583 16.3229 5 16.7188C4.33333 17.0938 3.66667 17.0938 3 16.7188C2.35417 16.3229 2.02083 15.75 2 15C2.02083 14.25 2.35417 13.6771 3 13.2812C3.66667 12.9062 4.33333 12.9062 5 13.2812C5.64583 13.6771 5.97917 14.25 6 15Z"
        fill={props.color ?? 'black'}
      />
    </svg>
  );
}
