import dayjs from 'dayjs';
import { Logger } from '~/src/shared/service/logger';
import { toNumber } from 'lodash-es';

export function jsonCase(
  obj: unknown,
  transformer: (input: string) => string,
): unknown {
  if (!obj || typeof obj !== 'object') {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => jsonCase(item, transformer));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const result: Record<string, any> = {};
  for (const [key, value] of Object.entries(obj)) {
    result[transformer(key)] = jsonCase(value, transformer);
  }

  return result;
}

export async function safeAwait<T, F>(func: () => Promise<T>, fallback?: F) {
  try {
    const result = await func();
    return result ?? fallback;
  } catch (error) {
    return fallback;
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function flattenErrors(obj: Record<string, any>, prefix = '') {
  let result: Record<string, string> = {};

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const newKey = prefix ? `${prefix}.${key}` : key;

      if (Array.isArray(obj[key]) && typeof obj[key][0] === 'string') {
        result[newKey] = obj[key][0];
      } else if (Array.isArray(obj[key])) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        obj[key].forEach((item: any, index: number) => {
          const transformedItem = flattenErrors(item, `${newKey}.${index}`);
          result = { ...result, ...transformedItem };
        });
      } else if (typeof obj[key] === 'object' && obj[key] !== null) {
        const transformedItem = flattenErrors(obj[key], newKey);
        result = { ...result, ...transformedItem };
      } else {
        result[newKey] = obj[key];
      }
    }
  }

  return result;
}

export function makeDropDownOptions(options: Record<string, string>) {
  return Object.entries(options).map(([k, v]) => ({ title: v, value: k }));
}

export function isNumeric(str: string) {
  return /^\d+$/.test(str);
}

export function prependHttps(website: string) {
  if (!website.startsWith('https://') && !website.startsWith('http://')) {
    website = 'https://' + website;
  }

  return website;
}

export function formatPhoneNumber(phone: string) {
  const hasCountryCode = phone.startsWith('+1');
  const numberWithoutCountryCode = hasCountryCode ? phone.substring(2) : phone;

  if (numberWithoutCountryCode.length < 10) {
    Logger.warn('Invalid phone number format');
    return phone;
  }

  return `(${numberWithoutCountryCode.substring(0, 3)}) ${numberWithoutCountryCode.substring(3, 6)}-${numberWithoutCountryCode.substring(6)}`;
}

export function formatCurrency(value: number) {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(value);
}

export function formatDate(value: string) {
  if (!value) return '';
  return dayjs(value).format('M/D/YYYY');
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const numberTransformer = (value: any) => {
  if (value === '') return undefined;
  return toNumber(value);
};

export type Brand<K, T> = K & { __brand: T };
