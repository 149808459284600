import * as React from 'react';
import { useToggle } from '~/src/shared/hooks/use-toggle.ts';
import useScreenSize from '~/src/shared/hooks/use-screen-size.ts';

export function usePopup<T extends HTMLElement>() {
  const { windowWidth, windowHeight } = useScreenSize();
  const ref = React.useRef<T>(null);
  const popUpRef = React.useRef<HTMLElement>();
  const [expanded, toggleExpanded, setExpanded] = useToggle(false);

  function positionPopUp(button: DOMRect, popup: HTMLElement) {
    popup.style.setProperty('left', `${button.left}px`);

    // If dropdown overflows viewport width, make it pop to the left
    if (button.right + popup.offsetWidth > windowWidth) {
      popup.style.removeProperty('left');
      popup.style.setProperty('right', `${windowWidth - button.right}px`);
    }

    const popUpFitBelowTheButton =
      button.bottom + popup.offsetHeight + 5 < windowHeight;
    const popUpFitAboveTheButton =
      windowHeight - button.y - 70 > popup.offsetHeight;

    if (popUpFitBelowTheButton) {
      popup.style.setProperty('top', `${button.bottom + 5}px`);
    } else if (popUpFitAboveTheButton) {
      popup.style.setProperty(
        'top',
        `${button.top + window.scrollY - popup.offsetHeight - 5}px`,
      );
    } else {
      popup.style.setProperty('top', `${button.bottom + 5}px`);
      popup.style.setProperty(
        'height',
        `${windowHeight - button.bottom - 20}px`,
      );
    }
  }

  function onPopupDisplay(element: HTMLElement | null) {
    if (element === null || !ref.current) {
      return;
    }

    popUpRef.current = element;

    const rectButton = ref.current.getBoundingClientRect();
    const buttonWidth = ref.current.offsetWidth;

    if (buttonWidth > element.offsetWidth) {
      element.style.setProperty('width', `${buttonWidth}px`);
    }

    positionPopUp(rectButton, element);
  }

  React.useEffect(() => {
    function onFocusChange(event: FocusEvent) {
      if (
        event.target instanceof Element &&
        !popUpRef.current?.contains(event.target)
      ) {
        setExpanded(false);
      }
    }

    document.addEventListener('focusin', onFocusChange);
    return () => {
      document.addEventListener('focusin', onFocusChange);
    };
  }, [setExpanded]);

  React.useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        !popUpRef.current?.contains(event.target as Node) &&
        !ref.current?.contains(event.target as Node)
      ) {
        setExpanded(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setExpanded]);

  return { ref, onPopupDisplay, expanded, toggleExpanded, setExpanded };
}
