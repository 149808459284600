import { Select } from '~/src/shared/ui/components/select/select.tsx';
import { Input } from '~/src/shared/ui/components';
import { numberTransformer } from '~/src/shared/utils.ts';
import { UnitOfMeasure, UnitOfMeasureOptions } from '~/src/inventory/consts.ts';
import { Datepicker } from '~/src/shared/ui/components/datepicker/datepicker.tsx';
import { useCustomForm } from '~/src/shared/hooks/use-custom-form.ts';
import {
  InventoryItem,
  InventoryItemVendor,
} from '~/src/inventory/inventory.type.ts';
import { paths } from '~/src/shared/api/api.schema.ts';
import { Logger } from '~/src/shared/service/logger';
import { FormModal } from '~/src/shared/ui/components/modal/modal';
import {
  QueryObserverResult,
  RefetchOptions,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import { mutation, query } from '~/src/shared/api/request-config.ts';
import useAlert from '~/src/shared/ui/components/alert/hooks/use-alert.tsx';
import { useNavigate } from 'react-router-dom';
import useUpdateEffect from '~/src/shared/hooks/use-update-effect.ts';

interface InventoryItemVendorModalProps {
  refetchVendors: (
    options?: RefetchOptions | undefined,
  ) => Promise<
    QueryObserverResult<InventoryItemVendor[] | InventoryItemVendor, Error>
  >;
  item: InventoryItem;
}

export default function InventoryItemVendorModal(
  props: InventoryItemVendorModalProps,
) {
  const { item, refetchVendors } = props;
  const navigate = useNavigate();
  const alert = useAlert();

  const { mutate, status, error } = useMutation(
    mutation(paths.createInventoryItemVendor, {
      params: { id: item.id.toString() },
    }),
  );

  const { data: vendors } = useQuery({
    ...query(paths.vendors),
  });

  const defaultInventoryItemVendor = {
    vendor: {},
    unitOfMeasure: '' as UnitOfMeasure,
    vendorUnitOfMeasure: '' as UnitOfMeasure,
  } as InventoryItemVendor;

  useUpdateEffect(() => {
    if (status === 'success') {
      Logger.info('Created new inventory item vendor.');
      alert.showSuccess('Created new inventory item vendor.');
      refetchVendors();
      navigate(-1);
    }

    if (status === 'error') {
      Logger.warn('Error on creating inventory item vendor.', { data: error });
      navigate(-1);
      alert.showError('Error on creating inventory item vendor.');
    }
  }, [status]);

  const { setValue, register, handleSubmit, isDirty, isSubmitting } =
    useCustomForm<InventoryItemVendor>({
      initialData: defaultInventoryItemVendor,
      watchChangesOf: ['qtyPerSKU', 'skuCost'],
      onChange: (data) => {
        if (data.skuCost !== null && data.qtyPerSKU !== null) {
          const unitPrice = (
            (data.skuCost as number) / (data.qtyPerSKU as number)
          ).toFixed(2);
          setValue('unitPrice', parseFloat(unitPrice));
        }
      },
      handleSubmit: async (data) => {
        mutate(data);
      },
    });

  return (
    <FormModal
      name="inventory-item-vendor"
      title="Add New Vendor"
      id="new-inventory-item-vendor-modal"
      data-testid="inventory-item-vendor-modal"
      onSubmit={() => handleSubmit}
      isDirty={isDirty}
      isSubmitting={isSubmitting}
      floating
    >
      <div className="_section">
        <div className="_row">
          <Select label="Vendor" required {...register(`vendor`)}>
            <option value="0" disabled>
              Select a vendor
            </option>
            {vendors?.map((vendor, vendor_index) => (
              <option
                key={vendor_index}
                value={vendor.id}
                className="!hover:bg-black"
              >
                {vendor.name}
              </option>
            ))}
          </Select>
          <Input
            label="SKU"
            placeholder="SKU Number"
            required
            {...register(`sku`)}
          />
        </div>
        <div className="_row">
          <Input
            label="SKU Cost"
            type="number"
            step="0.01"
            placeholder="$"
            required
            {...register(`skuCost`, {
              setValueAs: numberTransformer,
            })}
          />
          <Input
            label="Quantity Per SKU"
            type="number"
            placeholder="Quantity"
            required
            {...register(`qtyPerSKU`, {
              setValueAs: numberTransformer,
            })}
          />
        </div>

        <div className="_row">
          <Select
            label="Sold as Unit of Measure"
            required
            {...register(`unitOfMeasure`)}
            helpText="How we sell the product to our customers"
          >
            <option value="" disabled>
              Select a unit of measure
            </option>
            {Object.entries(UnitOfMeasureOptions).map(
              ([uomKey, uomTitle], index) => (
                <option key={index} value={uomKey} className="!hover:bg-black">
                  {uomTitle}
                </option>
              ),
            )}
          </Select>
          <Select
            label="Vendor Unit of Measure"
            required
            {...register(`vendorUnitOfMeasure`)}
            helpText="How our vendor sell it to us"
          >
            <option value="" disabled>
              Select a unit of measure
            </option>
            {Object.entries(UnitOfMeasureOptions).map(
              ([uomKey, uomTitle], index) => (
                <option key={index} value={uomKey} className="!hover:bg-black">
                  {uomTitle}
                </option>
              ),
            )}
          </Select>
        </div>
        <div className="_row">
          <Input
            label="Unit Price"
            type="number"
            step="0.01"
            disabled
            placeholder="$"
            {...register(`unitPrice`, {
              setValueAs: numberTransformer,
            })}
            helpText="Calculated as Cost and Qty are entered"
          />
          <Datepicker
            label="Date pricing"
            {...register(`datePricing`)}
            placeholder="mm/dd/yyyy"
          />
        </div>

        <div className="_row">
          <Input
            required={item.category.split('_')[0] === 'CANDY'}
            label="Pieces Per Pound"
            type="number"
            placeholder="-"
            step="0.01"
            {...register('piecesPerPound', {
              setValueAs: numberTransformer,
            })}
          />
          <Input
            label="Quantity Per Pallet"
            type="number"
            placeholder="-"
            {...register('quantityPerPallet', {
              setValueAs: numberTransformer,
            })}
          />
        </div>
        <div className="_row">
          <Input
            label="Vendor Notes"
            placeholder="Add vendor notes here..."
            {...register('vendorNotes')}
            defaultValue=""
          />
        </div>
      </div>
    </FormModal>
  );
}
