import { useLocation, useNavigate } from 'react-router-dom';
import { capitalize } from 'lodash-es';

import { AngleRight } from '~/src/shared/ui/icons/angles.tsx';

import './breadcrumbs.css';
import { isNumeric } from '~/src/shared/utils.ts';

interface BreadcrumbsProps {
  idMapping?: Record<string, string>;
  depth?: number;
}

export function Breadcrumbs({ idMapping, depth }: BreadcrumbsProps) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const originalPathnames = pathname.split('/').filter((x) => x);
  const displayPathnames = originalPathnames.map((x) => x.replace(/-/g, ' '));

  const limitedPathnames = depth
    ? originalPathnames.slice(0, depth + 1)
    : originalPathnames;
  const limitedDisplayPathnames = depth
    ? displayPathnames.slice(0, depth + 1)
    : displayPathnames;

  return (
    <nav aria-label="breadcrumb">
      <ol className="_breadcrumbs">
        {limitedPathnames.slice(0, -1).map((value, index) => {
          const to = `/${limitedPathnames.slice(0, index + 1).join('/')}`;
          const text =
            idMapping && isNumeric(value)
              ? idMapping[value]
              : limitedDisplayPathnames[index];

          return (
            <li key={to} className="flex">
              {index !== 0 && <AngleRight size="xs" />}
              <button onClick={() => navigate(to)}>{capitalize(text)}</button>
            </li>
          );
        })}
      </ol>
    </nav>
  );
}
