import { Table } from '~/src/shared/ui/components/table/table.tsx';
import { TableColumn } from '~/src/shared/ui/components/table/types.ts';
import { useNavigate } from 'react-router-dom';
import { routes } from '~/src/routes.ts';
import { DropDownOption } from '~/src/shared/ui/components/select/dropdown.tsx';
import {
  PurchaseOrder,
  PurchaseOrderStatusOptions,
} from '~/src/purchase-orders/purchase-orders.type.ts';

export function PurchaseOrdersTable(props: {
  purchaseOrders: PurchaseOrder[];
}) {
  const { purchaseOrders } = props;
  const navigate = useNavigate();

  const columns = [
    {
      field: 'submittedDate',
      headerName: 'Submitted Date',
      formatType: 'date',
    },
    {
      field: 'deliveryByDate',
      headerName: 'Delivery by Date',
      formatType: 'date',
    },
    { field: 'internalNotes', headerName: 'Internal Notes' },
    { field: 'notesForVendor', headerName: 'PO Notes for Vendor' },
    { field: 'total', headerName: 'PO Total', formatType: 'currency' },
    { field: 'poNumber', headerName: 'PO Number' },
  ] as TableColumn<Partial<PurchaseOrder>>[];

  const statusOptions = Object.entries(PurchaseOrderStatusOptions).map(
    ([key, value]) => ({ value: key, title: value }),
  ) as DropDownOption[];

  return (
    <Table<Partial<Omit<PurchaseOrder, 'id' | 'vendor' | 'contact' | 'lines'>>>
      name="Purchase Order"
      columns={columns}
      rows={purchaseOrders}
      defaultRowsPerPage={50}
      onRowClick={({ id }) => navigate(routes.purchaseOrderInfo.getPath(id))}
      filters={{
        status: statusOptions,
      }}
    />
  );
}
