import * as React from 'react';

export default function Checkmark(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.8359 4.91406C13.0469 5.14844 13.0469 5.5 12.8359 5.71094L6.64844 11.8984C6.41406 12.1328 6.0625 12.1328 5.85156 11.8984L2.66406 8.71094C2.42969 8.5 2.42969 8.14844 2.66406 7.9375C2.875 7.70312 3.22656 7.70312 3.4375 7.9375L6.22656 10.7266L12.0391 4.91406C12.25 4.70312 12.6016 4.70312 12.8125 4.91406H12.8359Z"
        fill="white"
      />
    </svg>
  );
}
