import { isNil } from 'lodash-es';

type LocalStorageKey = 'AuthUser' | 'AuthToken';
type SessionStorageKey = 'Flow' | 'SessionId';

class StorageWrapper<
  T extends SessionStorageKey | LocalStorageKey,
  V = unknown,
> {
  private storage: Storage;
  private storageType: string;
  cache: Record<string, V | null> = {};
  excludeFromCache: T[];

  constructor(storage: Storage, excludeFromCache?: T[]) {
    this.storage = storage;
    this.excludeFromCache = excludeFromCache ?? [];
    this.storageType =
      storage == localStorage ? 'localStorage' : 'sessionStorage';
  }

  private prefixed(key: T): string {
    return `LYG__${key}`;
  }

  clear(key: T): void {
    delete this.cache[key];
    try {
      const prefixedKey = this.prefixed(key);
      this.storage.removeItem(prefixedKey);
    } catch (error) {
      console.warn(`Failed to remove item from ${this.storageType}`);
    }
  }

  read(key: T): V | null {
    if (!this.excludeFromCache.includes(key) && key in this.cache) {
      return this.cache[key];
    }

    let stored = null;
    try {
      stored = this.storage.getItem(this.prefixed(key));
    } catch (error) {
      console.warn(`Failed to read item from ${this.storageType}`);
      return null;
    }

    if (stored) {
      try {
        this.cache[key] = JSON.parse(stored) as V;
      } catch (error) {
        this.cache[key] = stored as unknown as V;
      }
    } else {
      this.cache[key] = null;
    }

    return this.cache[key];
  }

  write(key: T, data: V): void {
    if (isNil(data) || data === '') {
      this.clear(key);
      return;
    }

    if (!this.excludeFromCache.includes(key)) {
      this.cache[key] = data;
    }

    try {
      const prefixedKey = this.prefixed(key);
      const content =
        typeof data === 'object' ? JSON.stringify(data) : String(data);
      this.storage.setItem(prefixedKey, content);
    } catch (error) {
      console.warn(`Failed to write item to ${this.storageType}`);
    }
  }
}

export const SessionStorage = new StorageWrapper<SessionStorageKey>(
  sessionStorage,
);
export const LocalStorage = new StorageWrapper<LocalStorageKey>(localStorage, [
  'AuthToken',
]);
