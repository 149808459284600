import { Table } from '~/src/shared/ui/components/table/table.tsx';
import { TableColumn } from '~/src/shared/ui/components/table/types.ts';
import { useLocation, useNavigate } from 'react-router-dom';
import { paths } from '~/src/shared/api/api.schema.ts';
import { query } from '~/src/shared/api/request-config.ts';
import { useQuery } from '@tanstack/react-query';
import { routes } from '~/src/routes.ts';
import { Button } from '~/src/shared/ui/components';
import {
  PurchaseOrder,
  PurchaseOrderStatusOptions,
} from '../purchase-orders.type.ts';
import { DropDownOption } from '~/src/shared/ui/components/select/dropdown.tsx';
import { PurchaseOrderStandaloneModal } from '../components/purchase-order-standalone-modal.component.tsx';

export function PurchaseOrders() {
  const { data, fetchStatus } = useQuery({
    ...query(paths.purchaseOrders),
  });

  const isLoading = fetchStatus === 'fetching';
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const columns = [
    { field: 'vendorName', headerName: 'Vendor' },
    {
      field: 'submittedDate',
      headerName: 'Submitted Date',
      formatType: 'date',
    },
    {
      field: 'deliveryByDate',
      headerName: 'Delivery by Date',
      formatType: 'date',
    },
    { field: 'internalNotes', headerName: 'Internal Notes' },
    { field: 'notesForVendor', headerName: 'PO Notes for Vendor' },
    { field: 'total', headerName: 'PO Total', formatType: 'currency' },
    { field: 'poNumber', headerName: 'PO Number' },
  ] as TableColumn<Partial<PurchaseOrder>>[];

  const statusOptions = Object.entries(PurchaseOrderStatusOptions).map(
    ([key, value]) => ({ value: key, title: value }),
  ) as DropDownOption[];

  return (
    <div className="_page">
      <header>
        <div>
          <h1>Purchase Orders</h1>
        </div>

        <div className="_header-actions">
          <Button onClick={() => {}}>
            Create Purchase Order Based on Orders
          </Button>
          <Button
            onClick={() =>
              navigate(routes.createPurchaseOrderStandaloneModal.path)
            }
          >
            Create Stand Alone Purchase Order
          </Button>
        </div>
      </header>

      <Table<
        Partial<Omit<PurchaseOrder, 'id' | 'vendor' | 'contact' | 'lines'>>
      >
        name="Purchase Order"
        columns={columns}
        rows={data ?? []}
        loading={isLoading}
        defaultRowsPerPage={50}
        onRowClick={({ id }) => navigate(routes.purchaseOrderInfo.getPath(id))}
        showSearchInput
        filters={{
          // TODO LUCKYYOU-41: make this a toggle instead of dropdown
          status: statusOptions,
        }}
        initialFilters={{
          status: statusOptions.find((o) => o.value === 'OPEN'),
        }}
      />

      {pathname.includes('create-standalone') ? (
        <PurchaseOrderStandaloneModal />
      ) : null}
    </div>
  );
}
