import { Table } from '~/src/shared/ui/components/table/table.tsx';
import { TableColumn } from '~/src/shared/ui/components/table/types.ts';
import { Order } from '../orders.type.ts';
import { useNavigate } from 'react-router-dom';
import { routes } from '~/src/routes.ts';

interface OrdersTableProps {
  orders: Required<Order>[];
  isLoading: boolean;
  includeCompany?: boolean;
}

export function OrdersTable({
  orders,
  isLoading,
  includeCompany = true,
}: OrdersTableProps) {
  const navigate = useNavigate();

  const columns = [
    ...(includeCompany
      ? [{ field: 'customer.companyName', headerName: 'Company' }]
      : []),
    { field: 'name', headerName: 'Order Name' },
    { field: 'shipDate', headerName: 'Ship Date', formatType: 'date' },
    { field: 'inStoreDate', headerName: 'In Store Date', formatType: 'date' },
    { field: 'quantityOrdered', headerName: 'Qty Ordered' },
    { field: 'quantityProduced', headerName: 'Qty Produced' },
    { field: 'orderTotal', headerName: 'Order Total', formatType: 'currency' },
  ] as TableColumn<Partial<Order>>[];

  return (
    <Table<Partial<Omit<Order, 'components'>>>
      name="Order"
      columns={columns}
      rows={orders}
      loading={isLoading}
      defaultRowsPerPage={50}
      onRowClick={(row) => navigate(routes.orderDetail.getPath(row.id))}
      showSearchInput
    />
  );
}
