import { useEffect, useMemo } from 'react';
import { Breadcrumbs } from '~/src/shared/ui/components/breadcrumbs/breadcrumbs';
import InfoPiece from '~/src/shared/ui/components/info-piece/info-piece.component';
import { Menu, MenuOption } from '~/src/shared/ui/components/menu/menu';
import Pencil from '~/src/shared/ui/icons/pencil';
import Trash from '~/src/shared/ui/icons/trash';
import Print from '~/src/shared/ui/icons/print';
import Envelope from '~/src/shared/ui/icons/envelope';
import { Colors } from '~/src/shared/ui/styles/const';
import { useParams } from 'react-router-dom';
import '~/src/purchase-orders/styles/purchase-orders-common.page.css';
import { ItemsTable } from '../components/items-table.component';
import { paths } from '~/src/shared/api/api.schema';
import { query } from '~/src/shared/api/request-config';
import { useQuery } from '@tanstack/react-query';
import Loader from '~/src/shared/ui/components/loading/loading';
import { formatDate } from '~/src/shared/utils';
import useAlert from '~/src/shared/ui/components/alert/hooks/use-alert';
import { PurchaseOrderLineRow } from '../purchase-orders.type';
import { PurchaseOrderDocument } from '../components/purchase-order-document.component';
import { usePDF } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';

export function PurchaseOrderDetail() {
  const { purchaseOrderId } = useParams();
  const alert = useAlert();

  const { data: purchaseOrder } = useQuery({
    ...query(paths.purchaseOrderDetail, {
      params: { id: purchaseOrderId as string },
    }),
  });

  const purchaseOrderDocument = useMemo(
    () => <PurchaseOrderDocument order={purchaseOrder} />,
    [purchaseOrder],
  );

  const [pdfInstance, updatePdf] = usePDF({
    document: purchaseOrderDocument,
  });

  useEffect(() => {
    updatePdf(purchaseOrderDocument);
  }, [purchaseOrder, updatePdf, purchaseOrderDocument]);

  if (!purchaseOrder) {
    return <Loader />;
  }

  const linesAsRows: Required<PurchaseOrderLineRow>[] = purchaseOrder.lines.map(
    (line) => ({
      id: line.id,
      vendorInventoryItemId: line.inventoryItemVendor.id,
      itemName: line.inventoryItemVendor.name as string,
      sku: line.inventoryItemVendor.sku,
      skuCost: line.inventoryItemVendor.skuCost,
      sellUnitPrice: line.inventoryItemVendor.unitPrice as number,
      qty: line.quantity,
      numberOfPallets: line.numberOfPallets,
      lineTotal: line.lineTotal,
      receivedDate: line.receivedDate as string,
    }),
  );

  return (
    <div id="purchase-orders-details-page" className="_page">
      <header>
        <div>
          <Breadcrumbs />
          <h1>#{purchaseOrder.poNumber}</h1>
        </div>

        <div>
          <Menu>
            <MenuOption
              action={() => alert.showWarning('Not yet implemented.')}
              icon={<Pencil />}
              label="Edit"
            />
            <MenuOption
              action={() =>
                pdfInstance.blob &&
                saveAs(pdfInstance.blob, `PO# ${purchaseOrder?.poNumber}.pdf`)
              }
              icon={<Print />}
              label="Print"
            />
            <MenuOption
              action={() => alert.showWarning('Not yet implemented.')}
              icon={<Envelope />}
              label="Email"
            />
            <MenuOption
              action={() => alert.showWarning('Not yet implemented.')}
              icon={<Trash color={Colors.danger['500']} />}
              label="Delete Purchase Order"
              color="danger"
            />
          </Menu>
        </div>
      </header>

      <div className="_row">
        <InfoPiece label="Vendor" content={purchaseOrder.vendor.name} />
        <InfoPiece label="Contact" content={purchaseOrder.contact.fullName} />
        <InfoPiece
          label="Phone Number"
          content={purchaseOrder.vendor.phoneNumber}
        />
        <InfoPiece label="Email" content={purchaseOrder.contact.email} />
      </div>
      <div className="_row">
        <InfoPiece
          label="Purchase Order Date"
          content={formatDate(purchaseOrder.submittedDate)}
        />
        <InfoPiece
          label="Delivery by Date"
          content={formatDate(purchaseOrder.deliveryByDate)}
        />
        <InfoPiece label="Buyer" content={purchaseOrder.orderedBy} />
        <span className="w-[25%]"></span>
      </div>
      <div className="_row">
        <InfoPiece
          label="P.O. Notes for Vendor"
          content={purchaseOrder.notesForVendor}
        />
      </div>

      <div className="_container">
        <ItemsTable purchaseOrderLines={linesAsRows} readOnly={true} />
      </div>

      <div className="_row">
        <InfoPiece label="Associated Orders" content="" />
      </div>
      <div className="_row">
        <InfoPiece
          label="Internal Notes"
          content={purchaseOrder.internalNotes}
        />
      </div>
    </div>
  );
}
