import * as React from 'react';

import { clean } from '~/src/shared/ui/styles/helpers';
import './radio-button.css';
import { capitalize } from 'lodash-es';

interface RadioButtonProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  name: string;
  label?: string;
}

export function RadioButton(props: RadioButtonProps) {
  const { name, value, className, children, ...rest } = props;
  const inputId = `${name}__value__${value}`;
  return (
    <label
      title={capitalize(name)}
      htmlFor={inputId}
      className={clean(`_radio-button-label ${className}`)}
    >
      <input type="radio" id={inputId} name={name} value={value} {...rest} />

      {children}
    </label>
  );
}
