import * as React from 'react';

import { clean } from '~/src/shared/ui/styles/helpers';

import './button.css';

interface ButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  loading?: boolean;
  loadingText?: string;
}

export function Button(props: ButtonProps) {
  const {
    loading,
    loadingText = 'Please wait...',
    children,
    className,
    disabled,
    ...rest
  } = props;

  return (
    <button
      type="button"
      className={clean(`_button ${className}`)}
      disabled={disabled || loading}
      {...rest}
    >
      {loading ? loadingText : children}
    </button>
  );
}

interface TextButton extends ButtonProps {
  leftIcon?: React.JSX.Element;
  rightIcon?: React.JSX.Element;
}

export function TextButton(props: TextButton) {
  const { children, leftIcon, rightIcon, ...rest } = props;
  return (
    <button
      type="button"
      className="w-fit font-medium flex items-center gap-1"
      {...rest}
    >
      {leftIcon}
      <span className="text-sm">{children}</span>
      {rightIcon}
    </button>
  );
}
