import {
  CustomInventoryItemVendor,
  InventoryItem,
  InventoryItemVendor,
} from '~/src/inventory/inventory.type';
import { QuoteItemType } from '~/src/quotes/quotes.type.ts';

export interface InventoryAPI {
  'GET /inventory/': {
    response: Required<InventoryItem>[];
  };
  'POST /inventory/': {
    body: Required<InventoryItem>;
    response: Required<InventoryItem>;
  };
  'GET /inventory/:id/': {
    params: { id: string };
    response: Required<InventoryItem>;
  };
  'DELETE /inventory/:id/': {
    params: { id: string };
    response: null;
  };
  'PUT /inventory/:id/': {
    params: { id: string };
    response: Required<InventoryItem>;
  };
  'GET /inventory/:id/item-vendors/': {
    params: { id: string };
    response: Required<InventoryItemVendor>[];
  };
  'POST /inventory/:id/item-vendors/': {
    params: { id: string };
    body: Omit<InventoryItemVendor, 'id'>;
    response: Required<InventoryItemVendor>;
  };
  'GET /inventory-item-vendors/': {
    query: { category_type?: QuoteItemType };
    response: Required<CustomInventoryItemVendor>[];
  };
}

export const inventoryPaths = {
  inventoryItem: 'GET /inventory/:id/',
  inventoryItems: 'GET /inventory/',
  createInventoryItem: 'POST /inventory/',
  deleteInventoryItem: 'DELETE /inventory/:id/',
  editInventoryItem: 'PUT /inventory/:id/',
  itemVendors: 'GET /inventory/:id/item-vendors/',
  inventoryItemVendors: 'GET /inventory-item-vendors/',
  createInventoryItemVendor: 'POST /inventory/:id/item-vendors/',
} satisfies Record<string, keyof InventoryAPI>;
