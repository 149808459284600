import { Brand } from '../shared/utils';
import { Contact } from '~/src/contacts/contacts.type.ts';
import { Customer } from '~/src/customers/customers.type.ts';
import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query';
import { InventoryItemVendor } from '~/src/inventory/inventory.type.ts';

export const QuoteStatus = ['open', 'bought', 'lost', 'sample'] as const;

export const QuoteDescriptionSection = [
  'container',
  'content',
  'enhancement',
  'packaging',
] as const;

export const QuoteNoteType = [
  'quote_note',
  'production_note',
  'order_note',
  'shipping_note',
] as const;

export interface QuoteDescription {
  id: Brand<number, 'QuoteDescription'>;
  section: (typeof QuoteDescriptionSection)[number];
  marketingDescription?: string;
  packCardDescription?: string;
  quickBooks?: string;
}

export interface QuoteNote {
  id: Brand<number, 'QuoteNote'>;
  type: (typeof QuoteNoteType)[number];
  note: string;
}

export type QuoteItemType =
  | 'container'
  | 'content'
  | 'enhancement'
  | 'packaging'
  | 'set_up_fees';

interface QuoteItemBase {
  id: number;
  quote: number;
  type: QuoteItemType;
  quantity: number;
}

export interface QuoteItem extends QuoteItemBase {
  inventoryItemVendor: InventoryItemVendor;
}

export interface QuoteItemPayload extends QuoteItemBase {
  inventoryItemVendor: number;
}

export interface SetUpFee {
  id?: number;
  name: string;
  amount: string;
  isActive: boolean;
}

export interface Discount {
  id?: number;
  description: string;
  value: string;
  isActive: boolean;
}

export interface FreightMarkup {
  id?: number;
  reason: string;
  value: string;
  is_active: boolean;
}

export interface Quote {
  id: Brand<number, 'Quote'>;
  name: string;
  status: (typeof QuoteStatus)[number];
  customer: Customer;
  contact: Contact;
  quoteNumber: string;
  quotedBy: string;
  quoteDate: string;
  giftPrice: string;
  discountedGiftPrice: string;
  preTaxTotal: string;
  marketingCategory: keyof typeof MarketingCategory;
  quantity: number;
  depositRequired: boolean;
  laborMinutes: number;
  costPerMinute: string;
  caQuantity: string;

  billingMethod: string;
  shippingService: string;
  accountNumber: string;
  shipLabelReference: string;
  shipDate: string;
  inStoreDate: string;
  shipWeight: number;
  shipHeight: number;
  shipWidth: number;
  shipLength: number;

  pieceCount: number;
  packCardPrice: number;
  packCardDiscount: number;
  quoteDescriptions?: QuoteDescription[];
  quoteNotes?: QuoteNote[];
  quoteItems?: QuoteItem[];

  freightMarkups: FreightMarkup[];
  setUpFees: SetUpFee[];
  discounts: Discount[];

  totalFreightMarkup: string;
  totalSetUpFees: string;
  materialTotal: string;
  laborTotal: string;
  materialPlusLabor: string;
  freightCost: string;
  costOfGoods: string;
  profitPerGift: string;
  totalOrderPrice: string;
  totalDiscount: string;
  postDiscountProfit: string;
  totalDiscountedOrderPrice: string;
  total: string;
  isActive: boolean;
  markup: string;
  taxRate: string;
}

export const MarketingCategory = {
  anniversary: 'Anniversary',
  credit_card: 'Credit Card',
  holiday: 'Holiday',
  sales_contest: 'Sales Contest',
};

export interface QuoteControl {
  quote: Quote;
  updateQuote: (data: Partial<Quote>) => void;
  refetchQuote: (
    options?: RefetchOptions | undefined,
  ) => Promise<QueryObserverResult<Quote, Error>>;
}
