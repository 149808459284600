import { IconProps } from '~/src/shared/ui/icons/types.ts';

export function CartShopping(props: IconProps) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.625 8H9.78125C10.875 8.0625 11.6562 8.5625 12.125 9.5H31.3906C32.0156 9.53125 32.5156 9.78125 32.8906 10.25C33.2344 10.7188 33.3438 11.25 33.2188 11.8438L31.2969 19.0156C31.0781 19.7656 30.6719 20.3594 30.0781 20.7969C29.4844 21.2656 28.7969 21.5 28.0156 21.5H14.5156L14.75 22.8594C14.9062 23.4219 15.2812 23.7188 15.875 23.75H29.375C30.0625 23.8125 30.4375 24.1875 30.5 24.875C30.4375 25.5625 30.0625 25.9375 29.375 26H15.875C15.0312 26 14.3125 25.75 13.7188 25.25C13.0938 24.7188 12.7031 24.0625 12.5469 23.2812L10.1094 10.5781C10.0781 10.3594 9.96875 10.25 9.78125 10.25H7.625C6.9375 10.1875 6.5625 9.8125 6.5 9.125C6.5625 8.4375 6.9375 8.0625 7.625 8ZM12.6406 11.75L14.0938 19.25H28.0156C28.5781 19.2188 28.9375 18.9375 29.0938 18.4062L30.9219 11.75H12.6406H30.9219H12.6406ZM14.75 32C13.9062 31.9688 13.25 31.5938 12.7812 30.875C12.4062 30.125 12.4062 29.375 12.7812 28.625C13.25 27.9062 13.9062 27.5312 14.75 27.5C15.5938 27.5312 16.25 27.9062 16.7188 28.625C17.0938 29.375 17.0938 30.125 16.7188 30.875C16.25 31.5938 15.5938 31.9688 14.75 32ZM30.5 29.75C30.4688 30.5938 30.0938 31.25 29.375 31.7188C28.625 32.0938 27.875 32.0938 27.125 31.7188C26.4062 31.25 26.0312 30.5938 26 29.75C26.0312 28.9062 26.4062 28.25 27.125 27.7812C27.875 27.4062 28.625 27.4062 29.375 27.7812C30.0938 28.25 30.4688 28.9062 30.5 29.75Z"
        fill="black"
      />
    </svg>
  );
}
