import * as React from 'react';

import { clean } from '~/src/shared/ui/styles/helpers';

import './text-area.css';
import { CircleExclamationSolid } from '~/src/shared/ui/icons/circle-exclamation.tsx';

interface TextAreaProps
  extends React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  label: string;
  icon?: React.ReactElement;
  hideErrors?: boolean;
  action?: React.ReactElement;
  hideLabel?: boolean;
  invalid?: boolean;
  helpText?: string;
}

export const TextArea = React.forwardRef(function Input(
  props: TextAreaProps,
  ref: React.ForwardedRef<HTMLTextAreaElement>,
) {
  const {
    icon,
    name,
    label,
    className,
    hideErrors,
    hideLabel,
    required,
    invalid,
    helpText,
    ...rest
  } = props;

  const labelId = `${name}__label`;
  const errorId = `${name}__error`;

  return (
    <label htmlFor={name} className={clean(`_input-label ${className}`)}>
      <div
        id={labelId}
        className={clean(
          `_label-text ${required ? '_required' : ''} ${hideLabel ? 'hidden' : ''} ${invalid ? 'text-danger-500' : 'text-gray-900'}`,
        )}
      >
        {label}
      </div>
      <span className="left-3">{icon}</span>
      <textarea
        id={name}
        name={name}
        aria-describedby={errorId}
        className={clean(
          `_text-area ${icon ? 'pl-9' : ''} ${invalid ? 'border-danger-500' : ''}`,
        )}
        ref={ref}
        required={required}
        {...rest}
      />

      {invalid && (
        <span className="right-3">
          <CircleExclamationSolid />
        </span>
      )}

      {helpText && <div className="_help-text">{helpText}</div>}
      {!hideErrors && <div id={errorId} className="_error-message" />}
    </label>
  );
});
