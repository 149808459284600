import * as React from 'react';
import { clean } from '~/src/shared/ui/styles/helpers.ts';
import './toggle.css';

interface ToggleProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  leftLabel?: string;
  rightLabel?: string;
  invalid?: boolean;
}

export const Toggle = React.forwardRef(function Toggle(
  props: ToggleProps,
  ref: React.ForwardedRef<HTMLInputElement>,
) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { leftLabel, rightLabel, invalid, ...rest } = props;
  const style = clean(
    `${leftLabel && 'justify-between w-full'} ${rightLabel && 'gap-2 w-fit'}`,
  );

  return (
    <div className={clean(`flex items-center ${style}`)}>
      {leftLabel && <p>{leftLabel}</p>}
      <label className="_switch">
        <input type="checkbox" ref={ref} {...rest} />
        <span className="_slider" />
      </label>
      {!leftLabel && rightLabel && <p>{rightLabel}</p>}
    </div>
  );
});
