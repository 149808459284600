import { Brand } from '../shared/utils';
import { Contact } from '~/src/contacts/contacts.type.ts';
import { Customer } from '~/src/customers/customers.type.ts';
import { ShippingService } from '~/src/quotes/consts.ts';
import { UnitOfMeasure } from '~/src/inventory/consts.ts';
import { QuoteNoteType } from '~/src/quotes/quotes.type.ts';

export interface OrderComponentBase {
  quantity: number;
  modifier: string;
}

export interface OrderComponent extends OrderComponentBase {
  id: number;
  inventoryItemVendor: {
    name: string;
    unitOfMeasure: UnitOfMeasure;
  };
}

export interface OrderComponentWrite extends OrderComponentBase {
  inventoryItemVendor: number;
}

export const OrderType = [
  'order',
  'online_order',
  'sample',
  'closed',
  'order_to_invoice',
];

export const BillingMethod = {
  BILLED_THIRD_PARTY: 'Billed Third Party',
  INCLUDED: 'Included',
  BILLED_TO_CUSTOMER: 'Billed To Customer',
};

export interface OrderNote {
  id: Brand<number, 'OrderNote'>;
  type: (typeof QuoteNoteType)[number];
  note: string;
}

export interface Order {
  id: Brand<number, 'Order'>;
  name: string;
  orderDate: string;
  customer: Customer;
  contact: Contact;
  accountRep: string;
  components: OrderComponent[];
  orderType: (typeof OrderType)[number];

  quantity: number;
  sampleQuantity: number;
  caQuantity: number;
  nonCaQuantity: number;
  canadianQuantity: number;

  billingMethod: keyof typeof BillingMethod;
  shippingService: keyof typeof ShippingService;
  accountNumber: string;
  shippingMethod: string;
  shipWeight: number;
  shipHeight: number;
  shipWidth: number;
  shipLength: number;
  quantityPerPallet: number;
  shipDate: string;
  inStoreDate: string;

  includesInternational: boolean;
  shippingCharges: number;
  shippingReference: string;
  shippingNotes: string;
  labelsPrinted: boolean;
  labelsChecked: boolean;

  laborMinutes: number;
  hourlyGoalPerPerson: number;
  employees: number;

  quickBooksDescription: number;
  productionComplete: boolean;
  readyToInvoice: boolean;
  invoiceNumber: string;
  depositRequired: boolean;
  depositInvoiceNumber: string;

  quantityOrdered?: number;
  quantityProduced?: number;
  orderTotal?: number;

  orderNotes?: OrderNote[];
}
