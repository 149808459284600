import * as React from 'react';

export default function useCheckboxControl<T extends string | number>(
  rowsIds: T[],
) {
  const [selectedRows, setSelectedRows] = React.useState<T[]>([]);

  function handleCheckboxChange(id: T) {
    setSelectedRows((prev) => {
      if (prev.includes(id)) {
        return prev.filter((rowId) => rowId !== id);
      } else {
        return [...prev, id];
      }
    });
  }

  function handleHeaderCheckbox() {
    if (selectedRows.length === rowsIds.length) {
      setSelectedRows([]);
    } else {
      setSelectedRows(rowsIds);
    }
  }

  return {
    handleCheckboxChange,
    handleHeaderCheckbox,
    selectedRows,
    clearSelection: () => setSelectedRows([]),
  };
}
