import { IconProps } from '~/src/shared/ui/icons/types.ts';

export default function Pencil(props: IconProps) {
  const { color } = props;

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.125 13.2812C3.21875 12.9688 3.375 12.6562 3.5625 12.4062V12.375H3.59375C3.65625 12.2812 3.75 12.1875 3.8125 12.125L13.3125 2.625C14.0938 1.84375 15.375 1.84375 16.1562 2.625L17.375 3.84375C17.4688 3.9375 17.5625 4.0625 17.625 4.15625C18.1562 4.9375 18.0625 6 17.375 6.6875L7.875 16.1875C7.84375 16.2188 7.78125 16.25 7.75 16.3125C7.71875 16.3438 7.65625 16.375 7.625 16.4062V16.4375H7.59375C7.34375 16.625 7.03125 16.7812 6.71875 16.875L4.28125 17.5938L2.9375 18C2.6875 18.0625 2.40625 18 2.21875 17.7812C2 17.5938 1.9375 17.3125 2.03125 17.0625L2.40625 15.7188L3.125 13.2812ZM13.9688 7.96875L12.0312 6.03125L6.21875 11.8438L6.59375 13.4062L8.15625 13.7812L13.9688 7.96875ZM5.03125 13.2188L4.65625 13.4688C4.625 13.5312 4.59375 13.625 4.5625 13.7188L4.34375 14.4375L3.84375 16.1562L5.5625 15.6562L6.28125 15.4375C6.375 15.4062 6.46875 15.375 6.53125 15.3438L6.78125 15L5.8125 14.75C5.53125 14.6875 5.3125 14.4688 5.25 14.1875L5.03125 13.2188ZM11.8438 8.84375L8.84375 11.8438C8.65625 12.0312 8.3125 12.0312 8.125 11.8438C7.9375 11.6562 7.9375 11.3438 8.125 11.1562L11.125 8.15625C11.3125 7.9375 11.6562 7.9375 11.8438 8.15625C12.0312 8.34375 12.0312 8.65625 11.8438 8.84375Z"
        fill={color ?? 'black'}
      />
    </svg>
  );
}
