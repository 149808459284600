import { Table } from '~/src/shared/ui/components/table/table.tsx';
import {
  TableColumn,
  MultipleSelectionOptions,
} from '~/src/shared/ui/components/table/types.ts';
import { DropDownOption } from '~/src/shared/ui/components/select/dropdown.tsx';
import { Customer } from '~/src/customers/customers.type.ts';
import { Contact } from '~/src/contacts/contacts.type.ts';
import { useNavigate } from 'react-router-dom';
import { routes } from '~/src/routes.ts';
import { Vendor } from '~/src/vendors/vendors.type';

export function ContactsTable({
  parent,
  contacts,
  isLoading,
  tableOptions,
}: {
  parent: Required<Customer | Vendor>;
  contacts: Required<Contact>[];
  isLoading: boolean;
  tableOptions?: MultipleSelectionOptions<Partial<Contact>>[];
}) {
  const navigate = useNavigate();
  contacts.map((contact) => {
    contact['fullName'] = `${contact.firstName} ${contact.lastName}`;
  });

  const columns = [
    { field: 'fullName', headerName: 'Name' },
    { field: 'jobTitle', headerName: 'Job Title' },
    { field: 'department', headerName: 'Department' },
    { field: 'phoneNumber', headerName: 'Phone' },
  ] as TableColumn<Partial<Contact>>[];

  const infoRoute =
    parent.type === 'vendor'
      ? routes.vendorContactInfo
      : parent.type === 'customer'
        ? routes.customerContactInfo
        : null;

  if (!infoRoute) throw new Error('Unrecognized parent type for contact.');

  return (
    <Table<Required<Omit<Contact, 'phones' | 'addresses'>>>
      name="Contact"
      columns={columns}
      rows={contacts}
      loading={isLoading}
      defaultRowsPerPage={50}
      onRowClick={(contact) =>
        navigate(infoRoute.getPath(parent.id, contact.id))
      }
      withCheckbox
      multipleSelectionOptions={tableOptions}
      filters={{
        status: [
          { value: null, title: 'All' },
          { value: 'active', title: 'Active' },
          { value: 'inactive', title: 'Inactive' },
        ] as DropDownOption[],
      }}
    />
  );
}
