import { useNavigate } from 'react-router-dom';
import { Table } from '~/src/shared/ui/components/table/table.tsx';
import { TableColumn } from '~/src/shared/ui/components/table/types.ts';
import { routes } from '~/src/routes.ts';
import { Quote } from '../quotes.type.ts';

interface QuoteTableProps {
  quotes: Required<Quote>[];
  isLoading: boolean;
  includeCompany?: boolean;
}

export function QuotesTable({
  quotes,
  isLoading,
  includeCompany = true,
}: QuoteTableProps) {
  const navigate = useNavigate();
  const columns = [
    ...(includeCompany ? [{ field: 'customer', headerName: 'Company' }] : []),
    { field: 'name', headerName: 'Quote Name' },
    { field: 'contact', headerName: 'Contact' },
    { field: 'quoteDate', headerName: 'Quote Date', formatType: 'date' },
    {
      field: 'discountedGiftPrice',
      headerName: 'Disc. Gift Price',
      formatType: 'currency',
    },
    {
      field: 'giftPrice',
      headerName: 'Price Per Gift',
      formatType: 'currency',
    },
    {
      field: 'preTaxTotal',
      headerName: 'Pre-Tax Total',
      formatType: 'currency',
    },
  ] as TableColumn<Partial<Quote>>[];

  return (
    <Table<
      Partial<
        Omit<Quote, 'customer' | 'contact' | 'quoteDescriptions' | 'quoteNotes'>
      >
    >
      name="Quote"
      columns={columns}
      rows={quotes}
      loading={isLoading}
      defaultRowsPerPage={50}
      onRowClick={(quote) => navigate(routes.quoteBuilding.getPath(quote.id))}
      showSearchInput
    />
  );
}
