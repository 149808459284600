import { Table } from '~/src/shared/ui/components/table/table.tsx';
import { TableColumn } from '~/src/shared/ui/components/table/types.ts';
import { useNavigate } from 'react-router-dom';
import { InventoryItemVendor } from '~/src/inventory/inventory.type.ts';
import { routes } from '~/src/routes.ts';

export function VendorItemsTable(props: { items: InventoryItemVendor[] }) {
  const { items } = props;
  const navigate = useNavigate();

  const columns = [
    { field: 'name', headerName: 'Item Name' },
    { field: 'unitPrice', headerName: 'Unit Price' },
    { field: 'unitOfMeasure', headerName: 'UOM' },
    { field: 'skuCost', headerName: 'SKU Cost' },
    { field: 'qtyPerSKU', headerName: 'Qty Per SKU' },
    { field: 'sku', headerName: 'SKU' },
  ] as TableColumn<Partial<InventoryItemVendor>>[];

  return (
    <Table<Omit<InventoryItemVendor, 'vendor'>>
      name="Item"
      columns={columns}
      rows={items}
      defaultRowsPerPage={50}
      onRowClick={(row) =>
        navigate(routes.inventoryDetail.getPath(row.inventory as number))
      }
    />
  );
}
