import { Order } from '~/src/orders/orders.type.ts';
import { Input } from '~/src/shared/ui/components';

export default function ProductionTab(props: { order: Order }) {
  const { order } = props;

  return (
    <div className="flex flex-col gap-4">
      <div className="flex gap-4">
        <Input
          name="laborMinutes"
          label="Labor Minutes"
          placeholder="-"
          defaultValue={order.laborMinutes}
        />
        <Input
          name="hourlyGoalPerPerson"
          label="Hourly Goal Per Person"
          placeholder="-"
          defaultValue={order.hourlyGoalPerPerson}
        />
        <Input
          name="shiftGoalPerPerson"
          label="Shift Goal Per Person (Include Breaks)"
          placeholder="-"
          value={0}
        />
        <div className="w-full" />
      </div>

      <div className="flex gap-4">
        <Input
          name="employees"
          label="Employees"
          placeholder="-"
          value={order.employees}
          required
        />
        <Input
          name="hourlyShiftGoal"
          label="Hourly Shift Goal"
          placeholder="-"
          value={0}
          disabled
        />
        <Input
          name="shiftGoal"
          label="Shift Goal"
          placeholder="-"
          value={0}
          required
          disabled
        />
        <Input
          name="totalShifts"
          label="Total Shifts"
          placeholder="-"
          value={0}
          disabled
        />
        <div className="w-full" />
      </div>
    </div>
  );
}
