import { logoDark } from '~/src/shared/assets/logo';
import './loading.css';
import React from 'react';

const Loader: React.FC = () => {
  return (
    <div className="_loader-container">
      <div className="_logo">
        <img src={logoDark.href} alt={'Loading...'} />
      </div>
      <div className="_spinner"></div>
    </div>
  );
};

export default Loader;
