import { Order } from '~/src/orders/orders.type.ts';
import { Input } from '~/src/shared/ui/components';
import { SymbolInput } from '~/src/shared/ui/components/input/input.tsx';

export default function ItemPricingTab(props: { order: Order }) {
  const { order } = props;

  return (
    <div className="flex flex-col gap-4">
      <div className="flex gap-4">
        <Input
          name="orderQty"
          label="Order Qty"
          placeholder="-"
          defaultValue={order.quantity}
          required
        />
        <Input
          name="sampleQty"
          label="Sample Qty"
          placeholder="-"
          defaultValue={order.sampleQuantity}
          required
        />
        <Input
          name="totalQty"
          label="Total Qty"
          placeholder="-"
          value={order.quantityOrdered}
          required
          disabled
        />
        <div className="w-full" />
      </div>

      <div className="flex gap-4">
        <SymbolInput
          symbol="$"
          name="markups"
          label="Markups"
          placeholder="-"
          value={0}
          disabled
        />
        <SymbolInput
          symbol="$"
          name="unitPrice"
          label="Unit Price"
          placeholder="-"
          value={0}
          required
          disabled
        />
        <SymbolInput
          symbol="$"
          name="discountedUnitPrice"
          label="Discounted Unit Price"
          placeholder="-"
          value={0}
          required
          disabled
        />
        <SymbolInput
          symbol="$"
          name="preDiscOrderTotal"
          label="Pre-Discount Order Total"
          placeholder="-"
          value={0}
          required
          disabled
        />
      </div>

      <div className="flex gap-4">
        <SymbolInput
          symbol="$"
          name="discOrderTotal"
          label="Discounted Order Total"
          placeholder="-"
          value={0}
          disabled
        />
        <SymbolInput
          symbol="%"
          name="discountOffered"
          label="Discount Offered"
          placeholder="-"
          value={0}
          required
          disabled
        />
        <SymbolInput
          symbol="$"
          name="discountSavings"
          label="Discount Savings"
          placeholder="-"
          value={0}
          required
          disabled
        />
        <div className="w-full" />
      </div>

      <div className="flex gap-4">
        <Input
          name="californiaQty"
          label="California QTY"
          placeholder="-"
          defaultValue={order.caQuantity}
        />
        <Input
          name="nonCaliforniaQty"
          label="Non California QTY"
          placeholder="-"
          defaultValue={order.nonCaQuantity}
        />
        <Input
          name="canadianQty"
          label="Canadian QTY"
          placeholder="-"
          defaultValue={order.canadianQuantity}
        />
        <div className="w-full" />
      </div>

      <div className="flex gap-4">
        <SymbolInput
          symbol="$"
          name="taxes"
          label="Taxes"
          helpText="At 7.75%"
          placeholder="-"
          value={0}
          disabled
        />
        <div className="w-full" />
        <div className="w-full" />
        <div className="w-full" />
      </div>

      <div className="flex gap-4">
        <SymbolInput
          symbol="$"
          helpText="Total with Tax"
          name="finalOrderTotal"
          label="Final Order Total"
          placeholder="-"
          value={order.orderTotal}
          required
          disabled
        />
        <div className="w-full" />
        <div className="w-full" />
        <div className="w-full" />
      </div>
    </div>
  );
}
