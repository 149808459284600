import { IconProps } from '~/src/shared/ui/icons/types.ts';

export function TruckRampBox(props: IconProps) {
  const { size, ...rest } = props;

  const lg = (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M21.5 12.125C21.5312 10.9688 21.9375 10 22.7188 9.21875C23.5 8.4375 24.4688 8.03125 25.625 8H33.875C34.5625 8.0625 34.9375 8.4375 35 9.125C34.9375 9.8125 34.5625 10.1875 33.875 10.25H25.625C25.0938 10.25 24.6562 10.4375 24.3125 10.8125C23.9375 11.1562 23.75 11.5938 23.75 12.125V24.9219L24.9688 24.5938C25.4062 23.6562 26.0469 22.9062 26.8906 22.3438C27.7344 21.7812 28.6875 21.5 29.75 21.5C31.25 21.5312 32.4844 22.0469 33.4531 23.0469C34.4531 24.0156 34.9688 25.25 35 26.75C34.9688 28.25 34.4531 29.4844 33.4531 30.4531C32.4844 31.4531 31.25 31.9688 29.75 32C28.3125 31.9688 27.1094 31.4844 26.1406 30.5469C25.1719 29.6094 24.625 28.4375 24.5 27.0312L6.40625 31.9531C5.75 32.0781 5.29688 31.8125 5.04688 31.1562C4.92188 30.5 5.1875 30.0469 5.84375 29.7969L21.5 25.5312V12.125ZM32.75 26.75C32.7188 25.625 32.2188 24.7656 31.25 24.1719C30.25 23.6094 29.25 23.6094 28.25 24.1719C27.2812 24.7656 26.7812 25.625 26.75 26.75C26.7812 27.875 27.2812 28.7344 28.25 29.3281C29.25 29.8906 30.25 29.8906 31.25 29.3281C32.2188 28.7344 32.7188 27.875 32.75 26.75ZM6.07812 17.75C5.98438 17.3125 6.03125 16.9219 6.21875 16.5781C6.4375 16.2344 6.75 16.0156 7.15625 15.9219L9.3125 15.3125L10.2969 18.9219C10.4531 19.3594 10.75 19.5469 11.1875 19.4844L12.6406 19.0625C13.0781 18.9062 13.2656 18.6094 13.2031 18.1719L12.2188 14.5625L14.375 13.9531C14.8125 13.8594 15.2031 13.9062 15.5469 14.0938C15.8906 14.3125 16.125 14.625 16.25 15.0312L18.1719 22.25C18.2656 22.6562 18.2188 23.0312 18.0312 23.375C17.8125 23.75 17.5 23.9844 17.0938 24.0781L9.875 26.0469C9.4375 26.1406 9.04688 26.0938 8.70312 25.9062C8.35938 25.6875 8.14062 25.375 8.04688 24.9688L6.07812 17.75Z"
        fill="black"
      />
    </svg>
  );

  const xs = (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M 10.516 6.047 C 10.532 5.468 10.735 4.984 11.126 4.593 C 11.517 4.202 12.001 3.999 12.58 3.983 L 16.707 3.983 C 17.051 4.014 17.239 4.202 17.27 4.546 C 17.239 4.89 17.051 5.077 16.707 5.109 L 12.58 5.109 C 12.314 5.109 12.095 5.202 11.923 5.39 C 11.736 5.562 11.642 5.781 11.642 6.047 L 11.642 12.449 L 12.251 12.285 C 12.47 11.816 12.791 11.44 13.213 11.159 C 13.635 10.877 14.112 10.737 14.643 10.737 C 15.394 10.752 16.011 11.01 16.496 11.511 C 16.996 11.995 17.254 12.613 17.27 13.363 C 17.254 14.114 16.996 14.731 16.496 15.216 C 16.011 15.716 15.394 15.974 14.643 15.99 C 13.924 15.974 13.322 15.732 12.838 15.263 C 12.353 14.794 12.079 14.208 12.017 13.504 L 2.965 15.966 C 2.637 16.029 2.41 15.896 2.285 15.568 C 2.222 15.239 2.355 15.013 2.684 14.888 L 10.516 12.754 L 10.516 6.047 Z M 16.144 13.363 C 16.129 12.8 15.878 12.371 15.394 12.074 C 14.894 11.792 14.393 11.792 13.893 12.074 C 13.408 12.371 13.158 12.8 13.143 13.363 C 13.158 13.926 13.408 14.356 13.893 14.653 C 14.393 14.934 14.894 14.934 15.394 14.653 C 15.878 14.356 16.129 13.926 16.144 13.363 Z M 2.801 8.861 C 2.754 8.642 2.777 8.446 2.871 8.274 C 2.981 8.103 3.137 7.993 3.34 7.946 L 4.419 7.641 L 4.911 9.447 C 4.99 9.666 5.138 9.76 5.357 9.728 L 6.084 9.517 C 6.303 9.439 6.397 9.291 6.365 9.072 L 5.873 7.266 L 6.952 6.961 C 7.17 6.914 7.366 6.938 7.538 7.032 C 7.71 7.141 7.827 7.297 7.89 7.501 L 8.851 11.112 C 8.898 11.315 8.875 11.503 8.781 11.675 C 8.671 11.862 8.515 11.98 8.312 12.027 L 4.7 13.012 C 4.481 13.058 4.286 13.035 4.114 12.941 C 3.942 12.832 3.833 12.675 3.786 12.472 L 2.801 8.861 Z"
        fill="black"
      />
    </svg>
  );

  return size ? { xs, md: xs, lg }[size] : lg;
}
