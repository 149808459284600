import { QuoteControl } from '~/src/quotes/pages/quote-building.page.tsx';
import { useToggle } from '~/src/shared/hooks/use-toggle.ts';
import { useMutation } from '@tanstack/react-query';
import { mutation, request } from '~/src/shared/api/request-config.ts';
import { paths } from '~/src/shared/api/api.schema.ts';
import * as React from 'react';
import {
  DynamicTableColumn,
  TableRow,
} from '~/src/shared/ui/components/table/types.ts';
import { Discount } from '~/src/quotes/quotes.type.ts';
import { quotesPaths } from '~/src/quotes/quotes.schema.ts';
import { Button, Input } from '~/src/shared/ui/components';
import { SymbolInput } from '~/src/shared/ui/components/input/input.tsx';
import Floppy from '~/src/shared/ui/icons/floppy.tsx';
import { Colors } from '~/src/shared/ui/styles/const.ts';
import Trash from '~/src/shared/ui/icons/trash.tsx';
import { AngleDown } from '~/src/shared/ui/icons/angles.tsx';
import { clean } from '~/src/shared/ui/styles/helpers.ts';
import { Conditionally } from '~/src/shared/ui/components/switch/switch.ts';
import { DynamicTable } from '~/src/shared/ui/components/table/dynamic-table.tsx';

export default function DiscountsContainer(props: QuoteControl) {
  const { quote, refetchQuote } = props;
  const [expanded, toggleExpanded] = useToggle(true);

  const { mutate: createDiscountMutation } = useMutation(
    mutation(paths.createQuoteDiscount, {
      params: { id: quote.id },
    }),
  );

  function onRowChange(
    event: React.ChangeEvent<HTMLInputElement>,
    row: TableRow<Discount>,
  ) {
    const name = event.target.name;
    const value = name === 'value' ? event.target.value : event.target.checked;

    request(quotesPaths.updateQuoteDiscount, {
      params: { quoteId: quote.id, discountId: row.id },
    })
      .fn({ [name]: value })
      .then(() => refetchQuote());
  }

  const columns = [
    {
      field: 'description',
      headerName: 'Discount Description',
      width: 'w-full',
    },
    {
      field: 'value',
      headerName: 'Discount Value',
      formatType: 'input_percent',
      onUpdate: onRowChange,
    },
  ] as DynamicTableColumn<Partial<Discount>>[];

  const [newDiscount, setNewDiscount] = React.useState<React.JSX.Element>();

  function cancelDiscountCreation() {
    setNewDiscount(undefined);
  }

  function addDiscountToTable() {
    const NewDiscount = () => {
      type DiscountField = 'discountValue' | 'discountDescription';

      const [description, setDescription] = React.useState('');
      const [value, setValue] = React.useState('');
      const [errors, setErrors] = React.useState<string[]>([]);

      const discount = {
        discountDescription: description,
        discountValue: value,
      };

      function checkErrors() {
        let error = false;

        for (const name of ['discountValue', 'discountDescription']) {
          if (discount[name as DiscountField].trim().length === 0) {
            setErrors((err) => [...err, name]);
            error = true;
          } else {
            setErrors((err) => err.filter((e) => e !== name));
          }
        }

        return error;
      }

      function createDiscount() {
        const isInvalid = checkErrors();

        if (!isInvalid) {
          const discount = {
            description,
            value,
            isActive: true,
          };

          createDiscountMutation(discount, {
            onSuccess: () => {
              refetchQuote();
              cancelDiscountCreation();
            },
          });
        }
      }

      return (
        <tr>
          <td className="w-full">
            <Input
              name="discountDescription"
              className="w-full"
              label="New Discount"
              placeholder="Discount Description"
              onChange={(e) => setDescription(e.target.value)}
              invalid={errors.includes('discountDescription')}
              hideLabel
            />
          </td>
          <td>
            <SymbolInput
              type="number"
              className="w-32"
              symbol="%"
              placeholder="0"
              onChange={(e) => setValue(e.target.value)}
              name="discountValue"
              label="Discount Total"
              invalid={errors.includes('discountValue')}
              hideLabel
            />
          </td>
          <td className="flex flex-row justify-center">
            <Button className="_icon-button" onClick={createDiscount}>
              <Floppy color={Colors.success['500']} />
            </Button>

            <Button className="_icon-button" onClick={cancelDiscountCreation}>
              <Trash color={Colors.danger['500']} />
            </Button>
          </td>
        </tr>
      );
    };

    setNewDiscount(<NewDiscount />);
  }

  const footerRow = (
    <tr>
      <td className="py-4">
        <div className="flex items-center justify-between">
          <Button className="_outline" onClick={addDiscountToTable}>
            Add New Discount
          </Button>
          <p>Discount total</p>
        </div>
      </td>
      <td>
        <SymbolInput
          className="w-32"
          symbol="%"
          name="discountTotal"
          label="Discount Total"
          hideLabel
          value={quote.totalDiscount}
          disabled
        />
      </td>
      <td />
    </tr>
  );

  return (
    <div className="_quote-container">
      <div className="flex items-center justify-between">
        <p className="text-lg">Discounts</p>
        <div className="flex gap-20">
          <p>
            <span className="text-gray-300">Total:</span> {quote.totalDiscount}%
          </p>
          <AngleDown
            className={clean(
              `hover:cursor-pointer ${expanded ? 'rotate-180' : ''}`,
            )}
            onClick={toggleExpanded}
          />
        </div>
      </div>

      <Conditionally when={expanded}>
        {() => (
          <div>
            <DynamicTable<Discount>
              columns={columns}
              rows={quote.discounts as Required<Discount>[]}
              loading={false}
              onRowClick={() => {}}
              onToggle={onRowChange}
              customRows={newDiscount ? [newDiscount, footerRow] : [footerRow]}
            />
          </div>
        )}
      </Conditionally>
    </div>
  );
}
