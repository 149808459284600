import { Config } from '~/src/shared/config';
import { camelCase, snakeCase } from 'lodash-es';
import { jsonCase } from '~/src/shared/utils';
import { LocalStorage } from '~/src/shared/service/storage';
import { unprotectedPaths } from '~/src/shared/api/api.schema.ts';

export type HttpMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

type ResponseHandler = (response: Response) => void | Promise<void>;
const responseHandlers: ResponseHandler[] = [];

export class FetchError extends Error {
  status = 500;
  data = { detail: '' };

  constructor(response: Response) {
    super(response.statusText ?? response.status ?? 'Unknown status');
    this.status = response.status;
  }
}

const authPaths = Object.values(unprotectedPaths);

function isUnprotectedRoute(method: HttpMethod, route: string) {
  return authPaths.some((value) => {
    const [pathMethod, path] = value.split(' ');

    if (pathMethod !== method) {
      return false;
    }
    const regex = new RegExp(`^${path.replace(/:\w+/g, '\\d+')}$`);
    const cleanRoute = route.split('?')[0].replace(/\/\//gu, '/');

    return regex.test(cleanRoute);
  });
}

function getAuthHeader(method: HttpMethod, path: string) {
  if (!isUnprotectedRoute(method, path)) {
    const authToken = LocalStorage.read('AuthToken');

    if (!authToken) {
      return {};
    }

    return { Authorization: `Bearer ${authToken as string}` };
  }
}

async function parseResponse(response: Response) {
  responseHandlers.map((callback) => callback(response));

  if (response.status === 204) {
    return null;
  }

  try {
    const json = await response.clone().json();
    return jsonCase(json, camelCase) as typeof json;
  } catch (error) {
    return response.clone().text();
  }
}

async function _fetch<TResponseBody>(path: string, _config?: RequestInit) {
  const authHeader = getAuthHeader(_config?.method as HttpMethod, path);
  const config = {
    ..._config,
    headers: { ...authHeader, ..._config?.headers } as Record<string, string>,
  };

  if (typeof config.body === 'object') {
    config.body = JSON.stringify(jsonCase(config.body, snakeCase));
    config.headers['Content-Type'] = 'application/json';
  }

  const url = `${Config.apiUrl}${path.replace(/\/\//gu, '/')}`;
  const response = await fetch(url, config);
  const data = await parseResponse(response);

  if (!response.ok) {
    const error = new FetchError(response);
    error.data = data;
    throw error;
  }

  return data as TResponseBody;
}

function createFetcher(method: HttpMethod) {
  function fetcher<TResponseBody = unknown, TRequestBody = unknown>(
    path: string,
    body?: TRequestBody,
    config?: Partial<RequestInit>,
    silent = false,
  ) {
    // @ts-expect-error: body will be converted to a BodyInit in _fetch
    return _fetch<TResponseBody>(path, { ...config, method, body }, silent);
  }

  Object.defineProperty(fetcher, 'name', {
    value: `fetcher.${method}`,
    writable: false,
  });

  return fetcher;
}

function on(callback: ResponseHandler) {
  responseHandlers.push(callback);
}

export const fetcher = {
  get: createFetcher('GET'),
  post: createFetcher('POST'),
  put: createFetcher('PUT'),
  patch: createFetcher('PATCH'),
  delete: createFetcher('DELETE'),
  on,
};
