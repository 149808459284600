import * as React from 'react';

export default function Plus(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.75 4.25V9.25H15.75C16.1562 9.25 16.5 9.59375 16.5 10C16.5 10.4375 16.1562 10.75 15.75 10.75H10.75V15.75C10.75 16.1875 10.4062 16.5 10 16.5C9.5625 16.5 9.25 16.1875 9.25 15.75V10.75H4.25C3.8125 10.75 3.5 10.4375 3.5 10C3.5 9.59375 3.8125 9.25 4.25 9.25H9.25V4.25C9.25 3.84375 9.5625 3.5 10 3.5C10.4062 3.5 10.75 3.84375 10.75 4.25Z"
        fill="black"
      />
    </svg>
  );
}
