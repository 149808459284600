import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { Home } from '~/src/luckyyou/pages/home.page.tsx';
import { LoginPage, LoginCallback } from '~/src/luckyyou/pages/login.page.tsx';
import { Inventory } from '~/src/inventory/pages/inventory.page.tsx';
import { Customers } from '~/src/customers/pages/customers.page.tsx';
import '~/src/shared/setup/dayjs';
import '~/src/shared/setup/fonts';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from '~/src/shared/setup/fetch.ts';
import { PurchaseOrders } from '~/src/purchase-orders/pages/purchase-orders.page.tsx';
import { Quotes } from '~/src/quotes/pages/quotes.page.tsx';
import { CustomerDetail } from '~/src/customers/pages/customer-detail.page.tsx';
import { routes } from '~/src/routes.ts';
import { ContactDetail } from '~/src/contacts/pages/contact-detail.page.tsx';
import RedirectToCustomerDetail from '~/src/customers/components/customer-redirect.component.tsx';
import { VendorDetail } from './vendors/pages/vendor-detail.page';
import { Vendors } from './vendors/pages/vendors.page';
import RedirectToVendorDetail from './vendors/components/vendor-redirect.component';
import LoginProvider from '~/src/luckyyou/components/login-provider.tsx';
import { NavigationProvider } from '~/src/shared/ui/components/navigation-bar/navigation-bar.tsx';
import Recoil from '~/src/shared/service/store/recoil.tsx';
import { AlertProvider } from '~/src/shared/ui/components/alert/alert.tsx';
import { InventoryDetail } from '~/src/inventory/pages/inventory-detail.page.tsx';
import { PurchaseOrderCreate } from './purchase-orders/pages/purchase-orders-create.page';
import { QuoteBuilding } from '~/src/quotes/pages/quote-building.page.tsx';
import { PurchaseOrderDetail } from './purchase-orders/pages/purchase-orders-details.page';
import { Orders } from '~/src/orders/pages/orders.page';
import { OrderDetail } from '~/src/orders/pages/order-detail.page.tsx';

export function RoutesContainer() {
  return (
    <Routes>
      <Route path={routes.home.path} element={<Home />} />
      <Route path={routes.login.path} element={<LoginPage />} />
      <Route path={routes.loginCallback.path} element={<LoginCallback />} />

      <Route path={routes.purchaseOrders.path} element={<PurchaseOrders />} />
      <Route
        path={routes.createPurchaseOrderStandaloneModal.path}
        element={<PurchaseOrders />}
      />
      <Route
        path={routes.createPurchaseOrderPage.path}
        element={<PurchaseOrderCreate />}
      />
      <Route
        path={routes.addItemPurchaseOrderPage.path}
        element={<PurchaseOrderCreate />}
      />
      <Route
        path={routes.purchaseOrderInfo.path}
        element={<PurchaseOrderDetail />}
      />

      <Route path={routes.customers.path} element={<Customers />} />
      <Route path={routes.customerInfo.path} element={<CustomerDetail />} />
      <Route path={routes.newCustomer.path} element={<Customers />} />
      <Route path={routes.editCustomer.path} element={<CustomerDetail />} />

      <Route
        path={routes.customerContacts.path}
        element={<RedirectToCustomerDetail />}
      />
      <Route
        path={routes.customerContactInfo.path}
        element={<ContactDetail />}
      />
      <Route
        path={routes.newCustomerContact.path}
        element={<CustomerDetail />}
      />
      <Route
        path={routes.editCustomerContact.path}
        element={<ContactDetail />}
      />

      <Route path={routes.vendors.path} element={<Vendors />} />
      <Route path={routes.vendorInfo.path} element={<VendorDetail />} />
      <Route path={routes.newVendor.path} element={<Vendors />} />
      <Route path={routes.editVendor.path} element={<VendorDetail />} />

      <Route
        path={routes.vendorContacts.path}
        element={<RedirectToVendorDetail />}
      />
      <Route path={routes.vendorContactInfo.path} element={<ContactDetail />} />
      <Route path={routes.newVendorContact.path} element={<VendorDetail />} />
      <Route path={routes.editVendorContact.path} element={<ContactDetail />} />

      <Route path={routes.inventory.path} element={<Inventory />} />
      <Route path={routes.inventoryDetail.path} element={<InventoryDetail />} />
      <Route
        path={routes.includeInventoryItemVendor.path}
        element={<InventoryDetail />}
      />
      <Route path={routes.editInventory.path} element={<InventoryDetail />} />
      <Route path={routes.newInventory.path} element={<Inventory />} />

      <Route path={routes.quotes.path} element={<Quotes />} />
      <Route path={routes.newQuote.path} element={<Quotes />} />
      <Route path={routes.quoteBuilding.path} element={<QuoteBuilding />} />
      <Route
        path={routes.quoteAddInventoryItems.path}
        element={<QuoteBuilding />}
      />

      <Route path={routes.orders.path} element={<Orders />} />
      <Route path={routes.orderDetail.path} element={<OrderDetail />} />
      <Route path={routes.newOrderComponent.path} element={<OrderDetail />} />

      <Route path="*" element={<Navigate to="/login" replace />} />
    </Routes>
  );
}

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Recoil>
          <AlertProvider />
          <LoginProvider>
            <NavigationProvider />
            <RoutesContainer />
          </LoginProvider>
        </Recoil>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
