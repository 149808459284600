import { useLocation, useNavigate } from 'react-router-dom';
import { paths } from '~/src/shared/api/api.schema.ts';
import { query } from '~/src/shared/api/request-config.ts';
import { useQuery } from '@tanstack/react-query';
import { routes } from '~/src/routes.ts';
import { Button } from '~/src/shared/ui/components';
import { QuoteStatus } from '../quotes.type';
import QuoteModal from '~/src/quotes/components/quotes-modal.component.tsx';
import { Tab, Tabs } from '~/src/shared/ui/components/tabs/tabs.tsx';
import { capitalize } from 'lodash-es';
import { QuotesTable } from '../components/quotes-table.component';

export function Quotes() {
  const { data, fetchStatus, refetch } = useQuery({
    ...query(paths.quotes),
  });

  const isLoading = fetchStatus === 'fetching';
  const { pathname } = useLocation();
  const navigate = useNavigate();

  return (
    <div className="_page">
      <header>
        <div>
          <h1>Quotes</h1>
        </div>

        <div className="_header-actions">
          <Button onClick={() => navigate(routes.newQuote.path)}>
            Add New Quote
          </Button>
        </div>
      </header>

      <Tabs>
        {QuoteStatus.map((status, index) => (
          <Tab
            key={index}
            label={status === 'sample' ? 'Samples' : capitalize(status)}
          >
            <QuotesTable
              quotes={data?.filter((q) => q.status === status) ?? []}
              isLoading={isLoading}
            />
          </Tab>
        ))}
      </Tabs>

      {pathname.includes('new') && <QuoteModal refetchQuotes={refetch} />}
    </div>
  );
}
