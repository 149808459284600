import FontFaceObserver from 'fontfaceobserver';

function preloadFont(family: string, ...weights: number[]) {
  const fonts = weights.map((weight) =>
    new FontFaceObserver(family, { weight }).load(null, 10000),
  );

  Promise.all(fonts).then(() => {
    const root = document.querySelector('html') as HTMLHtmlElement;
    root.style.fontFamily = family;
  });
}

if (process.env.NODE_ENV !== 'test') {
  preloadFont('Poppins', 400, 500, 700);
}
