import * as React from 'react';

import { clean } from '~/src/shared/ui/styles/helpers';
import Calendar from '~/src/shared/ui/icons/calendar.tsx';

import './datepicker.css';
import { Colors } from '~/src/shared/ui/styles/const.ts';
import dayjs from 'dayjs';
import { Logger } from '~/src/shared/service/logger';
import { CircleExclamationSolid } from '~/src/shared/ui/icons/circle-exclamation.tsx';

interface InputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  label: string;
  hideErrors?: boolean;
  hideLabel?: boolean;
  invalid?: boolean;
}

export const Datepicker = React.forwardRef(function Datepicker(
  props: InputProps,
  ref: React.ForwardedRef<HTMLInputElement>,
) {
  const {
    name,
    label,
    className,
    hideErrors,
    hideLabel,
    required,
    placeholder,
    defaultValue,
    onChange,
    invalid,
    ...rest
  } = props;
  const inputRef = React.useRef<HTMLInputElement>(null);
  React.useImperativeHandle(
    ref,
    () => inputRef.current as HTMLInputElement,
    [],
  );

  const [maskedValue, setMaskedValue] = React.useState(
    defaultValue
      ? dayjs(defaultValue as string).format('MM/DD/YYYY')
      : undefined,
  );

  const labelId = `${name}__label`;
  const errorId = `${name}__error`;

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    let value = event.target.value;

    // If user manually inputs the slashes
    if (/^([0-9]{2}\/){1,2}$/.test(value)) {
      setMaskedValue(value.substring(0, 10));
      return;
    }

    value = value.replace(/[^0-9]/g, '');

    // If user don't input slashes, we insert it automatically
    if (value.length <= 2) {
      value = value.replace(/^(\d{0,2})/, '$1');
    } else if (value.length <= 4) {
      value = value.replace(/^(\d{0,2})(\d{0,2})/, '$1/$2');
    } else {
      value = value.replace(/^(\d{0,2})(\d{0,2})(\d{0,4})/, '$1/$2/$3');
    }

    setMaskedValue(value.substring(0, 10));
    reflectDateOnCalendarWidget(value.substring(0, 10));
  }

  function reflectDateOnCalendarWidget(dateStr: string) {
    if (inputRef.current && dateStr.length === 10) {
      const date = dayjs(dateStr, 'MM/DD/YYYY', true);

      if (!date.isValid()) {
        Logger.warn('Invalid date', { date });
        return;
      }

      inputRef.current.value = date.toISOString().substring(0, 10);
    }
  }

  function openCalendar() {
    if (inputRef.current) {
      inputRef.current.showPicker();
    }
  }

  function onDateChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target) {
      onChange && onChange(event);
      setMaskedValue(dayjs(event.target.value).format('MM/DD/YYYY'));
    }
  }

  return (
    <label htmlFor={name} className={clean(`_datepicker-label ${className}`)}>
      <div
        id={labelId}
        className={clean(
          `_label-text ${required ? '_required' : ''} ${hideLabel ? 'hidden' : ''} ${invalid ? 'text-danger-500' : 'text-gray-900'}`,
        )}
      >
        {label}
      </div>

      <input type="date" name={name} ref={inputRef} onChange={onDateChange} />
      <div className="relative">
        <input
          id={name}
          name={name}
          value={maskedValue}
          aria-describedby={errorId}
          required={required}
          onChange={handleChange}
          className={clean(`_input ${invalid ? 'border-danger-500' : ''}`)}
          placeholder={placeholder}
          {...rest}
        />

        <Calendar
          className={clean(
            `${invalid ? 'right-9' : 'right-3'} hover:cursor-pointer`,
          )}
          color={Colors.gray['900']}
          onClick={openCalendar}
        />
      </div>
      {invalid && <CircleExclamationSolid className="right-3" />}

      {!hideErrors && <div id={errorId} className="_error-message" />}
    </label>
  );
});
