import { Customer } from '~/src/customers/customers.type.ts';
import { Contact } from '~/src/contacts/contacts.type.ts';
import { Quote } from '../quotes/quotes.type';
import { Order } from '../orders/orders.type';

export interface CustomersAPI {
  'GET /customers/': {
    response: Required<Customer>[];
  };
  'GET /customers/:id': {
    params: { id: string };
    response: Required<Customer>;
  };
  'POST /customers/': {
    body: Omit<Customer, 'id'>;
    response: Required<Customer>;
  };
  'PUT /customers/:id/': {
    params: { id: string };
    body: Customer;
    response: Required<Customer>;
  };
  'PATCH /customers/:id/': {
    params: { id: string };
    body: Partial<Customer>;
    response: Required<Customer>;
  };
  'DELETE /customers/:id/': {
    params: { id: string };
    response: null;
  };
  'GET /customers/:id/contacts/': {
    params: { id: string };
    response: Required<Contact>[];
  };
  'GET /customers/:id/orders/': {
    params: { id: string };
    response: Required<Order>[];
  };
  'GET /customers/:id/quotes/': {
    params: { id: string };
    response: Required<Quote>[];
  };
  'POST /customers/:id/contacts/': {
    params: { id: string };
    body: Required<Contact>;
    response: Required<Contact>;
  };
  'GET /customers/:parentId/contacts/:contactId/': {
    params: { parentId: string; contactId: string };
    response: Required<Contact>;
  };
  'POST /customers/:id/contacts/active-multiple/': {
    params: { id: string };
    body: Required<Record<'id', number>[]>;
    response: Required<number[]>;
  };
  'POST /customers/:id/contacts/inactive-multiple/': {
    params: { id: string };
    body: Required<Record<'id', number>[]>;
    response: Required<number[]>;
  };
  'POST /customers/:id/contacts/delete-multiple/': {
    params: { id: string };
    body: Required<Record<'id', number>[]>;
    response: Required<number[]>;
  };
}

export const customersPaths = {
  customer: 'GET /customers/:id',
  customers: 'GET /customers/',
  createCustomer: 'POST /customers/',
  editCustomer: 'PUT /customers/:id/',
  partialUpdateCustomer: 'PATCH /customers/:id/',
  deleteCustomer: 'DELETE /customers/:id/',
  customerContacts: 'GET /customers/:id/contacts/',
  customerOrders: 'GET /customers/:id/orders/',
  customerQuotes: 'GET /customers/:id/quotes/',
  createCustomerContact: 'POST /customers/:id/contacts/',
  customerContact: 'GET /customers/:parentId/contacts/:contactId/',
  customerContactActiveMultiple:
    'POST /customers/:id/contacts/active-multiple/',
  customerContactInactiveMultiple:
    'POST /customers/:id/contacts/inactive-multiple/',
  customerContactDeleteMultiple:
    'POST /customers/:id/contacts/delete-multiple/',
} satisfies Record<string, keyof CustomersAPI>;
