import { Table } from '~/src/shared/ui/components/table/table.tsx';
import { TableColumn } from '~/src/shared/ui/components/table/types.ts';
import { PurchaseOrderLineRow } from '../purchase-orders.type';
import Divider from '~/src/shared/ui/components/divider/divider';
import { Button } from '~/src/shared/ui/components';
import Plus from '~/src/shared/ui/icons/plus';
import { formatCurrency } from '~/src/shared/utils';

export function ItemsTable({
  purchaseOrderLines,
  onClickAdd,
  readOnly,
}: {
  purchaseOrderLines: Required<PurchaseOrderLineRow>[];
  onClickAdd?: () => void;
  readOnly: boolean;
}) {
  const columns = [
    { field: 'itemName', headerName: 'Inventory Item' },
    { field: 'sku', headerName: 'SKU' },
    {
      field: 'skuCost',
      headerName: 'SKU Cost',
      formatType: 'currency',
    },
    { field: 'qty', headerName: 'SKU Qty' },
    { field: 'numberOfPallets', headerName: 'Number of Pallets' },
    { field: 'lineTotal', headerName: 'Line Total', formatType: 'currency' },
  ] as TableColumn<PurchaseOrderLineRow>[];

  if (readOnly) {
    columns.splice(4, 1, {
      field: 'receivedDate',
      headerName: 'Received Date',
      formatType: 'date',
    });
  }

  const formattedTotal = formatCurrency(
    purchaseOrderLines
      .map((line) => Number(line.lineTotal))
      .reduce((lineTotal, acc) => acc + lineTotal),
  );

  return (
    <div className="_items-table">
      {!readOnly && (
        <div className="table-controls">
          <Button className="_secondary" onClick={onClickAdd}>
            <Plus className="inline" />
            Add Items
          </Button>
        </div>
      )}
      <Table<Required<PurchaseOrderLineRow>>
        name="Contact"
        columns={columns}
        rows={purchaseOrderLines}
        loading={false}
        defaultRowsPerPage={50}
        onRowClick={() => {}}
      />
      <Divider />
      <div className="_bottom-line">
        Total<span>{formattedTotal}</span>
      </div>
    </div>
  );
}
