import { IconProps } from '~/src/shared/ui/icons/types.ts';

export function Building(props: IconProps) {
  return (
    <svg
      width="18"
      height="24"
      viewBox="0 0 18 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 2.25C2.57812 2.25 2.25 2.625 2.25 3V21C2.25 21.4219 2.57812 21.75 3 21.75H6.75V18.75C6.75 17.5312 7.73438 16.5 9 16.5C10.2188 16.5 11.25 17.5312 11.25 18.75V21.75H15C15.375 21.75 15.75 21.4219 15.75 21V3C15.75 2.625 15.375 2.25 15 2.25H3ZM0 3C0 1.35938 1.3125 0 3 0H15C16.6406 0 18 1.35938 18 3V21C18 22.6875 16.6406 24 15 24H3C1.3125 24 0 22.6875 0 21V3ZM4.125 4.875C4.125 4.5 4.45312 4.125 4.875 4.125H7.125C7.5 4.125 7.875 4.5 7.875 4.875V7.125C7.875 7.54688 7.5 7.875 7.125 7.875H4.875C4.45312 7.875 4.125 7.54688 4.125 7.125V4.875ZM10.875 4.125H13.125C13.5 4.125 13.875 4.5 13.875 4.875V7.125C13.875 7.54688 13.5 7.875 13.125 7.875H10.875C10.4531 7.875 10.125 7.54688 10.125 7.125V4.875C10.125 4.5 10.4531 4.125 10.875 4.125ZM4.125 10.875C4.125 10.5 4.45312 10.125 4.875 10.125H7.125C7.5 10.125 7.875 10.5 7.875 10.875V13.125C7.875 13.5469 7.5 13.875 7.125 13.875H4.875C4.45312 13.875 4.125 13.5469 4.125 13.125V10.875ZM10.875 10.125H13.125C13.5 10.125 13.875 10.5 13.875 10.875V13.125C13.875 13.5469 13.5 13.875 13.125 13.875H10.875C10.4531 13.875 10.125 13.5469 10.125 13.125V10.875C10.125 10.5 10.4531 10.125 10.875 10.125Z"
        fill={props.color ?? 'black'}
      />
    </svg>
  );
}
