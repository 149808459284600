import {
  Checkbox,
  DropDown,
  Input,
  TextButton,
} from '~/src/shared/ui/components';
import { useNavigate } from 'react-router-dom';
import { Customer } from '~/src/customers/customers.type.ts';
import { Contact } from '~/src/contacts/contacts.type.ts';
import { FormModal } from '~/src/shared/ui/components/modal/modal.tsx';

import { fetcher, FetchError } from '~/src/shared/service/fetch/fetcher.ts';
import { paths } from '~/src/shared/api/api.schema.ts';
import { Logger } from '~/src/shared/service/logger';
import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query';
import { flattenErrors, makeDropDownOptions } from '~/src/shared/utils.ts';
import { CircleExclamation } from '~/src/shared/ui/icons/circle-exclamation.tsx';
import Xmark from '~/src/shared/ui/icons/xmark.tsx';
import { Colors } from '~/src/shared/ui/styles/const.ts';
import { capitalize } from 'lodash-es';
import { prependHttps } from '~/src/shared/utils.ts';
import { useCustomForm } from '~/src/shared/hooks/use-custom-form.ts';

import '~/src/shared/ui/components/modal/form-modal.css';
import {
  AddressTypeOptions,
  PhoneTypeOptions,
  USAStates,
} from '~/src/shared/consts.ts';
import { Select } from '~/src/shared/ui/components/select/select.tsx';
import Plus from '~/src/shared/ui/icons/plus.tsx';
import { useFieldArray } from 'react-hook-form';
import * as React from 'react';
import useUpdateEffect from '~/src/shared/hooks/use-update-effect.ts';
import Divider from '~/src/shared/ui/components/divider/divider.tsx';
import { Vendor } from '~/src/vendors/vendors.type';
import { Conditionally } from '~/src/shared/ui/components/switch/switch';

interface ContactModalProps {
  refetchContacts: (
    options?: RefetchOptions | undefined,
  ) => Promise<QueryObserverResult<Contact[] | Contact, Error>>;
  parent: Customer | Vendor;
  contact?: Contact;
}

export function ContactModal(props: ContactModalProps) {
  const { refetchContacts, contact, parent } = props;
  const navigate = useNavigate();
  const [useCorporateAddress, setUseCorporateAddress] = React.useState(false);

  const defaultAddress = {
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    zipcode: '',
    addressType: undefined,
  };

  const defaultPhone = {
    phoneType: undefined,
    phoneNumber: '',
  };

  const defaultContact = {
    addresses: [defaultAddress],
    phones: [defaultPhone],
  };

  const {
    errorMessage,
    showError,
    register,
    handleSubmit,
    controlled,
    control,
    setFormError,
    isDirty,
    isSubmitting,
  } = useCustomForm<Contact>({
    initialData: contact ?? defaultContact,
    validate: (data) => {
      if (data.linkedin) {
        data.linkedin = prependHttps(data.linkedin);
      }

      for (const [index, address] of data.addresses.entries()) {
        if (!address.addressType) {
          setFormError([
            `addresses.${index}.addressType`,
            'Select an address type.',
          ]);
          return false;
        }
      }

      for (const [index, phoneData] of data.phones.entries()) {
        if (!phoneData.phoneNumber) {
          data.phones?.splice(index, 1);
          continue;
        }
        if (!phoneData.phoneType) {
          setFormError([`phones.${index}.phoneType`, 'Select a phone type.']);
          return false;
        }
      }

      return data;
    },
    handleSubmit: async (data) => {
      const baseParentPath =
        parent.type === 'vendor' ? paths.vendors : paths.customers;
      const parentPath = baseParentPath.split(' ')[1];
      const contactsPath = `${parentPath}/${parent.id}/contacts/`;
      const path = contact ? `${contactsPath}${contact.id}/` : contactsPath;

      try {
        const response = contact
          ? await fetcher.put(path, data)
          : await fetcher.post(path, data);

        refetchContacts();
        Logger.info('Contact successfully created.', { data: response });
        navigate(-1);
      } catch (error) {
        if (error instanceof FetchError && error.status === 400) {
          const firstError = Object.entries(flattenErrors(error.data))[0];

          setFormError(
            firstError ?? [
              '',
              `There was an error during contact ${contact ? 'update' : 'creation'}.`,
            ],
          );
        } else {
          Logger.error('Error on creating/updating contact.', {
            error: (error as Error).message,
          });
        }
      }
    },
  });

  const {
    fields: addressFields,
    append: appendAddress,
    replace: resetAddresses,
  } = useFieldArray({
    control,
    name: 'addresses',
  });

  const { fields: phoneFields, append: appendPhone } = useFieldArray({
    control,
    name: 'phones',
  });

  const parentAddress =
    parent.type === 'vendor' ? parent.vendorAddress : parent.corporateAddress;

  useUpdateEffect(() => {
    resetAddresses(
      useCorporateAddress
        ? [
            {
              ...parentAddress,
              addressType: undefined,
              id: undefined,
            },
          ]
        : [defaultAddress],
    );
  }, [useCorporateAddress]);

  return (
    <FormModal
      name="contact"
      title={contact ? 'Update Contact' : 'Add New Contact'}
      id="contact-modal"
      data-testid="contact-modal"
      onSubmit={() => handleSubmit}
      isDirty={isDirty}
      isUpdate={Boolean(contact)}
      isSubmitting={isSubmitting}
      floating
    >
      {showError && (
        <div className="_error-balloon">
          <CircleExclamation />
          <p>{capitalize(errorMessage)}</p>
          <button onClick={() => setFormError(['', ''])}>
            <Xmark color={Colors.danger['200']} size="md" />
          </button>
        </div>
      )}

      <div className="_row">
        <Input
          label="First Name"
          placeholder="John"
          required
          {...register('firstName')}
        />
        <Input
          label="Last Name"
          placeholder="Smith"
          {...register('lastName')}
        />
      </div>

      <div className="_row">
        <Input
          label="Job Title"
          placeholder="Job Title"
          required
          {...register('jobTitle')}
        />
        <Input
          label="Department"
          placeholder="Department"
          {...register('department')}
        />
      </div>

      <div className="_row">
        <Input
          label="Email"
          placeholder="john.smith@companyname.com"
          required
          {...register('email')}
        />
        <Input
          label="Linkedin"
          placeholder="https://"
          {...register('linkedin')}
        />
      </div>

      <Divider />

      <div className="flex flex-col gap-4">
        {phoneFields.map((field, index) => (
          <div key={field.id}>
            {phoneFields.length > 1 && (
              <p className="text-xs font-medium text-gray-300 italic">
                Phone {index + 1}
              </p>
            )}
            <div className="_section" key={field.id}>
              <div className="_row">
                <Input
                  label="Phone"
                  placeholder="111-111-1111"
                  {...register(`phones.${index}.phoneNumber`)}
                />
                <DropDown
                  title="Personal Mobile, Work Mobile, Office, Home"
                  label="Phone Type"
                  className="w-full"
                  {...controlled(`phones.${index}.phoneType`)}
                  options={makeDropDownOptions(PhoneTypeOptions)}
                />
              </div>
            </div>
          </div>
        ))}
      </div>

      <TextButton leftIcon={<Plus />} onClick={() => appendPhone(defaultPhone)}>
        Add Phone Number
      </TextButton>

      <Divider />

      <p className="text-xs font-medium text-gray-500">Address</p>

      <Checkbox
        checked={useCorporateAddress}
        onChange={() => setUseCorporateAddress((v) => !v)}
      >
        <span className="text-sm font-medium text-gray-900">
          Use Company&apos;s default corporate address.
        </span>
      </Checkbox>

      <div className="flex flex-col gap-6">
        {addressFields.map((field, index) => (
          <div key={field.id}>
            {addressFields.length > 1 && (
              <p className="text-xs font-medium text-gray-300 italic">
                Address {index + 1}
              </p>
            )}

            <div className="_section" key={field.id}>
              <div className="_row">
                <Input
                  label="Address line 1"
                  placeholder="1234 Name St."
                  required
                  {...register(`addresses.${index}.addressLine1`)}
                />
                <Input
                  label="Address line 2"
                  placeholder="Apartment, Suite"
                  {...register(`addresses.${index}.addressLine2`)}
                />
              </div>

              <div className="_row">
                <Input
                  label="City"
                  placeholder="City name"
                  required
                  {...register(`addresses.${index}.city`)}
                />
                <Select
                  label="State"
                  required
                  {...register(`addresses.${index}.state`)}
                >
                  <option value="" disabled>
                    Select a state
                  </option>
                  {Object.entries(USAStates).map(
                    ([stateKey, stateName], index) => (
                      <option
                        key={index}
                        value={stateKey}
                        className="!hover:bg-black"
                      >
                        {stateName}
                      </option>
                    ),
                  )}
                </Select>
              </div>
              <div className="_row">
                <Input
                  label="Zipcode"
                  placeholder="92210"
                  required
                  {...register(`addresses.${index}.zipcode`)}
                />
                <DropDown
                  title="Residential, Corporate"
                  label="Address Type"
                  className="w-full"
                  {...controlled(`addresses.${index}.addressType`)}
                  defaultValue={useCorporateAddress ? 'corporate' : null}
                  options={makeDropDownOptions(AddressTypeOptions)}
                  required
                />
              </div>
            </div>
          </div>
        ))}
      </div>

      <TextButton
        leftIcon={<Plus />}
        onClick={() => appendAddress(defaultAddress)}
      >
        Add Address
      </TextButton>

      <Conditionally when={parent.type == 'customer'}>
        {() => (
          <>
            <Divider />
            <p className="text-xs font-medium text-gray-500">
              Shipping Information
            </p>
            <div className="_row">
              <Input
                label="Fedex Account"
                placeholder="1234567890"
                {...register('fedexAccountNumber')}
              />
              <Input
                label="UPS Account"
                placeholder="1234567890"
                {...register('upsAccountNumber')}
              />
            </div>
          </>
        )}
      </Conditionally>
    </FormModal>
  );
}
