import React from 'react';
import './info-piece.component.css';
import { CorporateAddress } from '~/src/customers/customers.type';
import { clean } from '~/src/shared/ui/styles/helpers.ts';

type InfoPieceProps = {
  label: string;
  content?: string | number | React.ReactElement;
  link?: boolean;
  description?: string;
  className?: string;
};

export function getFormattedAddress(
  address: CorporateAddress,
): React.ReactElement {
  const addr = address.addressLine1;
  const addr2 = address.addressLine2;
  const city = address.city;
  const state = address.state;
  const zipcode = address.zipcode;

  return (
    <p>
      {addr} {addr2 && <span className="address-line">{addr2}</span>}
      <br />
      {city}, {state} {zipcode}
    </p>
  );
}

export default function InfoPiece({
  label,
  content,
  link,
  description,
  className,
}: InfoPieceProps) {
  // Check if content is a string and not empty
  const isStringContent = typeof content === 'string' && content.length > 0;

  return (
    <label aria-label={label} className={clean(`_info-piece ${className}`)}>
      <span>{label}</span>
      {content ? (
        link && isStringContent ? (
          <a href={content} className={'text-info-600 hover:text-info-700'}>
            {content}
          </a>
        ) : // Render content as is if it's an element, or wrap with <p> if it's a string
        isStringContent ? (
          <p>{content}</p>
        ) : (
          content
        )
      ) : (
        '-'
      )}
      <span className="!font-normal">{description}</span>
    </label>
  );
}
