import { useMutation, useQuery } from '@tanstack/react-query';
import { mutation, query } from '~/src/shared/api/request-config.ts';
import { paths } from '~/src/shared/api/api.schema.ts';
import { useParams } from 'react-router-dom';
import { Tab, Tabs } from '~/src/shared/ui/components/tabs/tabs.tsx';
import { useLocation, useNavigate } from 'react-router-dom';
import { routes } from '~/src/routes.ts';

import '~/src/vendors/styles/vendor-detail.page.css';

import Divider from '~/src/shared/ui/components/divider/divider.tsx';
import { Menu, MenuOption } from '~/src/shared/ui/components/menu/menu.tsx';
import Copy from '~/src/shared/ui/icons/copy.tsx';
import Pencil from '~/src/shared/ui/icons/pencil.tsx';
import Trash from '~/src/shared/ui/icons/trash.tsx';
import { Breadcrumbs } from '~/src/shared/ui/components/breadcrumbs/breadcrumbs.tsx';
import { Button } from '~/src/shared/ui/components';
import { Colors } from '~/src/shared/ui/styles/const.ts';
import { VendorModal } from '../components/vendor-modal.component';
import InfoPiece, {
  getFormattedAddress,
} from '~/src/shared/ui/components/info-piece/info-piece.component';
import { ContactsTable } from '~/src/contacts/components/contacts-table.component';
import { ContactModal } from '~/src/contacts/components/contact-modal.component';
import useConfirmation, {
  ConfirmationModalProps,
} from '~/src/shared/hooks/use-confirmation.tsx';
import useUpdateEffect from '~/src/shared/hooks/use-update-effect.ts';
import {
  deletion,
  deletionError,
} from '~/src/shared/ui/components/alert/models.tsx';
import useAlert from '~/src/shared/ui/components/alert/hooks/use-alert.tsx';
import { FetchError } from '~/src/shared/service/fetch/fetcher.ts';
import ToggleOn from '~/src/shared/ui/icons/toggle-on.tsx';
import ToggleOff from '~/src/shared/ui/icons/toggle-off.tsx';
import { MultipleSelectionOptions } from '~/src/shared/ui/components/table/types.ts';
import { Contact } from '~/src/contacts/contacts.type.ts';
import { VendorItemsTable } from '~/src/vendors/components/vendor-items-table.component.tsx';
import { PurchaseOrdersTable } from '~/src/vendors/components/purchase-orders-table.component.tsx';

export function VendorDetail() {
  const { id } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const alert = useAlert();

  const { data: vendor, refetch } = useQuery({
    ...query(paths.vendor, { params: { id: id as string } }),
  });

  const { data: inventoryItems } = useQuery({
    ...query(paths.vendorItems, { params: { id: id as string } }),
  });

  const { data: purchaseOrders } = useQuery({
    ...query(paths.vendorPurchaseOrders, { params: { id: id as string } }),
  });

  const {
    mutate: deleteMutate,
    status: deleteStatus,
    error,
  } = useMutation({
    ...mutation(paths.deleteVendor, { params: { id: id as string } }),
  });

  const { mutate: markActiveContactsMutate, status: markActiveContactsStatus } =
    useMutation(
      mutation(paths.vendorContactActiveMultiple, {
        params: { id: id as string },
      }),
    );

  const {
    mutate: markInactiveContactsMutate,
    status: markInactiveContactsStatus,
  } = useMutation(
    mutation(paths.vendorContactInactiveMultiple, {
      params: { id: id as string },
    }),
  );

  const {
    mutate: deleteContactsMutate,
    status: deleteContactsStatus,
    error: deleteContactsError,
  } = useMutation(
    mutation(paths.vendorContactDeleteMultiple, {
      params: { id: id as string },
    }),
  );

  const {
    data: contacts,
    fetchStatus: contactsFetchStatus,
    refetch: refetchContacts,
  } = useQuery({
    ...query(paths.vendorContacts, {
      params: { id: vendor?.id.toString() as string },
    }),
    enabled: Boolean(vendor?.id),
  });

  const createIdList = (rows: number[]) => rows.map((id) => ({ id }));

  function createModalProps(
    name: string,
    selectedRows: number[],
    resource?: string,
  ) {
    return {
      markActive: {
        title: `Are you sure you want to mark selected contacts as active?`,
        bodyText: '',
        actionName: 'Mark as active',
        onAction: () => markActiveContactsMutate(createIdList(selectedRows)),
        isDelete: false,
      },
      markInactive: {
        title: `Are you sure you want to mark selected contacts as inactive?`,
        bodyText: '',
        actionName: 'Mark as inactive',
        onAction: () => markInactiveContactsMutate(createIdList(selectedRows)),
        isDelete: false,
      },
      deleteContacts: {
        title: `Are you sure you want to remove all selected contacts?`,
        bodyText: "This action can't be undone.",
        actionName: 'Remove',
        onAction: () => deleteContactsMutate(createIdList(selectedRows)),
        isDelete: true,
      },
      deleteVendor: {
        title: `Are you sure you want to remove ${resource}?`,
        bodyText: "This action can't be undone.",
        actionName: 'Remove',
        onAction: () => deleteMutate(),
        isDelete: true,
      },
    }[name] as ConfirmationModalProps;
  }

  const { confirmationModal, showConfirmation, hideModal } = useConfirmation();

  useUpdateEffect(() => {
    if (deleteStatus === 'success') {
      navigate(routes.vendors.path);
      alert.show(deletion(vendor?.name ?? 'Vendor'));
    } else if (deleteStatus === 'error') {
      if (error instanceof FetchError && error.status === 423) {
        alert.show({
          title: `Error on deleting ${vendor?.name}`,
          text: error.data.detail,
          color: 'danger',
        });
      } else {
        alert.show(deletionError(vendor?.name ?? 'Vendor'));
      }
      hideModal();
    }
  }, [deleteStatus]);

  useUpdateEffect(() => {
    if (markActiveContactsStatus === 'success') {
      alert.showSuccess('Contacts successfully marked active.');
      refetchContacts();
    } else if (markActiveContactsStatus === 'error') {
      alert.showError('Error on mark contacts active.');
    }
    hideModal();
  }, [markActiveContactsStatus]);

  useUpdateEffect(() => {
    if (markInactiveContactsStatus === 'success') {
      alert.showSuccess('Contacts successfully marked inactive.');
      refetchContacts();
    } else if (markInactiveContactsStatus === 'error') {
      alert.showError('Error on mark contacts inactive.');
    }
    hideModal();
  }, [markInactiveContactsStatus]);

  useUpdateEffect(() => {
    if (deleteContactsStatus === 'success') {
      alert.showSuccess('Contacts successfully removed.');
      hideModal();
      refetchContacts();
    } else if (deleteContactsStatus === 'error') {
      if (
        deleteContactsError instanceof FetchError &&
        deleteContactsError.status === 423
      ) {
        alert.show({
          title: `Error on deleting selected contacts`,
          text: deleteContactsError.data.detail,
          color: 'danger',
        });
      } else {
        alert.show(deletionError('Contacts'));
      }
      hideModal();
    }
  }, [deleteContactsStatus]);

  function tableOptionAction(
    confirmationResource: string,
    selectedRows: number[],
  ) {
    if (selectedRows.length === 0) return;

    showConfirmation(createModalProps(confirmationResource, selectedRows));
  }

  const tableOptions = [
    {
      action: (selectedRows) => tableOptionAction('markActive', selectedRows),
      icon: <ToggleOn />,
      label: 'Mark Selected as Active',
    },
    {
      action: (selectedRows) => tableOptionAction('markInactive', selectedRows),
      icon: <ToggleOff color={Colors.danger['500']} />,
      label: 'Mark Selected as Inactive',
      color: 'danger',
    },
    {
      action: (selectedRows) =>
        tableOptionAction('deleteContacts', selectedRows),
      icon: <Trash color={Colors.danger['500']} />,
      label: 'Remove Selected Contacts',
      color: 'danger',
    },
  ] as MultipleSelectionOptions<Partial<Contact>>[];

  if (!vendor) {
    return (
      <div className="flex items-center justify-center w-full">
        Loading vendor...
      </div>
    );
  }

  vendor.type = 'vendor';
  const fullAddressFormatted = getFormattedAddress(vendor.vendorAddress);

  return (
    <div className="_page">
      <header>
        <div>
          <Breadcrumbs />
          <h1>{vendor.name}</h1>
        </div>

        <div>
          <Button
            onClick={() => navigate(routes.newVendorContact.getPath(vendor.id))}
          >
            Add New Vendor Contact
          </Button>

          <Menu>
            <MenuOption
              action={() => navigate(routes.editVendor.getPath(vendor.id))}
              icon={<Pencil />}
              label="Edit"
            />
            <MenuOption action={() => {}} icon={<Copy />} label="Duplicate" />
            <Divider />
            <MenuOption
              action={() =>
                showConfirmation(
                  createModalProps('deleteVendor', [], vendor?.name),
                )
              }
              icon={<Trash color={Colors.danger['500']} />}
              label="Remove"
              color="danger"
            />
          </Menu>
        </div>
      </header>

      <div className="_summary">
        <InfoPiece label="Corporate Address" content={fullAddressFormatted} />
        <InfoPiece label="Website" content={vendor.website} link />
        <InfoPiece
          label="Payment Terms"
          content={vendor.paymentTerms.replaceAll('_', ' ')}
        />
        <InfoPiece label="Delivery Days" content={vendor.deliveryDays} />
        <InfoPiece
          label="Minimum Order"
          content={`$${vendor.minimumOrder || '-'}`}
        />
      </div>

      <div className="_container">
        <Tabs>
          <Tab label="Contacts">
            <ContactsTable
              parent={vendor}
              contacts={contacts ?? []}
              isLoading={contactsFetchStatus === 'fetching'}
              tableOptions={tableOptions}
            />
          </Tab>
          <Tab label="Vendor Items">
            <VendorItemsTable items={inventoryItems ?? []} />
          </Tab>
          <Tab label="Purchase Orders">
            <PurchaseOrdersTable purchaseOrders={purchaseOrders ?? []} />
          </Tab>
        </Tabs>
      </div>

      {pathname.includes('edit') ? (
        <VendorModal refetchVendors={refetch} vendor={vendor} />
      ) : null}

      {pathname.includes('new-contact') ? (
        <ContactModal refetchContacts={refetchContacts} parent={vendor} />
      ) : null}

      {confirmationModal()}
    </div>
  );
}
