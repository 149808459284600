import { IconProps } from '~/src/shared/ui/icons/types.ts';

export default function CardsBlank(props: IconProps) {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.625 7C2.5 7.08333 2.46875 7.19792 2.53125 7.34375L7.75 16.375C7.83333 16.5 7.94792 16.5312 8.09375 16.4688L14.0938 13C14.2188 12.9167 14.25 12.8021 14.1875 12.6562L8.96875 3.625C8.88542 3.5 8.78125 3.46875 8.65625 3.53125L2.625 7ZM1.25 8.09375C1 7.65625 0.9375 7.21875 1.0625 6.78125C1.1875 6.32292 1.45833 5.96875 1.875 5.71875L7.90625 2.25C8.32292 2 8.76042 1.9375 9.21875 2.0625C9.65625 2.1875 10.0104 2.45833 10.2812 2.875L15.5 11.9062C15.7292 12.3438 15.7812 12.7812 15.6562 13.2188C15.5521 13.6771 15.2812 14.0312 14.8438 14.2812L8.84375 17.7812C8.40625 18.0104 7.95833 18.0625 7.5 17.9375C7.0625 17.8125 6.70833 17.5417 6.4375 17.125L1.25 8.09375ZM15.3438 15.1562C16.0104 14.7396 16.4375 14.1875 16.625 13.5C16.8125 12.7917 16.7292 12.0938 16.375 11.4062L12.0938 4C12.1354 4 12.1875 4 12.25 4H19.25C19.75 4.02083 20.1667 4.1875 20.5 4.5C20.8125 4.83333 20.9792 5.25 21 5.75V16.25C20.9792 16.75 20.8125 17.1667 20.5 17.5C20.1667 17.8125 19.75 17.9792 19.25 18H12.25C11.8125 18 11.4375 17.8646 11.125 17.5938L15.3438 15.1562Z"
        fill={props.color ?? 'black'}
      />
    </svg>
  );
}
