import { Input } from '~/src/shared/ui/components';
import { useNavigate } from 'react-router-dom';
import { FormModal } from '~/src/shared/ui/components/modal/modal.tsx';
import { fetcher, FetchError } from '~/src/shared/service/fetch/fetcher.ts';
import { paths } from '~/src/shared/api/api.schema.ts';
import { Logger } from '~/src/shared/service/logger';
import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query';
import { useCustomForm } from '~/src/shared/hooks/use-custom-form';
import { InventoryItem, InventoryItemVendor } from '../inventory.type';
import { Select } from '~/src/shared/ui/components/select/select';
import {
  InventoryCategory,
  InventoryCategoryOptions,
  UnitOfMeasure,
  UnitOfMeasureOptions,
} from '../consts';
import { Datepicker } from '~/src/shared/ui/components/datepicker/datepicker';
import { Vendor } from '~/src/vendors/vendors.type';
import Divider from '~/src/shared/ui/components/divider/divider.tsx';
import { Toggle } from '~/src/shared/ui/components/toggle/toggle.tsx';
import { numberTransformer } from '~/src/shared/utils.ts';
import { isEmpty, omit, pickBy, some } from 'lodash-es';

interface InventoryModalProps {
  refetchInventory: (
    options?: RefetchOptions | undefined,
  ) => Promise<QueryObserverResult<InventoryItem[] | InventoryItem, Error>>;
  item?: InventoryItem;
  vendors?: Vendor[];
}

export function InventoryModal(props: InventoryModalProps) {
  const { refetchInventory, item, vendors } = props;
  const navigate = useNavigate();

  const defaultInventoryItemVendor = {
    vendor: {},
    unitOfMeasure: '' as UnitOfMeasure,
    vendorUnitOfMeasure: '' as UnitOfMeasure,
  } as InventoryItemVendor;

  const defaultInventoryItem = {
    category: '' as InventoryCategory,
    inventoryItemVendors: [defaultInventoryItemVendor],
  } as InventoryItem;

  const initialData = item ?? defaultInventoryItem;

  const {
    setValue,
    errorMessage,
    showError,
    register,
    handleSubmit,
    setFormError,
    isDirty,
    isSubmitting,
    watch,
  } = useCustomForm<InventoryItem>({
    initialData: initialData,
    watchChangesOf: ['qtyPerSKU', 'skuCost'],
    onChange: (data) => {
      const item = (data.inventoryItemVendors as InventoryItemVendor[])[0];

      if (item.skuCost !== null && item.qtyPerSKU !== null) {
        const unitPrice = (item.skuCost / item.qtyPerSKU).toFixed(2);
        setValue('inventoryItemVendors.0.unitPrice', parseFloat(unitPrice));
      }
    },
    validate: (data) => {
      if (!shouldShowDimensions() || isEmpty(pickBy(data.dimensions))) {
        return omit(data, 'dimensions');
      }

      return data;
    },
    handleSubmit: async (data) => {
      const inventoryPath = paths.inventoryItems.split(' ')[1];
      const path = item ? `${inventoryPath}/${item.id}/` : inventoryPath;

      try {
        const response = item
          ? await fetcher.put(path, data)
          : await fetcher.post(path, data);

        refetchInventory();
        Logger.info('Inventory item successfully created/updated.', {
          data: response,
        });
        navigate(-1);
      } catch (error) {
        if (error instanceof FetchError && error.status === 400) {
          setFormError([
            '',
            `There was an error during item ${item ? 'update' : 'creation'}.`,
          ]);
        } else {
          Logger.error('Error on creating/updating inventory item.', {
            error: (error as Error).message,
          });
        }
      }
    },
  });

  function shouldShowDimensions() {
    return some([
      ['PACKAGING_BOXES', 'DOUBLE_DUTY_BOXES', 'TOWER_BOXES'].includes(
        selectedCategory,
      ),
      selectedCategory === 'CONTAINER' && Boolean(doublesAsShippingBox),
      selectedCategory === 'CONTAINER_DESK_DROPS' &&
        Boolean(doublesAsShippingBox),
    ]);
  }

  const selectedCategory = watch('category');
  const doublesAsShippingBox = watch('doublesAsShippingBox');
  const dimensionsRequired =
    selectedCategory === 'DOUBLE_DUTY_BOXES' || Boolean(doublesAsShippingBox);

  return (
    <FormModal
      name="inventory-item"
      title={item ? 'Update Inventory Item' : 'Add New Inventory Item'}
      id="inventory-modal"
      data-testid="inventory-modal"
      onSubmit={() => handleSubmit}
      isDirty={isDirty}
      isSubmitting={isSubmitting}
      floating
    >
      {showError && (
        <div className="_error-balloon">
          <p>{errorMessage}</p>
        </div>
      )}

      <Input
        label="Item Name"
        placeholder="Enter item name"
        required
        {...register('name')}
        helpText="Shows on Purchase Orders"
      />

      <Input
        label="Description"
        placeholder="Describe the item here..."
        required
        {...register('description')}
        helpText="Shows on Quotes"
      />

      <Input
        label="Pack List Description"
        placeholder="Describe the pack list here..."
        required
        {...register('packListDescription')}
      />

      <Select label="Inventory Category" required {...register('category')}>
        <option value="" disabled>
          Select a category
        </option>
        {Object.entries(InventoryCategoryOptions).map(
          ([catKey, catTitle], index) => (
            <option key={index} value={catKey} className="!hover:bg-black">
              {catTitle}
            </option>
          ),
        )}
      </Select>

      {initialData?.inventoryItemVendors?.map((_, index) => (
        <div key={index} className="_section">
          <Divider />

          <p className="_section-title">Vendor Information</p>

          <div className="_row">
            <Select
              label="Vendor"
              required
              {...register(`inventoryItemVendors.${index}.vendor`)}
            >
              <option value="0" disabled>
                Select a vendor
              </option>
              {vendors?.map((vendor, vendor_index) => (
                <option
                  key={vendor_index}
                  value={vendor.id}
                  className="!hover:bg-black"
                >
                  {vendor.name}
                </option>
              ))}
            </Select>
            <Input
              label="SKU"
              placeholder="SKU Number"
              required
              {...register(`inventoryItemVendors.${index}.sku`)}
            />
          </div>
          <div className="_row">
            <Input
              label="SKU Cost"
              type="number"
              step="0.01"
              placeholder="$"
              required
              {...register(`inventoryItemVendors.${index}.skuCost`, {
                setValueAs: numberTransformer,
              })}
            />
            <Input
              label="Quantity Per SKU"
              type="number"
              placeholder="Quantity"
              required
              {...register(`inventoryItemVendors.${index}.qtyPerSKU`, {
                setValueAs: numberTransformer,
              })}
            />
          </div>

          <div className="_row">
            <Select
              label="Sold as Unit of Measure"
              required
              {...register(`inventoryItemVendors.${index}.unitOfMeasure`)}
              helpText="How we sell the product to our customers"
            >
              <option value="" disabled>
                Select a unit of measure
              </option>
              {Object.entries(UnitOfMeasureOptions).map(
                ([uomKey, uomTitle], index) => (
                  <option
                    key={index}
                    value={uomKey}
                    className="!hover:bg-black"
                  >
                    {uomTitle}
                  </option>
                ),
              )}
            </Select>
            <Select
              label="Vendor Unit of Measure"
              required
              {...register(`inventoryItemVendors.${index}.vendorUnitOfMeasure`)}
              helpText="How our vendor sell it to us"
            >
              <option value="" disabled>
                Select a unit of measure
              </option>
              {Object.entries(UnitOfMeasureOptions).map(
                ([uomKey, uomTitle], index) => (
                  <option
                    key={index}
                    value={uomKey}
                    className="!hover:bg-black"
                  >
                    {uomTitle}
                  </option>
                ),
              )}
            </Select>
          </div>
          <div className="_row">
            <Input
              label="Unit Price"
              type="number"
              step="0.01"
              disabled
              placeholder="$"
              {...register(`inventoryItemVendors.${index}.unitPrice`, {
                setValueAs: numberTransformer,
              })}
              helpText="Calculated as Cost and Qty are entered"
            />
            <Datepicker
              label="Date pricing"
              {...register(`inventoryItemVendors.${index}.datePricing`)}
              placeholder="mm/dd/yyyy"
            />
          </div>

          <div className="_row">
            <Input
              label="Pieces Per Pound"
              type="number"
              placeholder="-"
              required={selectedCategory.split('_')[0] === 'CANDY'}
              step="0.01"
              {...register(`inventoryItemVendors.${index}.piecesPerPound`, {
                setValueAs: numberTransformer,
              })}
            />
            <Input
              label="Quantity Per Pallet"
              type="number"
              placeholder="-"
              {...register(`inventoryItemVendors.${index}.quantityPerPallet`, {
                setValueAs: numberTransformer,
              })}
            />
          </div>
          <div className="_row">
            <Input
              label="Vendor Notes"
              placeholder="Add vendor notes here..."
              {...register(`inventoryItemVendors.${index}.vendorNotes`)}
              defaultValue=""
            />
          </div>
        </div>
      ))}

      <Divider />

      {shouldShowDimensions() && (
        <>
          <p className="_section-title">Dimensions</p>

          <div className="_section">
            <div className="_row">
              <Input
                label="Length"
                type="number"
                step="0.01"
                placeholder="0"
                required={dimensionsRequired}
                {...register('dimensions.length', {
                  setValueAs: numberTransformer,
                })}
              />
              <Input
                label="Depth"
                type="number"
                step="0.01"
                placeholder="0"
                required={dimensionsRequired}
                {...register('dimensions.depth', {
                  setValueAs: numberTransformer,
                })}
              />
              <Input
                label="Height"
                type="number"
                step="0.01"
                placeholder="0"
                required={dimensionsRequired}
                {...register('dimensions.height', {
                  setValueAs: numberTransformer,
                })}
              />
              <Input
                label="Pallet Count"
                type="number"
                placeholder="0"
                required={dimensionsRequired}
                {...register('dimensions.palletCount', {
                  setValueAs: numberTransformer,
                })}
              />
              <Input
                label="Weight"
                type="number"
                step="0.01"
                placeholder="0"
                required={dimensionsRequired}
                {...register('dimensions.weight', {
                  setValueAs: numberTransformer,
                })}
              />
            </div>
            {selectedCategory !== 'DOUBLE_DUTY_BOXES' && (
              <Toggle
                leftLabel="Doubles As Shipping Box"
                {...register('doublesAsShippingBox')}
              />
            )}
            <Divider />
          </div>
        </>
      )}

      {/* Additional Options */}
      <div className="_section">
        <Toggle
          leftLabel="Allow Customization"
          {...register('allowCustomization')}
        />
        <Toggle leftLabel="Add Item to Sell Me List" {...register('sellMe')} />
      </div>

      <Divider />

      <div className="_section">
        <Input
          label="Global Notes"
          placeholder="Add global notes here..."
          {...register('globalNotes')}
          helpText="Only visible here"
        />
      </div>
    </FormModal>
  );
}
