import { RecoilState } from 'recoil';
import { Draft, produce } from 'immer';
import { setRecoil } from '~/src/shared/service/store/recoil';

export class Store<T> {
  state: T;
  atom: RecoilState<T>;

  constructor(initialState: T, atom: RecoilState<T>) {
    this.state = initialState;
    this.atom = atom;
  }

  protected produceState(producer: (draft: Draft<T>) => void) {
    this.state = produce(this.state, producer);
  }

  protected updateState(producer: (draft: Draft<T>) => void) {
    this.produceState(producer);
    this.commitChanges();
  }

  commitChanges() {
    setRecoil(this.atom, this.state);
  }
}
