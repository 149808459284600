import * as React from 'react';
import { Checkbox } from '~/src/shared/ui/components/checkbox/checkbox.tsx';
import { AngleDown } from '~/src/shared/ui/icons/angles.tsx';
import ArrowDownAZ from '~/src/shared/ui/icons/arrow-down-a-z.tsx';
import {
  Case,
  Conditionally,
  Switch,
} from '~/src/shared/ui/components/switch/switch.ts';
import {
  TableDynamicFilterOption,
  RowsPerPage,
  TableData,
  TableFilter,
  TableProps,
  TableRow,
  TableValue,
} from '~/src/shared/ui/components/table/types.ts';
import useSortBy from '~/src/shared/ui/components/table/hooks/use-sort-by.ts';
import useFilterBy from '~/src/shared/ui/components/table/hooks/use-filter-by.ts';
import usePagination from '~/src/shared/ui/components/table/hooks/use-pagination.ts';
import useCheckboxControl from '~/src/shared/ui/components/table/hooks/use-checkbox-control.ts';
import {
  DropDown,
  DropDownOption,
} from '~/src/shared/ui/components/select/dropdown.tsx';

import { Pagination } from '~/src/shared/ui/components/pagination/pagination.tsx';
import { Badge } from '~/src/shared/ui/components/badge/badge.tsx';
import { upperFirst, some } from 'lodash-es';
import { TextButton } from '~/src/shared/ui/components/button/button.tsx';
import Xmark from '~/src/shared/ui/icons/xmark.tsx';
import { Input } from '~/src/shared/ui/components/input/input.tsx';
import MagnifyingGlass from '~/src/shared/ui/icons/magnifying-glass.tsx';
import { Colors } from '~/src/shared/ui/styles/const.ts';
import useSearch from '~/src/shared/ui/components/table/hooks/use-search.ts';
import { formatCurrency, formatDate } from '~/src/shared/utils';
import { Menu, MenuOption } from '~/src/shared/ui/components/menu/menu.tsx';

import './table.css';
import useUpdateEffect from '~/src/shared/hooks/use-update-effect.ts';

export function Table<T extends TableData<T>>(props: TableProps<T>) {
  const {
    columns,
    rows,
    filters,
    dynamicFilters,
    withCheckbox,
    name,
    loading,
    defaultRowsPerPage = 10,
    onRowClick = () => {},
    showSearchInput,
    initialFilters = {},
    multipleSelectionOptions,
  } = props;
  const [currentFilters, setCurrentFiltersState] = React.useState<
    Record<keyof T, DropDownOption | null>
  >(initialFilters as Record<keyof T, DropDownOption | null>);

  const [currentDynamicFilters, setCurrentDynamicFilters] = React.useState<
    Record<string, TableDynamicFilterOption<T> | null>
  >({});
  const [search, setSearch] = React.useState<string>('');

  const { filteredRows, filtersBeingUsed, dynamicFiltersBeingUsed } =
    useFilterBy(rows, currentFilters, currentDynamicFilters);
  const filteredRowsBySearch = useSearch(filteredRows, columns, search);
  const { sortedRows, fieldToSort, onSetFieldToSort, descending } = useSortBy(
    filteredRowsBySearch,
    columns,
  );
  const hasActiveFilters = React.useMemo(
    () =>
      some([
        ...Object.values(filtersBeingUsed),
        ...Object.values(dynamicFiltersBeingUsed),
      ]),
    [filtersBeingUsed, dynamicFiltersBeingUsed],
  );
  const {
    handleCheckboxChange,
    handleHeaderCheckbox,
    selectedRows,
    clearSelection,
  } = useCheckboxControl<number>(rows.map((row) => row.id));
  const [rowsPerPage, setRowsPerPage] = React.useState(defaultRowsPerPage);
  const [currentPage, setCurrentPage] = React.useState(1);
  const rowsPages = usePagination(sortedRows, rowsPerPage);

  const changeRowsPerPage = (newAmount: RowsPerPage) => {
    setCurrentPage(1);
    setRowsPerPage(newAmount);
  };

  const setCurrentFilters: typeof setCurrentFiltersState = (newFilters) => {
    setCurrentPage(1); // Reset the current page to avoid reaching a non-existing one after new filter
    setCurrentFiltersState(newFilters);
  };

  useUpdateEffect(() => {
    if (!hasActiveFilters) return;
    clearSelection();
  }, [filtersBeingUsed, dynamicFiltersBeingUsed]);

  const columnFields = React.useMemo(
    () => columns.map((col) => col.field),
    [columns],
  ) as (keyof Partial<TableRow<T>>)[];

  const tableListDisplayOptions = [
    { value: '10', title: '10' },
    { value: '25', title: '25' },
    { value: '50', title: '50' },
  ] as DropDownOption[];

  const emptyBody = (message: string) => (
    <tbody>
      <tr className="pointer-events-none">
        <td colSpan={99} className="text-center text-gray-600 py-5">
          {message}
        </td>
      </tr>
    </tbody>
  );

  const getContentFromRow = (
    row: TableRow<T>,
    columnField: string | number | symbol,
  ) => {
    const columnMeta = columns.find((c) => c.field === columnField);

    let content: TableValue = row[columnField as keyof TableRow<T>];

    // Handle nested fields
    if (typeof columnField === 'string' && columnField.includes('.')) {
      content = getNestedFieldValue(row, columnField as string);
    }

    if (columnMeta?.formatType === 'currency') {
      content = formatCurrency(content as number);
    } else if (columnMeta?.formatType === 'date') {
      content = content && formatDate(content as string);
    }
    return content as Exclude<TableValue, object> | React.ReactElement;
  };

  // Helper function to get nested field value
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getNestedFieldValue = (row: any, path: string): TableValue => {
    return path.split('.').reduce((acc, key) => {
      return acc && acc[key];
    }, row);
  };

  const renderRowTds = (row: TableRow<T>) => {
    return columnFields.map((columnField, columnIndex) => {
      const content = getContentFromRow(row, columnField);
      return (
        <td key={columnIndex} onDoubleClick={() => onRowClick(row)}>
          {/* TODO LUCKYYOU-41: show tooltip with full data on hover */}
          <span className="_table-content">{content ?? '-'}</span>
          {columnIndex == 0 && 'status' in row && row?.status == 'inactive' && (
            <span className="_status-badge _table-content">
              <Badge key={columnIndex} color="gray" content="Inactive"></Badge>
            </span>
          )}
        </td>
      );
    });
  };

  return (
    <div className="_table-container flex flex-col gap-4">
      <div
        className={`_table-header ${filters || showSearchInput ? 'gap-2' : ''}`}
      >
        {name && (
          <span className="_table-count text-md text-gray-500">
            {sortedRows.length} {name.toLowerCase()}
            {sortedRows.length === 1 ? '' : 's'} found
          </span>
        )}

        <div className="flex items-center justify-between">
          <div className="flex items-center gap-4">
            {filters &&
              Object.entries(filters as TableFilter<T>).map(
                ([filterTitle, filterOptions]) => (
                  <DropDown
                    label={`Filter ${filterTitle}`}
                    hideLabel
                    name={`filter-${filterTitle}`}
                    key={filterTitle}
                    title={filterTitle}
                    fixedTitle
                    onChange={(value) =>
                      setCurrentFilters((prev) => ({
                        ...prev,
                        [filterTitle]: (filterOptions as DropDownOption[]).find(
                          (f) => f.value === value,
                        ),
                      }))
                    }
                    options={filterOptions as DropDownOption[]}
                    defaultValue={null}
                  />
                ),
              )}

            {dynamicFilters &&
              Object.entries(dynamicFilters).map(
                ([filterName, dynamicFilterOptions]) => (
                  <DropDown
                    label={`Dynamic Filter ${filterName}`}
                    hideLabel
                    name={`dynamic-filter-${filterName}`}
                    key={filterName}
                    title={filterName}
                    fixedTitle
                    onChange={(value) =>
                      setCurrentDynamicFilters((prev) => ({
                        ...prev,
                        [filterName]: dynamicFilterOptions.find(
                          (f) => f?.value === value,
                        ) as TableDynamicFilterOption<T>,
                      }))
                    }
                    options={dynamicFilterOptions}
                    defaultValue={null}
                  />
                ),
              )}

            {Object.entries(filtersBeingUsed).map(
              ([filterName, filterValue], index) => (
                <Badge
                  key={index}
                  content={upperFirst((filterValue as DropDownOption)?.title)}
                  color="info"
                  onClose={() =>
                    setCurrentFilters((prev) => ({
                      ...prev,
                      [filterName]: null,
                    }))
                  }
                />
              ),
            )}

            {Object.entries(dynamicFiltersBeingUsed).map(
              ([filterName, dynamicFilterOptions], index) => (
                <Badge
                  key={index}
                  content={upperFirst(dynamicFilterOptions?.title)}
                  color="info"
                  onClose={() =>
                    setCurrentDynamicFilters((prev) => ({
                      ...prev,
                      [filterName]: null,
                    }))
                  }
                />
              ),
            )}

            <Conditionally when={hasActiveFilters}>
              {() => (
                <TextButton
                  rightIcon={<Xmark size="md" />}
                  onClick={() => {
                    setCurrentFilters({} as Record<keyof T, DropDownOption>);
                    setCurrentDynamicFilters({});
                  }}
                >
                  Clear filters
                </TextButton>
              )}
            </Conditionally>
          </div>

          {showSearchInput && (
            <Input
              name="search"
              label="search"
              placeholder="Search"
              icon={<MagnifyingGlass color={Colors.gray['500']} />}
              onChange={(event) => setSearch(event.target.value)}
              hideLabel
            />
          )}
        </div>
      </div>

      <div className="_table-body">
        <table
          data-testid={`${name?.split(' ').join('-').toLowerCase()}-table`}
        >
          <thead>
            <tr>
              <Conditionally when={withCheckbox && rowsPages.length > 0}>
                {() => (
                  <td className="w-0">
                    <div className="flex items-center">
                      <Checkbox
                        onChange={() => handleHeaderCheckbox()}
                        checked={selectedRows.length === sortedRows.length}
                        indeterminate={
                          selectedRows.length > 0 &&
                          selectedRows.length < sortedRows.length
                        }
                      />
                      {/* <AngleDown /> */}
                      <Conditionally
                        when={
                          multipleSelectionOptions &&
                          multipleSelectionOptions?.length > 0
                        }
                      >
                        {() => (
                          <Menu icon={<AngleDown />}>
                            {multipleSelectionOptions?.map((option, index) => (
                              <MenuOption
                                key={index}
                                action={() =>
                                  option.action(
                                    selectedRows,
                                    rowsPages[currentPage - 1],
                                  )
                                }
                                icon={option.icon}
                                label={option.label}
                                color={option?.color}
                              />
                            ))}
                          </Menu>
                        )}
                      </Conditionally>
                    </div>
                  </td>
                )}
              </Conditionally>

              {columns.map((col, index) => (
                <td key={index}>
                  <div className="flex items-center gap-3">
                    {col.headerName}
                    <ArrowDownAZ
                      className="_clickable"
                      onClick={() => onSetFieldToSort(col.field)}
                      inactive={col.field !== fieldToSort}
                      inverted={col.field === fieldToSort && descending}
                    />
                  </div>
                </td>
              ))}
            </tr>
          </thead>
          <Switch>
            <Case
              when={loading}
              render={() => emptyBody(`Loading ${name ? name + 's' : ''}...`)}
            />
            <Case
              when={rowsPages.length > 0}
              render={() => (
                <tbody className="text-gray-900">
                  {rowsPages[currentPage - 1].map((row, index) => (
                    <tr key={index} data-testid="table-row">
                      <Conditionally when={withCheckbox}>
                        {() => (
                          <td>
                            <Checkbox
                              checked={selectedRows.includes(row.id)}
                              onChange={() => handleCheckboxChange(row.id)}
                            />
                          </td>
                        )}
                      </Conditionally>
                      {renderRowTds(row)}
                    </tr>
                  ))}
                </tbody>
              )}
            />
            <Case default render={() => emptyBody('No results')} />
          </Switch>
        </table>
      </div>

      <div className="_table-footer">
        <div className="self-start flex items-center gap-2">
          <span>Showing </span>
          <DropDown
            label="Rows per page"
            hideLabel
            name="rowsPerPage"
            onChange={(value) =>
              changeRowsPerPage(parseInt(value as string) as RowsPerPage)
            }
            options={tableListDisplayOptions}
            defaultValue={defaultRowsPerPage.toString()}
          />
          <span> of {sortedRows.length} items</span>
        </div>

        <Conditionally when={rowsPages.length > 1}>
          {() => (
            <Pagination
              className="justify-self-end lg:justify-self-center"
              amountOfPages={rowsPages.length}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          )}
        </Conditionally>
      </div>
    </div>
  );
}
