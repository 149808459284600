import { IconProps } from '~/src/shared/ui/icons/types.ts';

export default function Lock(props: IconProps) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.5 6V8V6V8H12.5V6C12.4792 5.29167 12.2396 4.69792 11.7812 4.21875C11.3021 3.76042 10.7083 3.52083 10 3.5C9.29167 3.52083 8.69792 3.76042 8.21875 4.21875C7.76042 4.69792 7.52083 5.29167 7.5 6ZM6 8V6V8V6C6.02083 4.875 6.40625 3.92708 7.15625 3.15625C7.92708 2.40625 8.875 2.02083 10 2C11.125 2.02083 12.0729 2.40625 12.8438 3.15625C13.5938 3.92708 13.9792 4.875 14 6V8H15C15.5625 8.02083 16.0312 8.21875 16.4062 8.59375C16.7812 8.96875 16.9792 9.4375 17 10V16C16.9792 16.5625 16.7812 17.0312 16.4062 17.4062C16.0312 17.7812 15.5625 17.9792 15 18H5C4.4375 17.9792 3.96875 17.7812 3.59375 17.4062C3.21875 17.0312 3.02083 16.5625 3 16V10C3.02083 9.4375 3.21875 8.96875 3.59375 8.59375C3.96875 8.21875 4.4375 8.02083 5 8H6ZM4.5 10V16V10V16C4.52083 16.3125 4.6875 16.4792 5 16.5H15C15.3125 16.4792 15.4792 16.3125 15.5 16V10C15.4792 9.6875 15.3125 9.52083 15 9.5H5C4.6875 9.52083 4.52083 9.6875 4.5 10Z"
        fill={props.color ?? 'black'}
      />
    </svg>
  );
}
