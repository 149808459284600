import * as React from 'react';
import { FormModal } from '~/src/shared/ui/components/modal/modal.tsx';
import {
  QueryObserverResult,
  RefetchOptions,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import { mutation, query } from '~/src/shared/api/request-config.ts';
import { paths } from '~/src/shared/api/api.schema.ts';
import {
  DynamicTableColumn,
  TableColumn,
  TableRow,
  TableValue,
} from '~/src/shared/ui/components/table/types.ts';
import { CustomInventoryItemVendor } from '~/src/inventory/inventory.type.ts';
import ArrowDownAZ from '~/src/shared/ui/icons/arrow-down-a-z.tsx';
import useSortBy from '~/src/shared/ui/components/table/hooks/use-sort-by.ts';
import { FormEvent, ReactElement } from 'react';
import { formatCurrency, formatDate } from '~/src/shared/utils.ts';
import { Button, Input } from '~/src/shared/ui/components';
import { clean } from '~/src/shared/ui/styles/helpers.ts';
import useSearch from '~/src/shared/ui/components/table/hooks/use-search.ts';
import MagnifyingGlass from '~/src/shared/ui/icons/magnifying-glass.tsx';
import { Colors } from '~/src/shared/ui/styles/const.ts';
import { Badge } from '~/src/shared/ui/components/badge/badge.tsx';
import useUpdateEffect from '~/src/shared/hooks/use-update-effect.ts';
import { Case, Switch } from '~/src/shared/ui/components/switch/switch.ts';
import { Quote, QuoteItemType } from '~/src/quotes/quotes.type.ts';
import { has, omit } from 'lodash-es';
import useAlert from '~/src/shared/ui/components/alert/hooks/use-alert.tsx';
import { useNavigate } from 'react-router-dom';
import { Logger } from '~/src/shared/service/logger';
import { capitalize } from 'lodash-es';

interface InventoryTableProps<CustomInventoryItemVendor> {
  columns: DynamicTableColumn<CustomInventoryItemVendor>[];
  rows: TableRow<CustomInventoryItemVendor>[];
  loading?: boolean;
  onChange: (rows: Record<number, number>) => void;
  focusFirstCol?: boolean;
}

function InventoryTable(props: InventoryTableProps<CustomInventoryItemVendor>) {
  const { columns, rows, onChange, focusFirstCol } = props;

  const [search, setSearch] = React.useState<string>('');
  const filteredRowsBySearch = useSearch(rows, columns, search);
  const { sortedRows, fieldToSort, onSetFieldToSort, descending } = useSortBy(
    filteredRowsBySearch,
    columns,
  );

  const columnFields = React.useMemo(
    () => columns.map((col) => col.field),
    [columns],
  ) as (keyof Partial<TableRow<CustomInventoryItemVendor>>)[];

  const [selectedItems, setSelectedItems] = React.useState<
    Record<number, number>
  >({});
  useUpdateEffect(() => onChange(selectedItems), [selectedItems]);

  const emptyBody = (message: string) => (
    <tbody>
      <tr className="pointer-events-none">
        <td colSpan={99} className="text-center text-gray-600 py-5">
          {message}
        </td>
      </tr>
    </tbody>
  );

  const removeItem = (rowId: number) =>
    setSelectedItems((prev) => omit(prev, [rowId]));

  function onRowChange(
    event: React.ChangeEvent<HTMLInputElement>,
    row: TableRow<CustomInventoryItemVendor>,
  ) {
    if (event.target.value.length === 0) {
      removeItem(row.id);
    } else {
      const value = parseInt(event.target.value, 10);
      setSelectedItems((prev) => ({ ...prev, [row.id]: value }));
    }
  }

  function TableInput(row: TableRow<CustomInventoryItemVendor>) {
    return (
      <Input
        name="quantity"
        type="number"
        className="w-14"
        label="quantity"
        onChange={(e) => onRowChange(e, row)}
        placeholder="0"
        onKeyDown={submitOnEnterPressed}
        hideLabel
      />
    );
  }

  // TODO LUCKYYOU-99: this is duplicate code from the Table component.
  const getContentFromRow = (
    row: TableRow<CustomInventoryItemVendor>,
    columnField: string | number | symbol,
  ) => {
    const columnMeta = columns.find((c) => c.field === columnField);

    let content: TableValue | ReactElement =
      row[columnField as keyof TableRow<CustomInventoryItemVendor>];

    if (columnMeta?.formatType === 'currency') {
      content = formatCurrency(content as number);
    } else if (columnMeta?.formatType === 'date') {
      content = content && formatDate(content as string);
    } else if (columnMeta?.formatType === 'input_number') {
      content = TableInput(row);
    }

    return content as Exclude<TableValue, object> | ReactElement;
  };

  function submitOnEnterPressed(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter' && !event.shiftKey) {
      const form = (event.target as HTMLTextAreaElement)
        .form as HTMLFormElement;
      const submitButton = form.querySelector(
        'button[type="submit"]',
      ) as HTMLButtonElement | null;

      if (submitButton) {
        event.preventDefault();
        submitButton.click();
      }
    }

    // Let event propagate for other keydown events
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center justify-between">
        <p className="text-sm text-gray-600">
          {sortedRows.length} inventory items
        </p>
        <Input
          className="w-auto"
          name="search"
          label="search"
          placeholder="Search"
          icon={<MagnifyingGlass color={Colors.gray['500']} />}
          onChange={(event) => setSearch(event.target.value)}
          hideLabel
        />
      </div>

      <div className="_table-body">
        <table>
          <thead>
            <tr>
              {columns.map((col, index) => (
                <td
                  key={index}
                  className={focusFirstCol && index === 0 ? 'w-full' : ''}
                >
                  <div className="flex items-center gap-3 min-w-max">
                    {col.headerName}
                    <ArrowDownAZ
                      className="_clickable"
                      onClick={() => onSetFieldToSort(col.field)}
                      inactive={col.field !== fieldToSort}
                      inverted={col.field === fieldToSort && descending}
                    />
                  </div>
                </td>
              ))}
            </tr>
          </thead>
          <Switch>
            <Case
              when={sortedRows.length > 0}
              render={() => (
                <tbody className="text-gray-900">
                  {sortedRows.map((row, index) => (
                    <tr
                      key={index}
                      data-testid="table-row"
                      className={clean(
                        `border-b-[1px] hover:!bg-white ${has(selectedItems, row.id) ? 'bg-info-50 hover:!bg-info-50' : ''}`,
                      )}
                    >
                      {columnFields.map((col, index) => (
                        <td
                          key={index}
                          className={
                            focusFirstCol && index !== 0 ? 'text-end' : ''
                          }
                        >
                          <span>{getContentFromRow(row, col) ?? '-'}</span>
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              )}
            />
            <Case default render={() => emptyBody('No results')} />
          </Switch>
        </table>

        <div className="mt-4 mb-2">
          <Button className="_outline">Create New Inventory Item</Button>
          <p className="text-sm text-gray-500 ml-2 mt-1">
            Existing vendors only
          </p>
        </div>
      </div>

      <div className="flex gap-4 min-h-6">
        {Object.keys(selectedItems)
          .map((k) => parseInt(k, 10))
          .map((itemId, index) => (
            <Badge
              key={index}
              content={
                sortedRows.find((row) => row.id === itemId)?.name as string
              }
              color="info"
              onClose={() => removeItem(itemId)}
            />
          ))}
      </div>
    </div>
  );
}

export default function InventoryItemQuoteModal(props: {
  quoteId: string;
  itemType: QuoteItemType;
  refetch: (
    options?: RefetchOptions | undefined,
  ) => Promise<QueryObserverResult<Quote, Error>>;
}) {
  const { quoteId, itemType, refetch } = props;
  const alert = useAlert();
  const { data } = useQuery({
    ...query(paths.inventoryItemVendors, {
      query: { category_type: itemType },
    }),
  });
  const { mutate, status } = useMutation(
    mutation(paths.assignQuoteItems, { params: { id: quoteId } }),
  );
  const navigate = useNavigate();

  const [selectedItems, setSelectedItems] = React.useState<
    Record<number, number>
  >({});
  const fullData = React.useMemo(
    () => data?.map((d) => ({ ...d, quantity: 0 })),
    [data],
  );

  function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const items = Object.entries(selectedItems).map(([itemId, quantity]) => ({
      quantity: quantity,
      type: itemType,
      inventoryItemVendor: parseInt(itemId, 10),
    }));

    if (items.length === 0) return;

    Logger.info('Assigning items to quote', { items });
    mutate(items);
  }

  useUpdateEffect(() => {
    if (status === 'success') {
      navigate(-1);
      refetch();
      alert.showSuccess('Items successfully assigned to quote.');
    } else if (status === 'error') {
      alert.showError('Error on assigning items to quote.');
    }
  }, [status]);

  const columns = [
    { field: 'name', headerName: 'Inventory Item' },
    { field: 'quantity', headerName: 'Qty', formatType: 'input_number' },
    { field: 'unitPrice', headerName: 'unitPrice', formatType: 'currency' },
    { field: 'datePricing', headerName: 'Date Pricing', formatType: 'date' },
    { field: 'unitOfMeasure', headerName: 'Vendor UOM' },
    { field: 'sellUnitsOnHand', headerName: 'Qtd On Hand' },
  ] as TableColumn<CustomInventoryItemVendor>[];

  function onTableChange(rows: Record<number, number>) {
    setSelectedItems(rows);
  }

  return (
    <FormModal
      name={`Add ${itemType}`}
      isSubmitting={status === 'pending'}
      title={`Add ${capitalize(itemType)}`}
      onSubmit={() => handleSubmit}
      actionName="Add Selected Items"
    >
      <InventoryTable
        columns={columns}
        rows={fullData ?? []}
        onChange={onTableChange}
        focusFirstCol
      />
    </FormModal>
  );
}
