import { IconProps } from '~/src/shared/ui/icons/types.ts';

export default function Print(props: IconProps) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.5 7H4V4C4.02083 3.4375 4.21875 2.96875 4.59375 2.59375C4.96875 2.21875 5.4375 2.02083 6 2H13.1875C13.7292 2 14.1979 2.19792 14.5938 2.59375L15.4062 3.40625C15.8021 3.80208 16 4.28125 16 4.84375V7H14.5V4.84375C14.5 4.69792 14.4479 4.57292 14.3438 4.46875L13.5312 3.65625C13.4271 3.55208 13.3125 3.5 13.1875 3.5H6C5.6875 3.52083 5.52083 3.6875 5.5 4V7V4V7ZM6 13.5V16.5H14V13.5H6H14H6ZM5.5 12H14.5C14.7917 12 15.0312 12.0938 15.2188 12.2812C15.4062 12.4688 15.5 12.7083 15.5 13H16.5V10C16.4792 9.6875 16.3125 9.52083 16 9.5H4C3.6875 9.52083 3.52083 9.6875 3.5 10V13H4.5C4.5 12.7083 4.59375 12.4688 4.78125 12.2812C4.96875 12.0938 5.20833 12 5.5 12ZM15.5 14.5V17C15.5 17.2917 15.4062 17.5312 15.2188 17.7188C15.0312 17.9062 14.7917 18 14.5 18H5.5C5.20833 18 4.96875 17.9062 4.78125 17.7188C4.59375 17.5312 4.5 17.2917 4.5 17V14.5H3C2.70833 14.5 2.46875 14.4062 2.28125 14.2188C2.09375 14.0312 2 13.7917 2 13.5V10C2.02083 9.4375 2.21875 8.96875 2.59375 8.59375C2.96875 8.21875 3.4375 8.02083 4 8H16C16.5625 8.02083 17.0312 8.21875 17.4062 8.59375C17.7812 8.96875 17.9792 9.4375 18 10V13.5C18 13.7917 17.9062 14.0312 17.7188 14.2188C17.5312 14.4062 17.2917 14.5 17 14.5H15.5H17H15.5Z"
        fill={props.color ?? 'black'}
      />
    </svg>
  );
}
