import { atom } from 'recoil';
import { AlertProps } from '~/src/shared/ui/components/alert/alert.type.ts';
import * as React from 'react';

interface UI {
  alert?: React.ReactElement<AlertProps>;
}

export const UIState = atom<UI>({
  key: 'UIState',
  default: {},
});
