import { AccountPayable } from '~/src/customers/customers.type.ts';
import InfoPiece from '~/src/shared/ui/components/info-piece/info-piece.component';

import '~/src/customers/styles/accounts-payable.component.css';
import {
  PaymentMethod,
  PaymentMethodOptions,
  PaymentTerms,
  PaymentTermsOptions,
} from '~/src/shared/consts.ts';

export function AccountsPayable({ data }: { data: AccountPayable }) {
  if (!data) {
    return (
      <div className="_accounts-payable-container">
        Account Payable information unavailable.
      </div>
    );
  }
  return (
    <div className="_accounts-payable-container">
      <div>
        <InfoPiece label="Contact" content={data.contact} />
        <InfoPiece label="Phone" content={data.phone} />
      </div>
      <div>
        <InfoPiece label="Email" content={data.email} />
      </div>

      <div />

      <div>
        <InfoPiece
          label="Payment Terms"
          content={PaymentTermsOptions[data.paymentTerms as PaymentTerms]}
        />
        <InfoPiece
          label="Payment Method"
          content={PaymentMethodOptions[data.paymentMethod as PaymentMethod]}
        />
      </div>
      <div>
        <InfoPiece label="Portal Address" content={data.portalAddress} link />
      </div>
      <div>
        <InfoPiece label="Username" content={data.username} />
        <InfoPiece label="Password" content={data.password} />
      </div>
    </div>
  );
}
