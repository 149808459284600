import * as React from 'react';

import ElipsisVertical from '~/src/shared/ui/icons/elipsis-vertical.tsx';
import { usePopup } from '~/src/shared/hooks/use-popup.ts';
import Divider from '~/src/shared/ui/components/divider/divider.tsx';
import ReactDOM from 'react-dom';

export interface MenuOptionProps {
  action: () => void;
  label: string;
  icon: React.ReactElement<SVGElement>;
  color?: 'default' | 'danger';
}

export function MenuOption(props: MenuOptionProps) {
  const { icon, label, action, color } = props;

  return (
    <button onClick={action} className="flex gap-6" role="menuitem">
      {icon}
      <p className={color !== 'danger' ? 'text-gray-900' : 'text-danger-500'}>
        {label}
      </p>
    </button>
  );
}

interface MenuProps {
  children?: React.ReactElement<MenuOptionProps>[];
  icon?: React.ReactElement;
}

export function Menu(props: MenuProps) {
  const { children, icon } = props;
  const { ref, expanded, toggleExpanded, onPopupDisplay } =
    usePopup<HTMLDivElement>();

  React.Children.forEach(children, (child) => {
    if (
      React.isValidElement(child) &&
      child.type !== MenuOption &&
      child.type !== Divider
    ) {
      throw new Error(
        'Menu component only accepts MenuOption or Divider components as children.',
      );
    }
  });

  function runActionAndCloseMenu(action: () => void) {
    action();
    toggleExpanded();
  }

  return (
    <div className="_menu" ref={ref}>
      <button
        onClick={toggleExpanded}
        className={`_icon-button ${expanded ? 'bg-gray-100' : ''}`}
      >
        {icon || <ElipsisVertical />}
      </button>

      {expanded &&
        ReactDOM.createPortal(
          <div className="_menu-popup" ref={onPopupDisplay} role="menu">
            {React.Children.map(children, (child) => {
              if (React.isValidElement(child)) {
                return React.cloneElement(child, {
                  action: () => runActionAndCloseMenu(child.props.action),
                });
              }
            })}
          </div>,
          document.body,
        )}
    </div>
  );
}
