import { Contact } from '../contacts/contacts.type';
import { InventoryItemVendorWithInventory } from '../inventory/inventory.type';
import { Brand } from '../shared/utils';
import { Vendor } from '../vendors/vendors.type';

export const PurchaseOrderStatusOptions = {
  OPEN: 'Open',
  CLOSED: 'Closed',
};
export type PurchaseOrderStatus = keyof typeof PurchaseOrderStatusOptions;

export interface PurchaseOrderLine {
  id: Brand<number, 'PurchaseOrderLine'>;
  quantity: number;
  inventoryItemVendor: InventoryItemVendorWithInventory;
  lineTotal: number;
  numberOfPallets: number;
  receivedDate?: string;
}

// Interface for easier representation of P.O. Lines as flat rows.
export interface PurchaseOrderLineRow {
  id?: Brand<number, 'PurchaseOrderLine'>;
  vendorInventoryItemId: Brand<number, 'InventoryItemVendor'>;
  itemName: string;
  sku: string;
  skuCost: number;
  sellUnitPrice: number;
  qty: number;
  numberOfPallets?: number;
  lineTotal: number;
  receivedDate?: string;
}

export interface PurchaseOrder {
  id: Brand<number, 'PurchaseOrder'>;
  vendor: Vendor;
  submittedDate: string;
  deliveryByDate: string;
  internalNotes: string;
  notesForVendor: string;
  total: number;
  poNumber: number;
  status: PurchaseOrderStatus;
  contact: Contact;
  branch: string;
  orderedBy: string; // TODO: handle user id / username
  lines: PurchaseOrderLine[];
}
