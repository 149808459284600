import { FormModal } from '~/src/shared/ui/components/modal/modal.tsx';
import { useCustomForm } from '~/src/shared/hooks/use-custom-form';
import '~/src/shared/ui/components/modal/form-modal.css';
import { useQuery } from '@tanstack/react-query';
import { query } from '~/src/shared/api/request-config.ts';
import { paths } from '~/src/shared/api/api.schema';
import { useMemo, useState } from 'react';
import { InventoryItem } from '~/src/inventory/inventory.type';
import { Vendor } from '~/src/vendors/vendors.type';
import { Logger } from '~/src/shared/service/logger';
import { useNavigate } from 'react-router-dom';
import { routes } from '~/src/routes';
import { DropDown } from '~/src/shared/ui/components';

export function PurchaseOrderStandaloneModal() {
  const navigate = useNavigate();

  const { data: vendors, isFetched: vendorsFetched } = useQuery({
    ...query(paths.vendors),
  });

  const { data: inventoryItems, isFetched: itemsFetched } = useQuery({
    ...query(paths.inventoryItems),
  });

  // These states are mutually exclusive: the form is either using item or vendor as main selection.
  const [selectedItem, setSelectedItem] = useState<InventoryItem | null>(null);
  const [selectedVendor, setSelectedVendor] = useState<Vendor | null>(null);
  const isItemMainSelection = !!selectedItem;
  const isVendorMainSelection = !!selectedVendor;

  // We only need vendors that have items associated to them
  const vendorsWithItems = useMemo(
    () =>
      vendors?.filter((v) =>
        inventoryItems?.some((i) =>
          i.inventoryItemVendors.some((iv) => iv.vendor.id === v.id),
        ),
      ) || [],
    [vendors, inventoryItems],
  );

  const { handleSubmit, isSubmitting, setValue, register, setFormError } =
    useCustomForm({
      initialData: {},
      validate: (data) => {
        if (!data.vendor) {
          setFormError(['vendor', 'Select a vendor.']);
          return false;
        }
        if (!data.inventoryItem) {
          setFormError(['inventoryItem', 'Select an inventory item.']);
          return false;
        }
        return data;
      },
      handleSubmit: async (data) => {
        navigate(
          routes.addItemPurchaseOrderPage.getPath(
            data.vendor,
            data.inventoryItem,
          ),
        );
      },
    });

  const formModalProps = {
    name: 'purchase-order-standalone-create',
    title: 'Choose Product or Vendor',
    id: 'purchase-order-standalone-create-modal',
    className: '!w-[640px] !h-[230px] !gap-1',
    'data-testid': 'purchase-order-standalone-create-modal',
    onSubmit: () => handleSubmit,
    isSubmitting: isSubmitting,
    actionName: 'Continue',
    floating: true,
  };

  const isLoading = !(vendorsFetched && itemsFetched);
  if (isLoading) {
    return <FormModal {...formModalProps}>Loading...</FormModal>;
  }
  if (!vendors || !inventoryItems) {
    Logger.warn(
      'Vendors or Inventory Items unavailable for Standalone Modal.',
      { vendors, inventoryItems },
    );
    return (
      <FormModal {...formModalProps}>
        Something went wrong. Please try again.
      </FormModal>
    );
  }

  // In case an item is selected, we find the vendors available for that item. All vendors are available otherwise.
  const availableVendors = isItemMainSelection
    ? selectedItem.inventoryItemVendors.map((inventoryVendor) =>
        vendorsWithItems.find(
          (vendor) => vendor.id === inventoryVendor.vendor.id,
        ),
      )
    : vendorsWithItems;
  const vendorOptions = availableVendors.map((vendor) => ({
    value: vendor!.id.toString(),
    title: vendor!.name,
  }));

  // In case a vendor is selected, we find the items available for that vendor. All items are available otherwise.
  const availableItems = isVendorMainSelection
    ? inventoryItems.filter((item) =>
        item.inventoryItemVendors.some(
          (iv) => iv.vendor.id === selectedVendor.id,
        ),
      )
    : inventoryItems;
  const itemOptions = availableItems.map((item) => ({
    value: item!.id.toString(),
    title: item!.name,
  }));

  return (
    <FormModal {...formModalProps}>
      <div className="_section">
        <div className="_row">
          <DropDown
            title="Vendor Name"
            label="Vendor Name"
            className="flex-1"
            required
            options={vendorOptions}
            {...register('vendor')}
            onChange={(value) => {
              if (value && !isItemMainSelection)
                setSelectedVendor(
                  vendorsWithItems.find((v) => v.id.toString() === value) ||
                    null,
                );
              setValue('vendor', value);
            }}
            disabled={isVendorMainSelection}
            onClear={() => {
              setSelectedVendor(null);
            }}
            searchable
          />

          <DropDown
            label="Inventory Item"
            title="Inventory Item"
            className="flex-1 "
            required
            options={itemOptions}
            {...register('inventoryItem')}
            onChange={(value) => {
              if (value && !isVendorMainSelection)
                setSelectedItem(
                  inventoryItems.find((i) => i.id.toString() === value) || null,
                );
              setValue('inventoryItem', value);
            }}
            disabled={isItemMainSelection}
            onClear={() => {
              setSelectedItem(null);
            }}
            searchable
          />
        </div>
      </div>
    </FormModal>
  );
}
