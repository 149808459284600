import * as React from 'react';
import useScreenSize from '~/src/shared/hooks/use-screen-size.ts';

export default function usePaginationWindow(
  amountOfPages: number,
  currentPage: number,
) {
  const { screenSize } = useScreenSize();

  return React.useMemo(() => {
    const numPagesToDisplayOnFooter = { xl: 9, lg: 9, md: 5, sm: 3, xs: 3 }[
      screenSize
    ];

    if (amountOfPages <= numPagesToDisplayOnFooter) {
      return [1, amountOfPages];
    }

    const numPagesEachSide = Math.floor(numPagesToDisplayOnFooter / 2);

    if (currentPage - numPagesEachSide < 1) {
      return [1, numPagesToDisplayOnFooter];
    }

    if (currentPage + numPagesEachSide > amountOfPages) {
      return [amountOfPages - numPagesToDisplayOnFooter + 1, amountOfPages];
    }

    return [currentPage - numPagesEachSide, currentPage + numPagesEachSide];
  }, [amountOfPages, currentPage, screenSize]);
}
