import { IconProps } from '~/src/shared/ui/icons/types.ts';

export default function Copy(props: IconProps) {
  const { color } = props;

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15 12.5C15.25 12.5 15.5 12.2812 15.5 12V5.625L13.375 3.5H9C8.71875 3.5 8.5 3.75 8.5 4V12C8.5 12.2812 8.71875 12.5 9 12.5H15ZM9 14C7.875 14 7 13.125 7 12V4C7 2.90625 7.875 2 9 2H13.375C13.75 2 14.1562 2.1875 14.4375 2.46875L16.5312 4.5625C16.8125 4.84375 17 5.25 17 5.625V12C17 13.125 16.0938 14 15 14H9ZM5 6H6V7.5H5C4.71875 7.5 4.5 7.75 4.5 8V16C4.5 16.2812 4.71875 16.5 5 16.5H11C11.25 16.5 11.5 16.2812 11.5 16V15H13V16C13 17.125 12.0938 18 11 18H5C3.875 18 3 17.125 3 16V8C3 6.90625 3.875 6 5 6Z"
        fill={color ?? 'black'}
      />
    </svg>
  );
}
