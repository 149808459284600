import * as ReactDOM from 'react-dom';

import { CircleInfo } from '~/src/shared/ui/icons/circle-info.tsx';
import { CircleCheck } from '~/src/shared/ui/icons/circle-check.tsx';
import { TriangleExclamation } from '~/src/shared/ui/icons/triangle-exclamation.tsx';
import { CircleExclamation } from '~/src/shared/ui/icons/circle-exclamation.tsx';
import Xmark from '~/src/shared/ui/icons/xmark.tsx';
import { clean } from '~/src/shared/ui/styles/helpers.ts';
import { Colors } from '~/src/shared/ui/styles/const.ts';
import { AlertProps } from '~/src/shared/ui/components/alert/alert.type.ts';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { UIState } from '~/src/state/atoms/ui.atom.ts';

import './alert.css';

export default function Alert(props: AlertProps & { duration: number }) {
  const { color, title, text, duration, ...rest } = props;
  const setUI = useSetRecoilState(UIState);

  function onAlertShow(alert: HTMLDivElement | null) {
    if (!alert) return;
    alert.style.animation = `fade ${duration}s linear`;
  }

  const iconMap = {
    success: <CircleCheck color={Colors.success['500']} />,
    info: <CircleInfo color={Colors.info['500']} />,
    warning: <TriangleExclamation color={Colors.warning['500']} />,
    danger: <CircleExclamation color={Colors.danger['500']} />,
  };

  const colorMap = {
    info: 'bg-info-50 border-info-100 text-info-700',
    success: 'bg-success-50 border-success-100 text-success-700',
    danger: 'bg-danger-50 border-danger-100 text-danger-700',
    warning: 'bg-warning-50 border-warning-100 text-warning-700',
  };

  return (
    <div
      ref={onAlertShow}
      role="alert"
      className={clean(`_alert ${colorMap[color]}`)}
      {...rest}
    >
      {iconMap[color]}
      <div>
        <p>{title}</p>
        <p>{text}</p>
      </div>
      <button onClick={() => setUI({})}>
        <Xmark size="md" color={Colors[color]['200']} />
      </button>
    </div>
  );
}

export function AlertProvider() {
  const { alert } = useRecoilValue(UIState);
  return alert ?? ReactDOM.createPortal(alert, document.body);
}
