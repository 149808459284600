import {
  Discount,
  FreightMarkup,
  Quote,
  QuoteItem,
  QuoteItemPayload,
  SetUpFee,
} from '~/src/quotes/quotes.type.ts';
import { Order } from '~/src/orders/orders.type.ts';

export interface QuotesAPI {
  'GET /quotes/:id/': {
    params: { id: string };
    response: Required<Quote>;
  };
  'GET /quotes/': {
    response: Required<Quote>[];
  };
  'POST /quotes/': {
    body: Quote;
    response: Quote;
  };
  'PATCH /quotes/:id/': {
    params: { id: string };
    body: Partial<Quote>;
    response: Quote;
  };
  'POST /quotes/:id/quote-items/': {
    params: { id: string };
    body: Omit<QuoteItemPayload, 'id' | 'quote'>[];
    response: Quote;
  };
  'PATCH /quotes/:quoteId/quote-items/:itemId/': {
    params: { quoteId: number; itemId: number };
    body: QuoteItemPayload;
    response: QuoteItem;
  };
  'PATCH /quotes/:quoteId/discounts/:discountId/': {
    params: { quoteId: number; discountId: number };
    body: Partial<Discount>;
    response: QuoteItem;
  };
  'POST /quotes/:id/discounts/': {
    params: { id: number };
    body: Partial<Discount>;
    response: Quote;
  };
  'PATCH /quotes/:quoteId/set-up-fees/:setupFeeId/': {
    params: { quoteId: number; setupFeeId: number };
    body: Partial<SetUpFee>;
    response: QuoteItem;
  };
  'PATCH /quotes/:quoteId/freight-markups/:markupId/': {
    params: { quoteId: number; markupId: number };
    body: Partial<FreightMarkup>;
    response: QuoteItem;
  };
  'POST /quotes/:id/freight-markups/': {
    params: { id: number };
    body: Partial<FreightMarkup>;
    response: Quote;
  };
  'POST /quotes/:id/set-up-fees/': {
    params: { id: number };
    body: Partial<SetUpFee>;
    response: Quote;
  };
  'GET /quotes/:id/quote-items/': {
    params: { id: string };
    response: Quote;
  };
  'DELETE /quotes/:quoteId/quote-items/:itemId': {
    params: { quoteId: number; itemId: number };
    response: null;
  };
  'POST /quotes/:id/create-order/': {
    params: { id: string };
    response: Order;
  };
}

export const quotesPaths = {
  quote: 'GET /quotes/:id/',
  quotes: 'GET /quotes/',
  createQuote: 'POST /quotes/',
  assignQuoteItems: 'POST /quotes/:id/quote-items/',
  updateQuoteItem: 'PATCH /quotes/:quoteId/quote-items/:itemId/',
  updateQuoteDiscount: 'PATCH /quotes/:quoteId/discounts/:discountId/',
  createQuoteDiscount: 'POST /quotes/:id/discounts/',
  createQuoteSetUpFee: 'POST /quotes/:id/set-up-fees/',
  createQuoteFreightMarkup: 'POST /quotes/:id/freight-markups/',
  updateQuoteSetUpFee: 'PATCH /quotes/:quoteId/set-up-fees/:setupFeeId/',
  updateQuoteFreightMarkup: 'PATCH /quotes/:quoteId/freight-markups/:markupId/',
  quoteItems: 'GET /quotes/:id/quote-items/',
  assignItemsToQuote: 'POST /quotes/:id/quote-items/',
  partialUpdateQuote: 'PATCH /quotes/:id/',
  deleteQuoteItem: 'DELETE /quotes/:quoteId/quote-items/:itemId',
  createOrder: 'POST /quotes/:id/create-order/',
} satisfies Record<string, keyof QuotesAPI>;
