import { IconProps } from '~/src/shared/ui/icons/types.ts';

export function House(props: IconProps) {
  return (
    <svg
      width="28"
      height="25"
      viewBox="0 0 28 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.25 1.28125C13.6719 0.953125 14.2812 0.953125 14.7031 1.28125L27.0781 11.7812C27.5469 12.2031 27.6406 12.9062 27.2188 13.375C26.7969 13.8438 26.0938 13.8906 25.625 13.5156L24.5 12.5312V21.25C24.5 23.3594 22.8125 25 20.75 25H7.25C5.14062 25 3.5 23.3594 3.5 21.25V12.5312L2.32812 13.5156C1.85938 13.8906 1.15625 13.8438 0.734375 13.375C0.359375 12.9062 0.40625 12.2031 0.875 11.7812L13.25 1.28125ZM14 3.625L5.75 10.6094V21.25C5.75 22.0938 6.40625 22.75 7.25 22.75H9.5V15.625C9.5 14.5938 10.2969 13.75 11.375 13.75H16.625C17.6562 13.75 18.5 14.5938 18.5 15.625V22.75H20.75C21.5469 22.75 22.25 22.0938 22.25 21.25V10.6094L14 3.625ZM11.75 22.75H16.25V16H11.75V22.75Z"
        fill={props.color ?? 'black'}
      />
    </svg>
  );
}
