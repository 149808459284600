import { CorporateAddress, Customer } from '~/src/customers/customers.type.ts';
import {
  Contact,
  ContactAddressType,
  ContactPhoneType,
} from '~/src/contacts/contacts.type.ts';
import InfoPiece from '~/src/shared/ui/components/info-piece/info-piece.component.tsx';
import Divider from '~/src/shared/ui/components/divider/divider.tsx';
import '~/src/contacts/styles/contact-information.component.css';
import { Vendor, VendorAddress } from '~/src/vendors/vendors.type';
import { Conditionally } from '~/src/shared/ui/components/switch/switch';
import { formatPhoneNumber } from '~/src/shared/utils.ts';
import { capitalize } from 'lodash-es';

export function ContactInformation({
  parent,
  contact,
}: {
  parent: Required<Customer | Vendor>;
  contact: Required<Contact>;
}) {
  function makeFullAddress(address: CorporateAddress | VendorAddress) {
    if (!address) {
      return 'Address unavailable.';
    }
    const addr = address.addressLine1;
    const city = address.city;
    const state = address.state;
    const zipcode = address.zipcode;
    return `${addr}, ${city}, ${zipcode}, ${state}, USA`;
  }

  function getAddressByType(addressType: ContactAddressType) {
    return contact.addresses.find(
      (address) => address.addressType === addressType,
    );
  }

  function getPhoneByType(phoneType: ContactPhoneType) {
    const phone = contact.phones.find((phone) => phone.phoneType === phoneType);
    if (!phone) return;

    return formatPhoneNumber(phone.phoneNumber);
  }

  const residentialAddress = getAddressByType('residential');
  const corporateAddress = getAddressByType('corporate');

  const personalPhone = getPhoneByType('personal_mobile');
  const workPhone = getPhoneByType('work_mobile');
  const officePhone = getPhoneByType('office');
  const homePhone = getPhoneByType('home');

  return (
    <div className="_contact-info-container">
      <div>
        <InfoPiece label="First Name" content={contact.firstName} />
        <InfoPiece label="Last Name" content={contact.lastName} />
      </div>

      <div>
        <InfoPiece label="Email" content={contact.email} />
        <InfoPiece label="Linkedin" content={contact.linkedin} link />
      </div>

      <div>
        <InfoPiece label="Personal Phone" content={personalPhone} />
        <InfoPiece label="Home Phone" content={homePhone} />
      </div>

      <div className="flex-col gap-1">
        <p className="text-xs text-gray-500 font-medium">Addresses</p>
        <div className="flex flex-col gap-2">
          {residentialAddress && (
            <InfoPiece
              label="Residential Address"
              content={makeFullAddress(residentialAddress)}
            />
          )}
          {corporateAddress && (
            <InfoPiece
              label="Corporate Address"
              content={makeFullAddress(corporateAddress)}
            />
          )}
        </div>
      </div>

      <Divider />

      <InfoPiece
        label="Business Name"
        content={parent.type === 'vendor' ? parent.name : parent.companyName}
      />
      <Conditionally when={parent.type === 'customer'}>
        {() => (
          <InfoPiece
            label="Active/Inactive"
            content={parent.type === 'customer' ? parent.status : ''}
          />
        )}
      </Conditionally>

      <div>
        <InfoPiece label="Job Title" content={contact.jobTitle} />
        <InfoPiece label="Department" content={contact.department} />
      </div>

      <InfoPiece
        label="Email"
        content={
          parent.type === 'customer' ? parent.accountPayable?.email : '-'
        }
      />

      <div>
        <InfoPiece label="Work Phone" content={workPhone} />
        <InfoPiece label="Office Phone" content={officePhone} />
      </div>

      <Divider />

      <div className="flex-col gap-1">
        <p className="_title">Business Address</p>

        <InfoPiece
          label={`${capitalize(parent.type)} Address`}
          content={makeFullAddress(
            parent.type === 'vendor'
              ? parent.vendorAddress
              : parent.corporateAddress,
          )}
        />
      </div>

      <Divider />

      <div className="flex-col gap-1">
        <p className="_title">Shipping Information</p>
        <div className="flex">
          <InfoPiece
            label="Fedex Account Number"
            content={contact.fedexAccountNumber}
          />
          <InfoPiece
            label="UPS Account Number"
            content={contact.upsAccountNumber}
          />
        </div>
      </div>
    </div>
  );
}
