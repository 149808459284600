import Loading from '~/src/shared/ui/components/loading/loading.tsx';
import { useAuthenticate } from '~/src/luckyyou/hooks/use-authenticate.ts';
import { PropsWithChildren } from 'react';

export default function LoginProvider(props: PropsWithChildren) {
  const isReady = useAuthenticate();

  if (!isReady) {
    return <Loading />;
  }

  return props.children;
}
