import { AuthUser } from '~/src/luckyyou/auth.type';
import { CustomersAPI } from '~/src/customers/customers.schema.ts';
import { InventoryAPI } from '~/src/inventory/inventory.schema.ts';
import { VendorsAPI } from '~/src/vendors/vendors.schema.ts';

export interface BaseAPI extends CustomersAPI, VendorsAPI, InventoryAPI {
  'POST /logging/events/': {
    body: object;
    response: null;
  };
  'POST /trigger-login-email/': {
    body: object;
    response: object;
  };
  'GET /self/': {
    headers: { Authorization: string };
    response: AuthUser;
  };
  'GET /users/': {
    response: AuthUser[];
  };
}

export const basePaths = {
  logging: 'POST /logging/events/',
  getSelfInfo: 'GET /self/',
  users: 'GET /users/',
} satisfies Record<string, keyof BaseAPI>;
