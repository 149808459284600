import { Table } from '~/src/shared/ui/components/table/table.tsx';
import { TableColumn } from '~/src/shared/ui/components/table/types.ts';
import { InventoryItemVendor } from '~/src/inventory/inventory.type.ts';
import { Vendor } from '~/src/vendors/vendors.type.ts';

export default function VendorsTable({
  vendors: itemVendors,
  isLoading,
}: {
  vendors: Required<InventoryItemVendor | Vendor>[];
  isLoading?: boolean;
}) {
  const columns = [
    { field: 'vendor.name', headerName: 'Vendor' },
    { field: 'unitPrice', headerName: 'Unit Price' },
    { field: 'unitOfMeasure', headerName: 'UOM' },
    { field: 'skuCost', headerName: 'SKU Cost' },
    { field: 'qtyPerSKU', headerName: 'Qty Per SKU' },
    // { field: '', headerName: 'Sold As' },
    { field: 'sku', headerName: 'SKU' },
    // { field: 'phoneNumber', headerName: 'Date Pricing' },
  ] as TableColumn<Partial<InventoryItemVendor>>[];

  return (
    <Table<Omit<InventoryItemVendor, 'vendor'>>
      name="Vendor"
      columns={columns}
      rows={itemVendors}
      loading={isLoading}
      defaultRowsPerPage={50}
      onRowClick={() => {}}
      withCheckbox
    />
  );
}
