import { Table } from '~/src/shared/ui/components/table/table.tsx';
import { TableColumn } from '~/src/shared/ui/components/table/types.ts';
import { DropDownOption } from '~/src/shared/ui/components/select/dropdown.tsx';
import { Customer } from '~/src/customers/customers.type.ts';
import { useLocation, useNavigate } from 'react-router-dom';
import { CustomerModal } from '~/src/customers/components/customer-modal.component.tsx';
import { paths } from '~/src/shared/api/api.schema.ts';
import { query } from '~/src/shared/api/request-config.ts';
import { useQuery } from '@tanstack/react-query';
import { routes } from '~/src/routes.ts';
import { Button } from '~/src/shared/ui/components';

export function Customers() {
  const { data, fetchStatus, refetch } = useQuery({
    ...query(paths.customers),
  });

  const isLoading = fetchStatus === 'fetching';
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const columns = [
    { field: 'companyName', headerName: 'Company Name' },
  ] as TableColumn<Partial<Customer>>[];

  return (
    <div className="_page">
      <header>
        <div>
          <h1>Customers</h1>
        </div>

        <div className="_header-actions">
          <Button onClick={() => navigate(routes.newCustomer.path)}>
            Add New Customer
          </Button>
        </div>
      </header>

      <Table<
        Required<
          Omit<
            Customer,
            'corporateAddress' | 'companyEstablishedDate' | 'accountPayable'
          >
        >
      >
        name="Customer"
        columns={columns}
        rows={data ?? []}
        filters={{
          status: [
            { value: null, title: 'All' },
            { value: 'active', title: 'Active' },
            { value: 'inactive', title: 'Inactive' },
          ] as DropDownOption[],
        }}
        initialFilters={{ status: { value: 'active', title: 'Active' } }}
        loading={isLoading}
        defaultRowsPerPage={50}
        onRowClick={(customer) =>
          navigate(routes.customerInfo.getPath(customer.id))
        }
        showSearchInput
      />

      {pathname.includes('new') ? (
        <CustomerModal refetchCustomers={refetch} />
      ) : null}
    </div>
  );
}
