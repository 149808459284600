import { TextArea } from '~/src/shared/ui/components';
import React from 'react';
import { Quote } from '~/src/quotes/quotes.type.ts';

interface DescriptionsTabProps {
  quote: Quote;
  handleNoteChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

export default function NotesTab(props: DescriptionsTabProps) {
  const { quote, handleNoteChange } = props;

  return (
    <div className="_table-body _notes-container">
      <div className="_row">
        <TextArea
          label="Quote Notes"
          placeholder="Write your Quotes Notes here..."
          helpText="Prints on the quote and is visible to the customer"
          data-type="quote_note"
          defaultValue={
            quote?.quoteNotes?.find((n) => n.type === 'quote_note')?.note
          }
          onBlur={handleNoteChange}
        />
      </div>
      <div className="_row">
        <TextArea
          label="Production Notes"
          placeholder="Write your Production Notes here..."
          helpText="Prints on the quote and is visible to the customer"
          data-type="production"
          defaultValue={
            quote?.quoteNotes?.find((n) => n.type === 'production_note')?.note
          }
          onBlur={handleNoteChange}
        />
      </div>
      <div className="_row">
        <TextArea
          label="Order Notes"
          placeholder="Write your Order Notes here..."
          helpText="Internal only within order"
          data-type="order_note"
          defaultValue={
            quote?.quoteNotes?.find((n) => n.type === 'order_note')?.note
          }
          onBlur={handleNoteChange}
        />
      </div>
      <div className="_row">
        <TextArea
          label="Shipping Notes"
          placeholder="Write your Shipping Notes here..."
          helpText="Internal only within order"
          data-type="shipping_note"
          defaultValue={
            quote?.quoteNotes?.find((n) => n.type === 'shipping_note')?.note
          }
          onBlur={handleNoteChange}
        />
      </div>
    </div>
  );
}
