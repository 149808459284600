import * as React from 'react';
import {
  TableDynamicFilterOption,
  TableData,
  TableRow,
} from '~/src/shared/ui/components/table/types.ts';
import {
  filter,
  isMatch,
  overEvery,
  mapValues,
  omitBy,
  isNull,
} from 'lodash-es';
import { DropDownOption } from '~/src/shared/ui/components/select/dropdown.tsx';

export default function useFilterBy<T extends TableData<T>>(
  rows: TableRow<T>[],
  filters?: Record<keyof T, DropDownOption | null>,
  dynamicFilters?: Record<string, TableDynamicFilterOption<T> | null>,
) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const filterValid = React.useCallback((f?: Record<string, any>) => {
    if (!f) return {};

    return omitBy(f, (option) => !option || isNull(option.value));
  }, []);

  const validFilters = React.useMemo(
    () => filterValid(filters) as Record<keyof T, DropDownOption>,
    [filters, filterValid],
  );

  const validDynamicFilters = React.useMemo(
    () => filterValid(dynamicFilters),
    [dynamicFilters, filterValid],
  );

  const dynamicFiltersFunctions = React.useMemo(() => {
    return Object.values(validDynamicFilters).map(
      (f) => (f as TableDynamicFilterOption<T>)?.fn,
    );
  }, [validDynamicFilters]);

  const validFilterValues = React.useMemo(
    () => mapValues(validFilters, (v) => v?.value),
    [validFilters],
  );

  return {
    filteredRows: React.useMemo(() => {
      return filter(
        rows,
        (row) =>
          overEvery(dynamicFiltersFunctions)(row) &&
          isMatch(row, validFilterValues),
      ) as TableRow<T>[];
    }, [rows, validFilterValues, dynamicFiltersFunctions]),
    filtersBeingUsed: validFilters as Record<keyof T, DropDownOption>,
    dynamicFiltersBeingUsed: validDynamicFilters as Record<
      string,
      TableDynamicFilterOption<T>
    >,
  };
}
