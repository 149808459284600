export type ColorVariant = 'info' | 'success' | 'danger' | 'warning' | 'gray';
export const Colors: Record<ColorVariant, Record<number, string>> = {
  success: {
    50: '#E8F7EF',
    100: '#B7E5CF',
    200: '#94D8B7',
    500: '#17AB63',
    700: '#107946',
  },
  info: {
    50: '#E7F5FD',
    100: '#B6DFF7',
    200: '#92D0F4',
    500: '#1399E6',
    600: '#118bd1',
    700: '#0D6DA3',
    900: '#084061',
  },
  warning: {
    50: '#FEF3E9',
    100: '#FCD9BA',
    200: '#FBC799',
    300: '#F9AE6A',
    500: '#F68621',
    700: '#AF5F17',
  },
  danger: {
    50: '#FDEBEB',
    100: '#F9C2BF',
    200: '#F7A4A1',
    300: '#F37A76',
    500: '#ED3932',
    700: '#A82824',
  },
  gray: {
    50: '#F7F8F8',
    100: '#E6E8EB',
    200: '#D9DDE1',
    300: '#C8CDD3',
    500: '#ADB5BD',
    600: '#9DA5AC',
    700: '#7b8186',
    800: '#5f6468',
    900: '#494C4F',
  },
};
