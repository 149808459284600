import * as React from 'react';

import { clean } from '~/src/shared/ui/styles/helpers';
import Checkmark from '~/src/shared/ui/icons/checkmark';
import './checkbox.css';
import Minus from '~/src/shared/ui/icons/minus.tsx';

interface CheckboxProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  indeterminate?: boolean;
}

export function Checkbox(props: CheckboxProps) {
  const { name, children, className, indeterminate, ...rest } = props;
  const checkbox = React.useRef<HTMLInputElement>(null);
  const labelId = `${name}__label`;

  React.useEffect(() => {
    if (checkbox.current) {
      checkbox.current.indeterminate = Boolean(indeterminate);
    }
  }, [indeterminate]);

  return (
    <label htmlFor={name} className={clean(`_checkbox-label ${className}`)}>
      <input type="checkbox" id={name} name={name} ref={checkbox} {...rest} />
      <span className="_checkbox-icon" aria-hidden>
        {indeterminate ? <Minus /> : <Checkmark />}
      </span>
      <span id={labelId} className="_label-text">
        {children}
      </span>
    </label>
  );
}
