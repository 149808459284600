import * as React from 'react';
import { IconProps } from '~/src/shared/ui/icons/types.ts';

export function AngleUp(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.46875 6.46875C9.75 6.1875 10.2188 6.1875 10.5 6.46875L15.5312 11.4688C15.8125 11.7812 15.8125 12.25 15.5312 12.5312C15.2188 12.8438 14.75 12.8438 14.4688 12.5312L10 8.0625L5.53125 12.5312C5.21875 12.8438 4.75 12.8438 4.46875 12.5312C4.15625 12.25 4.15625 11.7812 4.46875 11.5L9.46875 6.46875Z"
        fill="black"
      />
    </svg>
  );
}

export function AngleRight(props: IconProps) {
  const { size } = props;

  const xs = (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.3984 8.10156C10.6172 8.36719 10.6172 8.63281 10.3984 8.89844L6.64844 12.6484C6.38281 12.8672 6.11719 12.8672 5.85156 12.6484C5.63281 12.3828 5.63281 12.1172 5.85156 11.8516L9.20312 8.5L5.85156 5.14844C5.63281 4.88281 5.63281 4.61719 5.85156 4.35156C6.11719 4.13281 6.38281 4.13281 6.64844 4.35156L10.3984 8.10156Z"
        fill="#C8CDD3"
      />
    </svg>
  );

  const md = (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.5312 9.46875C13.8125 9.78125 13.8125 10.25 13.5312 10.5312L8.53125 15.5312C8.21875 15.8438 7.75 15.8438 7.46875 15.5312C7.15625 15.25 7.15625 14.7812 7.46875 14.5L11.9375 10.0312L7.46875 5.53125C7.15625 5.25 7.15625 4.78125 7.46875 4.5C7.75 4.1875 8.21875 4.1875 8.5 4.5L13.5312 9.46875Z"
        fill="black"
      />
    </svg>
  );

  return size ? { xs, md, lg: md }[size] : xs;
}

export function AngleDown(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.5312 12.5312C10.2188 12.8438 9.75 12.8438 9.46875 12.5312L4.46875 7.53125C4.15625 7.25 4.15625 6.78125 4.46875 6.5C4.75 6.1875 5.21875 6.1875 5.5 6.5L9.96875 10.9688L14.4688 6.46875C14.75 6.1875 15.2188 6.1875 15.5 6.46875C15.8125 6.78125 15.8125 7.25 15.5 7.53125L10.5312 12.5312Z"
        fill="black"
      />
    </svg>
  );
}

export function AngleLeft(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.46875 9.46875L11.4688 4.5C11.75 4.1875 12.2188 4.1875 12.5312 4.5C12.8125 4.78125 12.8125 5.25 12.5312 5.53125L8.03125 10L12.5 14.5C12.8125 14.7812 12.8125 15.25 12.5 15.5312C12.2188 15.8438 11.75 15.8438 11.4688 15.5312L6.46875 10.5312C6.15625 10.25 6.15625 9.78125 6.46875 9.46875Z"
        fill="black"
      />
    </svg>
  );
}
