/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';

interface SwitchProps {
  children: React.ReactNode;
}

export function Switch({ children }: SwitchProps) {
  const choice = React.Children.toArray(children).find((child) => {
    const props = (child as React.ReactElement).props;
    return props.when || props.default;
  });

  return choice ? (choice as React.ReactElement).props.render() : null;
}

interface CaseProps {
  when?: boolean;
  default?: boolean;
  render: () => React.ReactElement | null;
}

/* c8 ignore next 3 */
export function Case(_: CaseProps) {
  return null;
}

export function Conditionally(props: {
  when?: boolean;
  children: () => React.ReactElement | null;
}) {
  const { when, children } = props;
  return when ? children() : null;
}
