import { DropDown, Input } from '~/src/shared/ui/components';
import { useNavigate } from 'react-router-dom';
import { Datepicker } from '~/src/shared/ui/components/datepicker/datepicker.tsx';
import {
  PaymentMethodOptions,
  PaymentTermsOptions,
  USAStates,
} from '~/src/shared/consts.ts';
import { Select } from '~/src/shared/ui/components/select/select.tsx';
import { Customer } from '~/src/customers/customers.type.ts';
import { FormModal } from '~/src/shared/ui/components/modal/modal.tsx';

import '~/src/shared/ui/components/modal/form-modal.css';
import { fetcher, FetchError } from '~/src/shared/service/fetch/fetcher.ts';
import { paths } from '~/src/shared/api/api.schema.ts';
import { Logger } from '~/src/shared/service/logger';
import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query';
import { flattenErrors, makeDropDownOptions } from '~/src/shared/utils.ts';
import { CircleExclamation } from '~/src/shared/ui/icons/circle-exclamation.tsx';
import Xmark from '~/src/shared/ui/icons/xmark.tsx';
import { Colors } from '~/src/shared/ui/styles/const.ts';
import { capitalize } from 'lodash-es';
import { useCustomForm } from '~/src/shared/hooks/use-custom-form.ts';
import Divider from '~/src/shared/ui/components/divider/divider.tsx';
import { prependHttps } from '~/src/shared/utils.ts';

interface CustomerModalProps {
  refetchCustomers: (
    options?: RefetchOptions | undefined,
  ) => Promise<QueryObserverResult<Customer[] | Customer, Error>>;
  customer?: Customer;
}

const emptyCustomer = { corporateAddress: { state: '' } } as Partial<Customer>;

export function CustomerModal(props: CustomerModalProps) {
  const { refetchCustomers, customer } = props;

  const navigate = useNavigate();
  const {
    errorMessage,
    showError,
    register,
    controlled,
    handleSubmit,
    setFormError,
    isDirty,
    isSubmitting,
  } = useCustomForm<Customer>({
    initialData: customer ?? emptyCustomer,
    validate: (data) => {
      if (data.website) {
        data.website = prependHttps(data.website);
      }

      if (data.companyEstablishedDate?.length === 0) {
        data.companyEstablishedDate = undefined;
      }

      if (data.corporateAddress?.state?.length === 0) {
        setFormError(['state', 'Select a state.']);
        return false;
      }

      return data;
    },
    handleSubmit: async (data) => {
      const customersPath = paths.customers.split(' ')[1];
      const path = customer
        ? `${customersPath}/${customer.id}/`
        : customersPath;

      try {
        const response = customer
          ? await fetcher.put(path, data)
          : await fetcher.post(path, data);

        refetchCustomers();
        Logger.info('Customer successfully created.', { data: response });
        navigate(-1);
      } catch (error) {
        if (error instanceof FetchError && error.status === 400) {
          const firstError = Object.entries(flattenErrors(error.data))[0];

          setFormError(
            firstError ?? [
              '',
              `There was an error during customer ${customer ? 'update' : 'creation'}.`,
            ],
          );
        } else {
          Logger.error('Error on creating/updating customer.', {
            error: (error as Error).message,
          });
        }
      }
    },
  });

  return (
    <FormModal
      name="customer"
      title={customer ? 'Update Customer' : 'Add new customer'}
      id="customer-modal"
      data-testid="customer-modal"
      onSubmit={() => handleSubmit}
      isDirty={isDirty}
      isSubmitting={isSubmitting}
      isUpdate={Boolean(customer)}
      floating
    >
      {showError && (
        <div className="_error-balloon">
          <CircleExclamation />
          <p>{capitalize(errorMessage)}</p>
          <button onClick={() => setFormError(['', ''])}>
            <Xmark color={Colors.danger['200']} size="md" />
          </button>
        </div>
      )}

      <Input
        label="Company name"
        placeholder="Company name"
        required
        {...register('companyName')}
      />

      <Divider />

      <div>
        <p className="_section-title mb-4">Corporate Address</p>
        {customer && (
          <Input
            label="Customer id"
            type="hidden"
            value={customer?.corporateAddress?.id}
            {...register('corporateAddress.id')}
            hideLabel
          />
        )}

        <div className="_section">
          <div className="_row">
            <Input
              label="Address line 1"
              placeholder="1234 Name St."
              required
              {...register('corporateAddress.addressLine1')}
            />
            <Input
              label="Address line 2"
              placeholder="Apartment, Suite"
              {...register('corporateAddress.addressLine2')}
            />
          </div>

          <div className="_row">
            <Input
              label="City"
              placeholder="City name"
              required
              {...register('corporateAddress.city')}
            />
            <Select
              label="State"
              required
              {...register('corporateAddress.state')}
            >
              <option value="" disabled>
                Select a state
              </option>
              {Object.entries(USAStates).map(([stateKey, stateName], index) => (
                <option
                  key={index}
                  value={stateKey}
                  className="!hover:bg-black"
                >
                  {stateName}
                </option>
              ))}
            </Select>
          </div>

          <div>
            <Input
              label="Zipcode"
              placeholder="92210"
              required
              {...register('corporateAddress.zipcode')}
            />
          </div>
        </div>
      </div>

      <Divider />

      <Input
        label="Website"
        placeholder="https://"
        {...register('website')}
        required
      />

      <Datepicker
        label="Company Established Date"
        {...register('companyEstablishedDate')}
        placeholder="mm/dd/yyyy"
      />

      {customer && (
        <>
          <Divider />

          <div className="_section">
            <p className="_section-title">Accounts Payable</p>
            {customer && (
              <Input
                label="Account Payable ID"
                type="hidden"
                value={customer?.accountPayable?.id}
                {...register('accountPayable.id')}
                hideLabel
              />
            )}

            <div className="_row">
              <Input
                label="Contact"
                placeholder="Contact name"
                {...register('accountPayable.contact')}
              />
              <Input
                label="Phone"
                placeholder="111-111-1111"
                {...register('accountPayable.phone')}
              />
            </div>

            <Input
              className="mb-4"
              label="Email"
              placeholder="user@companyname.com"
              {...register('accountPayable.email')}
            />

            <div className="_row">
              <DropDown
                title="Net 30, Net 60, Net 90, Net 120, Due on Receipt"
                label="Payment Terms"
                className="flex-1"
                {...controlled('accountPayable.paymentTerms')}
                options={makeDropDownOptions(PaymentTermsOptions)}
              />
              <DropDown
                title="EFT, Credit Card"
                label="Payment Method"
                className="flex-1"
                {...controlled('accountPayable.paymentMethod')}
                options={makeDropDownOptions(PaymentMethodOptions)}
              />
            </div>

            <Input
              label="Portal Address"
              placeholder="https://www"
              {...register('accountPayable.portalAddress')}
            />

            <div className="_row">
              <Input
                label="Username"
                placeholder="Login User"
                {...register('accountPayable.username')}
              />
              <Input
                label="Password"
                placeholder="Login password"
                {...register('accountPayable.password')}
              />
            </div>
          </div>
        </>
      )}
    </FormModal>
  );
}
