export const BillingMethod = {
  BILLED_THIRD_PARTY: 'Billed Third Party',
  INCLUDED: 'Included',
  BILLED_TO_CUSTOMER: 'Billed To Customer',
};

export const ShippingService = {
  UPS: 'UPS',
  FEDEX: 'FedEx',
  DHL: 'DHL',
  OTHER: 'Other',
};

export const ShippingMethod = {
  GROUND: 'Ground',
};
