import { logoDark } from '~/src/shared/assets/logo.ts';
import { avatar } from '~/src/shared/assets/avatar.ts';
import * as NavigationIcons from './icons';
import useNavigationBar from '~/src/shared/ui/components/navigation-bar/hooks/use-navigation-bar.ts';
import { RadioButton } from '~/src/shared/ui/components/radio-button/radio-button.tsx';

import './navigation-bar.css';
import React from 'react';
import { Page } from '~/src/state/flow.types.ts';
import { IconProps } from '~/src/shared/ui/icons/types.ts';
import { useLocation } from 'react-router-dom';

export function NavigationBar() {
  const inputProps = useNavigationBar();

  const iconMapping = {
    home: NavigationIcons.House,
    customers: NavigationIcons.Building,
    inventory: NavigationIcons.Boxes,
    vendors: NavigationIcons.TruckRampBox,
    'purchase-orders': NavigationIcons.CartShopping,
    quotes: NavigationIcons.FileInvoiceDollar,
    orders: NavigationIcons.CheckList,
  } as Record<Page, (props: IconProps) => React.JSX.Element>;

  return (
    <div>
      <div className="_navbar">
        <img src={logoDark.href} alt="Lucky You Logo" />

        <div>
          {Object.entries(iconMapping).map(([pageName, PageIcon], index) => (
            <RadioButton
              key={index}
              name={pageName}
              {...inputProps(pageName as Page)}
            >
              <div className="_navicon">
                <PageIcon />
              </div>
            </RadioButton>
          ))}
        </div>

        <img src={avatar.href} alt="Lucky You Logo" />
      </div>

      <div className="_placeholder" />
    </div>
  );
}

export function NavigationProvider() {
  const path = useLocation().pathname;
  return path !== '/login' && path !== '/login-callback' && <NavigationBar />;
}
