import * as React from 'react';
import {
  DynamicTableColumn,
  TableData,
  TableRow,
} from '~/src/shared/ui/components/table/types.ts';
import { orderBy } from 'lodash-es';
import { useToggle } from '~/src/shared/hooks/use-toggle.ts';

export default function useSortBy<T extends TableData<T>>(
  rows: TableRow<T>[],
  columns: DynamicTableColumn<T>[],
) {
  const [fieldToSort, setFieldToSort] = React.useState(columns[0].field);
  const [sortedRows, setSortedRows] = React.useState(rows);
  const [descending, toggleDescending, setToggleDescending] = useToggle(false);

  function onSetFieldToSort(field: keyof T) {
    if (field === fieldToSort) {
      toggleDescending();
    } else {
      setToggleDescending(false);
      setFieldToSort(field);
    }
  }

  React.useMemo(() => {
    const sorted = orderBy(
      rows,
      [fieldToSort],
      [`${descending ? 'desc' : 'asc'}`],
    );
    setSortedRows(sorted);
  }, [fieldToSort, descending, rows]);

  return {
    fieldToSort,
    onSetFieldToSort,
    descending,
    sortedRows,
  };
}
