import { useEffect, useState } from 'react';
import useUpdateEffect from '~/src/shared/hooks/use-update-effect.ts';
import { Breadcrumbs } from '~/src/shared/ui/components/breadcrumbs/breadcrumbs.tsx';
import { Menu, MenuOption } from '~/src/shared/ui/components/menu/menu.tsx';
import Pencil from '~/src/shared/ui/icons/pencil.tsx';
import Divider from '~/src/shared/ui/components/divider/divider.tsx';
import Trash from '~/src/shared/ui/icons/trash.tsx';
import { Colors } from '~/src/shared/ui/styles/const.ts';
import InfoPiece from '~/src/shared/ui/components/info-piece/info-piece.component.tsx';
import {
  QueryObserverResult,
  RefetchOptions,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import { mutation, query } from '~/src/shared/api/request-config.ts';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { paths } from '~/src/shared/api/api.schema.ts';
import Loading from '~/src/shared/ui/components/loading/loading.tsx';
import { Tab, Tabs } from '~/src/shared/ui/components/tabs/tabs.tsx';
import {
  MaterialTotalContainer,
  PricesContainer,
  QuoteContainer,
  QuoteTotalContainer,
} from '~/src/quotes/components/quote-container.component.tsx';
import Copy from '~/src/shared/ui/icons/copy.tsx';
import Lock from '~/src/shared/ui/icons/lock.tsx';
import CardsBlank from '~/src/shared/ui/icons/cards-blank.tsx';
import Envelope from '~/src/shared/ui/icons/envelope.tsx';
import ListRadio from '~/src/shared/ui/icons/list-radio.tsx';
import Print from '~/src/shared/ui/icons/print.tsx';
import { MarketingCategory, QuoteItemType } from '~/src/quotes/quotes.type.ts';
import { formatDate } from '~/src/shared/utils.ts';
import InventoryItemQuoteModal from '~/src/quotes/components/container-modal.component.tsx';
import useAlert from '~/src/shared/ui/components/alert/hooks/use-alert.tsx';
import { routes } from '~/src/routes.ts';
import {
  Quote,
  QuoteDescription,
  QuoteNote,
} from '~/src/quotes/quotes.type.ts';
import React from 'react';
import ShippingTab from '~/src/quotes/components/shipping-tab.component.tsx';
import DescriptionsTab from '~/src/quotes/components/descriptions.component.tsx';
import NotesTab from '~/src/quotes/components/notes-tab.component.tsx';
import FreightMarkupContainer from '~/src/quotes/components/freight-markup-container.component.tsx';
import SetUpFeesContainer from '~/src/quotes/components/set-up-fee-container.component.tsx';
import DiscountsContainer from '~/src/quotes/components/discount-container.component.tsx';

export interface QuoteControl {
  quote: Quote;
  updateQuote: (data: Partial<Quote>) => void;
  refetchQuote: (
    options?: RefetchOptions | undefined,
  ) => Promise<QueryObserverResult<Quote, Error>>;
}

export function QuoteBuilding() {
  const { id } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const alert = useAlert();
  const [currentType, setCurrentType] =
    React.useState<QuoteItemType>('container');

  type QuoteDescriptionField =
    | 'marketingDescription'
    | 'packCardDescription'
    | 'quickBooks';

  const [quote, setQuote] = useState<Quote | null>(null);

  const { data, refetch } = useQuery({
    ...query(paths.quote, { params: { id: id as string } }),
  });

  const { data: inventoryItems } = useQuery({
    ...query(paths.inventoryItems),
  });

  const quoteContainerItems = React.useCallback(
    (category: QuoteItemType) => {
      return quote?.quoteItems?.filter((i) => i.type === category);
    },
    [quote],
  );

  useEffect(() => {
    if (data) {
      setQuote(data);
    }
  }, [data]);

  const { mutate: partialUpdateMutate } = useMutation(
    mutation(paths.partialUpdateQuote, {
      params: { id: id as string },
    }),
  );

  const { mutate: createOrderMutate, status: createOrderStatus } = useMutation(
    mutation(paths.createOrder, {
      params: { id: id as string },
    }),
  );

  useUpdateEffect(() => {
    if (createOrderStatus === 'success') {
      alert.showSuccess('Order successfully created');
      // TODO Navigate to order detail
    } else if (createOrderStatus === 'error') {
      alert.showError('Error on creating order');
    }
  }, [createOrderStatus]);

  const createOrder = () => {
    if (quote?.status === 'open') {
      createOrderMutate();
    } else {
      alert.showError('Quote status must be open to create order');
    }
  };

  const handleChange = (data: Partial<Quote>) => {
    partialUpdateMutate(data, {
      onSuccess: (data: Quote) => {
        setQuote(data);
      },
    });
  };

  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const { value, dataset } = event.target;
    const quoteDescription = {
      section: dataset.section,
      [dataset.field as QuoteDescriptionField]: value,
    } as Partial<QuoteDescription>;

    const payload = {
      quoteDescriptions: [quoteDescription],
    } as Partial<Quote>;

    partialUpdateMutate(payload, {
      onSuccess: (data: Quote) => {
        setQuote(data);
      },
    });
  };

  const handleNoteChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value, dataset } = event.target;
    const quoteNote = {
      type: dataset.type,
      note: value,
    } as QuoteNote;

    const payload = {
      quoteNotes: [quoteNote],
    } as Partial<Quote>;

    partialUpdateMutate(payload, {
      onSuccess: (data: Quote) => {
        setQuote(data);
      },
    });
  };

  if (!quote) {
    return <Loading />;
  }

  function goToAddItem(type: QuoteItemType) {
    setCurrentType(type);

    navigate(routes.quoteAddInventoryItems.getPath(parseInt(id as string, 10)));
  }

  const quoteControl = {
    quote,
    updateQuote: handleChange,
    refetchQuote: refetch,
  } as QuoteControl;

  return (
    <div className="_page">
      <header>
        <div>
          <Breadcrumbs />
          <h1>{quote.name}</h1>
        </div>

        <div>
          <Menu>
            <MenuOption action={() => {}} icon={<Pencil />} label="Edit" />
            <MenuOption
              action={() => {}}
              icon={<Copy />}
              label="Update Prices With Most Recent Max Prices"
            />
            <MenuOption
              action={() => {}}
              icon={<Envelope />}
              label="Email Quote"
            />
            <MenuOption
              action={() => {}}
              icon={<Print />}
              label="Print Quote"
            />
            <MenuOption
              action={() => {
                createOrder();
              }}
              icon={<ListRadio />}
              label="Create Order"
            />
            <MenuOption
              action={() => {}}
              icon={<CardsBlank />}
              label="Print Pack Card"
            />
            <MenuOption action={() => {}} icon={<Lock />} label="Lock Quote" />
            <Divider />
            <MenuOption
              action={() => {}}
              icon={<Trash color={Colors.danger['500']} />}
              label="Delete"
              color="danger"
            />
          </Menu>
        </div>
      </header>

      <div className="flex gap-4">
        <InfoPiece label="Customer" content={quote.customer.companyName} />
        <InfoPiece label="Contact" content={quote.contact.fullName} />
        <InfoPiece
          label="Marketing Category"
          content={MarketingCategory[quote.marketingCategory]}
        />
        <InfoPiece label="Quote Number" content={quote.quoteNumber} />
      </div>

      <div className="flex gap-4">
        <InfoPiece label="Quantity" content={quote.quantity} />
        <InfoPiece label="Quote Date" content={formatDate(quote.quoteDate)} />
        <InfoPiece label="Quoted By" content={quote.quotedBy} />
        <div className="flex-1" />
      </div>

      <div className="_container">
        <Tabs>
          <Tab label="Quote Building">
            <QuoteContainer
              title="Containers"
              actionName="Add containers"
              action={() => goToAddItem('container')}
              items={quoteContainerItems('container') || []}
              quote={quote}
              refetchItems={refetch}
            />

            <QuoteContainer
              title="Content"
              actionName="Add Content"
              action={() => goToAddItem('content')}
              items={quoteContainerItems('content') || []}
              quote={quote}
              refetchItems={refetch}
            />

            <QuoteContainer
              title="Enhancement"
              actionName="Add Enhancement"
              action={() => goToAddItem('enhancement')}
              items={quoteContainerItems('enhancement') || []}
              quote={quote}
              refetchItems={refetch}
            />

            <QuoteContainer
              title="Packaging"
              actionName="Add Packaging"
              action={() => goToAddItem('packaging')}
              items={quoteContainerItems('packaging') || []}
              quote={quote}
              refetchItems={refetch}
            />

            <Divider />

            <FreightMarkupContainer {...quoteControl} />
            <SetUpFeesContainer {...quoteControl} />
            <MaterialTotalContainer {...quoteControl} />
            <DiscountsContainer {...quoteControl} />
            <PricesContainer {...quoteControl} />

            <QuoteTotalContainer {...quoteControl} />
          </Tab>

          <Tab label="Shipping">
            <ShippingTab {...quoteControl} />
          </Tab>

          <Tab label="Descriptions">
            <DescriptionsTab
              quote={quote}
              items={inventoryItems ?? []}
              handleQuoteChange={handleChange}
              handleDescriptionChange={handleDescriptionChange}
            />
          </Tab>

          <Tab label="Notes">
            <NotesTab quote={quote} handleNoteChange={handleNoteChange} />
          </Tab>
        </Tabs>
      </div>

      {id && pathname.includes('add-items') && (
        <InventoryItemQuoteModal
          quoteId={id}
          itemType={currentType}
          refetch={refetch}
        />
      )}
    </div>
  );
}
