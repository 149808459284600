import * as React from 'react';
import { ConfirmationModal } from '~/src/shared/ui/components/modal/modal.tsx';
import ReactDOM from 'react-dom';

export interface ConfirmationModalProps {
  title: string;
  bodyText?: string;
  actionName: string;
  onAction: () => void;
  isDelete?: boolean;
}

export default function useConfirmation() {
  const [showConfirmation, setShowConfirmation] = React.useState(false);
  const [currentProps, setCurrentProps] =
    React.useState<ConfirmationModalProps>();

  function showModal(modalProps: ConfirmationModalProps) {
    setCurrentProps(modalProps);
    setShowConfirmation(true);
  }

  function hideModal() {
    setCurrentProps(undefined);
    setShowConfirmation(false);
  }

  const confirmationModal = React.useCallback(() => {
    if (!currentProps) return <></>;

    const { isDelete, bodyText, ...rest } = currentProps;

    return (
      showConfirmation &&
      ReactDOM.createPortal(
        <ConfirmationModal
          destructive={isDelete}
          onCancel={() => setShowConfirmation(false)}
          close={() => setShowConfirmation(false)}
          {...rest}
        >
          {bodyText}
        </ConfirmationModal>,
        document.body,
      )
    );
  }, [currentProps, showConfirmation]);

  return {
    confirmationModal,
    showConfirmation: showModal,
    hideModal,
  };
}
