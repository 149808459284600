import Image from '~/src/shared/ui/icons/image.tsx';
import DragAndDrop from '~/src/shared/ui/components/dragndrop/dragndrop.tsx';

export default function GraphicsTab() {
  return (
    <div>
      <p className="text-sm text-gray-900 mb-2">Graphics</p>

      <DragAndDrop
        maxFileSizeMB={10}
        acceptedFormats={['jpg', 'pdf', 'ai', 'eps']}
        fileName="Graphic"
        icon={<Image />}
      />
    </div>
  );
}
