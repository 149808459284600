import { Vendor } from '~/src/vendors/vendors.type.ts';
import { Contact } from '~/src/contacts/contacts.type.ts';
import { InventoryItemVendor } from '~/src/inventory/inventory.type.ts';
import { PurchaseOrder } from '~/src/purchase-orders/purchase-orders.type.ts';

export interface VendorsAPI {
  'GET /vendors/': {
    response: Required<Vendor>[];
  };
  'POST /vendors/': {
    body: Required<Vendor>;
    response: Required<Vendor>;
  };
  'POST /vendors/:id': {
    params: { id: string };
    body: Required<Vendor>;
    response: Required<Vendor>[];
  };
  'DELETE /vendors/:id': {
    params: { id: string };
    response: null;
  };
  'GET /vendors/:id': {
    params: { id: string };
    response: Required<Vendor>;
  };
  'GET /vendors/:id/contacts/': {
    params: { id: string };
    response: Required<Contact>[];
  };
  'POST /vendors/:id/contacts/': {
    params: { id: string };
    body: Required<Contact>;
    response: Required<Contact>;
  };
  'GET /vendors/:parentId/contacts/:contactId/': {
    params: { parentId: string; contactId: string };
    response: Required<Contact>;
  };
  'PUT /vendors/:parentId/contacts/:contactId/': {
    params: { id: string };
    body: Required<Contact>;
    response: Required<Contact>;
  };
  'DELETE /vendors/:parentId/contacts/:contactId/': {
    params: { parentId: string; contactId: string };
    response: null;
  };
  'POST /vendors/:id/contacts/active-multiple/': {
    params: { id: string };
    body: Required<Record<'id', number>[]>;
    response: Required<number[]>;
  };
  'POST /vendors/:id/contacts/inactive-multiple/': {
    params: { id: string };
    body: Required<Record<'id', number>[]>;
    response: Required<number[]>;
  };
  'POST /vendors/:id/contacts/delete-multiple/': {
    params: { id: string };
    body: Required<Record<'id', number>[]>;
    response: Required<number[]>;
  };
  'GET /vendors/:id/items/': {
    params: { id: string };
    response: Required<InventoryItemVendor>[];
  };
  'GET /vendors/:id/purchase-orders/': {
    params: { id: string };
    response: Required<PurchaseOrder>[];
  };
}

export const vendorsPaths = {
  vendor: 'GET /vendors/:id',
  deleteVendor: 'DELETE /vendors/:id',
  createVendor: 'POST /vendors/',
  vendors: 'GET /vendors/',
  vendorContacts: 'GET /vendors/:id/contacts/',
  vendorItems: 'GET /vendors/:id/items/',
  vendorPurchaseOrders: 'GET /vendors/:id/purchase-orders/',
  createVendorContact: 'POST /vendors/:id/contacts/',
  vendorContact: 'GET /vendors/:parentId/contacts/:contactId/',
  editVendorContact: 'PUT /vendors/:parentId/contacts/:contactId/',
  deleteVendorContact: 'DELETE /vendors/:parentId/contacts/:contactId/',
  vendorContactActiveMultiple: 'POST /vendors/:id/contacts/active-multiple/',
  vendorContactInactiveMultiple:
    'POST /vendors/:id/contacts/inactive-multiple/',
  vendorContactDeleteMultiple: 'POST /vendors/:id/contacts/delete-multiple/',
} satisfies Record<string, keyof VendorsAPI>;
