import { LoggerBackend } from '~/src/shared/service/logger/logger';

export class ConsoleLogger extends LoggerBackend {
  constructor() {
    super(({ level, message, timestamp, data }) => {
      console[level](`[${+timestamp}] ${message}`);
      if (data !== undefined) {
        console[level](data);
      }
    });
  }
}
