import type { API } from '~/src/shared/api/api.schema';
import { fetcher } from '~/src/shared/service/fetch/fetcher';

function parameterize(path: string, params?: object, query?: object) {
  // eslint-disable-next-line prefer-const
  let [method, parameterized] = path.split(' ');

  if (params) {
    parameterized = parameterized.replace(
      /:(\w+)/gu,
      (_, key) => params[key as keyof typeof params] ?? '',
    );
  }

  parameterized = parameterized.replace(/\/\//gu, '/');

  if (query) {
    const search = new URLSearchParams(parameterized.split('?')[1]);
    for (const key of Object.keys(query).sort()) {
      const value = query[key as keyof typeof query];
      if (value != null) {
        search.set(key, value);
      }
    }

    const queryString = search.toString();
    if (queryString) {
      parameterized = `${parameterized}?${queryString}`;
    }
  }

  return [method.toLowerCase(), parameterized] as [
    Exclude<keyof typeof fetcher, 'on'>,
    string,
  ];
}

export type RequestConfig<Path extends keyof API> = API[Path] extends {
  params: unknown;
  query: unknown;
}
  ? [
      config: {
        params: API[Path]['params'];
        query: API[Path]['query'];
      },
    ]
  : API[Path] extends { params: unknown }
    ? [
        config: {
          params: API[Path]['params'];
        },
      ]
    : API[Path] extends { query: unknown }
      ? [
          config: {
            query: API[Path]['query'];
          },
        ]
      : API[Path] extends { headers: unknown }
        ? [
            config: {
              headers: API[Path]['headers'];
            },
          ]
        : [];

export function request<Path extends keyof API>(
  path: Path,
  ...config: RequestConfig<Path>
) {
  type Config = API[Path];

  const [method, parameterized] = parameterize(
    path,
    // @ts-expect-error: parameterize handles this
    config[0]?.params,
    // @ts-expect-error: parameterize handles this
    config[0]?.query,
  );

  async function request(
    ...body: API[Path] extends { body: unknown }
      ? [body: API[Path]['body']]
      : []
  ) {
    return (await fetcher[method](
      parameterized,
      method === 'get' ? undefined : body?.[0],
      // @ts-expect-error: fallback to empty obj
      { headers: config[0]?.headers } ?? {},
    )) as Config['response'];
  }

  return {
    path: parameterized,
    fn: request,
    method: method.toUpperCase(),
  };
}

export function query<Path extends keyof API>(
  path: Path,
  ...config: RequestConfig<Path>
) {
  const requestConfig = request(path, ...config);
  return {
    queryKey: [requestConfig.path],
    queryFn: requestConfig.fn,
  };
}

export function mutation<Path extends keyof API>(
  path: Path,
  ...config: RequestConfig<Path>
) {
  const requestConfig = request(path, ...config);
  return {
    mutationKey: [requestConfig.path],
    mutationFn: requestConfig.fn,
  };
}
