import { DropDown, Input } from '~/src/shared/ui/components';
import { FormModal } from '~/src/shared/ui/components/modal/modal.tsx';
import { FormValues, useCustomForm } from '~/src/shared/hooks/use-custom-form';
import '~/src/shared/ui/components/modal/form-modal.css';
import { useEffect, useState } from 'react';
import {
  InventoryItem,
  InventoryItemVendor,
} from '~/src/inventory/inventory.type';
import { Logger } from '~/src/shared/service/logger';
import { useNavigate, useParams } from 'react-router-dom';
import { formatCurrency, numberTransformer } from '~/src/shared/utils';
import { PurchaseOrderLineRow } from '../purchase-orders.type';
import useAlert from '~/src/shared/ui/components/alert/hooks/use-alert';
import { routes } from '~/src/routes';

export function AddItemToPurchaseOrderModal({
  items,
  vendorId,
  onSubmit,
  existingLines,
}: {
  items: InventoryItem[];
  vendorId: string | number;
  onSubmit: (line: Omit<PurchaseOrderLineRow, 'id'>) => void;
  existingLines: Required<PurchaseOrderLineRow>[];
}) {
  const navigate = useNavigate();
  const alert = useAlert();
  const { itemId } = useParams();

  const [selectedItem, setSelectedItem] = useState<InventoryItem>();
  const [selectedItemInventoryVendor, setSelectedItemInventoryVendor] =
    useState<InventoryItemVendor>();

  // We only need items from this vendor and items not yet included in existing p.o. lines
  const vendorItems = items.filter((i) =>
    i.inventoryItemVendors.some(
      (iv) =>
        iv.vendor.id == vendorId &&
        !existingLines
          .map((line) => line.vendorInventoryItemId)
          .includes(iv.id),
    ),
  );
  const itemOptions = vendorItems.map((i) => ({
    title: i.name,
    value: i.id.toString(),
  }));

  useEffect(() => {
    const itemVendor = selectedItem?.inventoryItemVendors.find(
      (iv) => iv.vendor.id.toString() === vendorId.toString(),
    );
    setSelectedItemInventoryVendor(itemVendor);
  }, [selectedItem, vendorId]);

  const isValidState = () => {
    if (!selectedItem || !selectedItemInventoryVendor) {
      alert.show({
        color: 'danger',
        text: 'Something went wrong. Please try again.',
        title: 'Error adding line item.',
      });
      Logger.error('User was able to submit form in invalid state.');
      return false;
    }
    if (!selectedItemInventoryVendor.unitPrice) {
      setFormError(['inventoryItem', 'This item does not have a valid price.']);
      return false;
    }
    return true;
  };

  const getLine = (data: FormValues) => ({
    vendorInventoryItemId: selectedItemInventoryVendor!.id,
    itemName: selectedItem!.name,
    sku: selectedItemInventoryVendor!.sku,
    sellUnitPrice: selectedItemInventoryVendor!.unitPrice!,
    qty: data.quantity,
    numberOfPallets:
      selectedItemInventoryVendor!.quantityPerPallet &&
      Math.ceil(data.quantity / selectedItemInventoryVendor!.quantityPerPallet),
    lineTotal: selectedItemInventoryVendor!.skuCost * data.quantity,
    skuCost: selectedItemInventoryVendor!.skuCost,
  });

  const formValidate = (data: FormValues) => {
    if (!data.quantity) {
      setFormError(['quantity', 'Quantity is required.']);
      return false;
    }
    if (!data.inventoryItem) {
      setFormError(['inventoryItem', 'Select an inventory item.']);
      return false;
    }
    return data;
  };

  const {
    handleSubmit,
    isSubmitting,
    register,
    setValue,
    setFormError,
    reset,
    getValues,
  } = useCustomForm({
    initialData: {},
    validate: formValidate,
    handleSubmit: async (data) => {
      if (!isValidState()) return;
      const line: Omit<PurchaseOrderLineRow, 'id'> = getLine(data);
      onSubmit(line);
      navigate(routes.createPurchaseOrderPage.getPath(vendorId as number));
    },
  });

  const urlItem = items.find((i) => i.id.toString() == itemId);
  useEffect(() => {
    if (urlItem) {
      setSelectedItem(urlItem);
      setValue('inventoryItem', urlItem.id);
    }
  }, [urlItem, setValue]);

  const saveAndAddNew = async () => {
    const data = getValues();
    if (!(formValidate(data) && isValidState())) return;
    const line: Omit<PurchaseOrderLineRow, 'id'> = getLine(data);
    onSubmit(line);
    reset();
    setValue('inventoryItem', null);
    setSelectedItem(undefined);
    setSelectedItemInventoryVendor(undefined);
  };

  const formModalProps = {
    name: 'add-item-to-purchase-order',
    title: 'Add New Item to Purchase Order',
    id: 'add-item-to-purchase-order-modal',
    className: '!w-[640px] !h-[348px] !gap-1',
    'data-testid': 'add-item-to-purchase-order-modal',
    onSubmit: () => handleSubmit,
    isSubmitting: isSubmitting,
    actionName: 'Add to PO',
    floating: true,
    onSecondaryActionClick: saveAndAddNew,
    secondaryActionName: 'Save and Add New Item',
  };

  return (
    <FormModal {...formModalProps}>
      <div className="_section">
        <div className="_row">
          <DropDown
            label="Inventory Item"
            title={
              itemOptions.length > 0
                ? 'Inventory Item'
                : 'No other items available.'
            }
            className="flex-1 "
            required
            options={itemOptions}
            {...register('inventoryItem')}
            onChange={(itemId) => {
              setSelectedItem(items.find((i) => i.id.toString() == itemId));
              setValue('inventoryItem', numberTransformer(itemId));
            }}
            value={selectedItem?.id.toString() || null}
            searchable
          />
        </div>
        <div className="_row">
          <Input
            label="Sell Unit Price"
            type="text"
            disabled
            placeholder="$"
            value={
              selectedItemInventoryVendor?.unitPrice
                ? formatCurrency(selectedItemInventoryVendor?.unitPrice)
                : '-'
            }
          />
          <Input
            label="SKU Cost"
            type="text"
            disabled
            placeholder="$"
            value={
              selectedItemInventoryVendor?.skuCost
                ? formatCurrency(selectedItemInventoryVendor?.skuCost)
                : '-'
            }
          />
          <Input
            label="SKU Quantity to Order"
            type="number"
            placeholder="Qty"
            required
            step="1"
            {...register(`quantity`, {
              setValueAs: numberTransformer,
            })}
            helpText={`Pallet quantity: ${selectedItemInventoryVendor?.quantityPerPallet || '-'}`}
          />
        </div>
      </div>
    </FormModal>
  );
}
