export const InventoryCategoryOptions = {
  YLD_FOOD_ITEMS_PACKED_IN_HOUSE: 'YLD Food Items Packed In-house',
  CANDY_ASSORTED_CHOCOLATE_MIX: 'Candy - Assorted Chocolate Mix',
  CANDY_CHOCOLATE: 'Candy - Chocolate',
  CANDY_CHOCOLATE_NOVELTY: 'Candy - Chocolate Novelty',
  CANDY_ASSORTED_HARD_CANDY_MIX: 'Candy - Assorted Hard Candy Mix',
  CANDY_FUN: 'Candy - Fun',
  CANDY_HARD: 'Candy - Hard',
  CANDY_THEMED_HOLIDAY: 'Candy Themed/Holiday',
  CUSTOM_KITCHEN_PRODUCT: 'Custom Kitchen Product',
  SPECIALTY_GOURMET_PRODUCTS: 'Specialty Gourmet Products',
  BAKED_GOODS: 'Baked Goods',
  BEVERAGES: 'BEVERAGES',
  MERCHANDISE: 'MERCHANDISE',
  ENHANCEMENTS_STANDARD: 'Enhancements - Standard',
  ENHANCEMENTS_SPECIALIZED: 'Enhancements - Specialized',
  CONTAINER_DESK_DROPS: 'Container - Desk Drops',
  CONTAINER: 'CONTAINER',
  PACKAGING: 'PACKAGING',
  FOODS: 'FOODS',
  FOODS_POPCORN: 'Foods - Popcorn',
  FOODS_NUTS_TRAIL_MIX: 'Foods - Nuts/Trail Mix',
  FOODS_CRACKERS: 'Foods - Crackers',
  FOODS_GRANOLA_BARS: 'Foods - Granola Bars',
  FOODS_HEALTHY_LO_CAL: 'Foods - Healthy/Lo-cal',
  FOODS_FRUIT: 'Foods - Fruit',
  FOODS_POTATO_CHIPS: 'Foods - Potato Chips',
  FOODS_PRETZELS: 'Foods - Pretzels',
  FOODS_SNACK_MIX: 'Foods - Snack Mix',
  FOODS_COOKIES: 'Foods - Cookies',
  LY_INGREDIENTS: 'LY - Ingredients',
  TOWER_BOXES: 'Tower Boxes',
  SUPPLY_ITEMS: 'Supply Items',
  MODIFIERS: 'Modifiers',
  PACKAGING_BOXES: 'Packaging boxes',
  DOUBLE_DUTY_BOXES: 'Double Duty Boxes',
};

export type InventoryCategory = keyof typeof InventoryCategoryOptions;

export const UnitOfMeasureOptions = {
  EACH: 'Each',
  LBS: 'Lbs',
  OUNCE: 'Ounce',
  GALLON: 'Gallon',
  INCH: 'Inch',
  PIECE: 'Piece',
  YARD: 'Yard',
  FOOT: 'Foot',
  MSI: 'Msi',
  CASE: 'Case',
  CUP: 'Cup',
  TBSP: 'TBSP',
  TSP: 'Tsp',
};

export type UnitOfMeasure = keyof typeof UnitOfMeasureOptions;
