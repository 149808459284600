import { PurchaseOrder } from '~/src/purchase-orders/purchase-orders.type';

export interface PurchaseOrdersAPI {
  'GET /purchase-orders/:id/': {
    params: { id: string };
    response: Required<PurchaseOrder>;
  };
  'GET /purchase-orders/': {
    response: Required<PurchaseOrder>[];
  };
  'POST /purchase-orders/': {
    body: Required<PurchaseOrder>;
    response: Required<PurchaseOrder>[];
  };
}

export const purchaseOrdersPaths = {
  purchaseOrders: 'GET /purchase-orders/',
  purchaseOrderDetail: 'GET /purchase-orders/:id/',
  createPurchaseOrders: 'POST /purchase-orders/',
} satisfies Record<string, keyof PurchaseOrdersAPI>;
