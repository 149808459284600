import Divider from '~/src/shared/ui/components/divider/divider.tsx';
import { Input, TextArea } from '~/src/shared/ui/components';
import {
  Quote,
  QuoteDescription,
  QuoteDescriptionSection,
  QuoteItem,
} from '~/src/quotes/quotes.type.ts';
import React from 'react';
import { InventoryItem } from '~/src/inventory/inventory.type.ts';
import { groupBy } from 'lodash-es';

interface DescriptionsTabProps {
  quote: Quote;
  items: InventoryItem[];
  handleQuoteChange: (data: Partial<Quote>) => void;
  handleDescriptionChange: (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => void;
}

type Section = (typeof QuoteDescriptionSection)[number];
type DescriptionField = keyof Omit<QuoteDescription, 'id' | 'section'>;

export default function DescriptionsTab(props: DescriptionsTabProps) {
  const { quote, items, handleQuoteChange, handleDescriptionChange } = props;

  const itemDescriptionMapping = React.useMemo(() => {
    return {
      packCardDescription: 'packListDescription',
      marketingDescription: 'description',
      quickBooks: 'name',
    } as Record<
      DescriptionField,
      'packListDescription' | 'description' | 'name'
    >;
  }, []);

  function description(section: Section, field: DescriptionField) {
    return quote?.quoteDescriptions?.find((d) => d.section === section)?.[
      field
    ];
  }

  const itemsBySection = React.useMemo(() => {
    const quoteItemBySection = groupBy(quote?.quoteItems, 'type') as Record<
      Section,
      QuoteItem[]
    >;
    const result: Record<Section, number[]> = {} as Record<Section, number[]>;
    for (const [section, item] of Object.entries(quoteItemBySection)) {
      result[section as Section] = item.map(
        (i) => i.inventoryItemVendor.inventory,
      );
    }

    return result;
  }, [quote]);

  const inventoryItemsDescriptions = React.useCallback(
    (section: Section, field: DescriptionField) => {
      const itemField = itemDescriptionMapping[field];
      return items
        .filter((i) => itemsBySection[section]?.includes(i.id))
        .map((i) => i[itemField])
        .join('\n');
    },
    [items, itemsBySection, itemDescriptionMapping],
  );

  return (
    <div>
      <div className="_table-body _container-grid">
        <div className="_container-grid-header">Section</div>
        <div className="_container-grid-header">Marketing Description</div>
        <div className="_container-grid-header">Pack Card Description</div>
        <div className="_container-grid-header">QuickBooks</div>
        <Divider className="_container-grid-divider" />
        <div className="_container-grid-section">Containers</div>
        <div className="flex flex-col gap-3">
          <TextArea
            label="Containers Items Marketing Description"
            value={inventoryItemsDescriptions(
              'container',
              'marketingDescription',
            )}
            disabled
            hideLabel
          />
          <TextArea
            hideLabel
            label="Containers Marketing Description"
            placeholder="Type your description here..."
            data-section="container"
            data-field="marketingDescription"
            defaultValue={description('container', 'marketingDescription')}
            onBlur={handleDescriptionChange}
          />
        </div>
        <div className="flex flex-col gap-3">
          <TextArea
            label="Containers Items Pack Card Description"
            value={inventoryItemsDescriptions(
              'container',
              'packCardDescription',
            )}
            disabled
            hideLabel
          />
          <TextArea
            hideLabel
            label="Containers Pack Card Description"
            placeholder="Type your description here..."
            data-section="container"
            data-field="packCardDescription"
            defaultValue={description('container', 'packCardDescription')}
            onBlur={handleDescriptionChange}
          />
        </div>
        <div className="flex flex-col gap-3">
          <TextArea
            label="Containers Items QuickBooks"
            value={inventoryItemsDescriptions('container', 'quickBooks')}
            disabled
            hideLabel
          />
          <TextArea
            hideLabel
            label="Containers QuickBooks"
            placeholder="Type your description here..."
            data-section="container"
            data-field="quickBooks"
            defaultValue={description('container', 'quickBooks')}
            onBlur={handleDescriptionChange}
          />
        </div>

        <Divider className="_container-grid-divider" />
        <div className="_container-grid-section">Content</div>
        <div className="flex flex-col gap-3">
          <TextArea
            label="Content Items Marketing Description"
            value={inventoryItemsDescriptions(
              'content',
              'marketingDescription',
            )}
            disabled
            hideLabel
          />
          <TextArea
            hideLabel
            label="Content Marketing Description"
            placeholder="Type your description here..."
            data-section="content"
            data-field="marketingDescription"
            defaultValue={description('content', 'marketingDescription')}
            onBlur={handleDescriptionChange}
          />
        </div>
        <div className="flex flex-col gap-3">
          <TextArea
            label="Content Items Pack Card Description"
            value={inventoryItemsDescriptions('content', 'packCardDescription')}
            disabled
            hideLabel
          />
          <TextArea
            hideLabel
            label="Content Pack Card Description"
            placeholder="Type your description here..."
            data-section="content"
            data-field="packCardDescription"
            defaultValue={description('content', 'packCardDescription')}
            onBlur={handleDescriptionChange}
          />
        </div>
        <div className="flex flex-col gap-3">
          <TextArea
            label="Content Items QuickBooks"
            value={inventoryItemsDescriptions('content', 'quickBooks')}
            disabled
            hideLabel
          />
          <TextArea
            hideLabel={true}
            label="Content QuickBooks"
            placeholder="Type your description here..."
            data-section="content"
            data-field="quickBooks"
            defaultValue={description('content', 'quickBooks')}
            onBlur={handleDescriptionChange}
          />
        </div>
        <Divider className="_container-grid-divider" />
        <div className="_container-grid-section">Enhancement</div>
        <div className="flex flex-col gap-3">
          <TextArea
            label="Enhancement Items Marketing Description"
            value={inventoryItemsDescriptions(
              'enhancement',
              'marketingDescription',
            )}
            disabled
            hideLabel
          />
          <TextArea
            hideLabel={true}
            label="Enhancement Marketing Description"
            placeholder="Type your description here..."
            data-section="enhancement"
            data-field="marketingDescription"
            defaultValue={description('enhancement', 'marketingDescription')}
            onBlur={handleDescriptionChange}
          />
        </div>
        <div className="flex flex-col gap-3">
          <TextArea
            label="Enhancement Items Pack Card Description"
            value={inventoryItemsDescriptions(
              'enhancement',
              'packCardDescription',
            )}
            disabled
            hideLabel
          />
          <TextArea
            hideLabel={true}
            label="Enhancement Pack Card Description"
            placeholder="Type your description here..."
            data-section="enhancement"
            data-field="packCardDescription"
            defaultValue={description('enhancement', 'packCardDescription')}
            onBlur={handleDescriptionChange}
          />
        </div>

        <div className="flex flex-col gap-3">
          <TextArea
            label="Enhancement Items QuickBooks"
            value={inventoryItemsDescriptions('enhancement', 'quickBooks')}
            disabled
            hideLabel
          />
          <TextArea
            hideLabel={true}
            label="Enhancement QuickBooks"
            placeholder="Type your description here..."
            data-section="enhancement"
            data-field="quickBooks"
            defaultValue={description('enhancement', 'quickBooks')}
            onBlur={handleDescriptionChange}
          />
        </div>
        <Divider className="_container-grid-divider" />
        <div className="_container-grid-section">Packaging</div>
        <div className="flex flex-col gap-3">
          <TextArea
            label="Packaging Items Marketing Description"
            value={inventoryItemsDescriptions(
              'packaging',
              'marketingDescription',
            )}
            disabled
            hideLabel
          />
          <TextArea
            hideLabel={true}
            label="Packaging Marketing Description"
            placeholder="Type your description here..."
            data-section="packaging"
            data-field="marketingDescription"
            defaultValue={description('packaging', 'marketingDescription')}
            onBlur={handleDescriptionChange}
          />
        </div>
        <div className="flex flex-col gap-3">
          <TextArea
            label="Packaging Items Marketing Description"
            value={inventoryItemsDescriptions(
              'packaging',
              'packCardDescription',
            )}
            disabled
            hideLabel
          />
          <TextArea
            hideLabel={true}
            label="Packaging Marketing Description"
            placeholder="Type your description here..."
            data-section="packaging"
            data-field="packCardDescription"
            defaultValue={description('packaging', 'packCardDescription')}
            onBlur={handleDescriptionChange}
          />
        </div>
        <div className="flex flex-col gap-3">
          <TextArea
            label="Packaging Items QuickBooks"
            value={inventoryItemsDescriptions('packaging', 'quickBooks')}
            disabled
            hideLabel
          />
          <TextArea
            hideLabel={true}
            label="Packaging QuickBooks"
            placeholder="Type your description here..."
            data-section="packaging"
            data-field="quickBooks"
            defaultValue={description('packaging', 'quickBooks')}
            onBlur={handleDescriptionChange}
          />
        </div>
      </div>
      <div className="_table-body mt-4">
        <div className="_row">
          <Input
            label="Piece count"
            placeholder="0"
            required
            defaultValue={quote?.pieceCount}
            onBlur={(e) =>
              handleQuoteChange({ pieceCount: Number(e.target.value) })
            }
          />
          <Input
            label="Pack Card Price"
            placeholder="$"
            required
            helpText="Pre Discount Price"
            defaultValue={quote?.packCardPrice}
            onBlur={(e) =>
              handleQuoteChange({ packCardPrice: Number(e.target.value) })
            }
          />
          <Input
            label="Pack Card Discount"
            placeholder="$"
            required
            helpText="Sum of percentages: Discounted Gift Price"
            defaultValue={quote?.packCardDiscount}
            onBlur={(e) =>
              handleQuoteChange({ packCardDiscount: Number(e.target.value) })
            }
          />
        </div>
      </div>
    </div>
  );
}
