import { Order, OrderComponent, OrderComponentWrite } from './orders.type.ts';

export interface OrdersAPI {
  'GET /orders/': {
    response: Required<Order>[];
  };
  'GET /orders/:id/': {
    params: { id: number };
    response: Required<Order>;
  };
  'PATCH /orders/:id/': {
    params: { id: number };
    body: Partial<Order>;
    response: Required<Order>;
  };
  'GET /orders/:id/components/': {
    params: { id: number };
    response: Required<OrderComponent>[];
  };
  'PATCH /orders/:orderId/components/:componentId/': {
    params: { orderId: number; componentId: number };
    body: Partial<OrderComponent>;
    response: Required<OrderComponent>;
  };
  'POST /orders/:id/components/': {
    params: { id: number };
    body: OrderComponentWrite;
    response: Required<OrderComponentWrite>;
  };
  'DELETE /orders/:orderId/components/:componentId/': {
    params: { orderId: number; componentId: number };
    response: null;
  };
}

export const ordersPaths = {
  orders: 'GET /orders/',
  orderComponents: 'GET /orders/:id/components/',
  addOrderComponent: 'POST /orders/:id/components/',
  deleteOrderComponent: 'DELETE /orders/:orderId/components/:componentId/',
  partialUpdateOrderComponent:
    'PATCH /orders/:orderId/components/:componentId/',
  order: 'GET /orders/:id/',
  partialUpdateOrder: 'PATCH /orders/:id/',
} satisfies Record<string, keyof OrdersAPI>;
