import { IconProps } from '~/src/shared/ui/icons/types.ts';

export default function Calendar(props: IconProps) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.75 2.75V4H12.25V2.75C12.25 2.34375 12.5625 2 13 2C13.4062 2 13.75 2.34375 13.75 2.75V4H15C16.0938 4 17 4.90625 17 6V6.5V8V16C17 17.125 16.0938 18 15 18H5C3.875 18 3 17.125 3 16V8V6.5V6C3 4.90625 3.875 4 5 4H6.25V2.75C6.25 2.34375 6.5625 2 7 2C7.40625 2 7.75 2.34375 7.75 2.75ZM4.5 8V16C4.5 16.2812 4.71875 16.5 5 16.5H15C15.25 16.5 15.5 16.2812 15.5 16V8H4.5Z"
        fill={props.color ?? 'black'}
      />
    </svg>
  );
}
