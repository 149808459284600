export const USAStates = {
  AL: 'Alabama',
  AK: 'Alaska',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
  DC: 'District of Columbia',
  AS: 'American Samoa',
  GU: 'Guam',
  MP: 'Northern Mariana Islands',
  PR: 'Puerto Rico',
  VI: 'Virgin Islands, U.S.',
};

export const PaymentTermsOptions = {
  NET_30: 'Net 30',
  NET_60: 'Net 60',
  NET_90: 'Net 90',
  NET_120: 'Net 120',
  DUE_ON_RECEIPT: 'Due On Receipt',
};

export const PhoneTypeOptions = {
  personal_mobile: 'Personal Mobile',
  work_mobile: 'Work Mobile',
  office: 'Office',
  home: 'Home',
};

export const AddressTypeOptions = {
  residential: 'Residential',
  corporate: 'Corporate',
};

export type PaymentTerms = keyof typeof PaymentTermsOptions;

export const PaymentMethodOptions = {
  eft: 'EFT',
  credit_card: 'Credit Card',
};

export type PaymentMethod = keyof typeof PaymentMethodOptions;

export const WeekdayOptions = {
  MONDAY: 'Monday',
  TUESDAY: 'Tuesday',
  WEDNESDAY: 'Wednesday',
  THURSDAY: 'Thursday',
  FRIDAY: 'Friday',
  SATURDAY: 'Saturday',
  SUNDAY: 'Sunday',
};

export type Weekdays = keyof typeof WeekdayOptions;
