import { useMutation } from '@tanstack/react-query';
import React, { FormEvent, useState } from 'react';
import { routes } from '~/src/routes';
import { paths } from '~/src/shared/api/api.schema';
import { mutation } from '~/src/shared/api/request-config';

import { logoDark } from '~/src/shared/assets/logo';
import { Button, Input } from '~/src/shared/ui/components';

export function LoginPage() {
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [infoMessage, setInfoMessage] = useState('');

  const { mutate, isPending } = useMutation(mutation(paths.triggerLoginEmail));

  const triggerLoginEmail = (e: FormEvent) => {
    e.preventDefault();

    setInfoMessage('');
    setErrorMessage('');

    mutate(
      {
        email_address: email,
        return_url: `${window.location.origin}${routes.loginCallback.path}`,
      },
      {
        onSuccess: (data: { sent: boolean }) => {
          if (!data.sent) {
            setErrorMessage(
              'Login email could not be sent. Please confirm your email address or contact your administrator.',
            );
            return;
          }
          setErrorMessage('');
          setInfoMessage('Login email was sent. Check your inbox.');
        },
        onError: () => {
          setInfoMessage('');
          setErrorMessage(
            'Could not send login email. Please try again later.',
          );
          console.error('Unable to send login email');
        },
      } as object,
    );
  };

  return (
    <div className="w-full flex min-h-screen items-center justify-center">
      <title>Login | Lucky You Gifts ERP</title>
      <form onSubmit={triggerLoginEmail} className="p-4">
        <div className="min-w-max flex flex-col items-center justify-center">
          <img
            src={logoDark.href}
            className="mb-8 block h-28 w-28 bg-contain bg-center bg-no-repeat"
            alt="Lucky You Logo"
          />
          {/* Conditionally rendered message boxes */}
          <DisplayMessage
            message={errorMessage || infoMessage || null}
            isError={Boolean(errorMessage)}
          />

          <div className="w-full mb-6">
            <Input
              type="email"
              hideLabel={true}
              label="Work Email"
              placeholder="Type your work email address."
              value={email}
              style={{ width: '360px', maxWidth: '90vw' }}
              onChange={(e) => {
                setEmail(e.target.value);
                setErrorMessage('');
                setInfoMessage('');
              }}
            />
          </div>
          <Button className="mt-2 w-20" type="submit" loading={isPending}>
            Send Login Email
          </Button>
        </div>
      </form>
    </div>
  );
}

export function LoginCallback() {
  return (
    <div className="w-full flex min-h-screen items-center justify-center">
      <div className="text-center items-center">
        <img
          src={logoDark.href}
          className="mb-8 block h-28 w-28 bg-contain bg-center bg-no-repeat"
          alt="Lucky You Logo"
        />
        <p className="text-lg">Logging you in...</p>
      </div>
    </div>
  );
}

const DisplayMessage = ({
  message,
  isError = false,
}: {
  message?: React.ReactNode;
  isError?: boolean;
}) => {
  const extraClasses = isError
    ? 'border-red-500 text-red-600'
    : 'border-blue-500 text-blue-600';
  return message ? (
    <div className={`mb-3 w-full border p-2 ${extraClasses}`}>
      <small>{message}</small>
    </div>
  ) : null;
};
