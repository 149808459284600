import { DropDown, Input } from '~/src/shared/ui/components';
import { makeDropDownOptions } from '~/src/shared/utils.ts';
import { Datepicker } from '~/src/shared/ui/components/datepicker/datepicker.tsx';
import { useCustomForm } from '~/src/shared/hooks/use-custom-form.ts';
import { paths } from '~/src/shared/api/api.schema.ts';
import { routes } from '~/src/routes.ts';
import { Logger } from '~/src/shared/service/logger';
import { FormModal } from '~/src/shared/ui/components/modal/modal';
import {
  QueryObserverResult,
  RefetchOptions,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import { mutation, query } from '~/src/shared/api/request-config.ts';
import useAlert from '~/src/shared/ui/components/alert/hooks/use-alert.tsx';
import { useNavigate } from 'react-router-dom';
import useUpdateEffect from '~/src/shared/hooks/use-update-effect.ts';
import { MarketingCategory, Quote } from '~/src/quotes/quotes.type.ts';
import * as React from 'react';
import Divider from '~/src/shared/ui/components/divider/divider.tsx';
import { CircleExclamation } from '~/src/shared/ui/icons/circle-exclamation.tsx';
import { capitalize } from 'lodash-es';
import Xmark from '~/src/shared/ui/icons/xmark.tsx';
import { Colors } from '~/src/shared/ui/styles/const.ts';

interface QuotesModalProps {
  refetchQuotes: (
    options?: RefetchOptions | undefined,
  ) => Promise<QueryObserverResult<Quote[] | Quote, Error>>;
  quote?: Quote;
}

export default function QuoteModal(props: QuotesModalProps) {
  const { refetchQuotes } = props;
  const navigate = useNavigate();
  const alert = useAlert();

  const {
    watch,
    controlled,
    register,
    handleSubmit,
    isDirty,
    isSubmitting,
    setFormError,
    showError,
    errorMessage,
  } = useCustomForm<Quote>({
    validate: (data) => {
      if (data.quantity < 1) {
        setFormError(['quantity', 'Quantity must be 1 or more.']);
        return false;
      }

      return data;
    },
    handleSubmit: async (data) => {
      Logger.info('Quote submitted', { data });
      createMutate(data);
    },
  });

  const selectedCustomer = watch('customer');

  const { data: customers } = useQuery({
    ...query(paths.customers),
  });

  const { data: contacts } = useQuery({
    ...query(paths.customerContacts, {
      params: { id: selectedCustomer?.toString() || '' },
    }),
    enabled: Boolean(selectedCustomer),
  });

  const { data: users } = useQuery({
    ...query(paths.users),
  });

  const {
    mutate: createMutate,
    status,
    error,
    data,
  } = useMutation(mutation(paths.createQuote));

  const customerOptions = React.useMemo(() => {
    return customers
      ?.filter((c) => c.isActive)
      .map((customer) => ({
        title: customer.companyName,
        value: customer.id.toString(),
      }));
  }, [customers]);

  const contactOptions = React.useMemo(() => {
    return contacts
      ?.filter((c) => c.isActive)
      .map((contact) => ({
        title: `${contact.firstName} ${contact.lastName}`,
        value: contact.id.toString(),
      }));
  }, [contacts]);

  const usersOptions = React.useMemo(() => {
    return users?.map((user) => ({
      title: user.firstName
        ? `${user.firstName} ${user.lastName}`
        : 'Unknown user',
      value: user.username || null,
    }));
  }, [users]);

  useUpdateEffect(() => {
    if (status === 'success') {
      Logger.info('Created Quote.');
      alert.showSuccess('Created new Quote.');
      refetchQuotes();
      navigate(routes.quoteBuilding.getPath(data.id));
    }

    if (status === 'error') {
      Logger.warn('Error on creating quote.', { data: error });
      navigate(-1);
      alert.showError('Error on creating Quote.');
    }
  }, [status]);

  return (
    <FormModal
      name="quote"
      title="Add New Quote"
      id="new-quote-modal"
      data-testid="quote-modal"
      onSubmit={() => handleSubmit}
      isDirty={isDirty}
      isSubmitting={isSubmitting}
      floating
      actionName="Continue"
    >
      {showError && (
        <div className="_error-balloon">
          <CircleExclamation />
          <p>{capitalize(errorMessage)}</p>
          <button onClick={() => setFormError(['', ''])}>
            <Xmark color={Colors.danger['200']} size="md" />
          </button>
        </div>
      )}

      <DropDown
        label="Customer"
        className="flex-1"
        {...controlled('customer')}
        options={customerOptions || []}
        required
        searchable
      />

      <div className="_row">
        <DropDown
          label="Contact"
          title="Select a Contact"
          className="flex-1"
          {...controlled('contact')}
          options={contactOptions || []}
          disabled={!selectedCustomer}
          required
        />
        <DropDown
          label="Marketing Category"
          title="Select a Marketing Category"
          className="flex-1"
          {...controlled('marketingCategory')}
          options={makeDropDownOptions(MarketingCategory)}
        />
      </div>

      <div className="_row">
        <DropDown
          label="Quoted By"
          className="flex-1"
          {...controlled('quotedBy')}
          options={usersOptions || []}
          required
        />
        <div className="flex-1" />
      </div>

      <Input
        label="Quote Name"
        placeholder="Enter quote name"
        {...register('name')}
        required
      />

      <Divider />

      <div className="_row">
        <Input
          label="Quantity"
          type="number"
          placeholder="-"
          {...register('quantity')}
          required
        />

        <Datepicker
          label="Quote Date"
          {...register('quoteDate')}
          placeholder="mm/dd/yyyy"
          required
        />
      </div>
    </FormModal>
  );
}
