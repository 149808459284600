import { FlowData, Page } from '~/src/state/flow.types.ts';
import { SessionStorage } from '~/src/shared/service/storage';
import { Logger } from '~/src/shared/service/logger';
import { flowState } from '~/src/state/atoms/flow.atom.ts';
import { Store } from '~/src/shared/service/store/store.ts';

export class FlowStore extends Store<FlowData> {
  static cachedKeys: (keyof FlowData)[] = ['currentPage'];

  static defaults() {
    return {
      currentPage: 'home',
    } satisfies Partial<FlowData>;
  }

  static restore() {
    return { ...this.defaults(), ...(SessionStorage.read('Flow') as object) };
  }

  constructor() {
    super(FlowStore.restore(), flowState);

    Logger.setContextGetter(() => ({
      currentPage: this.state.currentPage ?? undefined,
    }));
  }

  // private getCurrentData() {
  //   return Object.fromEntries(
  //     FlowStore.cachedKeys.map((key) => [key, this.state[key]]),
  //   );
  // }

  // private writeToStorage() {
  //   SessionStorage.write('Flow', this.getCurrentData());
  // }

  setPage(page: Page) {
    this.updateState((draft) => {
      draft.currentPage = page;
    });
  }
}

export const flowStore = new FlowStore();
