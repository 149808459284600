import { AlertProps } from '~/src/shared/ui/components/alert/alert.type.ts';

export const creation = (resource: string): AlertProps => {
  return {
    title: 'Congratulations!',
    text: `You just created a new ${resource}`,
    color: 'success',
  };
};

export const deletion = (resource: string): AlertProps => {
  return {
    title: 'Done!',
    text: `${resource} was deleted`,
    color: 'success',
  };
};

export const updateError = (resource: string): AlertProps => {
  return {
    title: 'Warning!',
    text: `Error on updating ${resource}. Please try again`,
    color: 'warning',
  };
};

export const deletionError = (resource: string): AlertProps => {
  return {
    title: 'Error!',
    text: `Error on deleting ${resource}`,
    color: 'danger',
  };
};

export const login = (): AlertProps => {
  return {
    title: 'Hello there!',
    text: 'You logged in successfully',
    color: 'success',
  };
};
