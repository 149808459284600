import { IconProps } from '~/src/shared/ui/icons/types.ts';

export default function Xmark(props: IconProps) {
  const { size, color, ...rest } = props;

  const xs = (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M11.5859 5.71094L8.77344 8.52344L11.5625 11.3125C11.7969 11.5234 11.7969 11.875 11.5625 12.0859C11.3516 12.3203 11 12.3203 10.7891 12.0859L7.97656 9.29688L5.1875 12.0859C4.97656 12.3203 4.625 12.3203 4.41406 12.0859C4.17969 11.875 4.17969 11.5234 4.41406 11.2891L7.20312 8.5L4.41406 5.71094C4.17969 5.5 4.17969 5.14844 4.41406 4.91406C4.625 4.70312 4.97656 4.70312 5.21094 4.91406L8 7.72656L10.7891 4.9375C11 4.70312 11.3516 4.70312 11.5859 4.9375C11.7969 5.14844 11.7969 5.5 11.5859 5.71094Z"
        fill={color ?? 'black'}
      />
    </svg>
  );

  const md = (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M14.7812 6.28125L11.0312 10.0312L14.75 13.75C15.0625 14.0312 15.0625 14.5 14.75 14.7812C14.4688 15.0938 14 15.0938 13.7188 14.7812L9.96875 11.0625L6.25 14.7812C5.96875 15.0938 5.5 15.0938 5.21875 14.7812C4.90625 14.5 4.90625 14.0312 5.21875 13.7188L8.9375 10L5.21875 6.28125C4.90625 6 4.90625 5.53125 5.21875 5.21875C5.5 4.9375 5.96875 4.9375 6.28125 5.21875L10 8.96875L13.7188 5.25C14 4.9375 14.4688 4.9375 14.7812 5.25C15.0625 5.53125 15.0625 6 14.7812 6.28125Z"
        fill={color ?? 'black'}
      />
    </svg>
  );

  const lg = (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M27.1719 14.4219L21.5469 20.0469L27.125 25.625C27.5938 26.0469 27.5938 26.75 27.125 27.1719C26.7031 27.6406 26 27.6406 25.5781 27.1719L19.9531 21.5938L14.375 27.1719C13.9531 27.6406 13.25 27.6406 12.8281 27.1719C12.3594 26.75 12.3594 26.0469 12.8281 25.5781L18.4062 20L12.8281 14.4219C12.3594 14 12.3594 13.2969 12.8281 12.8281C13.25 12.4062 13.9531 12.4062 14.4219 12.8281L20 18.4531L25.5781 12.875C26 12.4062 26.7031 12.4062 27.1719 12.875C27.5938 13.2969 27.5938 14 27.1719 14.4219Z"
        fill={color ?? 'black'}
      />
    </svg>
  );

  return size ? { xs, md, lg }[size] : xs;
}
