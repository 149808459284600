import { QuoteControl } from '~/src/quotes/pages/quote-building.page.tsx';
import { useToggle } from '~/src/shared/hooks/use-toggle.ts';
import * as React from 'react';
import {
  DynamicTableColumn,
  TableRow,
} from '~/src/shared/ui/components/table/types.ts';
import { FreightMarkup } from '~/src/quotes/quotes.type.ts';
import { mutation, request } from '~/src/shared/api/request-config.ts';
import { quotesPaths } from '~/src/quotes/quotes.schema.ts';
import { Input, SymbolInput } from '~/src/shared/ui/components/input/input.tsx';
import { AngleDown } from '~/src/shared/ui/icons/angles.tsx';
import { clean } from '~/src/shared/ui/styles/helpers.ts';
import { Conditionally } from '~/src/shared/ui/components/switch/switch.ts';
import { DynamicTable } from '~/src/shared/ui/components/table/dynamic-table.tsx';
import { useMutation } from '@tanstack/react-query';
import { paths } from '~/src/shared/api/api.schema.ts';
import { Button } from '~/src/shared/ui/components';
import Floppy from '~/src/shared/ui/icons/floppy.tsx';
import { Colors } from '~/src/shared/ui/styles/const.ts';
import Trash from '~/src/shared/ui/icons/trash.tsx';

export default function FreightMarkupContainer(props: QuoteControl) {
  const { quote, refetchQuote } = props;
  const [expanded, toggleExpanded] = useToggle(true);

  const { mutate: createFreightMarkupMutation } = useMutation(
    mutation(paths.createQuoteFreightMarkup, {
      params: { id: quote.id },
    }),
  );

  const [newFreightMarkup, setNewFreightMarkup] =
    React.useState<React.JSX.Element>();

  function cancelFreightMarkupCreation() {
    setNewFreightMarkup(undefined);
  }

  function onRowChange(
    event: React.ChangeEvent<HTMLInputElement>,
    row: TableRow<FreightMarkup>,
  ) {
    const name = event.target.name;
    const value = name === 'value' ? event.target.value : event.target.checked;

    request(quotesPaths.updateQuoteFreightMarkup, {
      params: { quoteId: quote.id, markupId: row.id },
    })
      .fn({ [name]: value })
      .then(() => refetchQuote());
  }

  const columns = [
    { field: 'reason', headerName: 'Markup Reason', width: 'w-full' },
    {
      field: 'value',
      headerName: 'Markup Value',
      formatType: 'input_percent',
      onUpdate: onRowChange,
    },
  ] as DynamicTableColumn<Partial<FreightMarkup>>[];

  const footerRow = (
    <tr>
      <td className="py-4">
        <div className="flex items-center justify-between">
          <Button className="_outline" onClick={addFreightMarkupToTable}>
            Add New Freight Markup
          </Button>
          <p className="font-medium">Freight Markup Total</p>
        </div>
      </td>
      <td>
        <SymbolInput
          className="w-32"
          symbol="%"
          name="freightMarkupTotal"
          label="Freight Markup Total"
          placeholder="-"
          value={quote.totalFreightMarkup}
          hideLabel
          disabled
        />
      </td>
      <td />
    </tr>
  );

  function addFreightMarkupToTable() {
    const NewFreightMarkup = () => {
      type FreightMarkupField = 'reason' | 'value';

      const [reason, setReason] = React.useState('');
      const [value, setValue] = React.useState('');
      const [errors, setErrors] = React.useState<string[]>([]);

      const freightMarkup = { reason, value };

      function checkErrors() {
        let error = false;

        for (const name of ['reason', 'value']) {
          if (freightMarkup[name as FreightMarkupField].trim().length === 0) {
            setErrors((err) => [...err, name]);
            error = true;
          } else {
            setErrors((err) => err.filter((e) => e !== name));
          }
        }

        return error;
      }

      function createFreightMarkup() {
        const isInvalid = checkErrors();

        if (!isInvalid) {
          const freightMarkup = {
            reason,
            value,
            isActive: true,
          };

          createFreightMarkupMutation(freightMarkup, {
            onSuccess: () => {
              refetchQuote();
              cancelFreightMarkupCreation();
            },
          });
        }
      }

      return (
        <tr>
          <td className="w-full">
            <Input
              name="setUpFeeName"
              className="w-full"
              label="New Set Up Fee"
              placeholder="Set Up Fee Name"
              onChange={(e) => setReason(e.target.value)}
              invalid={errors.includes('reason')}
              hideLabel
            />
          </td>
          <td>
            <SymbolInput
              type="number"
              className="w-32"
              symbol="%"
              placeholder="0"
              onChange={(e) => setValue(e.target.value)}
              name="setUpFeeAmount"
              label="Set Up Fee Amount"
              invalid={errors.includes('value')}
              hideLabel
            />
          </td>
          <td className="flex flex-row justify-center">
            <Button className="_icon-button" onClick={createFreightMarkup}>
              <Floppy color={Colors.success['500']} />
            </Button>

            <Button
              className="_icon-button"
              onClick={cancelFreightMarkupCreation}
            >
              <Trash color={Colors.danger['500']} />
            </Button>
          </td>
        </tr>
      );
    };

    setNewFreightMarkup(<NewFreightMarkup />);
  }

  return (
    <div className="_quote-container">
      <div className="flex items-center justify-between">
        <p className="text-lg">Freight Markup</p>

        <div className="flex gap-20">
          <p>
            <span className="text-gray-300">Total:</span>{' '}
            {quote.totalFreightMarkup}%
          </p>
          <AngleDown
            className={clean(
              `hover:cursor-pointer ${expanded ? 'rotate-180' : ''}`,
            )}
            onClick={toggleExpanded}
          />
        </div>
      </div>

      <Conditionally when={expanded}>
        {() => (
          <DynamicTable<FreightMarkup>
            columns={columns}
            rows={quote.freightMarkups as Required<FreightMarkup>[]}
            loading={false}
            onRowClick={() => {}}
            customRows={
              newFreightMarkup ? [newFreightMarkup, footerRow] : [footerRow]
            }
            onToggle={onRowChange}
          />
        )}
      </Conditionally>
    </div>
  );
}
