import { IconProps } from '~/src/shared/ui/icons/types.ts';

export default function Image(props: IconProps) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M29 11.75H11C10.5781 11.75 10.25 12.125 10.25 12.5V27.5V27.5469L10.4375 27.2656L14.1875 22.0156C14.4219 21.6875 14.75 21.5 15.125 21.5C15.4531 21.5 15.8281 21.6875 16.0156 21.9688L17.4688 23.9844L21.3594 18.9688C21.5469 18.6875 21.875 18.5 22.25 18.5C22.5781 18.5 22.9062 18.6875 23.1406 18.9688L29.5156 27.2188L29.75 27.5V12.5C29.75 12.125 29.375 11.75 29 11.75ZM11 9.5H29C30.6406 9.5 32 10.8594 32 12.5V27.5C32 29.1875 30.6406 30.5 29 30.5H11C9.3125 30.5 8 29.1875 8 27.5V12.5C8 10.8594 9.3125 9.5 11 9.5ZM14.75 18.5C13.9062 18.5 13.2031 18.0781 12.7812 17.375C12.3594 16.7188 12.3594 15.8281 12.7812 15.125C13.2031 14.4688 13.9062 14 14.75 14C15.5469 14 16.25 14.4688 16.6719 15.125C17.0938 15.8281 17.0938 16.7188 16.6719 17.375C16.25 18.0781 15.5469 18.5 14.75 18.5Z"
        fill={props.color ?? 'black'}
      />
    </svg>
  );
}
