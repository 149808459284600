import {
  CustomersAPI,
  customersPaths,
} from '~/src/customers/customers.schema.ts';
import {
  InventoryAPI,
  inventoryPaths,
} from '~/src/inventory/inventory.schema.ts';
import { VendorsAPI, vendorsPaths } from '~/src/vendors/vendors.schema.ts';
import { BaseAPI, basePaths } from '~/src/luckyyou/base.schema.ts';
import {
  PurchaseOrdersAPI,
  purchaseOrdersPaths,
} from '~/src/purchase-orders/purchase-orders.schema.ts';
import { QuotesAPI, quotesPaths } from '~/src/quotes/quotes.schema.ts';
import { OrdersAPI, ordersPaths } from '~/src/orders/orders.schema.ts';

export interface API
  extends BaseAPI,
    CustomersAPI,
    VendorsAPI,
    InventoryAPI,
    PurchaseOrdersAPI,
    QuotesAPI,
    OrdersAPI {}

const protectedPaths = {
  ...basePaths,
  ...inventoryPaths,
  ...vendorsPaths,
  ...customersPaths,
  ...purchaseOrdersPaths,
  ...quotesPaths,
  ...ordersPaths,
} satisfies Record<string, keyof API>;

export const unprotectedPaths = {
  triggerLoginEmail: 'POST /trigger-login-email/',
} satisfies Record<string, keyof API>;

export const paths = {
  ...unprotectedPaths,
  ...protectedPaths,
} satisfies Record<string, keyof API>;
