import * as React from 'react';
import {
  DeepPartial,
  DefaultValues,
  Path,
  PathValue,
  RegisterOptions,
  useForm,
} from 'react-hook-form';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type FormValues = Record<string, any>;

export interface useFormProps<T> {
  initialData?: Partial<T>;
  handleSubmit: (data: T) => Promise<void>;
  onChange?: (data: DeepPartial<T>) => void;
  watchChangesOf?: string[];
  validate?: (data: T) => T | false;
}

export function useCustomForm<T extends FormValues>(config: useFormProps<T>) {
  const {
    initialData,
    handleSubmit: customSubmit,
    onChange,
    watchChangesOf,
    validate,
  } = config;
  const {
    control,
    register,
    handleSubmit: standardSubmit,
    setValue,
    formState: { isDirty, isSubmitting, isValidating },
    watch,
    reset,
    getValues,
  } = useForm<T>({ defaultValues: initialData as DefaultValues<T> });
  const [isBusy, setIsBusy] = React.useState(false);
  const [formError, setFormError] = React.useState<[string, string]>(['', '']);

  React.useEffect(() => {
    const subscription = watch((data, { name }) => {
      setFormError(['', '']);
      if (!name) return;

      if (watchChangesOf !== undefined) {
        const field = name.split('.').slice(-1)[0];
        if (!watchChangesOf?.includes(field)) return;
      }

      if (onChange) onChange(data);
    });
    return () => subscription.unsubscribe();
  }, [watch, watchChangesOf, onChange]);

  function customRegister(inputName: Path<T>, options?: RegisterOptions) {
    return {
      ...register(inputName, options),
      // defaultValue: get(initialData, inputName) as string,
      invalid: formError[0] === inputName,
    };
  }

  function controlled(inputName: Path<T>, options?: RegisterOptions) {
    const { onBlur, name } = register(inputName, options);

    return {
      onChange: (value: string | null) =>
        setValue(inputName, value as PathValue<T, Path<T>>),
      invalid: formError[0] === inputName,
      // defaultValue: get(initialData, inputName) as string,
      onBlur,
      name,
    };
  }

  async function safeHandleSubmit(data: T) {
    setIsBusy(true);

    const payload = validate ? validate(data) : data;
    if (payload) await customSubmit(payload);

    setIsBusy(false);
  }

  return {
    errorMessage: formError[1],
    showError: formError[1].length > 0,
    register: customRegister,
    controlled,
    control,
    handleSubmit: standardSubmit(safeHandleSubmit),
    setFormError,
    isDirty,
    isSubmitting: isValidating || isSubmitting || isBusy,
    setIsBusy,
    watch,
    setValue,
    reset,
    getValues,
  };
}
