import * as React from 'react';
import {
  QueryObserverResult,
  RefetchOptions,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import { mutation, query } from '~/src/shared/api/request-config.ts';
import { paths } from '~/src/shared/api/api.schema.ts';
import { useParams } from 'react-router-dom';
import { Tab, Tabs } from '~/src/shared/ui/components/tabs/tabs.tsx';
import Divider from '~/src/shared/ui/components/divider/divider.tsx';
import { Menu, MenuOption } from '~/src/shared/ui/components/menu/menu.tsx';
import Trash from '~/src/shared/ui/icons/trash.tsx';
import { Breadcrumbs } from '~/src/shared/ui/components/breadcrumbs/breadcrumbs.tsx';
import { Colors } from '~/src/shared/ui/styles/const.ts';
import InfoPiece from '~/src/shared/ui/components/info-piece/info-piece.component';
import { formatDate } from '~/src/shared/utils.ts';
import Print from '~/src/shared/ui/icons/print.tsx';

import '~/src/vendors/styles/vendor-detail.page.css';
import ItemPricingTab from '~/src/orders/components/item-pricing-tab.component.tsx';
import ProductionTab from '../components/production-tab.component';
import GraphicsTab from '~/src/orders/components/graphics-tab.component.tsx';
import ShippingInfoTab from '~/src/orders/components/shipping-info-tab.component.tsx';
import { Order } from '~/src/orders/orders.type.ts';
import { useEffect } from 'react';
import ComponentsTab from '~/src/orders/components/components-tab.component.tsx';
import NotesTab from '~/src/orders/components/notes-tab.component.tsx';

export interface OrderControl {
  order: Order;
  update: (data: Partial<Order>) => void;
  refetch: (
    options?: RefetchOptions | undefined,
  ) => Promise<QueryObserverResult<Order, Error>>;
}

export function OrderDetail() {
  const { id } = useParams();
  const [order, setOrder] = React.useState<Order | null>(null);

  const { data, refetch } = useQuery({
    ...query(paths.order, { params: { id: parseInt(id as string, 10) } }),
  });

  const { mutate: partialUpdateOrder } = useMutation(
    mutation(paths.partialUpdateOrder, {
      params: { id: parseInt(id as string, 10) },
    }),
  );

  const handleChange = (data: Partial<Order>) => {
    partialUpdateOrder(data, {
      onSuccess: (data: Order) => {
        setOrder(data);
      },
    });
  };

  useEffect(() => {
    if (data) {
      setOrder(data);
    }
  }, [data]);

  const contactName = React.useMemo(
    () => `${order?.contact.firstName} ${order?.contact.lastName}`,
    [order],
  );

  if (!order) {
    return (
      <div className="flex items-center justify-center w-full">
        Loading order...
      </div>
    );
  }

  const orderControl = {
    order,
    update: handleChange,
    refetch,
  } as OrderControl;

  return (
    <div className="_page">
      <header>
        <div>
          <Breadcrumbs />
          <h1>{order.name}</h1>
        </div>

        <div>
          <Menu>
            <MenuOption
              action={() => {}}
              icon={<Print />}
              label="Print Pick List"
            />
            <MenuOption
              action={() => {}}
              icon={<Print />}
              label="Print Pack Card"
            />
            <Divider />
            <MenuOption
              action={() => {}}
              icon={<Trash color={Colors.danger['500']} />}
              label="Delete"
              color="danger"
            />
          </Menu>
        </div>
      </header>

      <div className="_summary">
        <InfoPiece label="Order Date" content={formatDate(order.orderDate)} />
        <InfoPiece label="Order Name" content={order.name} />
        <InfoPiece label="Customer" content={order.customer.companyName} />
        <InfoPiece label="Delivery Days" content={contactName} />
      </div>

      <div className="_summary">
        <InfoPiece label="Account Rep" content={order.accountRep} />
        <InfoPiece
          label="In Store Date"
          content={formatDate(order.inStoreDate ?? '')}
        />
        <InfoPiece label="Ship Date" content={formatDate(order.shipDate)} />
        <div className="_info-piece" />
      </div>

      <div className="_container">
        <Tabs>
          <Tab label="Item and Pricing">
            <ItemPricingTab order={order} />
          </Tab>
          <Tab label="Shipping Info">
            <ShippingInfoTab {...orderControl} />
          </Tab>
          <Tab label="Components">
            <ComponentsTab orderId={order.id} />
          </Tab>
          <Tab label="Production">
            <ProductionTab order={order} />
          </Tab>
          <Tab label="Graphics">
            <GraphicsTab />
          </Tab>
          <Tab label="Notes">
            <NotesTab {...orderControl} />
          </Tab>
          <Tab label="Close Out">Close Out</Tab>
          <Tab label="Hours Worked">Hours Worked</Tab>
        </Tabs>
      </div>
    </div>
  );
}
