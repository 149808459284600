import { DropDown, Input } from '~/src/shared/ui/components';
import { FormModal } from '~/src/shared/ui/components/modal/modal.tsx';
import { useCustomForm } from '~/src/shared/hooks/use-custom-form.ts';
import { useNavigate, useParams } from 'react-router-dom';
import {
  QueryObserverResult,
  RefetchOptions,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import { mutation, query } from '~/src/shared/api/request-config.ts';
import { paths } from '~/src/shared/api/api.schema.ts';
import * as React from 'react';
import {
  OrderComponent,
  OrderComponentWrite,
} from '~/src/orders/orders.type.ts';
import useUpdateEffect from '~/src/shared/hooks/use-update-effect.ts';
import { Logger } from '~/src/shared/service/logger';
import useAlert from '~/src/shared/ui/components/alert/hooks/use-alert.tsx';
import { CircleExclamation } from '~/src/shared/ui/icons/circle-exclamation.tsx';
import { capitalize } from 'lodash-es';
import Xmark from '~/src/shared/ui/icons/xmark.tsx';
import { Colors } from '~/src/shared/ui/styles/const.ts';
import { CustomInventoryItemVendor } from '~/src/inventory/inventory.type.ts';

interface ComponentModalProps {
  refetchComponents: (
    options?: RefetchOptions | undefined,
  ) => Promise<QueryObserverResult<OrderComponent[] | OrderComponent, Error>>;
}

export default function ComponentModal(props: ComponentModalProps) {
  const { refetchComponents } = props;
  const { id } = useParams();
  const navigate = useNavigate();
  const alert = useAlert();
  const [addNew, setAddNew] = React.useState(false);

  const { data: items } = useQuery({
    ...query(paths.inventoryItemVendors, { query: {} }),
  });

  const { mutate, status, error } = useMutation({
    ...mutation(paths.addOrderComponent, {
      params: { id: parseInt(id as string, 10) },
    }),
  });

  useUpdateEffect(() => {
    if (status === 'success') {
      Logger.info('Created new component.');
      alert.showSuccess('A new component was added.');
      refetchComponents();

      if (!addNew) {
        navigate(-1);
      } else {
        reset();
        setAddNew(false);
      }
    }

    if (status === 'error') {
      Logger.warn('Error on creating component.', { data: error });
      navigate(-1);
      alert.showError('Error on creating component.');
    }
  }, [status]);

  const formValidate = (data: OrderComponentWrite) => {
    if (!data.inventoryItemVendor) {
      setFormError(['inventoryItemVendor', 'Inventory Item is required.']);
      return false;
    }

    if (!data.quantity) {
      setFormError(['units', 'Units is required.']);
      return false;
    }

    if (!data.modifier) {
      setFormError(['modifier', 'Modifier is required.']);
      return false;
    }

    return data;
  };

  const {
    handleSubmit,
    isSubmitting,
    setFormError,
    showError,
    errorMessage,
    isDirty,
    reset,
    getValues,
    controlled,
    register,
    watch,
  } = useCustomForm<OrderComponentWrite>({
    validate: formValidate,
    handleSubmit: async (data) => {
      mutate(data);
    },
  });

  const selectedItemId = watch('inventoryItemVendor') as unknown as string;

  const selectedItem = React.useMemo<
    CustomInventoryItemVendor | undefined
  >(() => {
    return items?.find((i) => i.id === parseFloat(selectedItemId));
  }, [items, selectedItemId]);

  function saveAndAddNew() {
    const data = getValues();
    if (!formValidate(data)) return;
    setAddNew(true);
    mutate(data);
  }

  const itemsOptions = React.useMemo(
    () =>
      items?.map((item) => ({
        title: `${item.name} - ${item.unitOfMeasure}`,
        value: item.id.toString(),
      })),
    [items],
  );

  return (
    <FormModal
      name="add-component"
      title="Add Component"
      id="add-component-modal"
      onSubmit={() => handleSubmit}
      isDirty={isDirty}
      isSubmitting={isSubmitting}
      onSecondaryActionClick={saveAndAddNew}
      secondaryActionName="Save and Add New Item"
      floating
    >
      {showError && (
        <div className="_error-balloon">
          <CircleExclamation />
          <p>{capitalize(errorMessage)}</p>
          <button onClick={() => setFormError(['', ''])}>
            <Xmark color={Colors.danger['200']} size="md" />
          </button>
        </div>
      )}

      <div>
        <div className="_row">
          <DropDown
            title="Inventory Item"
            label="Inventory Item"
            className="flex-1"
            required
            options={itemsOptions ?? []}
            {...controlled('inventoryItemVendor')}
            searchable
          />
          <Input
            label="Unit price"
            className="flex-1"
            placeholder="-"
            value={selectedItem?.unitPrice}
            disabled
          />
        </div>
        <div className="_row">
          <Input
            label="Quantity"
            placeholder="0"
            required
            {...register('quantity')}
          />
          <Input
            label="Modifier"
            placeholder="-"
            required
            {...register('modifier')}
          />
        </div>
      </div>
    </FormModal>
  );
}
