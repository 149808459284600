import { IconProps } from '~/src/shared/ui/icons/types.ts';

export default function MagnifyingGlass(props: IconProps) {
  const { color, ...rest } = props;

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M13.5 8.5C13.5 6.71875 12.5312 5.09375 11 4.1875C9.4375 3.28125 7.53125 3.28125 6 4.1875C4.4375 5.09375 3.5 6.71875 3.5 8.5C3.5 10.3125 4.4375 11.9375 6 12.8438C7.53125 13.75 9.4375 13.75 11 12.8438C12.5312 11.9375 13.5 10.3125 13.5 8.5ZM12.5312 13.625C11.4062 14.5 10 15 8.5 15C4.90625 15 2 12.0938 2 8.5C2 4.9375 4.90625 2 8.5 2C12.0625 2 15 4.9375 15 8.5C15 10.0312 14.4688 11.4375 13.5938 12.5625L17.7812 16.7188C18.0625 17.0312 18.0625 17.5 17.7812 17.7812C17.4688 18.0938 17 18.0938 16.7188 17.7812L12.5312 13.625Z"
        fill={color ?? 'black'}
      />
    </svg>
  );
}
