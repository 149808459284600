import * as React from 'react';

import { useMutation, useQuery } from '@tanstack/react-query';
import { mutation, query } from '~/src/shared/api/request-config.ts';
import { paths } from '~/src/shared/api/api.schema.ts';
import { useParams } from 'react-router-dom';
import { Tab, Tabs } from '~/src/shared/ui/components/tabs/tabs.tsx';
import { MultipleSelectionOptions } from '~/src/shared/ui/components/table/types.ts';
import { ContactsTable } from '~/src/contacts/components/contacts-table.component.tsx';
import { OrdersTable } from '~/src/orders/components/orders-table.component';
import { QuotesTable } from '~/src/quotes/components/quotes-table.component';
import { useLocation, useNavigate } from 'react-router-dom';
import InfoPiece, {
  getFormattedAddress,
} from '~/src/shared/ui/components/info-piece/info-piece.component';
import { AccountsPayable } from '~/src/customers/components/accounts-payable.component.tsx';
import { routes } from '~/src/routes.ts';
import { CustomerModal } from '~/src/customers/components/customer-modal.component.tsx';

import '~/src/customers/styles/customer-detail.page.css';
import { Menu, MenuOption } from '~/src/shared/ui/components/menu/menu.tsx';
import Copy from '~/src/shared/ui/icons/copy.tsx';
import Pencil from '~/src/shared/ui/icons/pencil.tsx';
import ToggleOff from '~/src/shared/ui/icons/toggle-off.tsx';
import ToggleOn from '~/src/shared/ui/icons/toggle-on.tsx';
import Trash from '~/src/shared/ui/icons/trash.tsx';
import { Breadcrumbs } from '~/src/shared/ui/components/breadcrumbs/breadcrumbs.tsx';
import { Button } from '~/src/shared/ui/components';
import { Colors } from '~/src/shared/ui/styles/const.ts';
import { ContactModal } from '~/src/contacts/components/contact-modal.component.tsx';
import Divider from '~/src/shared/ui/components/divider/divider.tsx';
import { formatDate } from '~/src/shared/utils';
import { Contact } from '~/src/contacts/contacts.type.ts';
import useAlert from '~/src/shared/ui/components/alert/hooks/use-alert.tsx';
import { ConfirmationModal } from '~/src/shared/ui/components/modal/modal';
import useUpdateEffect from '~/src/shared/hooks/use-update-effect.ts';
import { deletionError } from '~/src/shared/ui/components/alert/models.tsx';

export function CustomerDetail() {
  const { id } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const alert = useAlert();

  type ConfirmationType =
    | 'markInactiveContacts'
    | 'markActiveContacts'
    | 'deleteContacts'
    | 'markActiveInactive'
    | 'delete'
    | undefined;
  const [showConfirmation, setShowConfirmation] =
    React.useState<ConfirmationType>(undefined);
  const [selectedRows, setSelectedRows] = React.useState<number[]>([]);

  const { data: customer, refetch: refetchCustomers } = useQuery({
    ...query(paths.customer, { params: { id: id as string } }),
  });

  const {
    data: contacts,
    fetchStatus: contactsFetchStatus,
    refetch: refetchContacts,
  } = useQuery({
    ...query(paths.customerContacts, {
      params: { id: customer?.id.toString() as string },
    }),
    enabled: Boolean(customer?.id),
  });

  const { data: orders, fetchStatus: ordersFetchStatus } = useQuery({
    ...query(paths.customerOrders, {
      params: { id: customer?.id.toString() as string },
    }),
    enabled: Boolean(customer?.id),
  });

  const { data: quotes, fetchStatus: quotesFetchStatus } = useQuery({
    ...query(paths.customerQuotes, {
      params: { id: customer?.id.toString() as string },
    }),
    enabled: Boolean(customer?.id),
  });

  const { mutate: partialUpdateMutate, status: partialUpdateStatus } =
    useMutation(
      mutation(paths.partialUpdateCustomer, {
        params: { id: id as string },
      }),
    );

  const { mutate: deleteMutate, status: deleteStatus } = useMutation(
    mutation(paths.deleteCustomer, {
      params: { id: id as string },
    }),
  );

  const { mutate: markActiveContactsMutate, status: markActiveContactsStatus } =
    useMutation(
      mutation(paths.customerContactActiveMultiple, {
        params: { id: id as string },
      }),
    );

  const {
    mutate: markInactiveContactsMutate,
    status: markInactiveContactsStatus,
  } = useMutation(
    mutation(paths.customerContactInactiveMultiple, {
      params: { id: id as string },
    }),
  );

  const { mutate: deleteContactsMutate, status: deleteContactsStatus } =
    useMutation(
      mutation(paths.customerContactDeleteMultiple, {
        params: { id: id as string },
      }),
    );

  useUpdateEffect(() => {
    if (partialUpdateStatus === 'success') {
      alert.showSuccess(
        `Customer successfully marked ${customer?.isActive ? 'inactive' : 'active'}.`,
      );
      setShowConfirmation(undefined);
      navigate(routes.customers.path);
    } else if (partialUpdateStatus === 'error') {
      alert.showError(
        `Error on mark customer ${customer?.isActive ? 'inactive' : 'active'}.`,
      );
      setShowConfirmation(undefined);
    }
  }, [partialUpdateStatus]);

  useUpdateEffect(() => {
    if (deleteStatus === 'success') {
      alert.showSuccess('Customer successfully removed.');
      setShowConfirmation(undefined);
      navigate(routes.customers.path);
    } else if (deleteStatus === 'error') {
      alert.show(deletionError('Customer'));
      setShowConfirmation(undefined);
    }
  }, [deleteStatus]);

  useUpdateEffect(() => {
    if (markActiveContactsStatus === 'success') {
      alert.showSuccess('Contacts successfully marked active.');
      setShowConfirmation(undefined);
      refetchContacts();
    } else if (markActiveContactsStatus === 'error') {
      alert.showError('Error on mark contacts active.');
      setShowConfirmation(undefined);
    }
  }, [markActiveContactsStatus]);

  useUpdateEffect(() => {
    if (markInactiveContactsStatus === 'success') {
      alert.showSuccess('Contacts successfully marked inactive.');
      setShowConfirmation(undefined);
      refetchContacts();
    } else if (markInactiveContactsStatus === 'error') {
      alert.showError('Error on mark contacts inactive.');
      setShowConfirmation(undefined);
    }
  }, [markInactiveContactsStatus]);

  useUpdateEffect(() => {
    if (deleteContactsStatus === 'success') {
      alert.showSuccess('Contacts successfully removed.');
      setShowConfirmation(undefined);
      refetchContacts();
    } else if (deleteContactsStatus === 'error') {
      alert.show(deletionError('Contacts'));
      setShowConfirmation(undefined);
    }
  }, [deleteContactsStatus]);

  const showActiveContactsModal = (selectedRows: number[]) => {
    setShowConfirmation('markActiveContacts');
    setSelectedRows(selectedRows);
  };

  const showInactiveContactsModal = (selectedRows: number[]) => {
    setShowConfirmation('markInactiveContacts');
    setSelectedRows(selectedRows);
  };

  const showDeleteContactsModal = (selectedRows: number[]) => {
    setShowConfirmation('deleteContacts');
    setSelectedRows(selectedRows);
  };

  const tableOptions = [
    {
      action: showActiveContactsModal,
      icon: <ToggleOn />,
      label: 'Mark Selected as Active',
    },
    {
      action: showInactiveContactsModal,
      icon: <ToggleOff color={Colors.danger['500']} />,
      label: 'Mark Selected as Inactive',
      color: 'danger',
    },
    {
      action: showDeleteContactsModal,
      icon: <Trash color={Colors.danger['500']} />,
      label: 'Remove Selected Contacts',
      color: 'danger',
    },
  ] as MultipleSelectionOptions<Partial<Contact>>[];

  if (!customer) {
    return (
      <div className="flex items-center justify-center w-full">
        Loading customer...
      </div>
    );
  }

  customer.type = 'customer';
  const fullAddressFormatted = getFormattedAddress(customer.corporateAddress);

  const companyEstablishedDate =
    customer.companyEstablishedDate &&
    formatDate(customer.companyEstablishedDate);

  return (
    <div className="_page">
      <header>
        <div>
          <Breadcrumbs />
          <h1>{customer.companyName}</h1>
        </div>

        <div>
          <Button
            onClick={() =>
              navigate(routes.newCustomerContact.getPath(customer.id))
            }
          >
            Add new contact
          </Button>

          <Menu>
            <MenuOption
              action={() => navigate(routes.editCustomer.getPath(customer.id))}
              icon={<Pencil />}
              label="Edit"
            />
            <MenuOption action={() => {}} icon={<Copy />} label="Duplicate" />
            <Divider />
            {customer.isActive ? (
              <MenuOption
                action={() => {
                  setShowConfirmation('markActiveInactive');
                }}
                icon={<ToggleOff color={Colors.danger['500']} />}
                label="Mark Customer Inactive"
                color="danger"
              />
            ) : (
              <MenuOption
                action={() => {
                  setShowConfirmation('markActiveInactive');
                }}
                icon={<ToggleOn />}
                label="Mark Customer Active"
              />
            )}
            <MenuOption
              action={() => {
                setShowConfirmation('delete');
              }}
              icon={<Trash color={Colors.danger['500']} />}
              label="Remove Customer"
              color="danger"
            />
          </Menu>
        </div>
      </header>

      <div className="_summary">
        <InfoPiece label="Corporate Address" content={fullAddressFormatted} />
        <InfoPiece label="Website" content={customer.website} link />
        <InfoPiece
          label="Company Established Date"
          content={companyEstablishedDate}
        />
        <InfoPiece label="First Order Placed" content={'-'} />
      </div>

      <div className="_container">
        <Tabs>
          <Tab label="Contacts">
            <ContactsTable
              parent={customer}
              contacts={contacts ?? []}
              isLoading={contactsFetchStatus === 'fetching'}
              tableOptions={tableOptions}
            />
          </Tab>
          <Tab label="Accounts Payable">
            <AccountsPayable data={customer.accountPayable} />
          </Tab>
          <Tab label="Orders">
            <OrdersTable
              orders={orders ?? []}
              isLoading={ordersFetchStatus === 'fetching'}
              includeCompany={false}
            />
          </Tab>
          <Tab label="Quotes">
            <QuotesTable
              quotes={quotes ?? []}
              isLoading={quotesFetchStatus === 'fetching'}
              includeCompany={false}
            />
          </Tab>
        </Tabs>
        {showConfirmation == 'markActiveInactive' && (
          <ConfirmationModal
            title={`Mark Customer ${customer.isActive ? 'Inactive' : 'Active'}`}
            actionName={`Mark ${customer.isActive ? 'Inactive' : 'Active'}`}
            onAction={() =>
              partialUpdateMutate({ isActive: !customer.isActive })
            }
            onCancel={() => setShowConfirmation(undefined)}
            close={() => setShowConfirmation(undefined)}
          >
            Are you sure you want to mark this customer as{' '}
            {customer.isActive ? 'Inactive' : 'Active'}?
          </ConfirmationModal>
        )}
        {showConfirmation == 'delete' && (
          <ConfirmationModal
            title={'Remove Customer'}
            actionName="Remove"
            onAction={() => deleteMutate()}
            onCancel={() => setShowConfirmation(undefined)}
            close={() => setShowConfirmation(undefined)}
          >
            Are you sure you want to remove this customer?
          </ConfirmationModal>
        )}
        {showConfirmation == 'markActiveContacts' && (
          <ConfirmationModal
            title={'Mark Contacts Active'}
            actionName="Mark Active"
            onAction={() =>
              markActiveContactsMutate(selectedRows.map((id) => ({ id })))
            }
            onCancel={() => setShowConfirmation(undefined)}
            close={() => setShowConfirmation(undefined)}
          >
            Are you sure you want to mark these contacts as active?
          </ConfirmationModal>
        )}
        {showConfirmation == 'markInactiveContacts' && (
          <ConfirmationModal
            title={'Mark Contacts Inactive'}
            actionName="Mark Inactive"
            onAction={() =>
              markInactiveContactsMutate(selectedRows.map((id) => ({ id })))
            }
            onCancel={() => setShowConfirmation(undefined)}
            close={() => setShowConfirmation(undefined)}
          >
            Are you sure you want to mark these contacts as inactive?
          </ConfirmationModal>
        )}
        {showConfirmation == 'deleteContacts' && (
          <ConfirmationModal
            title={'Remove Contacts'}
            actionName="Remove"
            onAction={() =>
              deleteContactsMutate(selectedRows.map((id) => ({ id })))
            }
            onCancel={() => setShowConfirmation(undefined)}
            close={() => setShowConfirmation(undefined)}
          >
            Are you sure you want to remove these contacts?
          </ConfirmationModal>
        )}
      </div>

      {pathname.includes('edit') ? (
        <CustomerModal
          refetchCustomers={refetchCustomers}
          customer={customer}
        />
      ) : null}

      {pathname.includes('new-contact') ? (
        <ContactModal refetchContacts={refetchContacts} parent={customer} />
      ) : null}
    </div>
  );
}
