import Image from '~/src/shared/ui/icons/image.tsx';
import DragAndDrop from '~/src/shared/ui/components/dragndrop/dragndrop.tsx';
import {
  BillingMethod,
  ShippingMethod,
  ShippingService,
} from '~/src/quotes/consts.ts';
import { Select } from '~/src/shared/ui/components/select/select.tsx';
import { Input, TextArea } from '~/src/shared/ui/components';
import { Datepicker } from '~/src/shared/ui/components/datepicker/datepicker.tsx';
import { Toggle } from '~/src/shared/ui/components/toggle/toggle.tsx';
import { SymbolInput } from '~/src/shared/ui/components/input/input.tsx';
import { OrderControl } from '~/src/orders/pages/order-detail.page.tsx';
import * as React from 'react';
import { Order, OrderNote } from '~/src/orders/orders.type.ts';

export default function ShippingInfoTab(props: OrderControl) {
  const { order, update } = props;

  function onChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) {
    const value =
      event.target.type === 'checkbox'
        ? (event.target as HTMLInputElement).checked
        : event.target.value;
    const name = event.target.name;

    update({ [name]: value });
  }

  const handleNoteChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.target;
    const orderNote = {
      type: 'shipping_note',
      note: value,
    } as OrderNote;

    const payload = {
      orderNotes: [orderNote],
    } as Partial<Order>;

    update(payload);
  };

  return (
    <div className="flex flex-col gap-4">
      <div>
        <p className="text-sm text-gray-900 mb-2">Original Shipping File</p>
        <DragAndDrop
          maxFileSizeMB={10}
          acceptedFormats={['xls', 'xlsx']}
          fileName="Original Shipping"
          icon={<Image />}
        />
      </div>
      <div>
        <p className="text-sm text-gray-900 mb-2">Lucky You Shipping Files</p>
        <DragAndDrop
          maxFileSizeMB={10}
          acceptedFormats={['xls', 'xlsx']}
          fileName="Master Shipping"
          icon={<Image />}
        />
      </div>

      <div className="flex gap-4">
        <Select
          name="billingMethod"
          label="Billing Method"
          defaultValue={order.billingMethod}
          onChange={onChange}
          required
        >
          <option value="" disabled>
            Select a Billing Method
          </option>
          {Object.entries(BillingMethod).map(([key, title], index) => (
            <option key={index} value={key} className="!hover:bg-black">
              {title}
            </option>
          ))}
        </Select>

        <Input
          name="accountNumber"
          label="Account Number"
          placeholder="-"
          defaultValue={order?.accountNumber}
          onBlur={onChange}
          required
        />

        <Select
          name="shippingService"
          label="Shipping Carrier"
          defaultValue={order.shippingService}
          onChange={onChange}
          required
        >
          <option value="" disabled>
            Select a Shipping Carrier
          </option>
          {Object.entries(ShippingService).map(([key, carrier], index) => (
            <option key={index} value={key} className="!hover:bg-black">
              {carrier}
            </option>
          ))}
        </Select>

        <Select
          name="shippingMethod"
          label="Shipping Carrier"
          defaultValue={order.shippingMethod}
          onChange={onChange}
          required
        >
          <option value="" disabled>
            Select a Shipping Method
          </option>
          {Object.entries(ShippingMethod).map(([key, method], index) => (
            <option key={index} value={key} className="!hover:bg-black">
              {method}
            </option>
          ))}
        </Select>
      </div>

      <div className="flex gap-4">
        <div className="flex gap-2 w-full">
          <Input
            name="shipWidth"
            onBlur={onChange}
            label="Ship Width"
            placeholder="-"
            defaultValue={order.shipWidth}
            required
          />
          <Input
            name="shipHeight"
            onBlur={onChange}
            label="Ship Height"
            placeholder="-"
            defaultValue={order.shipHeight}
            required
          />
          <Input
            label="Ship Length"
            placeholder="-"
            defaultValue={order.shipLength}
            name="shipLength"
            onBlur={onChange}
            required
          />
        </div>
        <Input
          name="shipWeight"
          onBlur={onChange}
          label="Ship Weight"
          placeholder="-"
          defaultValue={order.shipWeight}
          required
        />
        <Input
          name="quantityPerPallet"
          onBlur={onChange}
          label="Qty Per Pallet"
          placeholder="-"
          defaultValue={order.quantityPerPallet}
        />
        <div className="w-full" />
      </div>

      <div className="flex gap-4">
        <Datepicker
          name="inStoreDate"
          label="In Store Date"
          placeholder="mm/dd/yyyy"
          defaultValue={order.inStoreDate}
          onChange={onChange}
        />
        <Datepicker
          name="shipDate"
          label="Ship Date"
          placeholder="mm/dd/yyyy"
          defaultValue={order.shipDate}
          onChange={onChange}
        />
        <div className="w-full" />
        <div className="w-full" />
      </div>

      <div className="flex gap-4 mt-4">
        <div className="w-full self-center">
          <div className="flex items-start gap-2">
            <Toggle
              name="includesInternational"
              onChange={onChange}
              defaultChecked={order.includesInternational}
            />
            <div>
              <p className="text-sm">Includes International</p>
              <p className="text-xs text-gray-500">
                If shipment includes food, must go on our account
              </p>
            </div>
          </div>
        </div>

        <div className="w-full">
          <SymbolInput
            symbol="$"
            name="shippingCharges"
            onBlur={onChange}
            label="Shipping Charges"
            placeholder="0"
            defaultValue={order.shippingCharges}
          />
          <p className="text-xs text-gray-500">
            The cost of shipping that is billable to the customer
          </p>
        </div>

        <div className="w-full">
          <Datepicker
            name="shippingReference"
            label="Shipping Reference"
            placeholder="mm/dd/yyyy"
            defaultValue={order.shippingReference}
            onChange={onChange}
          />
        </div>
        <div className="w-full" />
      </div>

      <TextArea
        name="shippingNotes"
        label="Shipping Notes"
        placeholder=""
        defaultValue={
          order?.orderNotes?.find((n) => n.type === 'shipping_note')?.note
        }
        onBlur={handleNoteChange}
      />
      <div className="flex items-center gap-2">
        <Toggle
          name="labelsPrinted"
          onChange={onChange}
          defaultChecked={order.labelsPrinted}
        />
        <p className="text-sm">Labels have been printed</p>
      </div>
      <div className="flex items-center gap-2">
        <Toggle
          name="labelsChecked"
          onChange={onChange}
          defaultChecked={order.labelsChecked}
        />
        <p className="text-sm">Labels have been checked</p>
      </div>
    </div>
  );
}
