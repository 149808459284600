import * as React from 'react';

import { clean } from '~/src/shared/ui/styles/helpers';

import './input.css';
import { CircleExclamationSolid } from '~/src/shared/ui/icons/circle-exclamation.tsx';

interface InputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  label: string;
  icon?: React.ReactElement;
  hideErrors?: boolean;
  action?: React.ReactElement;
  hideLabel?: boolean;
  invalid?: boolean;
  helpText?: string;
}

export const Input = React.forwardRef(function Input(
  props: InputProps,
  ref: React.ForwardedRef<HTMLInputElement>,
) {
  const {
    type = 'text',
    icon,
    name,
    label,
    className,
    hideErrors,
    hideLabel,
    required,
    invalid,
    helpText,
    ...rest
  } = props;

  const labelId = `${name}__label`;
  const errorId = `${name}__error`;

  return (
    <label htmlFor={name} className={clean(`_input-label ${className}`)}>
      <div
        id={labelId}
        className={clean(
          `_label-text ${required ? '_required' : ''} ${hideLabel ? 'hidden' : ''} ${invalid ? 'text-danger-500' : 'text-gray-900'}`,
        )}
      >
        {label}
      </div>
      <span className="left-3">{icon}</span>
      <input
        id={name}
        type={type}
        name={name}
        aria-describedby={errorId}
        className={clean(
          `_input ${icon ? 'pl-9' : ''} ${invalid ? 'border-danger-500' : ''}`,
        )}
        ref={ref}
        required={required}
        {...rest}
      />

      {invalid && (
        <span className="right-3">
          <CircleExclamationSolid />
        </span>
      )}

      {helpText && <div className="_help-text">{helpText}</div>}
      {!hideErrors && <div id={errorId} className="_error-message" />}
    </label>
  );
});

export const SymbolInput = React.forwardRef(function Input(
  props: InputProps & { symbol: string },
  ref: React.ForwardedRef<HTMLInputElement>,
) {
  const {
    type = 'text',
    icon,
    name,
    label,
    className,
    hideErrors,
    hideLabel,
    required,
    invalid,
    helpText,
    symbol,
    ...rest
  } = props;

  const labelId = `${name}__label`;
  const errorId = `${name}__error`;

  return (
    <label htmlFor={name} className={clean(`_input-label ${className}`)}>
      <div
        id={labelId}
        className={clean(
          `_label-text ${required ? '_required' : ''} ${hideLabel ? 'hidden' : ''} ${invalid ? 'text-danger-500' : 'text-gray-900'}`,
        )}
      >
        {label}
      </div>
      <div className="flex">
        <div
          className={clean(
            `min-w-12 bg-gray-50 rounded-l-lg flex items-center justify-center border-[1px] ${invalid ? 'border-danger-500' : 'border-gray-100'}`,
          )}
        >
          {symbol}
        </div>
        <input
          id={name}
          type={type}
          name={name}
          aria-describedby={errorId}
          className={clean(
            `_input rounded-l-none border-l-0 ${icon ? 'pl-9' : ''} ${invalid ? 'border-danger-500' : ''}`,
          )}
          ref={ref}
          required={required}
          {...rest}
        />
      </div>

      {invalid && (
        <span className="right-3">
          <CircleExclamationSolid />
        </span>
      )}

      {helpText && <div className="_help-text">{helpText}</div>}
      {!hideErrors && <div id={errorId} className="_error-message" />}
    </label>
  );
});
