import { IconProps } from '~/src/shared/ui/icons/types.ts';

export function CircleCheck(props: IconProps) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10 3.5C7.65625 3.5 5.53125 4.75 4.34375 6.75C3.1875 8.78125 3.1875 11.25 4.34375 13.25C5.53125 15.2812 7.65625 16.5 10 16.5C12.3125 16.5 14.4375 15.2812 15.625 13.25C16.7812 11.25 16.7812 8.78125 15.625 6.75C14.4375 4.75 12.3125 3.5 10 3.5ZM10 18C7.125 18 4.5 16.5 3.0625 14C1.625 11.5312 1.625 8.5 3.0625 6C4.5 3.53125 7.125 2 10 2C12.8438 2 15.4688 3.53125 16.9062 6C18.3438 8.5 18.3438 11.5312 16.9062 14C15.4688 16.5 12.8438 18 10 18ZM13.5312 8.53125L9.53125 12.5312C9.21875 12.8438 8.75 12.8438 8.46875 12.5312L6.46875 10.5312C6.15625 10.25 6.15625 9.78125 6.46875 9.5C6.75 9.1875 7.21875 9.1875 7.53125 9.5L9 10.9688L12.4688 7.5C12.75 7.1875 13.2188 7.1875 13.5312 7.5C13.8125 7.78125 13.8125 8.25 13.5312 8.53125Z"
        fill={props.color ?? 'black'}
      />
    </svg>
  );
}
