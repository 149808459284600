import * as React from 'react';
import { LocalStorage } from '~/src/shared/service/storage';
import { Logger } from '~/src/shared/service/logger';
import { paths } from '~/src/shared/api/api.schema.ts';
import { authStore } from '~/src/state/auth.store.ts';
import { AuthUser } from '~/src/luckyyou/auth.type.ts';
import { routes } from '~/src/routes.ts';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { authState } from '~/src/state/atoms/auth.atom.ts';
import { request } from '~/src/shared/api/request-config.ts';
import useUpdateEffect from '~/src/shared/hooks/use-update-effect.ts';
import useAlert from '~/src/shared/ui/components/alert/hooks/use-alert.tsx';
import { login } from '~/src/shared/ui/components/alert/models.tsx';

export function useAuthenticate() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isReady, setIsReady] = React.useState(false);
  const { token } = useRecoilValue(authState);
  const alert = useAlert();

  useUpdateEffect(() => {
    if (!token) {
      navigate(routes.login.path);
      alert.show(
        {
          title: 'Your session has expired.',
          text: 'Please, login again to continue',
          color: 'warning',
        },
        10,
      );
    }
  }, [token]);

  React.useEffect(() => {
    const getEncodedAuthToken = () =>
      new URLSearchParams(decodeURIComponent(location.search)).get(
        'auth_token',
      ) as string;

    const authToken =
      getEncodedAuthToken() ?? (LocalStorage.read('AuthToken') as string);

    if (!authToken) {
      Logger.info('Auth token not found. Redirecting to login page.');
      navigate(routes.login.path);
      setIsReady(true);
      return;
    }

    Logger.info('Performing self-info query for setting auth state.');
    const headers = { headers: { Authorization: `Bearer ${authToken}` } };
    request(paths.getSelfInfo, headers)
      .fn()
      .then((data) => {
        authStore.setAuthData(data as AuthUser, authToken);
        Logger.info('Finished self-info call and set to state.');
        navigate(routes.home.path);
        alert.show(login(), 5);
      })
      .catch((error) => {
        Logger.warn('Error on authenticating user.', error);
        navigate(routes.login.path);
      })
      .finally(() => setIsReady(true));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isReady;
}
