import Alert from '~/src/shared/ui/components/alert/alert.tsx';
import { AlertProps } from '~/src/shared/ui/components/alert/alert.type.ts';
import { useSetRecoilState } from 'recoil';
import { UIState } from '~/src/state/atoms/ui.atom.ts';

export default function useAlert() {
  const setUI = useSetRecoilState(UIState);

  function show(props: AlertProps, duration: number = 10) {
    setUI({ alert: <Alert id="alert" duration={duration} {...props} /> });
    const timer = setTimeout(() => setUI({}), duration * 1000);
    return () => clearTimeout(timer);
  }

  function showSuccess(text: string, duration: number = 10) {
    return show(
      {
        title: 'Success!',
        text: text,
        color: 'success',
      },
      duration,
    );
  }

  function showError(text: string, duration: number = 10) {
    return show(
      {
        title: 'Error!',
        text: text,
        color: 'danger',
      },
      duration,
    );
  }

  function showWarning(text: string, duration: number = 10) {
    return show(
      {
        title: 'Warning!',
        text: text,
        color: 'warning',
      },
      duration,
    );
  }

  return { show, showSuccess, showError, showWarning };
}
