import { IconProps } from '~/src/shared/ui/icons/types.ts';

export default function ElipsisVertical(props: IconProps) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10 13.5C10.5312 13.5 11 13.8125 11.2812 14.25C11.5625 14.7188 11.5625 15.3125 11.2812 15.75C11 16.2188 10.5312 16.5 10 16.5C9.4375 16.5 8.96875 16.2188 8.6875 15.75C8.40625 15.3125 8.40625 14.7188 8.6875 14.25C8.96875 13.8125 9.4375 13.5 10 13.5ZM10 8.5C10.5312 8.5 11 8.8125 11.2812 9.25C11.5625 9.71875 11.5625 10.3125 11.2812 10.75C11 11.2188 10.5312 11.5 10 11.5C9.4375 11.5 8.96875 11.2188 8.6875 10.75C8.40625 10.3125 8.40625 9.71875 8.6875 9.25C8.96875 8.8125 9.4375 8.5 10 8.5ZM11.5 5C11.5 5.5625 11.1875 6.03125 10.75 6.3125C10.2812 6.59375 9.6875 6.59375 9.25 6.3125C8.78125 6.03125 8.5 5.5625 8.5 5C8.5 4.46875 8.78125 4 9.25 3.71875C9.6875 3.4375 10.2812 3.4375 10.75 3.71875C11.1875 4 11.5 4.46875 11.5 5Z"
        fill="black"
      />
    </svg>
  );
}
