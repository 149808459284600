import * as React from 'react';

interface ArrowDownAZInterface extends React.SVGProps<SVGSVGElement> {
  inactive?: boolean;
  inverted?: boolean;
}

export default function ArrowDownAZ(props: ArrowDownAZInterface) {
  const { inactive, inverted, ...rest } = props;

  if (inverted) {
    return (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
      >
        <path
          d="M2.46875 13.7812C2.17708 13.4271 2.17708 13.0729 2.46875 12.7188C2.82292 12.4271 3.17708 12.4271 3.53125 12.7188L5.25 14.4375V3.75C5.29167 3.29167 5.54167 3.04167 6 3C6.45833 3.04167 6.70833 3.29167 6.75 3.75V14.4375L8.46875 12.7188C8.82292 12.4271 9.17708 12.4271 9.53125 12.7188C9.82292 13.0729 9.82292 13.4271 9.53125 13.7812L6.53125 16.7812C6.17708 17.0729 5.82292 17.0729 5.46875 16.7812L2.46875 13.7812L5.46875 16.7812L2.46875 13.7812ZM12 3H16C16.3125 3.02083 16.5417 3.16667 16.6875 3.4375C16.7917 3.72917 16.75 4 16.5625 4.25L13.6562 7.5H16C16.4583 7.54167 16.7083 7.79167 16.75 8.25C16.7083 8.70833 16.4583 8.95833 16 9H12C11.6875 8.97917 11.4583 8.83333 11.3125 8.5625C11.2083 8.27083 11.25 8 11.4375 7.75L14.3438 4.5H12C11.5417 4.45833 11.2917 4.20833 11.25 3.75C11.2917 3.29167 11.5417 3.04167 12 3ZM14 10.5C14.2917 10.5 14.5104 10.6354 14.6562 10.9062L17.1562 15.9062C17.3229 16.3438 17.2188 16.6771 16.8438 16.9062C16.4062 17.0729 16.0729 16.9688 15.8438 16.5938L15.4062 15.75C15.3646 15.75 15.3125 15.75 15.25 15.75H12.5938L12.1562 16.5938C11.9271 16.9688 11.5938 17.0833 11.1562 16.9375C10.7812 16.6875 10.6771 16.3438 10.8438 15.9062L13.3438 10.9062C13.4896 10.6562 13.7083 10.5208 14 10.5ZM13.3438 14.25H14.6562L14 12.9375L13.3438 14.25L14 12.9375L13.3438 14.25Z"
          fill={inactive ? '#ADB5BD' : 'black'}
        />
      </svg>
    );
  }

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M2.46875 13.7812C2.15625 13.5 2.15625 13.0312 2.46875 12.7188C2.75 12.4375 3.21875 12.4375 3.53125 12.7188L5.25 14.4688V3.75C5.25 3.34375 5.5625 3 6 3C6.40625 3 6.75 3.34375 6.75 3.75V14.4688L8.46875 12.75C8.75 12.4375 9.21875 12.4375 9.5 12.75C9.8125 13.0312 9.8125 13.5 9.5 13.7812L6.5 16.7812C6.21875 17.0938 5.75 17.0938 5.46875 16.7812L2.46875 13.7812ZM12 11H16C16.2812 11 16.5625 11.1875 16.6562 11.4688C16.7812 11.7188 16.75 12.0312 16.5312 12.25L13.6562 15.5H16C16.4062 15.5 16.75 15.8438 16.75 16.25C16.75 16.6875 16.4062 17 16 17H12C11.6875 17 11.4062 16.8438 11.3125 16.5625C11.1875 16.3125 11.2188 16 11.4375 15.7812L14.3125 12.5H12C11.5625 12.5 11.25 12.1875 11.25 11.75C11.25 11.3438 11.5625 11 12 11ZM14 3C14.2812 3 14.5312 3.1875 14.6562 3.4375L17.1562 8.4375C17.3438 8.8125 17.1875 9.25 16.8125 9.4375C16.4375 9.625 16 9.46875 15.8125 9.09375L15.375 8.25C15.3438 8.25 15.2812 8.25 15.25 8.25H12.5625L12.1562 9.09375C11.9688 9.46875 11.5312 9.625 11.1562 9.4375C10.7812 9.25 10.625 8.8125 10.8125 8.4375L13.3125 3.4375C13.4375 3.1875 13.6875 3 14 3ZM13.3125 6.75H14.6562L14 5.4375L13.3125 6.75Z"
        fill={inactive ? '#ADB5BD' : 'black'}
      />
    </svg>
  );
}
