import { IconProps } from '~/src/shared/ui/icons/types.ts';

export function TriangleExclamation(props: IconProps) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.75 4.65625L3.5625 14.8125C3.5 14.9062 3.5 14.9688 3.5 15.0625C3.5 15.3125 3.6875 15.5 3.9375 15.5H16.0312C16.2812 15.5 16.5 15.3125 16.5 15.0625C16.5 14.9688 16.4688 14.9062 16.4062 14.8125L10.2188 4.65625C10.1875 4.5625 10.0938 4.5 10 4.5C9.875 4.5 9.8125 4.5625 9.75 4.65625ZM8.46875 3.875C8.78125 3.34375 9.375 3 10 3C10.5938 3 11.1875 3.34375 11.5 3.875L17.6875 14.0312C17.875 14.3438 18 14.6875 18 15.0625C18 16.125 17.125 17 16.0312 17H3.9375C2.875 17 2 16.125 2 15.0625C2 14.6875 2.09375 14.3438 2.28125 14.0312L8.46875 3.875ZM11 13.5C11 14.0625 10.5312 14.5 10 14.5C9.4375 14.5 9 14.0625 9 13.5C9 12.9688 9.4375 12.5 10 12.5C10.5312 12.5 11 12.9688 11 13.5ZM10.75 7.75V10.75C10.75 11.1875 10.4062 11.5 10 11.5C9.5625 11.5 9.25 11.1875 9.25 10.75V7.75C9.25 7.34375 9.5625 7 10 7C10.4062 7 10.75 7.34375 10.75 7.75Z"
        fill={props.color ?? 'black'}
      />
    </svg>
  );
}
